import {
  ClassPeriodGetDto,
  ClassroomDto,
  LearningUnitOverallStatisticGetDto
} from '@aksorn/teaching-backoffice-sdk';
import React, { useMemo } from 'react';

import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { OverviewBarChart } from 'feature/common/barchart/OverviewBarChart';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

interface LearningOutcomeOverallOverviewScoreSectionProps {
  loading: boolean;
  learningUnitOverallStatistic?: LearningUnitOverallStatisticGetDto;
  classroom?: ClassroomDto;
  classPeriod?: ClassPeriodGetDto;
}

export const LearningOutcomeOverallOverviewScoreSection = observer(
  ({
    loading,
    learningUnitOverallStatistic,
    classroom,
    classPeriod
  }: LearningOutcomeOverallOverviewScoreSectionProps) => {
    const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');
    const fileName = useMemo(() => {
      if (classPeriod && classroom) {
        // return `${downloadDate}_${classroom.gradeLevel.name.th}_${classroom.classroomName}_${classPeriod.subject.name.th}`;
        return `${downloadDate}_${classroom?.gradeLevel?.name?.th}_${classroom?.classroomName}_${classPeriod?.subject?.name?.th}`;
      }
    }, [classPeriod, classroom]);

    return (
      <EvaluationContainer
        className="mb-8"
        loading={{ isLoading: !classPeriod || loading, height: 202 }}
        title={`ภาพรวมคะแนนวิชา${classPeriod?.subject?.name?.th}`}
        fileName={`${fileName}_ภาพรวมคะแนนวิชา`}
      >
        <div className="flex">
          <SquareGraphLabel
            color="#FCC88D"
            label={`คะแนนเฉลี่ย ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
          />
          <SquareGraphLabel
            color="#F99F39"
            label={`คะแนนเฉลี่ยทั้งระดับชั้น ${classroom?.gradeLevel.name.th}`}
          />
        </div>
        <OverviewBarChart
          className="mt-6 h-28"
          axisNames={[classPeriod?.subject?.name?.th || '']}
          dataSource={[
            {
              barWidth: 32,
              barColor: '#FCC88D',
              value: [
                learningUnitOverallStatistic
                  ? learningUnitOverallStatistic?.classPeriod
                      ?.numberOfSubmissions > 0
                    ? learningUnitOverallStatistic?.classPeriod?.averageScore.toFixed(
                        1
                      )
                    : ''
                  : ''
              ]
            },
            {
              barWidth: 32,
              barColor: '#F99F39',
              value: [
                learningUnitOverallStatistic
                  ? learningUnitOverallStatistic?.gradeLevel
                      ?.numberOfSubmissions > 0
                    ? learningUnitOverallStatistic?.gradeLevel?.averageScore.toFixed(
                        1
                      )
                    : ''
                  : ''
              ]
            }
          ]}
          chartMaxScore={
            learningUnitOverallStatistic?.gradeLevel?.maxScore || 100
          }
          interval={10}
          grid={{ left: 28, right: 54 }}
        />
      </EvaluationContainer>
    );
  }
);
