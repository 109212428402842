import {
  AssignmentUnitUserListResponse,
  PaginationQuery
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useAssignmentUnitUsers = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<AssignmentUnitUserListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (assignmentUnitId: string, params?: PaginationQuery) => {
    return fire({
      url: `/assignment-units/${assignmentUnitId}/users`,
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error
  };
};
