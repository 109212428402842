/** @jsx jsx */

import { MEDIA_TYPE_TEXT_MAPPER } from 'feature/media-management/media-management';
import { MediaType } from 'feature/common/media';
import { MediaTypeFilterButton } from './MediaTypeFilterButton';
import { jsx } from '@emotion/react';

interface MediaTypeFilterButtonListProps {
  mediaTypeFilter: MediaType | string;
  setMediaTypeFilter: Function;
}

export const MediaTypeFilterButtonList = ({
  mediaTypeFilter,
  setMediaTypeFilter
}: MediaTypeFilterButtonListProps) => {
  return (
    <div className="flex overflow-hidden mb-8 flex-shrink-0">
      <MediaTypeFilterButton
        isActive={mediaTypeFilter === 'all'}
        mediaType={'all'}
        setMediaTypeFilter={setMediaTypeFilter}
      >
        ทั้งหมด
      </MediaTypeFilterButton>
      {Object.values(MediaType).map((mediaType: MediaType) => {
        if (mediaType !== MediaType.VIDEO) {
          return (
            <MediaTypeFilterButton
              isActive={mediaType === mediaTypeFilter}
              mediaType={mediaType}
              setMediaTypeFilter={setMediaTypeFilter}
            >
              {MEDIA_TYPE_TEXT_MAPPER[mediaType]}
            </MediaTypeFilterButton>
          );
        }
      })}
    </div>
  );
};
