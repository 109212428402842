import { action, makeObservable, observable } from 'mobx';

import { EvaluationMenu } from 'feature/evaluation/evaluation';
import ExcelJS from 'exceljs';
import { LearningOutcomeMenu } from 'feature/assignment-overall/assignment-overall';
import { downloadEvaluationBarChart } from 'feature/evaluation/downloadEvaluationBarChart';
import { downloadFile } from 'utils/downloadFile';

export const SELECT_ALL = 'all';

export type EvaluationDownloadProps = {
  download: HTMLDivElement;
  fileName: string;
};
interface Sheet {
  buffer: ExcelJS.Buffer | null;
  fileName: string;
}

class EvaluationDownloadStore {
  evaluationDownloads: EvaluationDownloadProps[] = [];
  sheet: Sheet = { buffer: null, fileName: '' };
  isDisableDownload: boolean = false;

  constructor() {
    makeObservable(this, {
      evaluationDownloads: observable,
      sheet: observable,
      isDisableDownload: observable,
      setEvaluationDownloads: action,
      setSheet: action,
      setIsDisableDownload: action,
      clearEvaluationDownloads: action,
      downloadEvaluation: action
    });
  }

  setEvaluationDownloads = (download: HTMLDivElement, fileName: string) => {
    this.evaluationDownloads.push({
      download: download,
      fileName: fileName
    });
  };

  setSheet = ({
    buffer,
    fileName
  }: {
    buffer: ExcelJS.Buffer;
    fileName: string;
  }) => {
    this.sheet.buffer = buffer;
    this.sheet.fileName = fileName;
  };

  setIsDisableDownload = (isDisableDownload: boolean) => {
    this.isDisableDownload = isDisableDownload;
  };

  clearEvaluationDownloads = () => {
    this.evaluationDownloads = [];
    this.sheet = { buffer: null, fileName: '' };
    this.isDisableDownload = false;
  };

  downloadEvaluation = (menu: LearningOutcomeMenu | EvaluationMenu) => {
    if (menu === LearningOutcomeMenu.ASSIGNMENT) {
      if (this.sheet.buffer) {
        const data = new Blob([this.sheet.buffer], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(data);
        downloadFile({ url, fileName: `${this.sheet.fileName}.xlsx` });
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    } else {
      this.evaluationDownloads?.map(({ download, fileName }) => {
        downloadEvaluationBarChart(download, fileName);
      });
    }
  };
}

export const evaluationDownloadStore = new EvaluationDownloadStore();
