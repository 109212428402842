import { EvaluationAssignmentSortBy } from 'feature/evaluation/evaluation';
import { EvaluationPrePostTestType } from 'feature/evaluation/evaluation';

export const EVALUATION_ASSIGNMENT_SORT_MAPPER = {
  [EvaluationAssignmentSortBy.STUDENT_NUMBER]: 'เลขที่',
  [EvaluationAssignmentSortBy.NAME]: 'ชื่อ',
  [EvaluationAssignmentSortBy.SCORE_DESC]: 'คะแนน มาก - น้อย',
  [EvaluationAssignmentSortBy.SCORE_ASC]: 'คะแนน น้อย - มาก'
};

export const EVALUATION_TEST_TYPE_MAPPER = {
  [EvaluationPrePostTestType.PRE_TEST]: 'คะแนนก่อนเรียน',
  [EvaluationPrePostTestType.POST_TEST]: 'คะแนนหลังเรียน'
};
