import domtoimage from 'dom-to-image';

export const downloadEvaluationBarChart = (
  evaluation: HTMLDivElement | undefined,
  fileName: string | undefined
) => {
  if (evaluation) {
    const scale = 3;
    const style = {
      transform: 'scale(' + scale + ')',
      transformOrigin: 'top left',
      width: evaluation.offsetWidth + 'px',
      height: evaluation.offsetHeight + 'px'
    };
    const params = {
      height: evaluation.offsetHeight * scale,
      width: evaluation.offsetWidth * scale,
      quality: 1,
      style
    };

    domtoimage.toJpeg(evaluation, params).then(function (dataUrl) {
      const link = document.createElement('a');
      link.download = `${fileName}.jpg`;
      link.href = dataUrl;
      link.click();
    });
  }
};
