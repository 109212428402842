import React from 'react';

export const Footer = () => {
  return (
    <section id="landing-page-footer" className="bg-white text-font-main">
      <div className="relative flex flex-wrap w-full text-base pl-32 pr-48 md:text-sm py-8">
        <div className="w-full flex justify-between mb-0 md:mb-4 lg:mb-0">
          <div>
            <p className="mb-12">
              142 ซอยแพร่งสรรพศาสตร์ ถนนตะนาว แขวงศาลเจ้าพ่อเสือ เขตพระนคร
              <br />
              กรุงเทพมหานคร 10200
              <br />
              เวลาทำการ: จันทร์-ศุกร์ เวลา 8.30 น. – 17.30 น.
              <br />
              <br />© 2020 Aksorn Education All Rights Reserved
            </p>
            <a href="#">
              <img src="assets/facebook.png" alt="" />
            </a>
          </div>
          <div>
            <p className="mb-4">
              เกี่ยวกับเรา
              <br />
              ข้อกำหนดการใช้งาน
              <br />
              นโยบายความเป็นส่วนตัว
              <br />
              ช่วยเหลือ
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
