import { useMemo, useState } from 'react';

export const useValidatePassword = () => {
  const [password, setPassword] = useState('');

  const atLeast8Char = useMemo(() => password.length >= 8, [password]);
  const atLeast4Char = useMemo(() => password.length >= 4, [password]);
  const atLeast6Char = useMemo(() => password.length >= 6, [password]);
  const oneUppercase = useMemo(() => /[A-Z]/.test(password), [password]);
  const oneLowercase = useMemo(() => /[a-z]/.test(password), [password]);
  const oneNumber = useMemo(() => /\d/.test(password), [password]);
  const oneSpecialChar = useMemo(
    () => /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password), // eslint-disable-line no-useless-escape
    [password]
  );
  const allAlphanumeric = useMemo(() => /^[A-Za-z0-9]+$/.test(password), [
    password
  ]);
  return {
    atLeast4Char,
    atLeast6Char,
    atLeast8Char,
    oneUppercase,
    oneLowercase,
    oneNumber,
    oneSpecialChar,
    allAlphanumeric,
    setPassword
  };
};
