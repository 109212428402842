import {
  ClassPeriodListDto,
  ClassPeriodModifyLicenseUsageDto,
  ClassPeriodModifyResponse
} from '@aksorn/teaching-backoffice-sdk';
import { useEffect, useState } from 'react';

import { ClassPeriodForm } from './ClassPeriodForm';
import { ClassPeriodLicenseList } from './ClassPeriodLicenseList';
import { Drawer } from 'antd';
import React from 'react';

interface ClassPeriodDrawerProps {
  title: string;
  visible: boolean;
  classroomId: string;
  classPeriodId?: string;
  classPeriods: ClassPeriodListDto[];
  onClose: () => void;
  refetch: () => void;
  mainSchoolTeacher?: string;
}

enum ClassPeriodDrawerStep {
  BASIC_INFO = 'basic-info',
  LICENSE = 'license'
}

export const ClassPeriodDrawer = ({
  title,
  visible,
  classroomId,
  classPeriodId,
  classPeriods,
  onClose,
  refetch,
  mainSchoolTeacher
}: ClassPeriodDrawerProps) => {
  const [drawerStep, setDrawerStep] = useState(
    ClassPeriodDrawerStep.BASIC_INFO
  );
  const [classPeriodLicenseUsages, setClassPeriodLicenseUsages] = useState<
    ClassPeriodModifyLicenseUsageDto[]
  >();
  const [drawerTitle, setDrawerTitle] = useState(title);
  const [errorApplyLicense, setErrorApplyLicense] = useState<
    ClassPeriodModifyResponse | undefined
  >();

  useEffect(() => {
    setDrawerTitle(title);
    setDrawerStep(ClassPeriodDrawerStep.BASIC_INFO);
    setClassPeriodLicenseUsages(undefined);
    setErrorApplyLicense(undefined);
  }, [visible]);

  const renderDrawerBody = (step: ClassPeriodDrawerStep) => {
    switch (step) {
      case ClassPeriodDrawerStep.BASIC_INFO:
        return (
          <ClassPeriodForm
            classroomId={classroomId}
            classPeriodId={classPeriodId}
            classPeriods={classPeriods}
            onClose={() => {
              onClose();
              refetch();
            }}
            onNext={({
              classPeriodLicenseUsages,
              error
            }: {
              classPeriodLicenseUsages: ClassPeriodModifyLicenseUsageDto[];
              error?: ClassPeriodModifyResponse;
            }) => {
              refetch();
              setDrawerTitle('ตัดสื่อเข้าคาบ');
              setClassPeriodLicenseUsages(classPeriodLicenseUsages);
              setErrorApplyLicense(error);
              setDrawerStep(ClassPeriodDrawerStep.LICENSE);
            }}
            mainSchoolTeacher={mainSchoolTeacher}
          />
        );
      case ClassPeriodDrawerStep.LICENSE:
        return (
          <ClassPeriodLicenseList
            classPeriodLicenseUsages={classPeriodLicenseUsages}
            error={errorApplyLicense}
            onBack={() => {
              setDrawerStep(ClassPeriodDrawerStep.BASIC_INFO);
              setDrawerTitle(title);
            }}
            onClose={onClose}
          />
        );
      default:
        break;
    }
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      visible={visible}
      destroyOnClose={true}
      maskClosable={false}
      width={480}
      onClose={onClose}
    >
      <div className="font-bold text-xl mb-4">{drawerTitle}</div>
      <div className="mt-14">{renderDrawerBody(drawerStep)}</div>
    </Drawer>
  );
};
