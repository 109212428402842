import {
  ClassroomListQuery,
  ClassroomListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useClassrooms = () => {
  const [{ data, loading, error }, fire] = useAxios<ClassroomListResponse>(
    {
      url: '/classrooms'
    },
    {
      manual: true
    }
  );

  const execute = (params?: ClassroomListQuery) => {
    return fire({
      url: '/classrooms',
      params: {
        pageNumber: 1,
        pageSize: params?.pageSize ?? 20,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error,
    total: data?.meta?.pagination?.total || 0
  };
};

 //pun add for create classroom 28/11/22
export const useAllClassrooms = () => {
  const [{ data, loading, error }, fire] = useAxios<ClassroomListResponse>(
    {
      url: '/classrooms/all'
    },
    {
      manual: true
    }
  );

  const execute = (params?: ClassroomListQuery) => {
    return fire({
      url: '/classrooms/all',
      params: {
        // pageNumber: 1,
        // pageSize: params?.pageSize ?? 20,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error,
    total: data?.meta?.pagination?.total || 0
  };
};

export const useGradeLevelCreateClassroom = () => {
  const [{ data, loading, error }, fire] = useAxios(
    {
      url: '/classrooms/gradelevel-create-classroom'
    },
    {
      manual: true
    }
  );

  const execute = () => {
    return fire({
      url: '/classrooms/gradelevel-create-classroom',
      params: {
        // pageNumber: 1,
        // pageSize: params?.pageSize ?? 20,
       // ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error,
     total: data?.meta?.pagination?.total || 0
  };
};

export const useClassroomsLicense = () => {
  const [{ data, loading, error }, fire] = useAxios<ClassroomListResponse>(
    {
      url: '/classrooms/licenses'
    },
    {
      manual: true
    }
  );

  const execute = (params?: ClassroomListQuery) => {
    return fire({
      url: '/classrooms/licenses',
      params: {
        pageNumber: 1,
        pageSize: params?.pageSize ?? 20,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error,
    total: data?.meta?.pagination?.total || 0
  };
};
