import React, { useEffect, useState } from 'react';

import { PageWrapper } from 'feature/common/components/PageWrapper';
import { SchoolDefaultPasswordButton } from 'feature/common/components/school-default-password/SchoolDefaultPasswordButton';
import { SchoolTeacherManagement } from 'feature/teacher-management/SchoolTeacherManagement';
import { SchoolTeacherManagementType } from 'feature/teacher-management/SchoolTeacherManagementType';
import { SchoolTeacherNewTemp } from 'feature/teacher-management/SchoolTeacherNewTemp';
import { Tabs } from 'antd';
import { UserDto } from '@aksorn/one-account-sdk';
// import { SchoolTeacherRequestManagement } from 'feature/teacher-management/SchoolTeacherRequestManagement';
import { getDefaultPassword } from 'api/default-password/getDefaultPassword';
import { getMe } from 'api/user/getMe';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { userStore } from 'stores/userStore';

const { TabPane } = Tabs;

export const TeacherManagementPage = () => {
  const { token, me } = userStore;

  const [defaultPassword, setDefaultPassword] = useState<string>('');
  const [defaultPasswordLoading, setDefaultPasswordLoading] = useState<boolean>(
    true
  );
  const [onRefresh, setOnRefresh] = useState(false);

  const { data: currentSchoolYear } = useCurrentSchoolYear();

  // const [triggerUntilFindMe, setTriggerUntilFindMe] = useState<number>(0);

  // edit by Dan, 20/04/2023
  // triggered by "token" instead of "me"
  // becuase when the value of "me" is change,
  // somehow this useEffect unable to triggered by "me".
  useEffect(() => {
    handleFetchDefaultPassword();
  }, [token]);

  const handleFetchDefaultPassword = () => {
    if (me) {
      fetchDefaultPassword(me);
    } else if (token) {
      fetchMeBeforeFetchDefaultPassword();
    }
  };

  const fetchMeBeforeFetchDefaultPassword = async () => {
    try {
      const localMe = await getMe();
      fetchDefaultPassword(localMe);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchDefaultPassword = async (meData: UserDto) => {
    setDefaultPasswordLoading(true);
    const schoolId = meData?.schoolId;
    try {
      if (schoolId) {
        const { data: schoolDefaultPassword } = await getDefaultPassword({
          schoolId: schoolId
        });
        setDefaultPassword(schoolDefaultPassword.schoolDefaultPassword);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setDefaultPasswordLoading(false);
    }
  };

  return (
    <PageWrapper>
      <div className="flex justify-between">
        <h1 className="text-xl font-bold">จัดการครู</h1>
        <SchoolDefaultPasswordButton
          defaultPassword={defaultPassword}
          schoolYearId={currentSchoolYear?.schoolYearId}
          fetchDefaultPassword={handleFetchDefaultPassword}
          loading={defaultPasswordLoading}
        />
      </div>
      <SchoolTeacherNewTemp setOnRefresh={setOnRefresh} />
      <h2 className="text-basic  font-bold">{'รายการครูทั้งหมด'}</h2>
      <Tabs defaultActiveKey="1" className="mt-3.5">
        <TabPane tab="ครูทั้งหมด" key="1">
          <SchoolTeacherManagement
            onRefresh={onRefresh}
            setOnRefresh={setOnRefresh}
            defaultPassword={defaultPassword}
          />
        </TabPane>
        {/* <TabPane tab="รออนุมัติ" key="2">
          <SchoolTeacherRequestManagement />
        </TabPane> */}
        <TabPane tab="รออนุมัติ" key="3">
          <SchoolTeacherManagementType />
        </TabPane>
      </Tabs>
    </PageWrapper>
  );
};
