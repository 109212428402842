import enCommon from 'locales/en/translations.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import thCommon from 'locales/th/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enCommon
    },
    th: {
      translation: thCommon
    }
  },
  lng: 'th',
  fallbackLng: 'th',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
