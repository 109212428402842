import useAxios from 'axios-hooks';

export const useSchoolTeacherTypeReject = () => {
  const [{ loading }, fire] = useAxios<Response>(
    {
      method: 'POST'
    },
    { manual: true }
  );

  const execute = async (schoolTeacherId: string) => {
    return fire({
      url: `/school-teachers/${schoolTeacherId}/reject`
    });
  };

  return {
    loading,
    execute
  };
};
