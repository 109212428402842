import {
  ClassPeriodListQuery,
  ClassPeriodListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useClassPeriods = (params?: ClassPeriodListQuery) => {
  const [{ data, loading, error }, fire] = useAxios<ClassPeriodListResponse>(
    // TODO: load more button
    {
      url: '/class-periods',
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    },
    {
      manual: true
    }
  );

  const execute = (params: ClassPeriodListQuery) => {
    return fire({
      url: '/class-periods',
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error
  };
};
