import {
  EvaluationStatisticGetQuery,
  EvaluationStatisticGetResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useEvaluationsStatistic = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<EvaluationStatisticGetResponse>({}, { manual: true });

  const execute = (params: EvaluationStatisticGetQuery) => {
    return fire({
      url: `/evaluations/statistic`,
      params
    });
  };

  return {
    data: response?.data.subjects || [],
    loading,
    error,
    execute
  };
};

export const useEvaluationsStatisticLicense = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<EvaluationStatisticGetResponse>({}, { manual: true });

  const execute = (params: EvaluationStatisticGetQuery) => {
    return fire({
      url: `/evaluations/statistic-licenses`,
      params
    });
  };

  return {
    data: response?.data.subjects || [],
    loading,
    error,
    execute
  };
};
