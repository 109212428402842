export enum Domain {
  SCHOOL_ADMIN = 'school-admin'
}

export enum LANG {
  TH = 'th',
  EN = 'en'
}

export enum DateFormat {
  DEFAULT = 'DD/MM/YY'
}
export enum UserIdentityDocumentType {
  CITIZEN_ID = 'citizenId',
  PASSPORT_ID = 'passportId'
}
export enum SortOrder {
  ASCEND = 'ascend',
  DESCEND = 'descend'
}
export enum DayOfWeek {
  SUNDAY = 'sunday',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday'
}

export const DayOfWeekMapper = {
  [DayOfWeek.MONDAY]: 'จันทร์',
  [DayOfWeek.TUESDAY]: 'อังคาร',
  [DayOfWeek.WEDNESDAY]: 'พุธ',
  [DayOfWeek.THURSDAY]: 'พฤหัส',
  [DayOfWeek.FRIDAY]: 'ศุกร์',
  [DayOfWeek.SATURDAY]: 'เสาร์',
  [DayOfWeek.SUNDAY]: 'อาทิตย์'
};

export interface ButtonProps {
  id?: string;
  label: string;
  loading?: boolean;
  onClick: Function;
}

export const getCover = (subjectName: string) => {
  switch (subjectName) {
    case 'คณิตศาสตร์':
      return '/assets/cover-math.jpg';
    case 'ภาษาอังกฤษ':
      return '/assets/cover-english.jpg';
    case 'วิทยาศาสตร์':
      return '/assets/cover-science.jpg';
    default:
      return '/assets/class-period-cover.png';
  }
};
