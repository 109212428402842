import {
  AssignmentUnitListDto,
  AssignmentUnitListQuery,
  ClassPeriodGetDto,
  ClassroomDto,
  PrePostTestStatisticGetDto,
  PrePostTestStatisticGetQuery
} from '@aksorn/teaching-backoffice-sdk';
import React, { useEffect, useMemo, useState } from 'react';

import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { LearningOutcomePrePostTestFilterBar } from './LearningOutcomePrePostTestFilterBar';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { MenuType } from './class-period-detail';
import { OverviewBarChart } from 'feature/common/barchart/OverviewBarChart';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import dayjs from 'dayjs';
import { getAssignmentUnitPrePostTestStatistic } from 'api/assignment-units/getAssignmentUnitPrePostTestStatistic';
import { getAssignmentUnits } from 'api/assignment-units/getAssignmentUnits';
import { observer } from 'mobx-react-lite';

interface LearningOutcomePrePostTestOverviewScoreSectionProps {
  isLoading: boolean;
  classroom?: ClassroomDto;
  classPeriod?: ClassPeriodGetDto;
  assignmentUnitPrePostTestStatistic?: PrePostTestStatisticGetDto;
}

export const LearningOutcomePrePostTestOverviewScoreSection = observer(
  ({
    isLoading,
    classroom,
    classPeriod,
    assignmentUnitPrePostTestStatistic: defaultAssignmentUnitPrePostTestStatistic
  }: LearningOutcomePrePostTestOverviewScoreSectionProps) => {
    const [assignmentUnits, setAssignmentUnits] = useState<
      AssignmentUnitListDto[]
    >([]);
    const [
      assignmentUnitPrePostTestStatistic,
      setAssignmentUnitPrePostTestStatistic
    ] = useState(defaultAssignmentUnitPrePostTestStatistic);
    const [selectedAssignmentUnitId, setSelectedAssignmentUnitId] = useState(
      ''
    );

    useEffect(() => {
      if (defaultAssignmentUnitPrePostTestStatistic) {
        setAssignmentUnitPrePostTestStatistic(
          defaultAssignmentUnitPrePostTestStatistic
        );
      }
    }, [defaultAssignmentUnitPrePostTestStatistic]);

    useEffect(() => {
      if (classPeriod && classroom) {
        getInitialData({
          classroomId: classroom?.classroomId,
          // subjectId: classPeriod?.subject.subjectId
          subjectId: classPeriod?.subject?.subjectId
        });
      }
    }, [classPeriod, classroom]);

    useEffect(() => {
      if (classPeriod) {
        fetchAssignmentUnitPrePostTestStatistic({
          classPeriodId: classPeriod.classPeriodId,
          unitNo: parseInt(selectedAssignmentUnitNo)
        });
      }
    }, [selectedAssignmentUnitId, classPeriod]);

    const chartMaxScore = useMemo(() => {
      const maxScore = assignmentUnitPrePostTestStatistic
        ? Math.max(
            assignmentUnitPrePostTestStatistic?.pre.gradeLevel.maxScore,
            assignmentUnitPrePostTestStatistic?.post.gradeLevel.maxScore
          )
        : 100;
      return maxScore > 0 ? maxScore : 100;
    }, [assignmentUnitPrePostTestStatistic]);

    const getInitialData = async ({
      classroomId,
      subjectId
    }: {
      classroomId: string;
      subjectId: string;
    }) => {
      try {
        const query: AssignmentUnitListQuery = {
          classroomId: classroomId,
          subjectId: subjectId,
          menuTypeIds: MenuType.PRE_POST_TEST
        };
        const { data: assignmentUnits } = await getAssignmentUnits(query);
        setAssignmentUnits(assignmentUnits);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchAssignmentUnitPrePostTestStatistic = async ({
      classPeriodId,
      unitNo
    }: {
      classPeriodId: string;
      unitNo: number;
    }) => {
      try {
        const query: PrePostTestStatisticGetQuery = {
          classPeriodId: classPeriodId,
          unitNo: unitNo
        };
        if (selectedAssignmentUnitId) {
          const unitNo = assignmentUnits.find(
            (assignmentUnit) =>
              assignmentUnit.assignmentUnitId === selectedAssignmentUnitId
          )?.unitNo;
          Object.assign(query, { unitNo });
        }
        const {
          data: assignmentUnitPrePostTestStatistic
        } = await getAssignmentUnitPrePostTestStatistic(query);
        setAssignmentUnitPrePostTestStatistic(
          assignmentUnitPrePostTestStatistic
        );
      } catch (error) {
        console.error(error);
      }
    };

    const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

    const fileName = useMemo(() => {
      if (classPeriod && classroom) {
        return `${downloadDate}_${classroom.gradeLevel.name.th}_${classroom.classroomName}_${classPeriod.subject.name.th}`;
      }
    }, [classPeriod, classroom]);

    const selectedAssignmentUnitNo = useMemo(() => {
      const selectedAssignmentUnit =
        assignmentUnits.find(
          (unit) => unit.assignmentUnitId === selectedAssignmentUnitId
        ) || undefined;

      if (selectedAssignmentUnit) {
        return `หน่วยที่${selectedAssignmentUnit.unitNo}`;
      } else {
        return 'คะแนนรวม';
      }
    }, [selectedAssignmentUnitId]);

    return (
      <EvaluationContainer
        className="mb-8"
        loading={{
          isLoading: !classPeriod || !classroom || isLoading,
          height: 202
        }}
        title={`คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียน ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
        fileName={`${fileName}_คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียน_${selectedAssignmentUnitNo}`}
      >
        <div className="flex">
          <SquareGraphLabel
            color="#80D5FF"
            label={`คะแนนเฉลี่ยก่อนเรียน  ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
          />
          <SquareGraphLabel
            color="#59ACFF"
            label={`คะแนนเฉลี่ยหลังเรียนระดับชั้น  ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
          />
        </div>
        <div className="flex my-4">
          <LineGraphLabel
            color="#FFD159"
            label={`คะแนนเฉลี่ยก่อนเรียนระดับชั้น ${classroom?.gradeLevel.name.th}`}
            description={`${
              assignmentUnitPrePostTestStatistic?.pre.gradeLevel.averageScore.toFixed(
                1
              ) ?? '0.0'
            } คะแนน/${
              assignmentUnitPrePostTestStatistic?.pre.gradeLevel
                .numberOfSubmissions
            } คน`}
          />
          <LineGraphLabel
            color="#FFA159"
            label={`คะแนนเฉลี่ยหลังเรียนระดับชั้น ${classroom?.gradeLevel.name.th}`}
            description={`${
              assignmentUnitPrePostTestStatistic?.post.gradeLevel.averageScore.toFixed(
                1
              ) ?? '0.0'
            } คะแนน/${
              assignmentUnitPrePostTestStatistic?.post.gradeLevel
                .numberOfSubmissions
            } คน`}
          />
        </div>
        <div className="w-full h-full">
          <LearningOutcomePrePostTestFilterBar
            data={assignmentUnits}
            selectedAssignmentUnitId={selectedAssignmentUnitId}
            setSelectedAssignmentUnitId={setSelectedAssignmentUnitId}
            classPeriodId={classPeriod?.classPeriodId}
          />
          <OverviewBarChart
            className="mt-6 h-28"
            axisNames={[classPeriod?.subject?.name?.th || '']}
            dataSource={[
              {
                barName: `คะแนนเฉลี่ยก่อนเรียน ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`,
                barWidth: 32,
                barColor: '#80D5FF',
                value: [
                  assignmentUnitPrePostTestStatistic
                    ? assignmentUnitPrePostTestStatistic?.pre.classPeriod
                        .numberOfSubmissions > 0
                      ? assignmentUnitPrePostTestStatistic?.pre.classPeriod.averageScore.toFixed(
                          1
                        )
                      : ''
                    : ''
                ]
              },
              {
                barName: `คะแนนเฉลี่ยหลังเรียนระดับชั้น ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`,
                barWidth: 32,
                barColor: '#59ACFF',
                value: [
                  assignmentUnitPrePostTestStatistic
                    ? assignmentUnitPrePostTestStatistic?.post.classPeriod
                        .numberOfSubmissions > 0
                      ? assignmentUnitPrePostTestStatistic?.post.classPeriod.averageScore.toFixed(
                          1
                        )
                      : ''
                    : ''
                ]
              }
            ]}
            chartMaxScore={chartMaxScore}
            markLines={[
              {
                lineColor: '#FFD159',
                value: assignmentUnitPrePostTestStatistic
                  ? assignmentUnitPrePostTestStatistic?.pre.gradeLevel
                      .averageScore > 0
                    ? assignmentUnitPrePostTestStatistic?.pre.gradeLevel
                        .averageScore
                    : 0
                  : 0
              },
              {
                lineColor: '#FFA159',
                value: assignmentUnitPrePostTestStatistic
                  ? assignmentUnitPrePostTestStatistic?.post.gradeLevel
                      .averageScore > 0
                    ? assignmentUnitPrePostTestStatistic?.post.gradeLevel
                        .averageScore
                    : 0
                  : 0
              }
            ]}
            interval={10}
            grid={{ left: 28, right: 54 }}
          />
        </div>
      </EvaluationContainer>
    );
  }
);
