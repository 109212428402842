import {
  LearningUnitOverallStatisticGetQuery,
  LearningUnitOverallStatisticGetResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import { teachingAxios } from 'utils/axios';

export const getLearningUnitOverallStatistic = async (
  params: LearningUnitOverallStatisticGetQuery
) => {
  const {
    data: response
  } = await teachingAxios.get<LearningUnitOverallStatisticGetResponse>(
    '/learning-units/overall/statistic',
    {
      params: {
        ...pickBy(params, identity)
      }
    }
  );

  return response;
};
