import {
  ClassroomModifyResponse,
  ClassroomUpdateBody
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useUpdateClassroom = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ClassroomModifyResponse>(
    {
      method: 'PUT'
    },
    { manual: true }
  );

  const execute = ({
    classroomId,
    data
  }: {
    classroomId: string;
    data: ClassroomUpdateBody;
  }) => {
    return fire({
      url: `/classrooms/${classroomId}`,
      data
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
