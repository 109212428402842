import {
  SchoolAdminListQuery,
  SchoolAdminListResponse
} from '@aksorn/one-account-sdk';
import { identity, pickBy } from 'lodash';

import { useOneAccountAxios } from 'utils/axios';

export const useSchoolAdmins = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useOneAccountAxios<SchoolAdminListResponse>({}, { manual: true });

  const execute = (params?: SchoolAdminListQuery) => {
    return fire({
      url: `/school-admins`,
      params: {
        pageNumber: 1,
        pageSize: 20,
        ...pickBy(params, identity)
      }
    });
  };
  return {
    data: response?.data,
    loading,
    execute,
    error: error,
    total: response?.meta?.pagination?.total
  };
};
