/** @jsx jsx */
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal as ModalAntd,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  message
} from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { GhostButton, SecondaryButton } from 'feature/common/components/Button';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/react';
import {
  downloadTeacherExcel,
  downloadTeacherPdf
} from 'api/download/getTeacherExcel';
import {
  getErrorMessage,
  useDeleteSchoolTeachers
} from 'hooks/school-teacher/useDeleteSchoolTeachers';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { ReactComponent as LinkIcon } from '../../assets/link-icon.svg';
import { ManageSchoolTeacherInformationDrawer } from './ManageSchoolTeacherInformationDrawer';
import { Modal } from 'feature/common/components/Modal';
import { ProviderIcon } from 'feature/common/components/ProviderIcon';
import { ReactComponent as ResetPasswordIcon } from 'assets/reset-password.svg';
import { SchoolTeacherDto } from '@aksorn/teaching-backoffice-sdk';
import { SchoolTeacherSortBy } from 'feature/common/school-teacher';
import { SearchType } from './teacher-management';
import { SortOrder } from 'feature/common/global';
import { UserResetDefaultPasswordBody } from '@aksorn/one-account-sdk';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { getDefaultPassword } from 'api/default-password/getDefaultPassword';
import { isEmpty } from 'lodash';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useRecreateInvitationSchoolTeacher } from 'hooks/school-teacher/useRecreateInvitationSchoolTeacher';
import { useResetPassword } from 'hooks/user/useResetPassword';
import { useSchoolTeachers } from 'hooks/school-teacher/useSchoolTeachers';
import { userStore } from 'stores/userStore';

const { Option } = Select;

type Props = {
  onEdit: (id: string) => void;
  onDeleting: (id: string) => void;
  onResend: (id: string) => void;
  setSortBy: (type: SchoolTeacherSortBy | undefined) => void;
  onResetPassword: (
    userId: string,
    providerId: string,
    email: string,
    studentName: string
  ) => void;
  total: number;
  loading: boolean;
  dataSource: any;
  rowSelection: any;
};

const TeacherTable = ({
  onEdit,
  onDeleting,
  onResend,
  onResetPassword,
  total,
  setSortBy,
  dataSource,
  rowSelection,
  loading
}: Props) => {
  const columns: any = [
    {
      title: 'บัญชีผู้ใช้งาน (' + total.toString() + ')',
      // dataIndex: 'email',
      // key: 'email',
      // render: (email: string) => {
      //   // pun add temp email 12/12/22
      //   return <Fragment>{email ? email : '-'}</Fragment>;
      // }
      render: (record: SchoolTeacherDto) => {
        // console.log(record)
        return (
          <Fragment>
            {record?.isTemp === true
              ? record.email
              : record.emailAlternate
              ? record.emailAlternate
              : '-'}
          </Fragment>
        );
      }

      // อย่าลืม แก้ให้sort temp email
      // sorter: (
      //   a: SchoolTeacherDto,
      //   b: SchoolTeacherDto,
      //   sortOrder: SortOrder
      // ) => {
      //   switch (sortOrder) {
      //     case SortOrder.ASCEND:
      //       setSortBy(SchoolTeacherSortBy.EMAIL_ASC);
      //       break;
      //     case SortOrder.DESCEND:
      //       setSortBy(SchoolTeacherSortBy.EMAIL_DESC);
      //       break;
      //     default:
      //       break;
      //   }
      // }
    },
    {
      title: `รายชื่อครู (${total})`,
      dataIndex: 'teacherName',
      key: 'teacherName',
      render: (text: any, record: SchoolTeacherDto) => {
        const firstName = record.firstName.th;
        const lastName = record.lastName.th;
        return (
          <div className="flex items-center">
            <img
              src="assets/profile-colour.png"
              alt="school-teacher-profile"
              className="w-8 h-8 mr-2"
            />
            {record.title && firstName && lastName
              ? record.title + firstName + ' ' + lastName
              : '-'}
          </div>
        );
      },
      sorter: (
        a: SchoolTeacherDto,
        b: SchoolTeacherDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolTeacherSortBy.NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolTeacherSortBy.NAME_DESC);
            break;
          default:
            break;
        }
      }
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
      sorter: (
        a: SchoolTeacherDto,
        b: SchoolTeacherDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolTeacherSortBy.EMAIL_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolTeacherSortBy.EMAIL_DESC);
            break;
          default:
            break;
        }
      },
      render: (email: string, record: SchoolTeacherDto) => {
        return (
          <Fragment>
            {record.isTemp === false ? (
              <div className="flex items-center">
                <ProviderIcon
                  providerName={record?.provider?.name}
                  className="mr-2 w-5 h-5"
                />{' '}
                <div className="my-0">{email}</div>
              </div>
            ) : (
              // : record.emailAlternate
              //   ? record.emailAlternate
              '-'
            )}
          </Fragment>
        );
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'emailVerifiedAt',
      key: 'emailVerifiedAt',
      render: (verifiedAt: string, record: SchoolTeacherDto) => (
        <div className="flex space-x-2 items-left justify-left">
          <div
            className={classNames([
              {
                'text-status-success': verifiedAt,
                'text-status-waiting': !verifiedAt
              }
            ])}
          >
            {verifiedAt ? 'ใช้งานได้' : 'รอยืนยันตัวตน'}
          </div>
          {!verifiedAt ? (
            <LinkIcon
              title="ส่ง Link ยืนยันตัวตนใหม่"
              className="cursor-pointer"
              onClick={() => onResend(record.schoolTeacherId)}
            />
          ) : (
            ''
          )}
        </div>
      )
    },
    {
      title: 'แก้ไขล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string) => (
        <div>{dayjs(updatedAt).add(543, 'year').format('DD/MM/YYYY')}</div>
      ),
      sorter: (
        a: SchoolTeacherDto,
        b: SchoolTeacherDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolTeacherSortBy.UPDATED_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolTeacherSortBy.UPDATED_AT_DESC);
            break;
          default:
            break;
        }
      }
    },
    {
      title: 'ใช้งานล่าสุด',
      dataIndex: 'lastLoggedInAt',
      key: 'lastLoggedInAt',
      render: (lastLoggedInAt: string) => (
        <div>
          {lastLoggedInAt == null
            ? ''
            : dayjs(lastLoggedInAt).add(543, 'year').format('DD/MM/YYYY')}
        </div>
      ),
      sorter: (
        a: SchoolTeacherDto,
        b: SchoolTeacherDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolTeacherSortBy.LAST_LOGGEDIN_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolTeacherSortBy.LAST_LOGGEDIN_AT_DESC);
            break;
          default:
            break;
        }
      }
    },
    {
      title: '',
      render: (record: SchoolTeacherDto) => (
        <div className="flex space-x-8 items-center justify-center">
          {/* {record.emailVerifiedAt ? ( */}
          {true ? (
            <ResetPasswordIcon
              title="Reset Password"
              className={
                !record.provider.isSocial
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed'
              }
              css={css`
                ${!record.provider.isSocial ? '' : `path { fill: #e3e4e8; }`}
              `}
              onClick={() => {
                if (!record.provider.isSocial) {
                  onResetPassword(
                    record?.userId,
                    record?.provider.providerId,
                    record?.email,
                    `${record?.title ? record?.title : ''}${
                      record?.firstName?.th ? record?.firstName?.th : ''
                    } ${record?.lastName?.th ? record?.lastName?.th : ''}`
                  );
                }
              }}
            />
          ) : (
            <div className="w-3 h-4" />
          )}
          {/* <div className="w-3 h-4" /> */}
          {/* {!record.emailVerifiedAt ? ( */}
          {false ? (
            // <EditIcon
            //   className="cursor-pointer"
            //   onClick={() => onEdit(record.schoolTeacherId)}
            // />
            <EditIcon
              className="cursor-not-allowed"
              css={css`
                path {
                  fill: #e3e4e8;
                }
              `}
            />
          ) : (
            <div className="w-4 h-4" />
          )}
          {/* <div className="w-4 h-4" /> */}
          <DeleteIcon
            // className={
            //   !!record.isTemp ? 'cursor-pointer' : 'cursor-not-allowed'
            // }
            // css={css`
            //   ${!!record.isTemp ? '' : `path { fill: #e3e4e8; }`}
            // `}
            className={'cursor-pointer'}
            onClick={() => {
              // if (!!record.isTemp) {
              onDeleting(record.schoolTeacherId);
              // }
            }}
          />
        </div>
      )
    }
  ];
  return (
    <Table
      rowKey="schoolTeacherId"
      loading={loading}
      pagination={false}
      rowSelection={rowSelection}
      dataSource={dataSource}
      columns={columns as any}
      onChange={(pagination: any, filters: any, sorter: any) => {
        if (!sorter.order) setSortBy(undefined);
      }}
    />
  );
};
interface SchoolTeacherManagementProps {
  setOnRefresh: Function;
  onRefresh: boolean;
  defaultPassword: string;
}
export const SchoolTeacherManagement = ({
  setOnRefresh,
  onRefresh,
  defaultPassword
}: SchoolTeacherManagementProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showManageTeacherDrawer, setShowManageTeacherDrawer] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteManyModal, setOpenDeleteManyModal] = useState(false);
  const [openResendModal, setOpenResendModal] = useState(false);
  const [selectedSchoolTeacherId, setSelectedSchoolTeacherId] = useState('');
  const [type, setType] = useState<SearchType>(SearchType.NAME);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState<SchoolTeacherSortBy>();
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedTeacherName, setSelectedTeacherName] = useState('');
  const [selectedTeacherUserId, setSelectedTeacherUserId] = useState('');
  const [selectedTeacherProviderId, setSelectedTeacherProviderId] = useState(
    ''
  );
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [isDocDownload, setIsDocDownload] = useState(false);

  const { me } = userStore;
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const { execute: deleteSchoolTeachers } = useDeleteSchoolTeachers();
  const {
    data: teachers,
    loading,
    total,
    execute: getSchoolTeachers
  } = useSchoolTeachers();
  const {
    execute: recreateInvitationSchoolTeacher
  } = useRecreateInvitationSchoolTeacher();
  const {
    execute: resetPassword,
    loading: resetPasswordLoading
  } = useResetPassword();

  useEffect(() => {
    getSchoolTeachers({
      [type]: keyword,
      sortBy: sortBy as any,
      pageNumber: currentPage
    });
  }, [keyword, sortBy, currentPage]);

  useEffect(() => {
    if (onRefresh) {
      getSchoolTeachers({
        [type]: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
      setOnRefresh(false);
    }
  }, [onRefresh]);

  const [form] = Form.useForm();

  const rowSelection = {
    selectedRowKeys,
    onChange: (selected: any) => {
      setSelectedRowKeys(selected);
    }
  };

  const handleResendUserInvite = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await recreateInvitationSchoolTeacher(selectedSchoolTeacherId);
      message.success('ส่ง Link ยืนยันตัวตนสำเร็จ');
      setSelectedSchoolTeacherId('');
      setOpenResendModal(false);
    } catch (error) {
      message.error('ส่ง Link ยืนยันตัวตนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleDeleteTeacher = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await deleteSchoolTeachers([selectedSchoolTeacherId]);
      message.success('ลบคุณครูสำเร็จ');
      setSelectedSchoolTeacherId('');
      setOpenDeleteModal(false);
      getSchoolTeachers({
        [type]: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
    } catch (error) {
      message.error(getErrorMessage((error as any).message));
    } finally {
      hide();
    }
  };

  const handleDeleteManyTeachers = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await deleteSchoolTeachers(selectedRowKeys);
      message.success('ลบคุณครูสำเร็จ');
      setOpenDeleteManyModal(false);
      getSchoolTeachers({
        [type]: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
    } catch (error) {
      message.error(getErrorMessage((error as any).message));
    } finally {
      hide();
    }
  };

  const handleResetPassword = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      const data = {
        userId: selectedTeacherUserId,
        providerId: selectedTeacherProviderId
      } as UserResetDefaultPasswordBody;
      await resetPassword({ data });
      message.success('Reset Password สำเร็จ');
      setOpenResetPasswordModal(false);
      setSelectedTeacherName('');
      setSelectedEmail('');
      setSelectedTeacherProviderId('');
      setSelectedTeacherUserId('');
    } catch (error) {
      message.error('Reset Password ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleSearch = () => {
    setKeyword(searchText);
    setCurrentPage(1);
  };

  const handleTeacherDownloadExcel = async () => {
    const schoolId = me?.schoolId;
    if (schoolId && currentSchoolYears) {
      setIsDocDownload(true);
      try {
        await downloadTeacherExcel(
          schoolId,
          currentSchoolYears.schoolYearId,
          currentSchoolYears.name
        );
      } catch (error) {
        console.log(error);
      }
      setIsDocDownload(false);
    }
  };

  const handleTeacherDownloadPdf = async () => {
    const schoolId = me?.schoolId;
    if (schoolId && currentSchoolYears) {
      setIsDocDownload(true);
      try {
        await downloadTeacherPdf(
          schoolId,
          currentSchoolYears.schoolYearId,
          currentSchoolYears.name
        );
      } catch (error) {
        console.log(error);
      }
    }
    setIsDocDownload(false);
  };

  const handleTeacherDownloadAll = async () => {
    const schoolId = me?.schoolId;
    if (schoolId && currentSchoolYears) {
      setIsDocDownload(true);
      try {
        await downloadTeacherPdf(
          schoolId,
          currentSchoolYears.schoolYearId,
          currentSchoolYears.name
        );
      } catch (error) {
        console.log(error);
      }
      try {
        await downloadTeacherExcel(
          schoolId,
          currentSchoolYears.schoolYearId,
          currentSchoolYears.name
        );
      } catch (error) {
        console.log(error);
      }
      setIsDocDownload(false);
    }
  };

  return (
    <div className="pt-6">
      {/* <div className="pt-7" >
        <h2 className="text-xl font-bold">{"Account ครู"}</h2>
        <div className="bg-white border border-secondary-gray-10"
          css={css`
         `}
        >
          <Row
            gutter={8}
            className="mt-4 mb-4 mx-4"
          >
            <Col span={10}>
              <div className="text-base font-bold">ตำแหน่ง</div>
            </Col>
            <Col
              span={4}
              className="relative"
              css={css`
                                                    top: 10px;
                                                    `}
            >

            </Col>
            <Col span={5}>
              <div className="text-base font-bold ">จำนวนครู</div>
            </Col>
            <Col
              span={5}
              // className="relative "
              css={css`
                             top: 10px;
                             `}
            >

            </Col>

          </Row>
          <Divider
            className="my-0" />
          <Row
            gutter={8}
            className="mt-4 mb-4 mx-4"
            css={css``}
          // key={classroom.classroomId}
          >
            <Col span={10}
              className="flex items-center text-center"
            >
              <p className="font-bold text-left">{"ครู"}</p>

            </Col>
            <Col
              span={4}
              // className="relative "
              css={css`
                             top: 10px;
                             `}
            >

            </Col>
            <Col span={5}

              //   className=" justify-center items-center"
              //className="my-0"
              className="flex items-center text-center"
            >

              <Input className="rounded-xl h-10"

                value={numOfNewTeacherTemp}

                onChange={(e) => {
                  console.log("จำนวนครู" + " = " + e.target.value)
                  setNumOfNewTeacherTemp(parseInt(e.target.value))
                  //console.log(e.target.value)
                  //setNumberOfClassroomStudent(classroom.classroomId, e.target.value);
                }}
                placeholder="จำนวนครู" />
            </Col>

            <Col
              span={5}
              // className="relative "
              css={css`
                             top: 10px;
                             `}
            >

            </Col>
          </Row>
          <Divider className='my-0' />

        </div>
        <Row className="my-4">
          <Col span={20}></Col>
          <Col span={4} className="flex justify-end items-right">
            <Button
              className="flex items-center text-base border-none font-bold text-white"
              css={css`
                            background-color: #f99d34;
                            :hover {
                                background-color: #fcbd10;
                            }
                            height: 40px;
                            border-radius: 10px;
                            `}
              onClick={handleGenTeacherTemp}>
              บันทึกและ Gen Temp E-mail
            </Button>

          </Col>
        </Row >
      </div> */}

      <div className="flex items-center">
        {/* <Form form={form}>
          <div className="flex items-center"> */}
        {/* <Form.Item label="ประเภท">
              <Select
                css={css`
                  .ant-select-selector {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                  }
                `}
                value={type}
                onChange={(value: SearchType) => setType(value)}
              >
                <Option value={SearchType.NAME}>รายชื่อครู</Option>
                <Option value={SearchType.EMAIL}>One Account</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Input
                className="rounded-l-none w-72"
                placeholder="ค้นหาจากรายชื่อครู"
                suffix={<SearchOutlined onClick={handleSearch} />}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onPressEnter={handleSearch}
              />
            </Form.Item> */}
        {/* <div className="flex items-center">
              <Form.Item>
                <Input
                  className="w-72"
                  placeholder="ค้นหาจากรายชื่อครู"
                  suffix={<SearchOutlined onClick={handleSearch} />}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  onPressEnter={handleSearch}
                />
              </Form.Item>
              </div>

          </div>

        </Form> */}
      </div>
      <div className="flex justify-between items-center  mt-2 mb-6">
        <Form.Item>
          <Input
            className="w-72"
            placeholder="ค้นหาจากรายชื่อครู"
            suffix={<SearchOutlined onClick={handleSearch} />}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onPressEnter={handleSearch}
          />
        </Form.Item>
        {/* <DeleteIcon
          className="cursor-pointer"
          onClick={() => {
            if (isEmpty(selectedRowKeys)) return;
            setOpenDeleteManyModal(true);
          }}
        /> */}
        {/* <Button
          className="flex justify-between items-center rounded-lg border-none font-bold text-white"
          css={css`
            background-color: #fc9d10;
            :hover {
              background-color: #fcbd10;
            }
          `}
          onClick={() => setShowManageTeacherDrawer(true)}
        >
          + เพิ่ม
        </Button> */}
        <div className="flex items-center justify-end">
          <div className="ml-4">
            <Form.Item>
              <GhostButton
                className="flex  rounded-lg border-none font-bold "
                css={css`
                  /* background-color: #333333; */
                  /* :hover {
                    background-color: #000000;
                  } */
                  /* color: selectedRowKeys.length === 0 ?#C7C8D1:#333333; */
                `}
                disabled={selectedRowKeys.length === 0}
                onClick={() => {
                  if (isEmpty(selectedRowKeys)) return;
                  setOpenDeleteManyModal(true);
                }}
              >
                <DeleteIcon className="mr-2" /> ลบครูออกจากโรงเรียน
              </GhostButton>
            </Form.Item>
          </div>
          <div className="ml-4">
            <Form.Item>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      className="px-4 py-2 text-base text-secondary-gray-60"
                      onClick={() => {
                        handleTeacherDownloadAll();
                      }}
                      css={css`
                        white-space: normal;
                        width: 240px;
                      `}
                    >
                      ดาวน์โหลดไฟล์ท้ังหมด
                    </Menu.Item>{' '}
                    <Menu.Item
                      className="px-4 py-2 text-base text-secondary-gray-60"
                      onClick={() => {
                        handleTeacherDownloadExcel();
                      }}
                      css={css`
                        white-space: normal;
                        width: 240px;
                      `}
                    >
                      ดาวน์โหลดเฉพาะใบรายการ บัญชีผู้ใช้งาน (ไฟล์ Excel)
                    </Menu.Item>
                    <Menu.Item
                      className="px-4 py-2 text-base text-secondary-gray-60"
                      onClick={() => {
                        handleTeacherDownloadPdf();
                      }}
                      css={css`
                        white-space: normal;
                        width: 240px;
                      `}
                    >
                      ดาวน์โหลดเฉพาะบัตรประจำ ตัวบัญชีผู้ใช้งาน (ไฟล์ PDF)
                    </Menu.Item>
                  </Menu>
                }
              >
                <GhostButton
                  className="flex rounded-lg border-none font-bold text-black"
                  // css={css`
                  //   background-color: #fc9d10;
                  //   :hover {
                  //     background-color: #fcbd10;
                  //   }
                  // `}
                  // onClick={() => handleTeacherDownload()}
                >
                  <DownloadIcon className="mr-2" />
                  ดาวน์โหลดบัญชีผู้ใช้งาน
                </GhostButton>
              </Dropdown>
            </Form.Item>
          </div>
        </div>
      </div>
      <TeacherTable
        dataSource={teachers}
        loading={loading || resetPasswordLoading}
        onDeleting={(id) => {
          setSelectedSchoolTeacherId(id);
          setOpenDeleteModal(true);
        }}
        onEdit={(id) => {
          setSelectedSchoolTeacherId(id);
          setShowManageTeacherDrawer(true);
        }}
        onResend={(id) => {
          setSelectedSchoolTeacherId(id);
          setOpenResendModal(true);
        }}
        onResetPassword={(
          userId: string,
          providerId: string,
          email: string,
          teacherName: string
        ) => {
          //console.log(teacherName)
          setSelectedEmail(email);
          if (!teacherName) {
            setSelectedTeacherName('');
          } else {
            setSelectedTeacherName(teacherName);
          }
          setSelectedTeacherName(teacherName);
          setSelectedTeacherUserId(userId);
          setSelectedTeacherProviderId(providerId);
          setOpenResetPasswordModal(true);
        }}
        setSortBy={setSortBy}
        rowSelection={rowSelection}
        total={total}
      />
      {total > 0 && (
        <Pagination
          defaultPageSize={20}
          current={currentPage}
          showSizeChanger={false}
          total={total}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
      <ManageSchoolTeacherInformationDrawer
        title={selectedSchoolTeacherId ? 'แก้ไขครู' : 'เพิ่มครู'}
        visible={showManageTeacherDrawer}
        schoolTeacherId={selectedSchoolTeacherId}
        onSubmit={() => {
          getSchoolTeachers({
            [type]: keyword,
            sortBy: sortBy as any,
            pageNumber: currentPage
          });
          setShowManageTeacherDrawer(false);
          setSelectedSchoolTeacherId('');
        }}
        onClose={() => {
          setShowManageTeacherDrawer(false);
          setSelectedSchoolTeacherId('');
        }}
      />
      <Modal
        title="ยืนยันการนำออกจากระบบ"
        subTitle="กรุณายืนยันเพื่อลบครูออก"
        cancel={{
          onClick: () => {
            setOpenDeleteModal(false);
            setSelectedSchoolTeacherId('');
          },
          label: 'ยกเลิก'
        }}
        submit={{
          onClick: handleDeleteTeacher,
          label: 'ยืนยัน'
        }}
        visible={openDeleteModal}
      />
      <Modal
        title="ยืนยันการนำออกจากระบบ"
        subTitle="กรุณายืนยันเพื่อลบครูออก"
        cancel={{
          onClick: () => setOpenDeleteManyModal(false),
          label: 'ยกเลิก'
        }}
        submit={{
          onClick: handleDeleteManyTeachers,
          label: 'ยืนยัน'
        }}
        visible={openDeleteManyModal}
      />
      <Modal
        title="ยืนยันการส่ง Link ยืนยันตัวตนใหม่"
        subTitle="กรุณายืนยันเพื่อส่ง Link ยืนยันตัวตนใหม่"
        cancel={{
          onClick: () => setOpenResendModal(false),
          label: 'ยกเลิก'
        }}
        submit={{
          onClick: handleResendUserInvite,
          label: 'ยืนยัน'
        }}
        visible={openResendModal}
      />
      <Modal
        title="ยืนยันการรีเซ็ตรหัสผ่าน?"
        subTitle={`หากกดยืนยัน รหัสผ่านของ ${selectedTeacherName} (${selectedEmail}) จะถูกรีเซ็ตเป็น 
          ${defaultPassword}`}
        cancel={{
          onClick: () => setOpenResetPasswordModal(false),
          label: 'ยกเลิก'
        }}
        submit={{
          onClick: handleResetPassword,
          label: 'ยืนยัน'
        }}
        visible={openResetPasswordModal}
      />
      {/* <Modal
        title="กำลังดาวน์โหลดเอกสาร"
        visible={isDocDownload}
        width={400}
        //onCancel={() => setIsSubmitVisible(false)}
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      /> */}
      <ModalAntd
        visible={isDocDownload}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2 h-20">
          <p className="text-semibold text-xl mb-2">กำลังดาวน์โหลดเอกสาร</p>
          <p>
            <Spin spinning={isDocDownload}></Spin>
          </p>
        </div>
      </ModalAntd>
    </div>
  );
};
