import { ImportExcelErrorMessage } from 'feature/dashboard/dashboard';
export const importExcelErrorMapper: {
  [key: string]: string;
} = {
  [ImportExcelErrorMessage.SUBJECT_INVALID]: 'ข้อมูลวิชาไม่ถูกต้อง',
  [ImportExcelErrorMessage.SUBJECT_NOT_FOUND]: 'ข้อมูลวิชาไม่ถูกต้อง',
  [ImportExcelErrorMessage.SUBJECT_CONFLICTED]:
    'ไม่พบวิชาเรียนนี้ใน Worksheet ส่วนของวิชาเรียน',
  [ImportExcelErrorMessage.DAY_OF_WEEK_INVALID]: 'วันที่เรียนไม่ถูกต้อง',
  [ImportExcelErrorMessage.START_TIME_INVALID]: 'เวลาเริ่มเรียนไม่ถูกต้อง ',
  [ImportExcelErrorMessage.END_TIME_INVALID]: 'เวลาสิ้นสุดไม่ถูกต้อง',
  [ImportExcelErrorMessage.END_TIME_CONFLICTED]: 'เวลาคาบเรียนไม่ถูกต้อง',
  [ImportExcelErrorMessage.SCHEDULE_OVERLAP]:
    'เวลาคาบเรียนในห้องต้องไม่ทับซ้อนกัน',

  [ImportExcelErrorMessage.SCHOOL_YEAR_INVALID]: 'ข้อมูลปีการศึกษาไม่ถูกต้อง',
  [ImportExcelErrorMessage.SCHOOL_YEAR_NOT_FOUND]: 'ข้อมูลปีการศึกษาไม่ถูกต้อง',
  [ImportExcelErrorMessage.GRADE_LEVEL_INVALID]: 'ข้อมูลระดับขั้นไม่ถูกต้อง',
  [ImportExcelErrorMessage.GRADE_LEVEL_NOT_FOUND]: 'ข้อมูลระดับขั้นไม่ถูกต้อง',
  [ImportExcelErrorMessage.CLASSROOM_NAME_INVALID]:
    'ข้อมูลชื่อห้องเรียนไม่ถูกต้อง',

  [ImportExcelErrorMessage.EMAIL_INVALID]: 'ข้อมูลอีเมลไม่ถูกต้อง',
  [ImportExcelErrorMessage.EMAIL_DUPLICATE]: 'ข้อมูลอีเมลไม่ถูกต้อง',
  [ImportExcelErrorMessage.TITLE_INVALID]: 'ข้อมูลคำนำหน้าชื่อไม่ถูกต้อง',
  [ImportExcelErrorMessage.FIRSTNAME_TH_INVALID]: 'ข้อมูลชื่อไม่ถูกต้อง',
  [ImportExcelErrorMessage.LASTNAME_TH_INVALID]: 'ข้อมูลนามสกุลไม่ถูกต้อง',
  [ImportExcelErrorMessage.FIRSTNAME_EN_INVALID]: 'ข้อมูลชื่อไม่ถูกต้อง',
  [ImportExcelErrorMessage.LASTNAME_EN_INVALID]: 'ข้อมูลนามสกุลไม่ถูกต้อง',
  [ImportExcelErrorMessage.CITIZEN_ID_INVALID]:
    'ข้อมูลเลขบัตรประชาชนไม่ถูกต้อง',
  [ImportExcelErrorMessage.PASSPORT_NUMBER_INVALID]:
    'ข้อมูลหมายเลขหนังสือเดินทางไม่ถูกต้อง',
  [ImportExcelErrorMessage.IDENTITY_DOCUMENT_REQUIRE]:
    'ระบุข้อมูลเลขบัตรประชาชนหรือเลขหนังสือเดินทางอย่างน้อยอย่างใดอย่างนึง',
  [ImportExcelErrorMessage.GENDER_INVALID]: 'ข้อมูลเพศไม่ถูกต้อง',
  [ImportExcelErrorMessage.DATE_OF_BIRTH_INVALID]: 'ข้อมูลปีเกิดไม่ถูกต้อง',
  [ImportExcelErrorMessage.TEACHER_STATUS_INVALID]: 'ข้อมูลสถานะครูไม่ถูกต้อง',
  [ImportExcelErrorMessage.TEACHER_PROFILE_CONFLICT]:
    'ข้อมูลรายละเอียดของบัญชีครู ในแต่ละจุดไม่ตรงกัน',

  [ImportExcelErrorMessage.CLASSROOM_EMPTY]: 'ไม่พบข้อมูลห้องเรียน',
  [ImportExcelErrorMessage.CLASSROOM_MORE_THAN_ONE]:
    'มีการระบุห้องเรียนเกินกว่า 1 ห้อง',
  [ImportExcelErrorMessage.CLASSROOM_MAIN_TEACHER_NOT_IN_TEACHER_LIST]:
    'ครูประจำชั้นหลักต้องเป็นครูประจำชั้น',
  [ImportExcelErrorMessage.CLASSROOM_MAIN_TEACHER_EMPTY]:
    'ห้องเรียนนี้ยังไม่มีครูประจำชั้นหลัก',
  [ImportExcelErrorMessage.CLASSROOM_MAIN_TEACHER_MULTIPLE]:
    'มีการระบุครูประจำชั้นหลักเกินกว่า 1 ท่าน',
  [ImportExcelErrorMessage.CLASSROOM_TEACHER_EMPTY]:
    'ต้องมีครูประจำชั้นอย่างน้อย 1 ท่าน',

  [ImportExcelErrorMessage.CLASS_PERIOD_MAIN_TEACHER_NOT_IN_TEACHER_LIST]:
    'ครูประจำวิชาหลักต้องเป็นครูประจำวิชา',
  [ImportExcelErrorMessage.CLASS_PERIOD_MAIN_TEACHER_EMPTY]:
    'วิชาเรียนนี้ยังไม่มีครูประจำวิชาหลัก',
  [ImportExcelErrorMessage.CLASS_PERIOD_MAIN_TEACHER_MULTIPLE]:
    'มีการระบุครูประจำวิชาหลักเกินกว่า 1 ท่าน',
  [ImportExcelErrorMessage.CLASS_PERIOD_TEACHER_EMPTY]:
    'ต้องมีครูประจำวิชาอย่างน้อย 1 ท่าน',

  [ImportExcelErrorMessage.CLASSROOM_STUDENT_NUMBER_INVALID]:
    'ข้อมูลเลขที่ไม่ถูกต้อง',
  [ImportExcelErrorMessage.STUDENT_CODE_INVALID]:
    'ข้อมูลรหัสประจำตัวไม่ถูกต้อง',

  [ImportExcelErrorMessage.ACCOUNT_NOT_TEACHER]:
    'ตรวจพบบัญชี อีเมล นี้ไม่ได้เป็นบัญชีครู',
  [ImportExcelErrorMessage.ACCOUNT_ALREADY_IN_OHTER_SCHOOL]:
    'ตรวจพบบัญชี อีเมล นี้เป็นบัญชีครูหรือนักเรียนที่โรงเรียนอื่น',
  [ImportExcelErrorMessage.ACCOUNT_HAS_PENDING_TEACHER_REQUEST]:
    'บัญชีนี้อยู่ในสถานะรออนุมัติคำขอเข้าเป็นคุณครูโรงเรียน ไม่สามารถนำเข้าโรงเรียนได้',

  [ImportExcelErrorMessage.ACCOUNT_NOT_STUDENT]:
    'ตรวจพบบัญชี อีเมล นี้ไม่ได้เป็นบัญชีนักเรียน',

  [ImportExcelErrorMessage.TEACHER_PRIVATE_NOT_SHARE_ANYONE]:
    'ท่านไม่สามารถใช้ข้อมูลของครูท่านอื่นได้'
};
