/** @jsx jsx */
import { Divider, Form, Modal, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { PageWrapper } from 'feature/common/components/PageWrapper';
import { SchoolDefaultPasswordButton } from 'feature/common/components/school-default-password/SchoolDefaultPasswordButton';
import { StudentListTable } from 'feature/student-management/StudentListTable';
import { getDefaultPassword } from 'api/default-password/getDefaultPassword';
import { jsx } from '@emotion/react';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';
import { userStore } from 'stores/userStore';

const { Option } = Select;
export const StudentManagementPage = () => {
  const { me } = userStore;

  const [schoolYear, setSchoolYear] = useState<string>('');
  const { data: schoolYears, loading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<string>('');
  const [defaultPassword, setDefaultPassword] = useState<string>('');
  const [defaultPasswordLoading, setDefaultPasswordLoading] = useState<boolean>(
    true
  );

  useEffect(() => {
    if (schoolYears && currentSchoolYears) {
      setSchoolYear(currentSchoolYears.schoolYearId);
      setSelectedSchoolYearId(currentSchoolYears.schoolYearId);
    }
  }, [schoolYears, currentSchoolYears]);

  useEffect(() => {
    if (me) {
      fetchDefaultPassword();
    }
  }, [me]);

  const fetchDefaultPassword = async () => {
    setDefaultPasswordLoading(true);
    const schoolId = me?.schoolId;
    try {
      if (schoolId) {
        const { data: schoolDefaultPassword } = await getDefaultPassword({
          schoolId: schoolId
        });
        setDefaultPassword(schoolDefaultPassword.schoolDefaultPassword);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDefaultPasswordLoading(false);
    }
  };

  return (
    <PageWrapper>
      <Spin spinning={loading}>
        <div className="flex w-full justify-between">
          <h1 className="text-xl font-bold">จัดการนักเรียน</h1>
          <div className="flex">
            <SchoolDefaultPasswordButton
              defaultPassword={defaultPassword}
              schoolYearId={selectedSchoolYearId}
              fetchDefaultPassword={fetchDefaultPassword}
              loading={defaultPasswordLoading}
              className="mr-4"
            />
            <Form>
              <Form.Item label="ปีการศึกษา">
                {/* <Select
                placeholder="ปีการศึกษา"
                value={schoolYear}
                onSelect={(value: string) => setSchoolYear(value)}
              >
                {schoolYears?.map(({ schoolYearId, name }) => (
                  <Option value={schoolYearId} key={schoolYearId}>
                    {name}
                  </Option>
                ))}
              </Select> */}
                <Select
                  value={selectedSchoolYearId}
                  onSelect={(schoolYearId) => {
                    setSelectedSchoolYearId(schoolYearId);
                  }}
                >
                  {schoolYears?.map(({ schoolYearId, name }) => (
                    <Option
                      key={schoolYearId}
                      value={schoolYearId}
                      disabled={schoolYearId !== selectedSchoolYearId}
                    >
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Divider />
        <StudentListTable
          schoolYearId={schoolYear}
          defaultPassword={defaultPassword}
        />
      </Spin>
    </PageWrapper>
  );
};
