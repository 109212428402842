import React, { useEffect } from 'react';

import { AssigmentInfoSkeleton } from './AssigmentInfoSkeleton';
import { AssignmentInfo } from './AssignmentInfo';
import { Modal } from 'antd';
import { SecondaryButton } from 'feature/common/components/Button';
import { useAssignment } from 'hooks/assignments/useAssignment';

interface AssignmentInfoModalProps {
  visible: boolean;
  assignmentId: string | undefined;
  onClose: () => void;
}

interface FooterProps {
  onClose: () => void;
}

export const AssignmentInfoModal = ({
  visible,
  assignmentId,
  onClose
}: AssignmentInfoModalProps) => {
  const {
    data: assignment,
    loading: assignmentLoading,
    execute: getAssignment
  } = useAssignment();

  useEffect(() => {
    if (assignmentId) {
      getAssignment(assignmentId);
    }
  }, [assignmentId]);

  return (
    <Modal
      visible={visible}
      centered
      closable={false}
      width={800}
      footer={<Footer onClose={onClose} />}
    >
      {assignmentLoading ? (
        <AssigmentInfoSkeleton />
      ) : (
        <AssignmentInfo assignment={assignment} />
      )}
    </Modal>
  );
};

const Footer = ({ onClose }: FooterProps) => {
  return (
    <div className="flex h-full items-center justify-end">
      <SecondaryButton className="mx-4" onClick={onClose}>
        ปิด
      </SecondaryButton>
    </div>
  );
};
