import {
  ClassPeriodModifyResponse,
  ClassPeriodUpdateBody
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useUpdateClassPeriod = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ClassPeriodModifyResponse>(
    {
      method: 'PUT'
    },
    { manual: true }
  );

  const execute = ({
    classPeriodId,
    data
  }: {
    classPeriodId: string;
    data: ClassPeriodUpdateBody;
  }) => {
    return fire({
      url: `/class-periods/${classPeriodId}`,
      data
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
