import {
  EvaluationPrePostTestStatisticGetQuery,
  EvaluationPrePostTestStatisticGetResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useEvaluationsPrePostTestStatistic = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<EvaluationPrePostTestStatisticGetResponse>({}, { manual: true });

  const execute = (params?: EvaluationPrePostTestStatisticGetQuery) => {
    return fire({
      url: `/evaluations/pre-post-test/statistic`,
      params
    });
  };

  return {
    data: response?.data.subjects || [],
    loading,
    error,
    execute
  };
};

export const useEvaluationsPrePostTestStatisticLicense = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<EvaluationPrePostTestStatisticGetResponse>({}, { manual: true });

  const execute = (params?: EvaluationPrePostTestStatisticGetQuery) => {
    return fire({
      url: `/evaluations/pre-post-test/statistic-licenses`,
      params
    });
  };

  return {
    data: response?.data.subjects || [],
    loading,
    error,
    execute
  };
};
