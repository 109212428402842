import { teachingAxios } from 'utils/axios';

const handleResponseDownload = (response: any, fileName: string) => {
  const blob = new Blob([response.data], {
    type: response.data.type || response.headers?.['content-type']
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const handleResponseDownloadWithFileName = (response: any, type: string) => {
  const filename = response.data.filename;
  const blob = new Blob([Buffer.from(response.data.data, 'base64')], {
    type:
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'application/pdf'
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadStudentExcel = async (
  schoolId: string,
  schoolYearId: string,
  schoolYearName: string,
  gradeLevelIds: string,
  classroomNames: string
) => {
  const response = await teachingAxios.get(`download/student-excel/`, {
    params: {
      schoolId: schoolId,
      schoolYearId: schoolYearId,
      gradeLevelIds: gradeLevelIds,
      classroomNames: classroomNames
    }
    // responseType: 'blob'
  });
  handleResponseDownloadWithFileName(response, 'excel');
  return response;
};

export const downloadStudentPdf = async (
  schoolId: string,
  schoolYearId: string,
  schoolYearName: string,
  gradeLevelIds: string,
  classroomNames: string
) => {
  teachingAxios.defaults.timeout = 300000;
  const response = await teachingAxios.get(`download/student-pdf/`, {
    params: {
      schoolId: schoolId,
      schoolYearId: schoolYearId,
      gradeLevelIds: gradeLevelIds,
      classroomNames: classroomNames
    }
    // responseType: 'arraybuffer',
    // headers: {
    //   Accept: 'application/pdf'
    // }
  });
  handleResponseDownloadWithFileName(response, 'pdf');
  return response;
};
