/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { MediaType } from 'feature/common/media';
import classNames from 'classnames';

interface MediaTypeFilterButtonProps {
  isActive: boolean;
  mediaType: MediaType | string;
  setMediaTypeFilter: Function;
  children: any;
}

export const MediaTypeFilterButton = ({
  isActive,
  mediaType,
  setMediaTypeFilter,
  children
}: MediaTypeFilterButtonProps) => (
  <div
    className={classNames(
      'transition-colors rounded-xl px-2 sm:px-4 md:px-6 lg:px-8 py-2 mr-2.5 cursor-pointer',
      {
        'font-bold bg-secondary-light-pink': isActive
      }
    )}
    css={css`
      :hover {
        background-color: #fff5f5;
      }
    `}
    onClick={() => {
      setMediaTypeFilter(mediaType);
    }}
  >
    {children}
  </div>
);
