import {
  AssignmentUnitOverallUserListQuery,
  AssignmentUnitOverallUserListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useAssignmentUnitOverallUsers = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<AssignmentUnitOverallUserListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (params: AssignmentUnitOverallUserListQuery) => {
    return fire({
      url: '/assignment-units/overall/users',
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error
  };
};
