import { ClassroomModifyResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useDeleteClassroom = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ClassroomModifyResponse>(
    {
      method: 'DELETE'
    },
    { manual: true }
  );

  const execute = (classroomId: string) => {
    return fire({
      url: `/classrooms/${classroomId}`
    });
  };

  return {
    data: response?.data,
    loading: loading,
    error,
    execute
  };
};
