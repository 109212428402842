import { ClassroomGetResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useGetClassroom = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ClassroomGetResponse>({}, { manual: true });

  const execute = (classroomId: string) => {
    return fire({
      url: `/classrooms/${classroomId}`
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
