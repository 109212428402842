import { Response, SchoolAdminCreateBody } from '@aksorn/one-account-sdk';

import { useOneAccountAxios } from 'utils/axios';

export const useCreateSchoolAdmin = () => {
  const [{ loading, error }, fire] = useOneAccountAxios<Response>(
    {
      method: 'POST'
    },
    {
      manual: true
    }
  );

  const execute = ({ data }: { data: SchoolAdminCreateBody }) => {
    return fire({
      url: '/school-admins',
      data
    });
  };

  return {
    loading: loading,
    error,
    execute
  };
};
