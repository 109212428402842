import { Divider, Form, Modal, Select, Skeleton, Spin } from 'antd';
import { Global, css } from '@emotion/react';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useAllClassrooms,
  useGradeLevelCreateClassroom
} from 'hooks/classroom/useClassrooms';

import { ClassroomListQuery } from '@aksorn/teaching-backoffice-sdk';
import { CreateClassroomForm } from './components/CreateClassroomForm';
import { ReactComponent as SuccessIcon } from 'assets/success-all.svg';
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useHistory } from 'react-router-dom';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';

const { Option } = Select;

export const CreateClassroom = () => {
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState('');
  const { data: schoolYears, loading: schoolYearsLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const [divRefCurrent, setDivRefCurrent] = useState<HTMLDivElement>();
  const [isSubmitVisible, setIsSubmitVisible] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [isLicenseErrorVisible, setIsLicenseErrorVisible] = useState<boolean>(
    false
  );
  const [
    isDuplicateClassNameErrorVisible,
    setIsDuplicateClassNameErrorVisible
  ] = useState<boolean>(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);
  const [onRefresh, setOnRefresh] = useState<boolean>(false);
  const {
    data: classrooms,
    execute: getClassrooms,
    loading: classroomLoading,
    total: numberOfClassrooms
  } = useAllClassrooms();
  const {
    data: gradeLevels,
    execute: getGradeLevelCreateClassroom,
    loading: gradeLevelLoading
  } = useGradeLevelCreateClassroom();
  const loading = classroomLoading;
  const history = useHistory();

  const divRef = useCallback((element: HTMLDivElement) => {
    if (element) {
      setDivRefCurrent(element);
    }
  }, []);

  useEffect(() => {
    if (!schoolYearsLoading && currentSchoolYears) {
      setSelectedSchoolYearId(currentSchoolYears.schoolYearId);
      getInitialData(currentSchoolYears.schoolYearId);
    }
  }, [schoolYearsLoading, currentSchoolYears]);

  useEffect(() => {
    if (onRefresh) {
      if (currentSchoolYears) {
        getInitialData(currentSchoolYears.schoolYearId);
      }
    }
  }, [onRefresh]);

  const getInitialData = async (schoolYearId: string) => {
    try {
      const request: ClassroomListQuery = {
        schoolYearId
        // gradeLevelIds: gradeLevelIds?.join(',')
      };
      await getGradeLevelCreateClassroom();
      await getClassrooms(request);
    } catch (error) {
      console.log(error);
    } finally {
      setOnRefresh(false);
    }
  };

  return (
    <div
      css={css`
        max-width: 100%;
      `}
      ref={divRef}
    >
      <div className="flex justify-between h-10">
        <div className="flex items-center h-8 font-bold text-2xl text-secondary-black">
          สร้างห้องเรียน
        </div>
        <div className="flex">
          {schoolYearsLoading ? (
            <div className="flex w-full justify-end mb-5">
              <Skeleton.Input className="mr-0" active />
            </div>
          ) : (
            <Form.Item label="ปีการศึกษา" className="w-48">
              <Select
                value={selectedSchoolYearId}
                onSelect={(schoolYearId) => {
                  setSelectedSchoolYearId(schoolYearId);
                }}
              >
                {schoolYears.map(({ name, schoolYearId }) => (
                  <Option
                    key={schoolYearId}
                    value={schoolYearId}
                    // disabled={parseInt(name) - 543 !== moment().year()}
                    disabled={schoolYearId !== selectedSchoolYearId}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
      </div>
      <Divider className="my-8" />
      <div className="flex items-center h-8 font-bold text-2xl text-secondary-black">
        ห้องเรียนและนักเรียน
      </div>
      <div
        className="flex items-center h-8 text-base "
        style={{ color: '#F99D34' }}
      >
        {
          'สามารถตั้งชื่อห้องเรียนได้ โดยใช้ตัวอักษรภาษาอังกฤษ (A-Z) หรือตัวเลข (0-9) เท่านั้น'
        }
      </div>

      {/* {
                loading ? (
                    <TableSkeleton />
                ) : (
                    <div className="pt-6">
                        <CreateClassroomTable
                            classrooms={classrooms}
                        />
                    </div>
                )
            } */}

      <Spin spinning={loading}>
        <CreateClassroomForm
          setOnRefresh={setOnRefresh}
          gradeLevels={gradeLevels}
          classrooms={classrooms}
          setIsSubmitVisible={() => setIsSubmitVisible(true)}
          setIsAlertVisible={() => setIsAlertVisible(true)}
          setIsLicenseErrorVisible={() => setIsLicenseErrorVisible(true)}
          setIsConfirmVisible={() => setIsConfirmVisible(true)}
          setIsDuplicateClassNameErrorVisible={
            setIsDuplicateClassNameErrorVisible
          }
          schoolYears={currentSchoolYears}
        />
      </Spin>
      <Modal
        visible={isSubmitVisible}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <SuccessIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'บันทึกข้อมูลห้องเรียนเสร็จสิ้น!'}
          </h3>
          <p className="text-sm text-center">
            {'ระบบได้ทำการบันทึกข้อมูลห้องเรียน'}
          </p>

          <p className="text-sm text-center">
            {'และสร้างบัญชีผู้ใช้งานนักเรียนเรียบร้อย'}
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <PrimaryButton
                className="w-24"
                onClick={() => setIsSubmitVisible(false)}
              >
                {'ตกลง'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isAlertVisible}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <WarningIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'ไม่สามารถลบห้องเรียนนี้ได้'}
          </h3>
          <p className="text-sm text-center">
            {'เนื่องจากมีนักเรียนอยู่ในห้องเรียนนี้ '}
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <PrimaryButton
                className="w-24"
                onClick={() => setIsAlertVisible(false)}
              >
                {'ตกลง'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isLicenseErrorVisible}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <WarningIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'ไม่สามารถลดจำนวนนักเรียนได้'}
          </h3>

          <p className="text-sm text-center">
            {
              'กรุณาเลือกบัญชีผู้ใช้งานนักเรียนที่ท่านต้องการลบ ด้วยการเข้าเมนูจัดการนักเรียน'
            }
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <SecondaryButton
                className="mx-4"
                onClick={() => setIsLicenseErrorVisible(false)}
              >
                ยกเลิก
              </SecondaryButton>
              <PrimaryButton
                className="w-24"
                onClick={() => {
                  setIsLicenseErrorVisible(false);
                  history.push('/student-management');
                }}
              >
                {'ตกลง'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isDuplicateClassNameErrorVisible}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <WarningIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'ไม่สามารถใช้ชื่อห้องเรียนนี้ได้'}
          </h3>

          <p className="text-sm text-center">
            {
              'เนื่องจากชื่อห้องเรียนนี้มีการใช้งานอยู่ในระบบ กรุณาระบุชื่อห้องเรียนใหม่'
            }
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              {/* <SecondaryButton className="mx-4" onClick={() => setIsDuplicateClassNameErrorVisible(false)}>
                                ยกเลิก
                            </SecondaryButton> */}
              <PrimaryButton
                className="w-24"
                onClick={() => {
                  setIsDuplicateClassNameErrorVisible(false);
                  setOnRefresh(true);
                  //history.push('/student-management')
                }}
              >
                {'ตกลง'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isConfirmVisible}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <WarningIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'ยืนยันการเพิ่มจำนวนนักเรียน'}
          </h3>
          <p className="text-sm text-center">
            {
              'กรุณากดปุ่มยืนยันเพื่อเพิ่ม Temp E-mail ของนักเรียน จำนวน 2 รายการลงในระบบ'
            }
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <SecondaryButton
                className="mx-4"
                onClick={() => setIsConfirmVisible(false)}
              >
                ยกเลิก
              </SecondaryButton>
              <PrimaryButton
                className="w-24"
                onClick={() => setIsConfirmVisible(false)}
              >
                {'ตกลง'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
