/** @jsx jsx */


import { Global, css, jsx } from '@emotion/react';

import { PageWrapper } from 'feature/common/components/PageWrapper';

import { MediaManagementForClassrooms } from 'feature/media-management-for-classroom/MediaManagementForClassroom';


export const MediaManagementForClassroomsPage = () => {


  return (
    <PageWrapper className="bg-secondary-white">
      <Global
        styles={css`
          thead.ant-table-thead > tr > th {
            background-color: #fff !important;
            font-weight: 700 !important;
          }
          .ant-table-container table > thead > tr:first-child th:first-child {
            border-top-left-radius: 1rem;
          }
          .ant-table-container table > thead > tr:first-child th:last-child {
            border-top-right-radius: 1rem;
          }
          .ant-table {
            border-radius: 1rem !important;
          }
          .ant-table-tbody > tr:last-child > td {
            border-bottom: none !important;
          }
          .ant-tree-select > ::-webkit-scrollbar {
            display: none;
          }
          .ant-select-selection-overflow {
            align-items: center;
            flex-wrap: nowrap !important;
          }
          tr.ant-table-row-level-0 {
            cursor: pointer !important;
          }
          .editable-cell {
            position: relative;
          }

          .editable-cell-value-wrap {
            padding: 5px 12px;
            cursor: pointer;
          }

          .editable-row:hover .editable-cell-value-wrap {
            padding: 4px 11px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
          }

          [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
            border: 1px solid #434343;
          }

          .ant-table-wrapper .ant-checkbox-wrapper .ant-checkbox-inner{
           border: 2px solid  #434343 ;
           },

           .ant-table-container table>thead>tr:first-child th:last-child {
            border-bottom: 3px solid #f0f0f0 !important;
}  
           /* .ant-table-wrapper .ant-checkbox-wrapper .ant-checkbox-disabled  {
            border: 2px solid #d9d9d9 !important;
              } */
        `}
      />
      <MediaManagementForClassrooms />




    </PageWrapper>
  );
};
