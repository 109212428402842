import { Modal } from 'antd';
import { PrimaryButton } from 'feature/common/components/Button';
import React from 'react';
import { ReactComponent as SuccessIcon } from 'assets/success-all.svg';

interface SuccessModalProps {
  visible: boolean;
  setVisible: Function;
}

export const SuccessModal = ({ visible, setVisible }: SuccessModalProps) => {
  return (
    <Modal
      visible={visible}
      width={400}
      footer={null}
      closable={false}
      centered
      bodyStyle={{
        paddingLeft: 40,
        paddingRight: 40
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <SuccessIcon />
        <h3 className="font-bold text-xl mt-2 text-center">
          ตัดสื่อเข้าห้องเรียนสำเร็จ!
        </h3>
        <p className="text-lg text-center mt-2">
          ระบบได้ทำการตัดสื่อตามวิชาที่เลือก เข้าห้องเรียนเรียบร้อย
        </p>
        <div className="flex justify-center gap-4 mt-8 mx-auto">
          <PrimaryButton className="w-30" onClick={() => setVisible(false)}>
            ตกลง
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
