import { Avatar, Col, Row } from 'antd';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ClassroomStudentGetDto } from '@aksorn/teaching-backoffice-sdk';
import { ReactComponent as ClockIcon } from 'assets/clock.svg';
import React from 'react';
import styled from '@emotion/styled';

interface StudentInfoProps {
  classroomStudent: ClassroomStudentGetDto;
}

export const StudentInfo = ({ classroomStudent }: StudentInfoProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={24} sm={7} className="mr-8">
          <div className="flex flex-col justify-center items-center">
            <Avatar size={90} className="text-4xl font-bold">
              {/* {classroomStudent.firstName.th.substring(0, 3)} */}
              {classroomStudent?.firstName?.th || classroomStudent?.firstName?.en ? classroomStudent.firstName.th.substring(0, 3) : null}
            </Avatar>
            {classroomStudent.emailVerifiedAt ? (
              <div className="flex items-center justify-center mt-4">
                <CheckIcon
                  css={css`
                    width: 16px;
                    height: 16px;
                    path {
                      fill: #6fcf97;
                    }
                  `}
                />
                <p className="text-sm text-system-complete font-bold ml-1 mb-8 sm:mb-0">
                  ใช้งานได้
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-center mt-4">
                <ClockIcon
                  css={css`
                    width: 16px;
                    height: 16px;
                    path {
                      fill: #f99d34;
                    }
                  `}
                />
                <p className="ml-1 text-sm text-aksorn-orange font-bold">
                  รอยืนยันตัวตน
                </p>
              </div>
            )}
          </div>
        </Col>
        <Col xs={24} sm={15}>
          <Row className="mb-2">
            <Col
              className="text-secondary-gray-60 text-base font-bold whitespace-no-wrap mr-5"
              xs={8}
              sm={9}
            >
              ชื่อ - นามสกุล
            </Col>
            <Col className="text-secondary-gray-60 text-base" xs={14} sm={13}>
              <p>
                {classroomStudent.firstName.th}&nbsp;
                {classroomStudent.lastName.th}
              </p>
              {(classroomStudent.firstName.en ||
                classroomStudent.lastName.en) && (
                <p>
                  {classroomStudent.firstName.en}&nbsp;
                  {classroomStudent.lastName.en}
                </p>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              className="text-secondary-gray-60 text-base font-bold whitespace-no-wrap mr-5"
              xs={8}
              sm={9}
            >
              ระดับชั้น
            </Col>
            <Col className="text-secondary-gray-60 text-base" xs={14} sm={13}>
              {classroomStudent.gradeLevel.name.th}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              className="text-secondary-gray-60 text-base font-bold whitespace-no-wrap mr-5"
              xs={8}
              sm={9}
            >
              ห้อง
            </Col>
            <Col className="text-secondary-gray-60 text-base" xs={14} sm={13}>
              {classroomStudent.classroomName}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              className="text-secondary-gray-60 text-base font-bold whitespace-no-wrap mr-5"
              xs={8}
              sm={9}
            >
              รหัสประจำตัว
            </Col>
            <Col className="text-secondary-gray-60 text-base" xs={14} sm={13}>
              {classroomStudent.studentCode}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col
              className="text-secondary-gray-60 text-base font-bold whitespace-no-wrap mr-5"
              xs={8}
              sm={9}
            >
              เลขที่
            </Col>
            <Col className="text-secondary-gray-60 text-base" xs={14} sm={13}>
              {classroomStudent.classroomStudentNumber}
            </Col>
          </Row>
          <Row>
            <Col
              className="text-secondary-gray-60 text-base font-bold whitespace-no-wrap mr-5"
              xs={8}
              sm={9}
            >
              One account
            </Col>
            <Col className="text-secondary-gray-60 text-base" xs={14} sm={13}>
              {classroomStudent.email}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
    </React.Fragment>
  );
};

const Divider = styled.div`
  border: 1px solid #e3e4e8;
  margin: 32px 0px;
  height: 1px;
`;
