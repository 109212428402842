import { action, makeObservable, observable } from 'mobx';

import { ClassPeriodLicenseGetDto } from '@aksorn/teaching-backoffice-sdk';

export const SELECT_ALL = 'all';

class ClassPeriodLicenseStore {
  classPeriodLicense?: ClassPeriodLicenseGetDto = undefined;

  constructor() {
    makeObservable(this, {
      classPeriodLicense: observable,
      setClassPeriodLicense: action
    });
  }

  setClassPeriodLicense = (classPeriodLicense: ClassPeriodLicenseGetDto) => {
    this.classPeriodLicense = classPeriodLicense;
  };
}

export const classPeriodLicenseStore = new ClassPeriodLicenseStore();
