import { action, makeObservable, observable } from 'mobx';

import { UserDto } from '@aksorn/one-account-sdk';
import { UserMeDto } from '@aksorn/teaching-backoffice-sdk';

class UserStore {
  token?: string;
  me?: UserDto;
  teachingProfile?: UserMeDto;
  selectedSchoolYearId?: string;
  // gradeLevelIds: string[] = [];
  // classroomNames: string[] = [];
  // teacherName?: string;

  constructor() {
    this.load();
    makeObservable(this, {
      token: observable,
      me: observable,
      teachingProfile: observable,
      selectedSchoolYearId: observable,
      // gradeLevelIds: observable,
      // classroomNames: observable,
      // teacherName: observable,
      load: action,
      setMe: action,
      setToken: action,
      setTeachingProfile: action,
      setSelectedSchoolYearId: action
      // setGradeLevelIds: action,
      // setClassroomNames: action,
      // setTeacherName: action,
    });
  }

  load = () => {
    console.log('load token');
    if (typeof localStorage !== 'undefined') {
      const token = localStorage.getItem('token');
      if (token) {
        this.token = token;
      }
    }
  };

  setMe = (user: UserDto) => {
    this.me = user;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  setTeachingProfile = (teachingProfile: UserMeDto) => {
    this.teachingProfile = teachingProfile;
  };

  @action
  setSelectedSchoolYearId = (selectedSchoolYearId: string) => {
    this.selectedSchoolYearId = selectedSchoolYearId;
  };

  // @action
  // setGradeLevelIds = (gradeLevelIds: string[]) => {
  //   this.gradeLevelIds = gradeLevelIds;
  // };

  // @action
  // setClassroomNames = (classroomNames: string[]) => {
  //   this.classroomNames = classroomNames;
  // };

  // @action
  // setTeacherName = (teacherName: string) => {
  //   this.teacherName = teacherName;
  // };

  @action
  setFilter = (data: UserStore) => {
    if (data.selectedSchoolYearId) {
      this.setSelectedSchoolYearId(data.selectedSchoolYearId);
    }
    // if (data.gradeLevelIds) {
    //   this.setGradeLevelIds(data.gradeLevelIds);
    // }
    // if (data.classroomNames) {
    //   this.setClassroomNames(data.classroomNames);
    // }
    // if (data.teacherName) {
    //   this.setTeacherName(data.teacherName);
    // }
  };
}

export const userStore = new UserStore();
