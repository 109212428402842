import { i18n } from 'i18next';

export const prefixMapper: any = {
  'ด.ช.': {
    th: 'ด.ช.',
    en: 'Mister(underage) '
  },
  'ด.ญ.': {
    th: 'ด.ญ.',
    en: 'Miss(underage) '
  },
  นาย: {
    th: 'นาย',
    en: 'Mr. '
  },
  นาง: {
    th: 'นาง',
    en: 'Mrs. '
  },
  นางสาว: {
    th: 'นางสาว',
    en: 'Miss '
  },
  null: {
    th: '',
    en: ' '
  }
};

//translate localization object
export const tObject = (
  i18n: i18n,
  localizationObject: any,
  defaultText?: string
) => {
  return (
    localizationObject?.[i18n.language] ??
    localizationObject?.th ??
    defaultText ??
    null
  );
};

//translate full name
export const tFullName = (
  i18n: i18n,
  thaiPrefix: string,
  firstName: any,
  lastName: any
) => {
  var language = i18n.language;
  if (!firstName?.[i18n.language] || !lastName?.[i18n.language]) {
    language = 'th';
  }
  const tPrefix = prefixMapper?.[thaiPrefix]?.[language] ?? '';
  const tFirstName = firstName?.[language] ?? '';
  const tLastName = lastName?.[language] ?? '';
  return `${tPrefix}${tFirstName} ${tLastName}`;
};
