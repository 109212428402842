import { SchoolAdminRoleListResponse } from '@aksorn/one-account-sdk';
import { useOneAccountAxios } from 'utils/axios';

export const useSchoolAdminRoles = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useOneAccountAxios<SchoolAdminRoleListResponse>({}, { manual: true });

  const execute = () => {
    return fire({
      url: `/school-admin-roles`,
      params: {
        pageNumber: 1,
        pageSize: 100
      }
    });
  };

  return {
    data: response?.data || [],
    loading,
    error,
    execute,
    total: response?.meta?.pagination?.total || 0
  };
};
