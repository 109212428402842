import { ClassPeriodLicenseGetResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useClassPeriodLicense = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<ClassPeriodLicenseGetResponse>(
    {},
    {
      manual: true
    }
  );
  const execute = (classPeriodId: string) => {
    return fire({
      url: `/class-period-licenses/${classPeriodId}`
    });
  };

  return {
    data: data?.data,
    loading: loading,
    execute,
    error: error
  };
};

// export const useClassPeriodLicense = async (
//   classPeriodId: string
// ): Promise<ClassPeriodLicenseGetResponse> => {
//   const {
//     data: response
//   } = await teachingWebAxios.get<ClassPeriodLicenseGetResponse>(
//     `/class-period-licenses/${classPeriodId}`
//   );

//   return response;
// };
