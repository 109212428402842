/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { ReactComponent as EmptyIcon } from 'assets/evaluation-empty-icon.svg';
import { EmptyTable } from 'feature/common/components/Table/EmptyTable';

export const EmptyEvaluation = () => {
  return (
    <EmptyTable
      icon={
        <EmptyIcon
          className="w-20 h-20"
          css={css`
            path {
              fill: #e3e4e8;
            }
          `}
        />
      }
      emptyText={'ไม่มีข้อมูล'}
      emptyClassName={'text-secondary-gray-60'}
      style={{ minHeight: '400px' }}
    />
  );
};
