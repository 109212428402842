import { teachingAxios } from 'utils/axios';

const handleResponseDownload = (response: any, fileName: string) => {
  const blob = new Blob([response.data], {
    type: response.data.type || response.headers?.['content-type']
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadTeacherExcel = async (
  schoolId: string,
  schoolYearId: string,
  schoolYearName: string
) => {
  const fileName =
    'รายชื่อผู้ใช้ AOL Plus ปีการศึกษา ' + schoolYearName + ' ครู';
  var newfilename = fileName.replace(/['" ,.]/g, '_');
  const language = 'th';
  const response = await teachingAxios.get(`download/teacher-excel/`, {
    params: { schoolId: schoolId, schoolYearId: schoolYearId },
    responseType: 'blob'
  });
  handleResponseDownload(response, newfilename);
  return response;
};

export const downloadTeacherPdf = async (
  schoolId: string,
  schoolYearId: string,
  schoolYearName: string
) => {
  const fileName =
    'รายชื่อผู้ใช้ AOL Plus ปีการศึกษา ' + schoolYearName + ' ครู';
  var newFileName = fileName.replace(/['" ,.]/g, '_');
  newFileName = newFileName + '.pdf';
  const language = 'th';
  const response = await teachingAxios.get(`download/teacher-pdf/`, {
    params: { schoolId: schoolId, schoolYearId: schoolYearId },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf'
    }
  });
  handleResponseDownload(response, newFileName);
  return response;
};
