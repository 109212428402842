import {
  ClassroomCreateBody,
  GradeLevelDto,
  SchoolTeacherDto,
  SchoolYearDto
} from '@aksorn/teaching-backoffice-sdk';
import { Col, Form, Input, Radio, Row, Select, Spin, message } from 'antd';
import { FormRule, getRule } from 'utils/getRule';
import { Global, css, jsx } from '@emotion/react';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';

/** @jsx jsx */
import { ClassroomType } from './classroom-management';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ProviderIcon } from 'feature/common/components/ProviderIcon';
import moment from 'moment';
import { useCreateClassroom } from 'hooks/classroom/useCreateClassroom';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useForm } from 'antd/lib/form/Form';
import { useGetClassroom } from 'hooks/classroom/useGetClassroom';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';
import { useHistory } from 'react-router-dom';
import { useSchoolTeachers } from 'hooks/school-teacher/useSchoolTeachers';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';
import { useUpdateClassroom } from 'hooks/classroom/useUpdateClassroom';

const { Option } = Select;
interface ClassroomFormProps {
  onClose: () => void;
  onSubmit: () => void;
  classroomId?: string;
  // // unlock mainSchoolTeacher 19/08/2022
  mainSchoolTeacher?: string;
}

export const ClassroomForm = ({
  onClose,
  onSubmit,
  classroomId,
  // // unlock mainSchoolTeacher 19/08/2022
  mainSchoolTeacher
}: ClassroomFormProps) => {
  const [classroomForm] = useForm();

  const [selectedSchoolTeachers, setSelectedSchoolTeachers] = useState<
    SchoolTeacherDto[]
  >([]);
  const [mainClassTeacherId, setMainClassTeacherId] = useState<string>('');

  const { data: schoolYears } = useSchoolYears();
  const { data: gradeLevels } = useGradeLevels();

  const {
    data: schoolTeachers,
    loading: getSchoolTeachersLoading,
    execute: getSchoolTeachers
  } = useSchoolTeachers();

  const {
    data: classroom,
    loading: fetching,
    execute: getClassroom
  } = useGetClassroom();
  const { loading: creating, execute: createClassroom } = useCreateClassroom();
  const { loading: updating, execute: updateClassroom } = useUpdateClassroom();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const history = useHistory();

  useEffect(() => {
    // // unlock mainSchoolTeacher 19/08/2022
    // getSchoolTeachers({ pageSize: 100 });
    // console.log("step9",mainSchoolTeacher);
    getSchoolTeachers({ pageSize: 100, mainSchoolTeacher: mainSchoolTeacher });
  }, []);

  const nonSelectedTeachers = useMemo(() => {
    const selectedSchoolTeacherIds = selectedSchoolTeachers?.map(
      ({ schoolTeacherId }) => schoolTeacherId
    );
    const filteredSchoolTeachers = schoolTeachers?.filter(
      ({ schoolTeacherId }) =>
        !selectedSchoolTeacherIds?.includes(schoolTeacherId)
    );
    return filteredSchoolTeachers;
  }, [schoolTeachers, selectedSchoolTeachers]);

  useEffect(() => {
    // console.log("step3",classroom);
    if (classroom) {
      // console.log("step4",classroom);
      setMainClassTeacherId(classroom?.mainSchoolTeacher?.schoolTeacherId);
      const classTeacherIds = classroom.classroomTeachers.map(
        ({ schoolTeacherId }) => schoolTeacherId
      );
      // console.log("step5",classTeacherIds);
      classTeacherIds.unshift(classroom?.mainSchoolTeacher?.schoolTeacherId);
      // console.log("step6",classroom.mainSchoolTeacher.schoolTeacherId);
      // console.log("step7",schoolTeachers);
      const selectedSchoolTeachers = schoolTeachers.filter(
        ({ schoolTeacherId }) => classTeacherIds.includes(schoolTeacherId)
      );
      // console.log("step8",selectedSchoolTeachers[0]);
      setSelectedSchoolTeachers(selectedSchoolTeachers);

      classroomForm.setFieldsValue({
        schoolYear: classroom.schoolYear.schoolYearId,
        classroomType: classroom.classroomType,
        gradeLevel: classroom.gradeLevel.gradeLevelId,
        classroomName: classroom.classroomName,
        mainTeacher: classroom?.mainSchoolTeacher?.schoolTeacherId
      });
    } else {
      if (classroomId) getMyClassroom(classroomId);
    }
  }, [classroomForm, classroom]);

  const getMyClassroom = useCallback(async (classroomId: string) => {
    if (classroomId) await getClassroom(classroomId);
  }, []);

  useEffect(() => {
    // console.log("step1",classroomId);
    // if (classroomId) {
    //   // getMyClassroom(classroomId);
    //   // console.log("step2",classroom);
    // } else {
    //pun addcondition set default year as current year
    const getCurYearId = currentSchoolYears?.schoolYearId;
    classroomForm.setFieldsValue({
      schoolYear: getCurYearId
    });
    // }
  }, [schoolTeachers]);

  const handleSubmitForm = async (value: any) => {
    const loading = message.loading(
      `กำลัง${!classroomId ? 'สร้าง' : 'แก้ไข'}ห้องเรียน...`
    );
    try {
      delete value.classTeacherValidatorField;
      const classTeacherIds = selectedSchoolTeachers.map((t) => ({
        schoolTeacherId: t.schoolTeacherId
      }));
      //pun add get teacher Type from teacher 22/02/22
      const teacherType = selectedSchoolTeachers[0]?.teacherType;

      const payload: ClassroomCreateBody = {
        classroomName: value.classroomName,
        classroomType: ClassroomType.PRIMARY.toString() as ClassroomCreateBody.classroomType,
        classroomSubType: teacherType as ClassroomCreateBody.classroomSubType,
        schoolYearId: value.schoolYear,
        gradeLevelId: value.gradeLevel,
        mainSchoolTeacherId: value.mainTeacher,
        classroomTeachers: classTeacherIds
      };

      if (classroomId) {
        await updateClassroom({
          classroomId: classroomId as string,
          data: payload
        });
      } else {
        await createClassroom({ data: payload });
      }
      message.success(`${!classroomId ? 'สร้าง' : 'แก้ไข'}ห้องเรียนสำเร็จ`);
      classroomForm.resetFields();
      onSubmit();
    } catch (e) {
      if (e?.response?.data?.message === 'classroom name exists') {
        classroomForm.setFields([
          { name: 'classroomName', errors: ['มีเลขห้อง / ชื่อห้องนี้แล้ว'] }
        ]);
      } else {
        message.error(`${!classroomId ? 'สร้าง' : 'แก้ไข'}ห้องเรียนไม่สำเร็จ`);
      }
    } finally {
      loading();
    }
  };
  const loading = fetching || getSchoolTeachersLoading;
  return (
    <Spin spinning={loading}>
      <Form form={classroomForm} onFinish={handleSubmitForm}>
        <Global
          styles={css`
            label {
              background-color: #ffffff !important;
            }
          `}
        />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item
              name="schoolYear"
              rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกปีการศึกษา')]}
              label="ปีการศึกษา"
            >
              <Select>
                {schoolYears?.map(({ schoolYearId, name }: SchoolYearDto) => (
                  <Option
                    value={schoolYearId}
                    key={schoolYearId}
                    disabled={schoolYearId !== currentSchoolYears?.schoolYearId}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="gradeLevel"
              rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกชั้นปี')]}
              label="ชั้นปี"
            >
              <Select>
                {gradeLevels?.map(({ gradeLevelId, name }: GradeLevelDto) => (
                  <Option value={gradeLevelId} key={gradeLevelId}>
                    {name.th}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="classroomName"
              label="เลขห้อง / ชื่อห้อง"
              rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเลขห้อง / ชื่อห้อง')]}
            >
              <Input className="rounded-xl h-10" />
            </Form.Item>
          </Col>
        </Row>
        <div className="mt-2">
          <div className="text-sm text-secondary-gray-60 font-bold">
            ครูประจำชั้น
          </div>
          {/* unlock mainSchoolTeacher 19/08/2022 */}
          {/* {console.log("selectedSchoolTeachers.length",selectedSchoolTeachers.length)} */}
          {selectedSchoolTeachers.length !== 0 && (
            <div className="mt-2">
              <p className="text-sm text-secondary-gray-60">ครูหลัก</p>
              <div>
                <Form.Item
                  className="mb-0"
                  name="mainTeacher"
                  rules={[getRule(FormRule.REQUIRE, 'เลือกครูหลัก')]}
                >
                  <Radio.Group
                    className="w-full"
                    value={mainClassTeacherId}
                    onChange={(e) => {
                      setMainClassTeacherId(e?.target?.value);
                    }}
                  >
                    {selectedSchoolTeachers?.map(
                      ({
                        schoolTeacherId,
                        title,
                        firstName,
                        lastName,
                        deletedAt
                      }) => (
                        <div className="w-full mt-2" key={schoolTeacherId}>
                          <Radio
                            value={schoolTeacherId}
                            key={schoolTeacherId}
                            className="flex w-full items-center"
                          >
                            <div className="flex w-full justify-between items-center">
                              <img
                                alt="profile"
                                src="/assets/profile-colour.png"
                                className="w-8 ml-8 mr-2"
                              />
                              <div className="flex items-center whitespace-pre-line text-sm text-font-main w-64 mr-16">
                                {/* unlock mainSchoolTeacher 19/08/2022 */}
                                {/* {console.log("deletedAt", deletedAt)} */}
                                {`${title + firstName.th} ${lastName.th}`}&nbsp;
                                {/* {deletedAt ? (
                                  <span style={{color:"red",fontWeight:"bold"}}>{' (ลาออกแล้ว)'}</span>
                                ) : null} */}
                                {deletedAt ? (
                                  <span>{' (ย้ายโรงเรียน)'}</span>
                                ) : null}
                              </div>

                              <DeleteIcon
                                onClick={() => {
                                  const selectedTeachers = selectedSchoolTeachers?.filter(
                                    (selectedSchoolTeacher) =>
                                      schoolTeacherId !==
                                      selectedSchoolTeacher?.schoolTeacherId
                                  );
                                  setSelectedSchoolTeachers(selectedTeachers);
                                  if (schoolTeacherId === mainClassTeacherId) {
                                    const newMainSchoolTeacher = selectedSchoolTeachers?.find(
                                      (selectedSchoolTeacher) =>
                                        schoolTeacherId !==
                                        selectedSchoolTeacher?.schoolTeacherId
                                    );
                                    if (newMainSchoolTeacher) {
                                      setMainClassTeacherId(
                                        newMainSchoolTeacher?.schoolTeacherId
                                      );
                                      classroomForm.setFieldsValue({
                                        mainTeacher:
                                          newMainSchoolTeacher?.schoolTeacherId
                                      });
                                    } else {
                                      setMainClassTeacherId('');
                                    }
                                  }
                                }}
                              />
                            </div>
                          </Radio>
                        </div>
                      )
                    )}
                  </Radio.Group>
                </Form.Item>
              </div>
            </div>
          )}
        </div>
        <Form.Item
          className="mt-5 mb-0"
          name="selectedSchoolTeacher"
          rules={[
            () => ({
              validator(_rule, _value) {
                if (selectedSchoolTeachers?.length === 0) {
                  return Promise.reject('กรุณาเลือกครู');
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <Select
            placeholder="ค้นหาครูเพื่อเพิ่มในห้องเรียน"
            showSearch
            notFoundContent={null}
            filterOption={(input, option) =>
              option?.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={(newSelectedTeacherId: string) => {
              const newSelectedTeacher = schoolTeachers?.find(
                ({ schoolTeacherId }) =>
                  schoolTeacherId === newSelectedTeacherId
              );
              if (newSelectedTeacher) {
                setSelectedSchoolTeachers([
                  ...selectedSchoolTeachers,
                  newSelectedTeacher
                ]);
              }
              classroomForm?.setFieldsValue({
                selectedSchoolTeacher: null
              });
              if (selectedSchoolTeachers?.length === 0) {
                setMainClassTeacherId(newSelectedTeacherId);
                classroomForm.setFieldsValue({
                  mainTeacher: newSelectedTeacherId
                });
              }
            }}
          >
            {nonSelectedTeachers?.map(
              // // unlock mainSchoolTeacher 19/08/2022
              // ({ schoolTeacherId, title, firstName, lastName }) => (
              //   <Option key={schoolTeacherId} value={schoolTeacherId}>
              //     {title + firstName?.th + ' ' + lastName?.th}
              //   </Option>
              // )
              ({
                schoolTeacherId,
                title,
                firstName,
                lastName,
                email,
                isTemp,
                provider
              }) => (
                <Option
                  key={schoolTeacherId}
                  value={schoolTeacherId}
                  text={`${title} + ${firstName?.th} + ' ' + ${lastName?.th}  ' ('+ ${email} +')'`}
                >
                  <div className="flex items-center">
                    <div className="my-0">
                      {' '}
                      {title + firstName?.th + ' ' + lastName?.th + '  (  '}
                    </div>
                    {isTemp ? (
                      <div className="mr-1"></div>
                    ) : (
                      <ProviderIcon
                        providerName={provider?.name}
                        className="mr-1 w-4 h-4 ml-1"
                      />
                    )}
                    <div className="my-0"> {email + '  )'}</div>
                  </div>
                </Option>
              )
            )}
          </Select>
        </Form.Item>
        <div className="mt-2">
          ไม่มีครูในระบบ?{' '}
          <span
            className="text-blue-400 cursor-pointer"
            onClick={() => {
              history.push('/teacher-management');
            }}
          >
            เพิ่มครู
          </span>
        </div>
        <div className="flex mt-12 items-center justify-end">
          <SecondaryButton className="mx-4" onClick={onClose}>
            ยกเลิก
          </SecondaryButton>
          <PrimaryButton htmlType="submit" loading={creating || updating}>
            {classroomId ? 'บันทึก' : 'เพิ่ม'}
          </PrimaryButton>
        </div>
      </Form>
    </Spin>
  );
};
