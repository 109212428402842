import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AssignmentOverallClassPeriodCard } from './AssignmentOverallClassPeriodCard';
import { AssignmentOverallClassPeriodDrawer } from './AssignmentOverallClassPeriodDrawer';
import { ClassPeriodScheduleGetDto } from '@aksorn/teaching-backoffice-sdk';
import React from 'react';
import { Spin } from 'antd';
import { useClassPeriods } from 'hooks/class-period/useClassPeriods';

export const AssignmentOverallClassPeriodContent = () => {
  const [isClassPeriodDrawerVisible, setIsClassPeriodDrawerVisible] = useState(
    false
  );
  const [classPeriodName, setClassPeriodName] = useState<string | undefined>(
    undefined
  );
  const [classPeriodSchedules, setClassPeriodSchedules] = useState<
    ClassPeriodScheduleGetDto[]
  >([]);

  const history = useHistory();
  const params = useParams();

  const {
    data: classPeriods,
    loading: classPeriodsLoading,
    execute: getClassPeriods
  } = useClassPeriods();

  const { classroomId } = params as any;

  const loading = classPeriodsLoading;

  useEffect(() => {
    if (classroomId) {
      getClassPeriods({ classroomId });
    }
  }, [classroomId]);

  return (
    <Spin spinning={loading}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
        {classPeriods?.map((classPeriod) => (
          <AssignmentOverallClassPeriodCard
            subject={classPeriod?.subject}
            classPeriodName={classPeriod?.classPeriodName}
            mainSchoolTeacher={classPeriod?.mainSchoolTeacher}
            classPeriodSchedules={classPeriod?.classPeriodSchedules}
            key={classPeriod.classPeriodId}
            onClick={() => {
              history.push(
                `/assignment-overall/${classroomId}/${classPeriod.classPeriodId}`
              );
            }}
            onSeeAllSchedulesClick={() => {
              setIsClassPeriodDrawerVisible(true);
              setClassPeriodName(classPeriod?.classPeriodName);
              setClassPeriodSchedules(classPeriod?.classPeriodSchedules);
            }}
          />
        ))}
      </div>
      <AssignmentOverallClassPeriodDrawer
        visible={isClassPeriodDrawerVisible}
        classPeriodName={classPeriodName}
        classPeriodSchedules={classPeriodSchedules}
        onClose={() => {
          setIsClassPeriodDrawerVisible(false);
          setClassPeriodName(undefined);
        }}
      />
    </Spin>
  );
};
