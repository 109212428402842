import { Col, Drawer, Row } from 'antd';
import { DayOfWeek, DayOfWeekMapper } from 'feature/common/global';
import { Global, css } from '@emotion/react';
import React, { Fragment, useMemo } from 'react';

import { ClassPeriodScheduleGetDto } from '@aksorn/teaching-backoffice-sdk';
import { DAY_OF_WEEK_COLOR_MAPPER } from 'feature/assignment-overall/const';
import { SecondaryButton } from 'feature/common/components/Button';

interface AssignmentOverallClassPeriodDrawerProps {
  visible: boolean;
  classPeriodName: string | undefined;
  classPeriodSchedules: ClassPeriodScheduleGetDto[];
  onClose: () => void;
}

export const AssignmentOverallClassPeriodDrawer = ({
  visible,
  classPeriodName,
  classPeriodSchedules,
  onClose
}: AssignmentOverallClassPeriodDrawerProps) => {
  const sortedClassPeriodSchedules = useMemo(() => {
    return classPeriodSchedules?.sort((a, b) => {
      const daysOfWeekIndex = Object.values(DayOfWeek);
      const idxA = daysOfWeekIndex.findIndex((e) => (e as any) === a.dayOfWeek);
      const idxB = daysOfWeekIndex.findIndex((e) => (e as any) === b.dayOfWeek);
      return idxA - idxB;
    });
  }, [classPeriodSchedules]);

  return (
    <Drawer
      placement="right"
      closable={false}
      visible={visible}
      destroyOnClose={true}
      maskClosable={false}
      width={480}
      onClose={onClose}
    >
      <Global
        styles={css`
          .ant-drawer-body {
            padding-top: 24px;
            padding-left: 42px;
          }
        `}
      />
      <div>
        <div className="font-bold text-xl mb-1">ตารางสอน</div>
        <div className="text-secondary-gray-60 text-base">
          {classPeriodName}
        </div>
        <div className="mt-8">
          <Row>
            {sortedClassPeriodSchedules?.map(
              ({ dayOfWeek, startTime, endTime }) => (
                <Fragment>
                  <Col span={5} className="flex items-center mb-2">
                    <div
                      className={`flex rounded-full w-2 h-2 mr-2 bg-${DAY_OF_WEEK_COLOR_MAPPER[dayOfWeek]}`}
                    />
                    {DayOfWeekMapper[dayOfWeek]}
                  </Col>
                  <Col span={18}>
                    {startTime?.slice(0, 5) + '-' + endTime?.slice(0, 5)}
                  </Col>
                </Fragment>
              )
            )}
          </Row>
        </div>
      </div>

      <div className="flex items-center justify-end absolute inset-x-5 bottom-5">
        <SecondaryButton className="mx-4" onClick={onClose}>
          ปิด
        </SecondaryButton>
      </div>
    </Drawer>
  );
};
