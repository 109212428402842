import {
  AssignmentOverallDetailMenu,
  LearningOutcomeMenu,
  LearningOutcomeSortBy,
  LearningOutcomeTestType
} from './assignment-overall';

import { DayOfWeek } from 'feature/common/global';

export const ASSIGNMENT_OVERALL_DETAIL_MENU = {
  [AssignmentOverallDetailMenu.ASSIGNMENT_OVERVIEW]: 'ภาพรวมการส่งงาน',
  [AssignmentOverallDetailMenu.LEARNING_OUTCOME]: 'ผลการเรียนรู้'
};

export const LEARNING_OUTCOME_MENU = {
  [LearningOutcomeMenu.OVERALL]: 'ภาพรวม',
  [LearningOutcomeMenu.ASSIGNMENT]: 'งานที่มอบหมาย (K)',
  [LearningOutcomeMenu.PRE_POST_TEST]: 'แบบทดสอบก่อนเรียน-หลังเรียน'
};

export const LEARNING_OUTCOME_SORT_MAPPER = {
  [LearningOutcomeSortBy.CLASSROOM_STUDENT_NUMBER_ASC]: 'เลขที่',
  [LearningOutcomeSortBy.NAME_ASC]: 'ชื่อ',
  [LearningOutcomeSortBy.TOTAL_SCORE_DESC]: 'คะแนน มาก - น้อย',
  [LearningOutcomeSortBy.TOTAL_SCORE_ASC]: 'คะแนน น้อย - มาก'
};

export const LEARNING_OUTCOME_TEST_TYPE_MAPPER = {
  [LearningOutcomeTestType.PRE_TEST]: 'คะแนนก่อนเรียน',
  [LearningOutcomeTestType.POST_TEST]: 'คะแนนหลังเรียน'
};

export const DAY_OF_WEEK_COLOR_MAPPER = {
  [DayOfWeek.MONDAY]: 'monday-yellow',
  [DayOfWeek.TUESDAY]: 'tuesday-pink',
  [DayOfWeek.WEDNESDAY]: 'wednesday-green',
  [DayOfWeek.THURSDAY]: 'thursday-orange',
  [DayOfWeek.FRIDAY]: 'dark-blue',
  [DayOfWeek.SATURDAY]: 'dark-purple',
  [DayOfWeek.SUNDAY]: 'red-aksorn'
};
