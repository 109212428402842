/** @jsx jsx */

import { Drawer, Modal } from 'antd';
import { GhostButton, PrimaryButton } from 'feature/common/components/Button';
import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';

import { MediaDetailDrawerBody } from './MediaDetailDrawerBody';
import { MediaDetailDrawerFooter } from './MediaDetailDrawerFooter';
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg';
import { createClassPeriodLicense } from 'api/media/createClassPeriodLicense';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useParams } from 'react-router-dom';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';

interface MediaDetailDrawerProps {
  title: string;
  subTitle: string;
  visible: boolean;
  schoolLicenseId?: string;
  subjectId?: string;
  gradeLevelId?: string;
  tableLoading: boolean | undefined;
  refetchMediaData: Function;
  // fetchTableData: Function;
  onClose: () => void | undefined;
}

export const MediaDetailDrawer = ({
  title = '',
  subTitle = '',
  visible,
  schoolLicenseId,
  subjectId,
  gradeLevelId,
  tableLoading,
  refetchMediaData,
  // fetchTableData,
  onClose
}: MediaDetailDrawerProps) => {
  const [selectedMedia, setSelectedMedia] = useState<any>([]);
  const [licenseData, setLicenseData] = useState<any>({});
  const [
    isConfirmationModalVisible,
    setIsConfirmationModalVisible
  ] = useState<boolean>(false);
  const [
    isInSufficientModalVisible,
    setIsInSufficientModalVisible
  ] = useState<boolean>(false);
  const [page, setPage] = useState('select');
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentSubTitle, setCurrentSubTitle] = useState(subTitle);
  const [creating, setCreating] = useState<boolean | undefined>(false);
  const { productId } = useParams() as {
    productId: string;
  };

  const handleOnClose = () => {
    onClose();
  };

  const resetState = () => {
    setSelectedMedia([]);
    setLicenseData({});
    setIsConfirmationModalVisible(false);
    setPage('select');
    setCurrentTitle(title);
    setCurrentSubTitle(subTitle);
    setCreating(false);
  };

  const { data: schoolYears, loading: isSchoolYearLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();

  const schoolYearId = useMemo(() => {
    if (schoolYears?.length > 0 && !isSchoolYearLoading && currentSchoolYears) {
      return currentSchoolYears.schoolYearId;
    }
  }, [isSchoolYearLoading, currentSchoolYears]);

  const handleSubmitForm = async () => {
    let newClassPeriods = selectedMedia.map((eachMedia: any) => ({
      classPeriodId: eachMedia.classPeriodId
    }));
    let newData = {
      schoolLicenseId: schoolLicenseId,
      classPeriods: newClassPeriods,
      productId: productId // pun add
    };
    //console.log('newData', newData);
    //console.log('productId', productId);
    setCreating(true);
    try {
      var { data: summaryData } = await createClassPeriodLicense(newData);
      // console.log('summaryData1', summaryData);
      let newLicenseUsages = summaryData?.licenseUsages || [];
      newLicenseUsages = newLicenseUsages.filter(
        (newLicenseUsage: any) =>
          newLicenseUsage.schoolLicenseId === summaryData?.schoolLicenseId
      );
      summaryData['licenseUsages'] = newLicenseUsages;
      //console.log('summaryData2', summaryData);
      setLicenseData(summaryData);
      refetchMediaData();
      /* fetchTableData({
        schoolYearId: schoolYearId,
        schoolLicenseId: schoolLicenseId
      }); */
      setCurrentTitle('ตัดสื่อเข้าคาบ');
      setCurrentSubTitle('');
      setPage('summary');
      setIsConfirmationModalVisible(false);
    } catch (error) {
      const { data: errorsResponse } = error?.response;
      //console.log('error', error);
      if (errorsResponse?.code === 400) {
        setIsConfirmationModalVisible(false);
        setIsInSufficientModalVisible(true);
      }
    } finally {
      setCreating(false);
    }
  };

  const sameGrade = () => {
    return selectedMedia?.[0]?.gradeLevelId === gradeLevelId;
  };

  const sameSubject = () => {
    return selectedMedia?.[0]?.subjectId === subjectId;
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      destroyOnClose={true}
      visible={visible}
      width={480}
      onClose={handleOnClose}
      afterVisibleChange={resetState}
      maskClosable={false}
      footer={
        <MediaDetailDrawerFooter
          page={page}
          handleOnClose={handleOnClose}
          selectedMedia={selectedMedia}
          setIsConfirmationModalVisible={setIsConfirmationModalVisible}
        />
      }
      footerStyle={{
        border: 'none',
        padding: '20px 40px'
      }}
      css={css`
        .ant-drawer-body {
          padding-bottom: 0px;
        }
      `}
    >
      <div className="font-bold text-xl mb-4">{currentTitle}</div>
      <div className="text-base mb-4">{currentSubTitle}</div>
      <MediaDetailDrawerBody
        page={page}
        schoolYearId={schoolYearId as string}
        schoolLicenseId={schoolLicenseId}
        subjectId={subjectId}
        gradeLevelId={gradeLevelId}
        selectedMedia={selectedMedia}
        setSelectedMedia={setSelectedMedia}
        licenseData={licenseData}
      />
      {/* <Modal
        title={selectedMedia?.[0]?.gradeLevelId === gradeLevelId 'ยืนยันการเพิ่มคาบและตัดสื่อเข้าคาบ ?'}
        subTitle="สื่อที่ตัดแล้วไม่สามารถคืนสิทธิ์ได้"
        visible={isConfirmationModalVisible}
        cancel={{
          label: 'ยกเลิก',
          onClick: () => setIsConfirmationModalVisible(false)
        }}
        submit={{
          label: 'ตกลง',
          loading: creating || tableLoading,
          onClick: handleSubmitForm
        }}
      /> */}
      <Modal
        visible={isInSufficientModalVisible}
        width={386}
        footer={null}
        closable={false}
        onCancel={() => setIsInSufficientModalVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-6 pb-6 px-14">
          <WarningIcon className="mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'จำนวนสื่อไม่เพียงพอ'}
          </h3>
          <p className="text-sm text-center">
            {
              'จำนวนสิทธิ์ในการใช้สื่อไม่เพียงพอกับครูและนักเรียน จึงไม่สามารถเพิ่ม ห้องเรียนที่เลือกได้ กรุณาแจ้งผู้ดูแลระบบเพื่อเพิ่มจำนวนสิทธิ์ก่อนเลือกห้องอีกครั้ง'
            }
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <PrimaryButton
                className="w-24"
                onClick={() => setIsInSufficientModalVisible(false)}
              >
                {'รับทราบ'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isConfirmationModalVisible}
        width={sameGrade() && sameSubject() ? 368 : 386}
        footer={null}
        closable={false}
        onCancel={() => setIsConfirmationModalVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-6 pb-6 px-14">
          {sameGrade() && sameSubject() ? (
            <div></div>
          ) : (
            <WarningIcon className="mb-2" />
          )}
          <h3 className="font-bold text-xl mb-3 text-center">
            {sameGrade() && sameSubject()
              ? 'ยืนยันการเพิ่มคาบและตัดสื่อเข้าคาบ ?'
              : !sameGrade() && sameSubject()
              ? 'ท่านต้องการตัดสื่อเข้าห้องเรียน ข้ามระดับชั้นใช่หรือไม่?'
              : sameGrade() && !sameSubject()
              ? 'ท่านต้องการตัดสื่อเข้าห้องเรียน ข้ามวิชาใช่หรือไม่?'
              : 'ท่านต้องการตัดสื่อเข้าห้องเรียน ข้ามระดับชั้นและวิชาใช่หรือไม่?'}
          </h3>
          <p className="text-sm text-center">
            {sameGrade() && sameSubject()
              ? 'สื่อที่ตัดแล้วไม่สามารถคืนสิทธิ์ได้'
              : 'หากกดยืนยันระบบจะทำการตัดสื่อของท่านทันที'}
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <GhostButton
                className="w-24"
                onClick={() => setIsConfirmationModalVisible(false)}
              >
                {'ยกเลิก'}
              </GhostButton>
              <PrimaryButton
                className="w-24"
                loading={creating || tableLoading}
                onClick={handleSubmitForm}
              >
                {'ยืนยัน'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
