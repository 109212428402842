import Axios from 'axios';
import { Domain } from 'feature/common/global';
import React from 'react';
import { SidebarMenuKey } from 'feature/common/components/sidebar.type';
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg';
import { appStore } from 'stores/appStore';
import env from '@beam-australia/react-env';
import { makeUseAxios } from 'axios-hooks';

const teachingAxios = Axios.create({
  baseURL: env('TEACHING_API_URL'),
  headers: { 'X-API-KEY': env('TEACHING_API_KEY') }
} as any);

const oneAccountAxios = Axios.create({
  baseURL: env('ONE_ACCOUNT_API_URL'),
  headers: { 'X-API-KEY': env('ONE_ACCOUNT_API_KEY') }
} as any);

function headersConfig(config: any) {
  try {
    const token =
      typeof localStorage !== 'undefined'
        ? localStorage.getItem('token') || ''
        : '';
    config.headers.Authorization = token;
    return config;
  } catch (e) {
    console.error(e);
  }
}

export const useOneAccountAxios = makeUseAxios({
  axios: oneAccountAxios
});

// function handleError(error: any) {
//   if (error?.response?.data?.message === 'user session is invalid or expired') {
//     const pathname = window.location.pathname.split('/')[1];

//     const redirectPath = Object.values(SidebarMenuKey).includes(pathname as any)
//       ? pathname
//       : SidebarMenuKey.DASHBOARD;

//     appStore.confirmModal({
//       title: (
//         <>
//           <div className="flex justify-center">
//             <WarningIcon />
//           </div>
//           <div className="flex justify-center text-xl font-medium my-4">
//             ขออภัย
//           </div>
//           <div className="text-sm text-mono-gray-100 flex justify-center">
//             ไม่สามารถทำรายการดังกล่าวได้เนื่องจากเซสชั่นหมดอายุหรือมีผู้ใช้งานเข้าใช้บัญชีของท่านจากอุปกรณ์อื่น
//           </div>
//         </>
//       ),
//       onSubmit: () => {
//         // window.location = `${env('TEACHING_WEB_URL')}/login?domain=${
//         //   Domain.SCHOOL_ADMIN
//         // }&r=${redirectPath}` as any;
//         const locationHostName =
//           window.location.protocol + '//' + window.location.host + '/';
//         const asPath = window.location.href
//           .replace(locationHostName, '')
//           .replace('&access=public', '');
//         const encodePath = encodeURIComponent(asPath);
//         window.location.replace(
//           `${env('TEACHING_WEB_URL')}/auth?domain=${
//             Domain.SCHOOL_ADMIN
//           }&redirectPath=${encodePath}`
//         );
//       },
//       submitText: 'ตกลง'
//     });
//   }
//   return Promise.reject(error);
// }

teachingAxios.interceptors.request.use(headersConfig);
oneAccountAxios.interceptors.request.use(headersConfig);
teachingAxios.interceptors.response.use(
  function (response) {
    return response;
  }
  //handleError
);
oneAccountAxios.interceptors.response.use(
  function (response) {
    return response;
  }
  // handleError
);

export { teachingAxios, oneAccountAxios };
