/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Fragment } from 'react';
import { Table } from 'antd';

interface ImportExcelTableProps {
  dataSource?: any[];
  columns: any[];
  title: string;
  subTitle: string;
  emptyText?: string;
}

const ImportExcelTableStyled = css`
  .ant-table {
    font-size: 12px !important;
  }

  .ant-table-cell {
    vertical-align: baseline !important;
  }

  .ant-table-thead > tr > th {
    background-color: #fbfbfb !important;
  }
`;
export const ImportExcelTable = ({
  dataSource,
  columns,
  title,
  subTitle,
  emptyText = 'ไม่มีข้อมูล'
}: ImportExcelTableProps) => {
  return (
    <Fragment>
      <h3 className="text-lg font-bold">{title}</h3>
      <h5 className="text-sm mt-2">{subTitle}</h5>
      <div className="text-sm text-secondary-gray-60 mt-6 mb-4 font-bold">
        {dataSource?.length || 0} รายการ
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        css={ImportExcelTableStyled}
        pagination={false}
        locale={{ emptyText }}
      />
    </Fragment>
  );
};
