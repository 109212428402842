import { Divider } from './AssignmentInfo';
import React from 'react';
import { Skeleton } from 'antd';

export const AssigmentInfoSkeleton = () => {
  return (
    <React.Fragment>
      <div className="flex items-center mb-3">
        <Skeleton.Avatar
          active
          shape={'square'}
          className="mr-5"
          style={{ width: 24, height: 24 }}
        />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
      <div className="grid grid-cols-1 gap-3">
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <Divider />
      <div className="text-base font-bold text-secondary-gray-60 mb-3">
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
      <div className="grid grid-cols-1 gap-3">
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </div>
    </React.Fragment>
  );
};
