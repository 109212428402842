import {
  ClassroomDto,
  LearningUnitOverallStatisticGetDto,
  LearningUnitOverallUserListDto
} from '@aksorn/teaching-backoffice-sdk';
import { Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { EmptyTable } from 'feature/common/components/Table/EmptyTable';
import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { IndividualStudentBarChart } from 'feature/common/barchart/IndividualStudentBarChart';
import { LEARNING_OUTCOME_SORT_MAPPER } from 'feature/assignment-overall/const';
import { LearningOutcomeSortBy } from '../assignment-overall';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useClassPeriod } from 'hooks/class-period/useClassPeriod';
import { useParams } from 'react-router-dom';

const { Option } = Select;

interface LearningOutcomeOverallIndividualIndividualScoreSectionProps {
  loading: boolean;
  learningUnitOverallUsers?: LearningUnitOverallUserListDto[];
  learningUnitOverallStatistic?: LearningUnitOverallStatisticGetDto;
  classroom?: ClassroomDto;
  sortBy: LearningOutcomeSortBy;
  setSortBy: (sortBy: LearningOutcomeSortBy) => void;
}

export const LearningOutcomeOverallIndividualScoreSection = observer(
  ({
    loading,
    learningUnitOverallStatistic,
    learningUnitOverallUsers,
    classroom,
    sortBy,
    setSortBy
  }: LearningOutcomeOverallIndividualIndividualScoreSectionProps) => {
    const { data: classPeriod, execute: getClassPeriod } = useClassPeriod();

    const params = useParams();

    const { classPeriodId } = params as any;

    const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

    useEffect(() => {
      if (classPeriodId) {
        getClassPeriod(classPeriodId);
      }
    }, [classPeriodId]);

    const fileName = useMemo(() => {
      if (classroom && classPeriod) {
        // return `${downloadDate}_${classroom.gradeLevel.name.th}_${classroom.classroomName}_${classPeriod.subject.name.th}`;
        return `${downloadDate}_${classroom?.gradeLevel?.name?.th}_${classroom?.classroomName}_${classPeriod?.subject?.name?.th}`;
      }
    }, [classroom, classPeriod]);

    return (
      <EvaluationContainer
        loading={{ isLoading: !classroom || loading, height: 562 }}
        title={`คะแนนรายบุคคล ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
        fileName={`${fileName}_คะแนนรายบุคคล`}
      >
        {learningUnitOverallUsers && learningUnitOverallUsers?.length > 0 ? (
          <>
            <div className="flex justify-between h-10 mb-8">
              <div className="flex">
                <LineGraphLabel
                  color="#FF8080"
                  label={`คะแนนเฉลี่ย ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
                  description={`${
                    learningUnitOverallStatistic?.classPeriod?.averageScore &&
                    learningUnitOverallStatistic?.classPeriod?.averageScore > 0
                      ? learningUnitOverallStatistic?.classPeriod?.averageScore.toFixed(
                          1
                        )
                      : '0.0'
                  } คะแนน/${
                    learningUnitOverallStatistic?.classPeriod
                      ?.numberOfSubmissions
                  } คน`}
                />
                <LineGraphLabel
                  color="#ED1C24"
                  label={`คะแนนเฉลี่ยทั้งระดับชั้น ${classroom?.gradeLevel.name.th}`}
                  description={`${
                    learningUnitOverallStatistic?.gradeLevel?.averageScore &&
                    learningUnitOverallStatistic?.gradeLevel?.averageScore > 0
                      ? learningUnitOverallStatistic?.gradeLevel?.averageScore.toFixed(
                          1
                        )
                      : '0.0'
                  } คะแนน/${
                    learningUnitOverallStatistic?.gradeLevel
                      ?.numberOfSubmissions
                  } คน`}
                />
              </div>
              <Form.Item
                label="เรียงตาม"
                className="w-48"
                css={css`
                  margin-bottom: 0 !important;
                `}
              >
                <Select
                  value={sortBy}
                  onSelect={(value) => {
                    setSortBy(value);
                  }}
                >
                  {Object.values(LearningOutcomeSortBy).map((sortBy) => (
                    <Option key={sortBy} value={sortBy}>
                      {LEARNING_OUTCOME_SORT_MAPPER[sortBy]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <IndividualStudentBarChart
              students={learningUnitOverallUsers}
              dataSource={[
                {
                  barColor: '#F99F39',
                  barWidth: 32,
                  value:
                    learningUnitOverallUsers?.map(
                      ({ totalScore }) => totalScore?.toFixed(1) ?? ''
                    ) ?? ''
                }
              ]}
              chartMaxScore={
                learningUnitOverallStatistic?.gradeLevel?.maxScore || 100
              }
              markLines={[
                {
                  lineColor: '#FF8080',
                  value:
                    learningUnitOverallStatistic?.classPeriod.averageScore ?? 0
                },
                {
                  lineColor: '#ED1C24',
                  value:
                    learningUnitOverallStatistic?.gradeLevel.averageScore ?? 0
                }
              ]}
              interval={10}
            />
          </>
        ) : (
          <EmptyTable
            emptyClassName="text-secondary-gray-60"
            emptyText={'ไม่มีนักเรียนในห้องเรียน'}
            style={{
              minHeight: '400px'
            }}
          />
        )}
      </EvaluationContainer>
    );
  }
);
