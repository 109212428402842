import { SchoolYearCurrentGetResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useCurrentSchoolYear = () => {
  const [{ data, loading, error }] = useAxios<SchoolYearCurrentGetResponse>({
    url: '/school-years/current'
  });

  return {
    data: data?.data,
    loading: loading,
    error: error
  };
};
