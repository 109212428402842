/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { EmptyTable } from 'feature/common/components/Table/EmptyTable';
import { ReactComponent as NoUserIcon } from 'assets/no-user.svg';

export const EmptyStudent = () => {
  return (
    <EmptyTable
      icon={
        <NoUserIcon
          className="w-20 h-20"
          css={css`
            path {
              fill: #e3e4e8;
            }
          `}
        />
      }
      emptyText={'ไม่มีนักเรียนในห้องเรียน'}
      emptyClassName={'text-secondary-gray-60'}
      style={{ minHeight: '400px' }}
    />
  );
};
