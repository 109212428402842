import { Menu, Tooltip } from 'antd';
import { css, jsx } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as Admin } from 'assets/admin.svg';
import { ReactComponent as AssignmentOverall } from 'assets/assignment-overall.svg';
/** @jsx jsx */
import { ReactComponent as Book } from 'assets/book.svg';
import { BounceLoader } from 'react-spinners';
import { ReactComponent as Classroom } from 'assets/classroom.svg';
import { ReactComponent as Evaluation } from 'assets/evaluation.svg';
import { ReactComponent as School } from 'assets/school.svg';
import { SidebarMenuKey } from './sidebar.type';
import { ReactComponent as Student } from 'assets/student.svg';
import { ReactComponent as Teacher } from 'assets/teacher.svg';
import env from '@beam-australia/react-env';

const menuItemClassName = 'flex items-center font-bold transition-colors px-4';

const menuItemCSS = css`
  height: 50px !important;
  svg path {
    transition-property: fill;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-duration: 0.3s;
    fill: #000000;
  }
  &:hover&:not(.ant-menu-item-disabled) {
    color: #ed1c24;
    svg path {
      fill: #ed1c24;
    }
  }
  &.ant-menu-item-disabled {
    svg path {
      fill: #000000;
      fill-opacity: 0.25;
      stroke: none;
    }
  }
  :active {
    background: #fff7f7 !important;
  }
`;
type ComingSoonProps = {
  children: any;
};

const ComingSoon = ({ children }: ComingSoonProps) => {
  return (
    <Tooltip
      placement="right"
      color="red"
      title={
        <span>
          <BounceLoader size="20" color="white" />
          <span style={{ marginLeft: '25px' }}>อยู่ระหว่างการพัฒนา</span>
        </span>
      }
    >
      {children}
    </Tooltip>
  );
};

export const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();

  const pathname = location.pathname.split('/')[1];

  const currentMenu = Object.values(SidebarMenuKey).includes(pathname as any)
    ? pathname
    : SidebarMenuKey.DASHBOARD;

  return (
    <Menu
      className="bg-white py-6 h-full fixed px-2 shadow-lg border-none"
      onClick={({ key }) => {
        if (!Object.values(SidebarMenuKey).includes(key as any)) {
          history.push(`/${SidebarMenuKey.DASHBOARD}`);
        } else {
          history.push(`/${key}`);
        }
      }}
      style={{ width: 189 }}
      selectedKeys={[currentMenu]}
      mode="inline"
      css={css`
        .ant-menu-item:after {
          border: none;
        }
        .ant-menu-item-selected {
          background: #fff7f7 !important;
          color: #ed1c24;
          border-radius: 10px;
          svg path {
            fill: #ed1c24;
          }
        }
      `}
    >
      <Menu.Item
        key={SidebarMenuKey.DASHBOARD}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <School className="w-5 h-5 mr-5 flex-shrink-0" />
        หน้าหลักโรงเรียน
      </Menu.Item>
      <Menu.Item
        key={SidebarMenuKey.CREATE_CLASSROOM}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <Classroom className="w-5 h-5 mr-5 flex-shrink-0" />
        สร้างห้องเรียน
      </Menu.Item>
      <Menu.Item
        key={SidebarMenuKey.MEDIA_MANAGEMENT_FOR_CLASSROOM}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <Book className="w-5 h-5 mr-5 flex-shrink-0" />
        จัดการสื่อในระบบ
      </Menu.Item>
      {/* <Menu.Item
        key={SidebarMenuKey.MEDIA_MANAGEMENT}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <Book className="w-5 h-5 mr-5 flex-shrink-0" />
        สื่อในระบบ
      </Menu.Item>
      <Menu.Item
        key={SidebarMenuKey.CLASSROOM_MANAGEMENT}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <Classroom className="w-5 h-5 mr-5 flex-shrink-0" />
        จัดการห้องเรียน
      </Menu.Item> */}
      <Menu.Item
        key={SidebarMenuKey.STUDENT_MANAGEMENT}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <Student className="w-5 h-5 mr-5 flex-shrink-0" />
        จัดการนักเรียน
      </Menu.Item>
      <Menu.Item
        key={SidebarMenuKey.TEACHER_MANAGEMENT}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <Teacher className="w-5 h-5 mr-5 flex-shrink-0" />
        จัดการครู
      </Menu.Item>
      <Menu.Item
        key={SidebarMenuKey.SCHOOL_ADMIN_MANAGEMENT}
        className={menuItemClassName}
        css={menuItemCSS}
        icon={<Admin className="w-5 h-5 mr-5 flex-shrink-0" />}
        disabled={!Boolean(parseInt(env('ENABLE_ADMIN_MANAGEMENT')))}
      >
        {!Boolean(parseInt(env('ENABLE_ADMIN_MANAGEMENT'))) ? (
          <ComingSoon>ผู้ดูแลระบบ</ComingSoon>
        ) : (
          'ผู้ดูแลระบบ'
        )}
      </Menu.Item>
      {/* <Menu.Item
        key={SidebarMenuKey.EVALUATION}
        className={menuItemClassName}
        css={menuItemCSS}
        icon={<Evaluation className="w-5 h-5 mr-5 flex-shrink-0" />}
        disabled={!Boolean(parseInt(env('ENABLE_EVALUATION')))}
      >
        {!Boolean(parseInt(env('ENABLE_EVALUATION'))) ? (
          <ComingSoon>การประเมินผล</ComingSoon>
        ) : (
          'การประเมินผล'
        )}
      </Menu.Item>
      <Menu.Item
        key={SidebarMenuKey.ASSIGNMENT_OVERALL}
        className={menuItemClassName}
        css={menuItemCSS}
      >
        <AssignmentOverall className="w-5 h-5 mr-5 flex-shrink-0" />
        ภาพรวมการส่งงาน
      </Menu.Item> */}
    </Menu>
  );
};
