import { identity, pickBy } from 'lodash';

import { teachingAxios } from 'utils/axios';

export const getProductLicenseUsages = async (params?: any) => {
  const { data: response } = await teachingAxios.get(
    `/product-license-usages`,
    {
      params
    }
  );
  return response;
};

export const getProductLicenseUsagesForClassrooms = async (params?: any) => {
  const { data: response } = await teachingAxios.get(
    `/product-license-usages/classrooms`,
    {
      params
    }
  );
  return response;
};
