/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { ImportExcelModalHeader } from './ImportExcelModalHeader';
import { Modal } from 'antd';
import { PrimaryButton } from 'feature/common/components/Button';
import { ReactNode } from 'react';
import { ValidationStep } from 'feature/dashboard/dashboard';

interface Props {
  title: string;
  visible: boolean;
  showValidationStep?: boolean;
  validationStep?: ValidationStep;
  children?: ReactNode;
  onClose?: () => void;
}

export const ImportExcelModal = ({
  visible,
  onClose,
  title,
  showValidationStep,
  validationStep,
  children
}: Props) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      title={
        <ImportExcelModalHeader
          title={title}
          showValidationStep={showValidationStep}
          validationStep={validationStep}
        />
      }
      css={css`
        width: 100% !important;
        height: 95% !important;
        top: 4%;
        .ant-modal-header {
          background-color: #ed1c24 !important;
          height: 62px !important;
          border-top-left-radius: 20px !important;
          border-top-right-radius: 20px !important;
        }
        .ant-modal-body {
          max-height: calc(100% - 62px);
          overflow-y: auto;
          padding-bottom: 6.5rem !important;
        }
        .ant-modal-content {
          height: 100% !important;
          border-radius: 20px !important;
          margin-right: auto;
          margin-left: auto;
        }
      `}
    >
      {children}
      <div className="absolute bottom-0 w-full left-0 h-20 bg-secondary-white py-4 px-10 flex justify-end items-center rounded-b-2.5xl">
        <PrimaryButton className="w-40" onClick={onClose}>
          รับทราบ
        </PrimaryButton>
      </div>
    </Modal>
  );
};
