import { SchoolTeacherValidateResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const getErrorMessage = (text: string) => {
  let errorMessage;
  switch (text) {
    case 'this account is already in other school':
      errorMessage = 'บัญชีผู้ใช้นี้อยู่ในโรงเรียนอื่น ไม่สามารถเพิ่มในระบบได้';
      break;
    case 'this account is not teacher':
      errorMessage =
        'บัญชีผู้ใช้นี้ไม่ใช่บัญชีสำหรับคุณครู ไม่สามารถเพิ่มในระบบได้';
      break;
    case 'this account is already in this school':
      errorMessage =
        'บัญชีผู้ใช้นี้อยู่ในโรงเรียนนี้แล้ว ไม่สามารถเพิ่มในระบบได้';
      break;
    case 'this account is pending school teacher request':
      errorMessage =
        'บัญชีผู้ใช้นี้กำลังรอการอนุมัติเข้าโรงเรียน ไม่สามารถเพิ่มในระบบได้';
      break;
    default:
      errorMessage = 'สร้างครูไม่สำเร็จ';
      break;
  }
  return errorMessage;
};

export const useValidateSchoolTeacher = () => {
  const [
    { data: response, loading, error },
    refetch
  ] = useAxios<SchoolTeacherValidateResponse>(
    {
      url: `/school-teachers/validate`,
      method: 'POST'
    },
    { manual: true }
  );

  return {
    data: response?.data,
    loading: loading,
    error,
    execute: refetch
  };
};
