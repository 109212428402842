import { ReactComponent as DownloadDisableIcon } from 'assets/download-disable.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { LEARNING_OUTCOME_MENU } from 'feature/assignment-overall/const';
import { LearningOutcomeMenu } from 'feature/assignment-overall/assignment-overall';
import { LearningOutcomeMenuButton } from 'feature/common/components/MenuButton';
import React from 'react';
import className from 'classnames';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { observer } from 'mobx-react-lite';

interface AssignmentOverallLearningOutcomeMenuProps {
  menu: LearningOutcomeMenu;
  setMenu: (menu: LearningOutcomeMenu) => void;
}
export const AssignmentOverallLearningOutcomeMenu = observer(
  ({ menu, setMenu }: AssignmentOverallLearningOutcomeMenuProps) => {
    const { downloadEvaluation, isDisableDownload } = evaluationDownloadStore;

    return (
      <div className="flex justify-between mb-6 overflow-x-auto md:w-full">
        <div className="flex justify-start items-center text-lg">
          {Object.values(LearningOutcomeMenu).map((key) => (
            <LearningOutcomeMenuButton
              key={key}
              isActive={menu === key}
              onClick={() => setMenu(key)}
            >
              {LEARNING_OUTCOME_MENU[key]}
            </LearningOutcomeMenuButton>
          ))}
        </div>
        <button
          className={className(
            'flex items-center text-base font-bold rounded-xl outline-none px-4 ml-4 h-10 w-32',
            {
              'bg-red-aksorn text-white transition-colors duration-200 ease-in-out': !isDisableDownload,
              'bg-gray-10 text-secondary-gray-60 cursor-not-allowed': isDisableDownload
            }
          )}
          disabled={isDisableDownload}
          onClick={() => {
            downloadEvaluation(menu);
          }}
        >
          {isDisableDownload ? (
            <DownloadDisableIcon className="mr-2 w-5 h-5" />
          ) : (
            <DownloadIcon className="mr-2 w-5 h-5" />
          )}
          ดาวน์โหลด
        </button>
      </div>
    );
  }
);
