import {
  ClassroomListDto,
  GradeLevelDto,
  SubjectDto
} from '@aksorn/teaching-backoffice-sdk';
import { action, makeObservable, observable } from 'mobx';

export const SELECT_ALL = 'all';

export type FilterDataProps = {
  selectedSchoolYearId: string;
  selectedGradeLevel: GradeLevelDto;
  selectedClassroom?: ClassroomListDto;
  selectedSubject?: SubjectDto;
};

export enum LevelResetFilterData {
  SCHOOL_YEAR = 'school-year',
  GRADE_LEVEL = 'grade-level',
  CLASSROOM = 'classroom'
}

class EvaluationStore {
  isLoadingFilterData: boolean = true;
  defaultGradeLevel!: GradeLevelDto;
  filterData: FilterDataProps = {
    selectedSchoolYearId: '',
    selectedGradeLevel: this.defaultGradeLevel,
    selectedClassroom: undefined,
    selectedSubject: undefined
  };

  constructor() {
    makeObservable(this, {
      filterData: observable,
      isLoadingFilterData: observable,
      setSelectedSchoolYearId: action,
      setSelectedGradeLevel: action,
      setSelectedClassroom: action,
      setSelectedSubject: action,
      setDefaultGradeLevel: action,
      setIsLoadingFilterData: action
    });
  }

  resetFilterData = (levelReset: LevelResetFilterData) => {
    switch (levelReset) {
      case LevelResetFilterData.SCHOOL_YEAR: {
        this.filterData.selectedGradeLevel = this.defaultGradeLevel;
        this.filterData.selectedClassroom = undefined;
        this.filterData.selectedSubject = undefined;
        break;
      }
      case LevelResetFilterData.GRADE_LEVEL: {
        this.filterData.selectedClassroom = undefined;
        this.filterData.selectedSubject = undefined;
        break;
      }
      case LevelResetFilterData.CLASSROOM: {
        this.filterData.selectedSubject = undefined;
        break;
      }
    }
  };

  setSelectedSchoolYearId = (schoolYearId: string) => {
    this.filterData.selectedSchoolYearId = schoolYearId;
    this.resetFilterData(LevelResetFilterData.SCHOOL_YEAR);
  };

  setSelectedGradeLevel = (gradeLevel: GradeLevelDto) => {
    this.filterData.selectedGradeLevel = gradeLevel;
    this.resetFilterData(LevelResetFilterData.GRADE_LEVEL);
  };

  setSelectedClassroom = (classroom: ClassroomListDto | undefined) => {
    this.filterData.selectedClassroom = classroom;
    this.resetFilterData(LevelResetFilterData.CLASSROOM);
  };

  setSelectedSubject = (subject: SubjectDto | undefined) => {
    this.filterData.selectedSubject = subject;
  };

  setDefaultGradeLevel = (defaultGradeLevel: GradeLevelDto) => {
    this.defaultGradeLevel = defaultGradeLevel;
  };

  setIsLoadingFilterData = (loading: boolean) => {
    this.isLoadingFilterData = loading;
  };
}

export const evaluationStore = new EvaluationStore();
