/** @jsx jsx */
import {
  ClassPeriodScheduleDto,
  ClassPeriodSubjectGetDto,
  ClassPeriodTeacherGetDto
} from '@aksorn/teaching-backoffice-sdk';
import { Col, Row } from 'antd';
import { DayOfWeek, DayOfWeekMapper, getCover } from 'feature/common/global';
import { Fragment, useMemo } from 'react';
import { css, jsx } from '@emotion/react';

import { TwoLineTruncate } from 'feature/common/components/TwoLineTruncate';

interface AssignmentOverallClassPeriodCardProps {
  subject: ClassPeriodSubjectGetDto;
  classPeriodName: string;
  mainSchoolTeacher: ClassPeriodTeacherGetDto;
  classPeriodSchedules: ClassPeriodScheduleDto[];
  onClick: () => void;
  onSeeAllSchedulesClick: () => void;
}

export const AssignmentOverallClassPeriodCard = ({
  subject,
  classPeriodName,
  mainSchoolTeacher,
  classPeriodSchedules,
  onClick,
  onSeeAllSchedulesClick
}: AssignmentOverallClassPeriodCardProps) => {
  const sortedClassPeriodSchedules = useMemo(() => {
    return classPeriodSchedules?.sort((a, b) => {
      const daysOfWeekIndex = Object.values(DayOfWeek);
      const idxA = daysOfWeekIndex.findIndex((e) => (e as any) === a.dayOfWeek);
      const idxB = daysOfWeekIndex.findIndex((e) => (e as any) === b.dayOfWeek);
      return idxA - idxB;
    });
  }, [classPeriodSchedules]);

  return (
    <div
      className="bg-white w-full cursor-pointer"
      css={css`
        box-shadow: 0px 10px 30px rgba(46, 47, 56, 0.1);
        height: 400px;
        border-radius: 20px;
      `}
      onClick={onClick}
    >
      <img
        alt="classroom cover"
        src={getCover(subject?.name?.th)}
        className="w-full object-cover"
        css={css`
          height: 162px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        `}
      />
      <div className="px-6 py-3 w-full">
        <div className="text-xs font-semibold text-gray-60">
          {subject?.name?.th}
        </div>
        <TwoLineTruncate className="h-12 font-semibold mt-2">
          {classPeriodName}
        </TwoLineTruncate>
        <div className="mt-2 mb-4 flex justify-start items-center">
          <img
            alt="teacher profile"
            src="/assets/profile-colour.png"
            className="w-6"
          />
          <div className="text-sm ml-4 truncate">
            {mainSchoolTeacher?.firstName?.th ? mainSchoolTeacher?.title + mainSchoolTeacher?.firstName?.th + ' ' + mainSchoolTeacher?.lastName?.th : null}
            {/* unlock mainSchoolTeacher 19/08/2022 */}
            {/* {console.log("mainSchoolTeacher.deletedAt",mainSchoolTeacher?.deletedAt)} */}
            {/* {mainSchoolTeacher?.deletedAt ? (
                <span style={{color:"red",fontWeight:"bold"}}>{' (ลาออกแล้ว)'}</span>
              ) : null} */}
              {mainSchoolTeacher?.deletedAt ? (
                <span>{' (ย้ายโรงเรียน)'}</span>
              ) : null}
          </div>
        </div>
        {sortedClassPeriodSchedules
          ?.slice(0, 3)
          ?.map(({ dayOfWeek, startTime, endTime }) => (
            <Row className="mb-1">
              <Col className="text-sm" span={10}>
                {DayOfWeekMapper[dayOfWeek]}
              </Col>
              <Col className="text-sm" span={14}>
                {startTime?.slice(0, 5) + ' - ' + endTime?.slice(0, 5)}
              </Col>
            </Row>
          ))}
        {classPeriodSchedules?.length >= 4 ? (
          <div
            className="underline text-primary-blue text-center text-sm mt-2"
            onClick={(event) => {
              event.stopPropagation();
              onSeeAllSchedulesClick();
            }}
          >
            แสดงคาบทั้งหมด
          </div>
        ) : null}
      </div>
    </div>
  );
};
