import 'antd/dist/antd.css';
import './style/style.css';
import './style/App.css';
import './style/tailwind.css';
import 'dayjs/locale/th';
import './i18n';

import * as dayjs from 'dayjs';
import * as serviceWorker from './serviceWorker';

import App from './App';
import LRU from 'lru-cache';
import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'axios-hooks';
import { teachingAxios } from 'utils/axios';

dayjs.locale('th');

const cache = new LRU({ max: 10 });

configure({ cache, axios: teachingAxios });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
