import React, { useEffect, useState } from 'react';

import { UserGuideContentMain } from 'feature/user-guide/UserGuideContentMain';
import { useHistory } from 'react-router-dom';
import { userStore } from 'stores/userStore';

export const StudentUserGuidePage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { me } = userStore;

  useEffect(() => {
    checkPermission();
  }, [loading]);

  const checkPermission = async () => {
    setLoading(true);
    if (!me?.isSchoolAdmin) await history.push(`/`);
    setLoading(false);
  };
  return <>{!loading && <UserGuideContentMain />}</>;
};
