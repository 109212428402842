/** @jsx jsx */
import { Divider, Form, Input, Select, Spin, message } from 'antd';
import { FormRule, getRule } from 'utils/getRule';
import { Global, css, jsx } from '@emotion/react';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import {
  SchoolTeacherCreateBody,
  SchoolTeacherUpdateBody,
  SchoolTeacherValidateBody
} from '@aksorn/teaching-backoffice-sdk';
import {
  UserGender,
  UserIdentityDocumentType,
  UserType,
  genderMapper
} from 'feature/common/user';
import {
  getErrorMessage,
  useValidateSchoolTeacher
} from 'hooks/school-teacher/useValidateSchoolTeacher';
import { useEffect, useState } from 'react';
import {
  useSchoolTeacher,
  useSchoolTeacherType
} from 'hooks/school-teacher/useSchoolTeacher';

import { DateOfBirthPicker } from 'feature/common/components/DateOfBirthPicker';
import { TeacherFormStep } from './teacher-management';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { map } from 'lodash';
import { useCreateSchoolTeacher } from 'hooks/school-teacher/useCreateSchoolTeacher';
import { useForm } from 'antd/lib/form/Form';
import { useSchoolTeacherTypeActive } from 'hooks/school-teacher/useSchoolTeacherTypeActive';
import { useSchoolTeacherTypeApprove } from 'hooks/school-teacher/useSchoolTeacherTypeApprove';
import { useUpdateSchoolTeacher } from 'hooks/school-teacher/useUpdateSchoolTeacher';
import { validateCitizenId } from 'utils/validateCitizenId';

const { Option } = Select;

const titleOptions = {
  [UserType.TEACHER]: ['นาย', 'นาง', 'นางสาว'],
  [UserType.STUDENT]: ['ด.ช.', 'ด.ญ.', 'นาย', 'นาง', 'นางสาว']
};

interface SchoolTeacherFormProps {
  schoolTeacherId?: string;
  initialStep?: TeacherFormStep;
  onClose: () => void;
  onSubmit: () => void;
}

export const SchoolTeacherForm = ({
  initialStep = TeacherFormStep.EMAIL,
  schoolTeacherId,
  onClose,
  onSubmit
}: SchoolTeacherFormProps) => {
  const [step, setStep] = useState<TeacherFormStep>(initialStep);
  const [email, setEmail] = useState('');
  const [idType, setIdType] = useState(UserIdentityDocumentType.CITIZEN_ID);

  const {
    data: schoolTeacher,
    loading: fetching,
    execute: getSchoolTeacher
  } = useSchoolTeacher();

  const {
    data: schoolTeacherType,
    loading: fetchingType,
    execute: getSchoolTeacherType
  } = useSchoolTeacherType();

  const {
    loading: creating,
    execute: createSchoolTeacher
  } = useCreateSchoolTeacher();

  const {
    loading: updating,
    execute: updateSchoolTeacher
  } = useUpdateSchoolTeacher();

  const {
    loading: approving,
    execute: approveSchoolTeacherType
  } = useSchoolTeacherTypeApprove();

  const {
    loading: activing,
    execute: activeSchoolTeacherType
  } = useSchoolTeacherTypeActive();

  const {
    data: schoolTeacherProfile,
    loading: validating,
    execute: validateSchoolTeacher
  } = useValidateSchoolTeacher();

  const [emailForm] = useForm();
  const [informationForm] = useForm();

  useEffect(() => {
    if (schoolTeacherId) getSchoolTeacher(schoolTeacherId);
  }, [schoolTeacherId]);

  useEffect(() => {
    if (schoolTeacher) {
      setEmail(schoolTeacher.email);
      setIdType(schoolTeacher.identityDocument.type as any);
      emailForm.setFieldsValue({
        email: schoolTeacher.email
      });
      informationForm.setFieldsValue({
        title: schoolTeacher.title,
        firstNameTH: schoolTeacher.firstName.th,
        lastNameTH: schoolTeacher.lastName.th,
        firstNameEN: schoolTeacher.firstName.en,
        lastNameEN: schoolTeacher.lastName.en,
        gender: schoolTeacher.gender,
        dateOfBirth: schoolTeacher.dateOfBirth
          ? dayjs(schoolTeacher.dateOfBirth).year().toString()
          : undefined
        // identityDocument: schoolTeacher.identityDocument.number
      });
    }
  }, [schoolTeacher]);

  useEffect(() => {
    if (!validating && schoolTeacherProfile) {
      setEmail(schoolTeacherProfile.email);
      setIdType(schoolTeacherProfile.identityDocument.type as any);
      emailForm.setFieldsValue({
        email: schoolTeacherProfile.email
      });
      informationForm.setFieldsValue({
        title: schoolTeacherProfile.title,
        firstNameTH: schoolTeacherProfile.firstName.th,
        lastNameTH: schoolTeacherProfile.lastName.th,
        firstNameEN: schoolTeacherProfile.firstName.en,
        lastNameEN: schoolTeacherProfile.lastName.en,
        gender: schoolTeacherProfile.gender,
        dateOfBirth: schoolTeacherProfile.dateOfBirth
          ? dayjs(schoolTeacherProfile.dateOfBirth).year().toString()
          : undefined
        // identityDocument: schoolTeacherProfile.identityDocument.number
      });
    }
  }, [validating, schoolTeacherProfile]);

  const handleSubmitEmail = async (value: { email: string }) => {
    try {
      setEmail(value.email);
      await validateSchoolTeacher({
        data: { email: value.email } as SchoolTeacherValidateBody
      });
      setStep(TeacherFormStep.BASIC_INFO);
    } catch (error) {
      message.error(getErrorMessage(error?.response?.data?.message));
    }
  };

  const handleSubmitForm = async (value: any) => {
    const loading = message.loading(
      `กำลัง${!schoolTeacherId ? 'สร้าง' : 'แก้ไข'}คุณครู...`
    );
    try {
      if (!schoolTeacherId) {
        if (schoolTeacherProfile?.email) {
          const { data: teacherTypeResponse } = await getSchoolTeacherType(
            schoolTeacherProfile?.email
          );
          schoolTeacherId = teacherTypeResponse.data.schoolTeacherId;
          if (schoolTeacherId) {
            await updateSchoolTeacher({
              schoolTeacherId,
              data: {
                title: value.title,
                firstName: { th: value.firstNameTH, en: value.firstNameEN },
                lastName: { th: value.lastNameTH, en: value.lastNameEN },
                gender: value.gender,
                dateOfBirth: dayjs(value.dateOfBirth)
                  .startOf('year')
                  .toISOString(),
                identityDocument: {
                  type: UserIdentityDocumentType.PASSPORT_ID,
                  number: 'P'
                } as any
              } as SchoolTeacherUpdateBody
            });
            // await approveSchoolTeacherType(schoolTeacherId);
            await activeSchoolTeacherType(schoolTeacherId);
          } else {
            await createSchoolTeacher({
              data: {
                email,
                title: value.title,
                firstName: { th: value.firstNameTH, en: value.firstNameEN },
                lastName: { th: value.lastNameTH, en: value.lastNameEN },
                gender: value.gender,
                dateOfBirth: dayjs(value.dateOfBirth)
                  .startOf('year')
                  .toISOString(),
                identityDocument: {
                  type: UserIdentityDocumentType.PASSPORT_ID,
                  number: 'P'
                } as any
              } as SchoolTeacherCreateBody
            });
            const { data: teacherTypeResponse } = await getSchoolTeacherType(
              email
            );
            schoolTeacherId = teacherTypeResponse.data.schoolTeacherId;
            if (schoolTeacherId) {
              await approveSchoolTeacherType(schoolTeacherId);
              // await activeSchoolTeacherType(schoolTeacherId);
            }
          }
        } else {
          await createSchoolTeacher({
            data: {
              email,
              title: value.title,
              firstName: { th: value.firstNameTH, en: value.firstNameEN },
              lastName: { th: value.lastNameTH, en: value.lastNameEN },
              gender: value.gender,
              dateOfBirth: dayjs(value.dateOfBirth)
                .startOf('year')
                .toISOString(),
              identityDocument: {
                type: UserIdentityDocumentType.PASSPORT_ID,
                number: 'P'
              } as any
            } as SchoolTeacherCreateBody
          });
          const { data: teacherTypeResponse } = await getSchoolTeacherType(
            email
          );
          schoolTeacherId = teacherTypeResponse.data.schoolTeacherId;
          if (schoolTeacherId) {
            await approveSchoolTeacherType(schoolTeacherId);
          }
        }
        message.success(`สร้างคุณครูสำเร็จ`);
      } else {
        await updateSchoolTeacher({
          schoolTeacherId,
          data: {
            title: value.title,
            firstName: { th: value.firstNameTH, en: value.firstNameEN },
            lastName: { th: value.lastNameTH, en: value.lastNameEN },
            gender: value.gender,
            dateOfBirth: dayjs(value.dateOfBirth).startOf('year').toISOString(),
            identityDocument: {
              type: UserIdentityDocumentType.PASSPORT_ID,
              number: 'P'
            } as any
          } as SchoolTeacherUpdateBody
        });
        // await approveSchoolTeacherType(schoolTeacherId);
        message.success(`แก้ไขคุณครูสำเร็จ`);
      }
      // message.success(`${!schoolTeacherId ? 'สร้าง' : 'แก้ไข'}คุณครูสำเร็จ`);
      emailForm.resetFields();
      informationForm.resetFields();
      onSubmit();
    } catch (error) {
      console.error(error);
      message.error(`${!schoolTeacherId ? 'สร้าง' : 'แก้ไข'}คุณครูไม่สำเร็จ`);
    } finally {
      loading();
    }
  };

  switch (step) {
    case TeacherFormStep.EMAIL:
      return (
        <Form form={emailForm} onFinish={handleSubmitEmail}>
          <Form.Item
            name="email"
            label="อีเมล"
            normalize={(value) => value.trim()}
            rules={[
              getRule(FormRule.REQUIRE, 'กรุณากรอกอีเมล'),
              getRule(FormRule.EMAIL)
            ]}
          >
            <Input className="rounded-xl h-10" placeholder="อีเมล" />
          </Form.Item>
          <div className="flex mt-96 pt-36 items-center justify-end absolute inset-x-10 bottom-5">
            <SecondaryButton className="mx-4" onClick={onClose}>
              ยกเลิก
            </SecondaryButton>
            <PrimaryButton htmlType="submit" loading={validating}>
              ถัดไป
            </PrimaryButton>
          </div>
        </Form>
      );
    case TeacherFormStep.BASIC_INFO:
      return (
        <Spin spinning={fetching || fetchingType}>
          <Global
            styles={css`
              #form-teacher-profile .ant-select-selector {
                width: 100% !important;
              }
            `}
          />
          <div className="flex justify-between items-center">
            <div className="font-bold text-base"> อีเมล : {email}</div>
            {!schoolTeacherId && (
              <SecondaryButton
                className="font-bold rounded-xl border-black hover:text-primary hover:border-primary"
                onClick={() => setStep(TeacherFormStep.EMAIL)}
              >
                แก้ไขอีเมล
              </SecondaryButton>
            )}
          </div>
          <Divider className="mt-6 mb-8" />
          <Form
            name="form-teacher-profile"
            form={informationForm}
            onFinish={handleSubmitForm}
          >
            <Global
              styles={css`
                .ant-spin-container {
                  height: 614px;
                  @media (min-height: 900px) {
                    height: calc(100vh - 284px);
                  }
                }
              `}
            />
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="title"
                label="คำนำหน้าชื่อ"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกคำนำหน้า')]}
              >
                <Select placeholder="คำนำหน้า">
                  {titleOptions[UserType.TEACHER].map((title, idx) => (
                    <Option value={title} key={`${title}_${idx}`}>
                      {title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="firstNameTH"
                label="ชื่อ (ภาษาไทย)"
                rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อภาษาไทย')]}
              >
                <Input
                  className="rounded-xl h-10"
                  placeholder="ชื่อ (ภาษาไทย)"
                />
              </Form.Item>
              <Form.Item
                name="lastNameTH"
                label="นามสกุล (ภาษาไทย)"
                rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกนามสกุลภาษาไทย')]}
              >
                <Input
                  className="rounded-xl h-10"
                  placeholder="นามสกุล (ภาษาไทย)"
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item name="firstNameEN" label="ชื่อ (ภาษาอังกฤษ)">
                <Input
                  className="rounded-xl h-10"
                  placeholder="ชื่อ (ภาษาอังกฤษ)"
                />
              </Form.Item>
              <Form.Item name="lastNameEN" label="นามสกุล (ภาษาอังกฤษ)">
                <Input
                  className="rounded-xl h-10"
                  placeholder="นามสกุล (ภาษาอังกฤษ)"
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="gender"
                label="เพศ"
                rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเพศ')]}
              >
                <Select placeholder="เพศ">
                  {map(UserGender, (value) => (
                    <Option value={value} key={value}>
                      {genderMapper[value]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="dateOfBirth" label="ปีเกิด">
                <DateOfBirthPicker />
              </Form.Item>
            </div>
            {/* <Divider className="mt-2 mb-8" /> */}
            {/* <div className="flex items-center w-2/3 mb-6">
              <div
                className={classNames(
                  'flex cursor-pointer justify-center items-center w-60 mr-2 h-7 rounded-full',
                  {
                    'text-white bg-primary font-bold':
                      idType === UserIdentityDocumentType.CITIZEN_ID,
                    'order-gray-400 border':
                      idType !== UserIdentityDocumentType.CITIZEN_ID
                  }
                )}
                onClick={() => {
                  setIdType(UserIdentityDocumentType.CITIZEN_ID);
                }}
              >
                บัตรประชาชน
              </div>
              <div
                className={`flex cursor-pointer justify-center items-center w-60  h-7 rounded-full ${
                  idType === UserIdentityDocumentType.PASSPORT_ID
                    ? 'text-white bg-primary font-bold'
                    : 'text-gray-900 border-gray-400 border'
                }`}
                onClick={() => {
                  setIdType(UserIdentityDocumentType.PASSPORT_ID);
                }}
              >
                หนังสือเดินทาง
              </div>
            </div>
            <Form.Item
              name="identityDocument"
              normalize={(value) => value.trim()}
              rules={[
                getRule(FormRule.REQUIRE, 'กรุณากรอกเลขที่บัตรประชาชน'),
                () => ({
                  validator(rule, value) {
                    const isIdTypeCitizenId =
                      idType === UserIdentityDocumentType.CITIZEN_ID;
                    if (value && isIdTypeCitizenId && !validateCitizenId(value))
                      return Promise.reject('บัตรประชาชนไม่ถูกต้อง');
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input
                className="rounded-xl h-10 mb-3"
                placeholder={`${
                  idType === UserIdentityDocumentType.CITIZEN_ID
                    ? 'หมายเลขบัตรประชาชน'
                    : 'หมายเลขหนังสือเดินทาง'
                }`}
              />
            </Form.Item> */}
            <div className="flex items-center justify-end absolute bottom-0 inset-x-0">
              <SecondaryButton className="mx-4" onClick={onClose}>
                ยกเลิก
              </SecondaryButton>
              <PrimaryButton
                htmlType="submit"
                loading={creating || updating || approving}
              >
                {!schoolTeacherId ? 'เพิ่ม' : 'แก้ไข'}
              </PrimaryButton>
            </div>
          </Form>
        </Spin>
      );
  }
};
