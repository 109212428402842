import React, { useEffect, useRef, useState, version } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// import { ByteArkVideoPlayer } from './vdoPlayer/ByteArkPlayer';
import { UserGuideSidebar } from './UserGuideSideBar';
// import { YoutubePlayer } from './vdoPlayer/YouTubePlayer';
import { css } from '@emotion/react';
import { getUserGuideByTopic } from 'api/user-guide/getUserGuideByTopic';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

// May 24 jun 2022

const CardStyled = styled.div`box-shadow: 0px 2px 10px rgba(46, 47, 56, 0.1);
  border-radius: 16px
  padding: 2.8rem`;

type UserGuideData = {
  userGuideId: string;
  userGuideType: string;
  key: number;
  parentId: number;
  seqId: number;
  nodeLevel: number;
  titleEN: string;
  titleTH: string;
  descEN: string;
  descTH: string;
  isPdfUrl: boolean;
  pdfUrl: string;
  isVideoUrl: boolean;
  videoType: string;
  videoUrl: string;
};

export const UserGuideContentMain = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const { nodeId, topic } = params as any;
  const [selectedGuideNode, setSelectedGuideNode] = useState<any>(null);
  const [userGuides, setUserGuides] = useState<UserGuideData[]>([]);
  const [loading, setLoading] = useState(false);
  const componentMount = useRef(true);

  const topicList = ['teacher', 'admin', 'student'];
  useEffect(() => {
    if (!topicList.includes(topic)) history.push(`/user-guide`);
    if (userGuides.length === 0 && componentMount.current) fetchUserGuide();
    return () => {
      componentMount.current = false;
    };
  }, [loading, userGuides]);

  const fetchUserGuide = async () => {
    setLoading(true);
    try {
      let guideSelected;
      const { userGuideList: userGuideData } = await getUserGuideByTopic({
        topic
      });
      if (userGuideData.length > 0) {
        setUserGuides(userGuideData);
        if (nodeId === '0') guideSelected = userGuideData[0];
        else {
          guideSelected = userGuideData.filter(
            (item: { key: { toString: () => any } }) =>
              item.key.toString() === nodeId
          )[0];
        }
      }
      if (!guideSelected) history.push(`/user-guide`);
      await setSelectedGuideNode(guideSelected);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const setNewNodeId = (nodeId: string) => {
    let guideSelected;
    setLoading(true);
    if (nodeId && userGuides.length > 0) {
      guideSelected = userGuides.filter(
        (item) => item.key.toString() === nodeId
      )[0];
      if (!guideSelected) history.push(`/user-guide`);
      setSelectedGuideNode(guideSelected);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex">
        <div className="w-72">
          <UserGuideSidebar
            setSelectedNodeFromSidebar={(nodeId) => {
              setNewNodeId(nodeId);
            }}
            userGuideData={userGuides}
          />
        </div>
        <div
          className="w-full bg-white mt-10 px-9 md:px-10 xl:px-16"
          style={{
            display: 'cover'
          }}
          css={css`
            min-height: calc(100vh - 64px);
            padding-bottom: 68px !important;
          `}
        >
          <a
            className="flex hover:text-black"
            onClick={() => {
              history.push(`/user-guide`);
            }}
          >
            <img
              src={`/assets/aol-left-arrow.svg`}
              style={{
                display: 'block'
              }}
            />
            <span className="ml-4 text-lg font-bold">ย้อนกลับ</span>
          </a>
          <div key={'PCcontentTitle'} className="mt-6 mb-6 text-2xl font-bold ">
            {selectedGuideNode?.titleTH}
          </div>
          {/* {selectedGuideNode?.isVideoUrl &&
            selectedGuideNode?.videoType === 'byteark' && (
              <ByteArkVideoPlayer src={selectedGuideNode?.videoUrl} />
            )} */}
          {/* {selectedGuideNode?.isVideoUrl &&
            selectedGuideNode?.videoType === 'youtube' && (
              <YoutubePlayer youtubeUrl={selectedGuideNode?.videoUrl} />
            )} */}
          {selectedGuideNode?.isPdfUrl && (
            <div className="mt-8">
              <CardStyled className="group py-9 relative w-full h-38 rounded-2xl overflow-hidden group-hover:opacity-75 text-left items-center">
                <div className="grid grid-cols-2 items-center ">
                  <div className="flex xl:flex-row pl-12">
                    <img
                      className="w-18 h-18"
                      src={`/assets/icon-file.svg`}
                      style={{
                        display: 'block'
                      }}
                    />
                    <div>
                      <p className="text-left font-bold mt-2 mx-3">
                        ไฟล์คู่มือการใช้งาน
                      </p>
                      <p className="text-left font-normal mt-2 mx-3">
                        {selectedGuideNode?.descTH}
                      </p>
                    </div>
                  </div>
                  <div className="absolute right-0 pr-12">
                    <a href={selectedGuideNode?.pdfUrl} target="_blank">
                      <button className="w-64 h-10 bg-red-600 hover:bg-red-700 text-white px-8 rounded-xl inline-flex text-center items-center">
                        <img
                          className="fill-current w-4 h-4 mr-2"
                          src={`/assets/download-arrow.svg`}
                        />
                        <span className="text-base">
                          ดาวน์โหลดคู่มือการใช้งาน
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </CardStyled>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
