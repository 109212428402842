import { teachingAxios } from 'utils/axios';

interface updateDefaultPasswordParams {
  schoolId: string;
  newPassword: string;
  schoolYearId?: string;
}

export const updateDefaultPassword = async (
  params: updateDefaultPasswordParams
) => {
  teachingAxios.defaults.timeout = 1000000;
  const { data: response } = await teachingAxios.put(
    `/school/${params.schoolId}/defaultPassword`,
    {
      schoolDefaultPassword: params.newPassword,
      schoolYearId: params.schoolYearId
    }
  );

  return response;
};
