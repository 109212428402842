import { SubjectListResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useSubjects = () => {
  const [
    { data: response, loading, error },
    execute
  ] = useAxios<SubjectListResponse>(
    // TODO: load more button
    {
      url: `/subjects`,
      params: {
        pageNumber: 1,
        pageSize: 100
      }
    },
    { manual: true }
  );

  return {
    data: response?.data || [],
    loading,
    error,
    execute,
    total: response?.meta?.pagination?.total || 0
  };
};
