import { Fragment, useState } from 'react';
/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';
import { Modal, Steps } from 'antd';

import { CheckCircleFilled } from '@ant-design/icons';
import { ClassroomDto } from '@aksorn/teaching-backoffice-sdk';
import { GhostButton } from 'feature/common/components/Button';
import Lottie from 'react-lottie';
import { ProgressStepStyled } from './const';
import animationData from './success-lottie.json';
import { progressIcon } from 'feature/dashboard/utils/getProgressIcon';

interface ImportExcelSuccessfulModalProps {
  classroom: ClassroomDto;
  onClose: () => void;
}

const { Step } = Steps;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const ImportExcelSuccessfulHeaderStyled = css`
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: #fff !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #fff !important;
  }
  ${ProgressStepStyled}
`;

export const ImportExcelSuccessfulModal = ({
  classroom,
  onClose
}: ImportExcelSuccessfulModalProps) => {
  const [visible, setVisible] = useState(true);

  const ImportExcelSuccessfulModalHeader = () => {
    return (
      <div className="flex justify-between items-center w-full">
        <div className="flex justify-start items-center">
          <CheckCircleFilled style={{ color: '#FFF', fontSize: '20px' }} />
          <span className="text-xl text-white font-bold ml-2">
            อัปโหลดและแทนที่ข้อมูลห้องเรียน{' '}
            {classroom.gradeLevel.name.th + '/' + classroom.classroomName}{' '}
            สำเร็จ
          </span>
        </div>
        <Steps
          current={3}
          className="max-w-sm"
          progressDot={progressIcon}
          css={ImportExcelSuccessfulHeaderStyled}
          status="finish"
        >
          <Step description="จัดการครู" />
          <Step description="จัดการห้องเรียน" />
          <Step description="จัดการคาบเรียน" />
          <Step description="จัดการนักเรียน" />
        </Steps>
      </div>
    );
  };

  return (
    <Fragment>
      <Global
        styles={css`
          .ant-modal {
            width: 100% !important;
            height: 95% !important;
            top: 4%;
          }
          .ant-modal-header {
            background-color: #6fcf97 !important;
            height: 62px !important;
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
          }
          .ant-modal-body {
            max-height: calc(100% - 62px);
            height: 100% !important;
          }
        `}
      />
      <Modal
        visible={visible}
        title={<ImportExcelSuccessfulModalHeader />}
        footer={null}
        closable={false}
        css={css`
          .ant-modal-content {
            height: 100% !important;
            border-radius: 20px !important;
            margin-right: auto;
            margin-left: auto;
          }
        `}
      >
        <div className="h-full flex flex-col justify-center items-center">
          <Lottie options={defaultOptions} height={200} width={200} />
          <h3 className="text-2xl font-bold mt-16">
            แทนที่ข้อมูลห้องเรียน{' '}
            {classroom.gradeLevel.name.th + '/' + classroom.classroomName}{' '}
            สำเร็จ
          </h3>
          <h5 className="text-secondary-black text-sm mt-6">
            สามารถดูรายละเอียดเพิ่มเติมได้ที่เมนูจัดการห้องเรียน นักเรียน และครู
          </h5>

          <GhostButton
            onClick={() => {
              setVisible(false);
              onClose();
            }}
            className="w-full mt-16"
            css={css`
              max-width: 233px;
            `}
          >
            ปิดหน้าต่าง
          </GhostButton>
        </div>
      </Modal>
    </Fragment>
  );
};
