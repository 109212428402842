/** @jsx jsx */
import {
  ImportExcelErrorFieldType,
  ValidationStep
} from 'feature/dashboard/dashboard';
import {
  UserGender,
  UserIdentityDocumentType,
  genderMapper
} from 'feature/common/user';

import { ImportExcelErrorAnnotation } from './ImportExcelErrorAnnotation';
import { ImportExcelModal } from './ImportExcelModal';
import { ImportExcelSchoolTeacherBulkResponse } from '@aksorn/teaching-backoffice-sdk';
import { ImportExcelTable } from './ImportExcelTable';
import { TableCell } from 'feature/dashboard/utils/TableCell';
import dayjs from 'dayjs';
import { jsx } from '@emotion/react';
import { useState } from 'react';

interface ImportExcelSchoolTeacherResultProps {
  data: ImportExcelSchoolTeacherBulkResponse;
  onClose: () => void;
}

const classroomTeacherColumns = [
  {
    title: 'อีเมล',
    dataIndex: ['value', 'email'],
    key: 'email',
    render: (email: string, record: any) => {
      return (
        <TableCell
          value={email}
          record={record}
          validateField={ImportExcelErrorFieldType.EMAIL}
        />
      );
    }
  },
  {
    title: 'คำนำหน้าชื่อ',
    dataIndex: ['value', 'title'],
    key: 'title',
    render: (title: string, record: any) => (
      <TableCell
        value={title}
        record={record}
        validateField={ImportExcelErrorFieldType.TITLE}
      />
    )
  },
  {
    title: 'ชื่อ',
    dataIndex: ['value', 'firstName', 'th'],
    key: 'firstNameTh',
    render: (firstNameTh: string, record: any) => (
      <TableCell
        value={firstNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_TH}
      />
    )
  },
  {
    title: 'นามสกุล',
    dataIndex: ['value', 'lastName', 'th'],
    key: 'lastNameTh',
    render: (lastNameTh: string, record: any) => (
      <TableCell
        value={lastNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_TH}
      />
    )
  },
  {
    title: 'ชื่อ (ภาษาอังกฤษ)',
    dataIndex: ['value', 'firstName', 'en'],
    key: 'firstNameEn',
    render: (firstNameEn: string, record: any) => (
      <TableCell
        value={firstNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_EN}
      />
    )
  },
  {
    title: 'นามสกุล (ภาษาอังกฤษ)',
    dataIndex: ['value', 'lastName', 'en'],
    key: 'lastNameEn',
    render: (lastNameEn: string, record: any) => (
      <TableCell
        value={lastNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_EN}
      />
    )
  },
  {
    title: 'บัตรประชาชน',
    dataIndex: ['value', 'identityDocument'],
    key: 'citizenId',
    render: (identityDocument: any, record: any) => {
      const hasError = record?.errors?.find(
        (record: any) =>
          record?.fieldName === ImportExcelErrorFieldType.IDENTITY_DOCUMENT_TYPE
      );
      if (identityDocument?.type === UserIdentityDocumentType.CITIZEN_ID) {
        if (hasError) {
          return <div className="text-primary">{identityDocument?.number}</div>;
        }
        return (
          <TableCell
            value={identityDocument?.number}
            record={record}
            validateField={ImportExcelErrorFieldType.IDENTITY_DOCUMENT_NUMBER}
          />
        );
      }
      return <></>;
    }
  },
  {
    title: 'Passport Number',
    dataIndex: ['value', 'identityDocument'],
    key: 'passportNumber',
    render: (identityDocument: any, record: any) => {
      if (identityDocument?.type === UserIdentityDocumentType.PASSPORT_ID) {
        return (
          <TableCell
            value={identityDocument?.number}
            record={record}
            validateField={ImportExcelErrorFieldType.IDENTITY_DOCUMENT_NUMBER}
          />
        );
      }
      return <></>;
    }
  },
  {
    title: 'เพศ',
    dataIndex: ['value', 'gender'],
    key: 'gender',
    render: (gender: string, record: any) => (
      <TableCell
        value={
          Object.values(UserGender).includes(gender as any)
            ? genderMapper[gender as UserGender]
            : gender
        }
        record={record}
        validateField={ImportExcelErrorFieldType.GENDER}
      />
    )
  },
  {
    title: 'ปีเกิด (พ.ศ.)',
    dataIndex: ['value', 'dateOfBirth'],
    key: 'dateOfBirth',
    render: (dateOfBirth: string, record: any) => (
      <TableCell
        value={
          dateOfBirth
            ? dayjs(dateOfBirth)?.add(543, 'years')?.format('YYYY')
            : undefined
        }
        record={record}
        validateField={ImportExcelErrorFieldType.DATE_OF_BIRTH}
      />
    )
  },
  {
    title: 'สถานะครู',
    dataIndex: ['value', 'status'],
    key: 'status',
    render: (status: string, record: any) => (
      <TableCell
        value={status}
        record={record}
        validateField={ImportExcelErrorFieldType.STATUS}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    width: '10%',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

export const ImportExcelSchoolTeacherResult = ({
  data,
  onClose
}: ImportExcelSchoolTeacherResultProps) => {
  const [visible, setVisible] = useState(true);

  return (
    <ImportExcelModal
      title="ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบและอัปโหลดใหม่อีกครั้ง"
      validationStep={ValidationStep.SCHOOL_TEACHER}
      visible={visible}
      onClose={() => {
        setVisible(false);
        onClose();
      }}
    >
      <ImportExcelTable
        dataSource={data?.errors && data?.errors[0].fieldErrors}
        columns={classroomTeacherColumns}
        title="ข้อมูลครูประจำชั้นและครูประจำวิชา"
        subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
      />
    </ImportExcelModal>
  );
};
