export enum UserType {
  STUDENT = 'student',
  PARENT = 'parent',
  TEACHER = 'teacher'
}

export enum UserIdentityDocumentType {
  CITIZEN_ID = 'citizenId',
  PASSPORT_ID = 'passportId'
}

export enum UserGender {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown'
}

export const genderMapper = {
  [UserGender.MALE]: 'ชาย',
  [UserGender.FEMALE]: 'หญิง',
  [UserGender.UNKNOWN]: 'ไม่ระบุ'
};
