/** @jsx jsx */
import {
  GradeLevelDto,
  Localization,
  SubjectDto
} from '@aksorn/teaching-backoffice-sdk';
import { css, jsx } from '@emotion/react';

import { CloseCircleFilled } from '@ant-design/icons';

interface ImportExcelClassPeriodLicenseCard {
  gradeLevel: GradeLevelDto;
  coverImage: string;
  name: Localization;
  subject: SubjectDto;
}
export const ImportExcelClassPeriodLicenseCard = ({
  gradeLevel,
  coverImage,
  name,
  subject
}: ImportExcelClassPeriodLicenseCard) => {
  return (
    <div
      className="flex justify-start items-start w-full mr-6"
      css={css`
        max-width: 417px;
      `}
    >
      <img
        src={
          coverImage ||
          'https://assets.aksorn-dev.mydevkit.me/course-thumbnail/0fdccd55-771f-466b-958d-bacfe383286c/EB-200003.png'
        }
        alt="book-cover"
        className="object-cover rounded-lg"
        css={css`
          height: 9.5rem;
          width: 6.75rem;
        `}
      />
      <div className="ml-4">
        <div className="flex justify-start items-start text-sm text-secondary-gray-60">{`${subject?.name?.th} ${gradeLevel?.name?.th}`}</div>
        <div className="text-base font-bold mt-2">{name?.th}</div>
        <div className="flex justify-start items-start text-primary font-bold text-base mt-2">
          <span
            className="self-start mr-2"
            css={css`
              line-height: 21px;
            `}
          >
            <CloseCircleFilled style={{ verticalAlign: 'middle' }} />
          </span>
          <span>จำนวนสื่อไม่เพียงพอต่อการตัดสิทธิ์เข้าห้องเรียน</span>
        </div>
      </div>
    </div>
  );
};
