/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { EmptyTable } from 'feature/common/components/Table/EmptyTable';
import { ReactComponent as WritingIcon } from 'assets/writing.svg';

export const EmptyEvaluation = ({
  hasSelectedAssignmentUnit = false
}: {
  hasSelectedAssignmentUnit?: boolean;
}) => {
  return (
    <EmptyTable
      icon={
        <WritingIcon
          className="w-20 h-20"
          css={css`
            path {
              fill: #e3e4e8;
            }
          `}
        />
      }
      emptyText={hasSelectedAssignmentUnit ? 'ไม่มีกิจกรรม' : 'ไม่มีข้อมูล'}
      emptyClassName={'text-secondary-gray-60'}
    />
  );
};
