import {
  ClassroomNameDto,
  ClassroomNameListResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useClassroomNames = () => {
  const [{ data, loading, error }, fire] = useAxios<ClassroomNameListResponse>(
    {},
    { manual: true }
  );

  const execute = (schoolYearId: string) => {
    return fire({
      url: '/classroom-names',
      params: {
        pageNumber: 1,
        pageSize: 100,
        schoolYearId
      }
    });
  };

  return {
    data: data?.data as ClassroomNameDto[],
    loading: loading,
    error: error,
    execute
  };
};
