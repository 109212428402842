import { GhostButton, PrimaryButton } from './Button';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Modal as AntModal } from 'antd';
import { ButtonProps } from '../global';
import React from 'react';

export interface ModalProps {
  visible: boolean;
  width?: number;
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  cancel?: ButtonProps;
  submit?: ButtonProps;
  content?: React.ReactNode;
}

export const Modal = ({
  visible,
  width,
  title,
  subTitle,
  submit,
  cancel,
  content
}: ModalProps) => {
  return (
    <AntModal
      visible={visible}
      width={width || 368}
      footer={null}
      closable={false}
      onCancel={cancel?.onClick as any}
      centered
      css={css`
        .ant-modal-body {
          padding: 0;
        }
      `}
    >
      <div className="pt-6 pb-6 px-14">
        <h3 className="font-bold text-xl mb-3 text-center">{title}</h3>
        {subTitle && <p className="text-sm text-center">{subTitle}</p>}
        <div className="mt-8">{content}</div>
        {(submit || cancel) && (
          <div className="mt-8">
            {cancel ? (
              <div className="flex justify-center gap-4 m-auto">
                <GhostButton className="w-24" onClick={cancel?.onClick}>
                  {cancel?.label}
                </GhostButton>
                <PrimaryButton
                  className="w-24"
                  loading={submit?.loading}
                  onClick={submit?.onClick as any}
                >
                  {submit?.label}
                </PrimaryButton>
              </div>
            ) : (
              <div className="flex justify-center w-full">
                <PrimaryButton
                  id={submit?.id}
                  loading={submit?.loading}
                  onClick={submit?.onClick as any}
                >
                  {submit?.label}
                </PrimaryButton>
              </div>
            )}
          </div>
        )}
      </div>
    </AntModal>
  );
};
