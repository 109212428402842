import { action, makeObservable, observable } from 'mobx';

import { ClassPeriodGetDto } from '@aksorn/teaching-backoffice-sdk';

export const SELECT_ALL = 'all';

class ClassPeriodStore {
  classPeriod?: ClassPeriodGetDto = undefined;

  constructor() {
    makeObservable(this, {
      classPeriod: observable,
      setClassPeriod: action
    });
  }

  setClassPeriod = (classPeriod: ClassPeriodGetDto) => {
    this.classPeriod = classPeriod;
  };
}

export const classPeriodStore = new ClassPeriodStore();
