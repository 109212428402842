export enum SchoolAdminRoleType {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin'
}

export const SCHOOL_ADMIN_ROLE_TYPE_MAPPER: { [key: string]: string } = {
  [SchoolAdminRoleType.SUPER_ADMIN]: 'ผู้ดูแลสูงสุด',
  [SchoolAdminRoleType.ADMIN]: 'ผู้ดูแล'
};

export enum SearchType {
  NAME = 'name',
  EMAIL = 'email'
}
