export enum SchoolTeacherSortBy {
  NAME_ASC = 'nameAsc',
  NAME_DESC = 'nameDesc',
  EMAIL_ASC = 'emailAsc',
  EMAIL_DESC = 'emailDesc',
  UPDATED_AT_ASC = 'updatedAtAsc',
  UPDATED_AT_DESC = 'updatedAtDesc',
  LAST_LOGGEDIN_AT_ASC = 'lastLoggedInAtAsc',
  LAST_LOGGEDIN_AT_DESC = 'lastLoggedInAtDesc'
}
