/** @jsx jsx */
import { Divider, Form, Select, Spin } from 'antd';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { SearchTeacherFilterBar } from 'feature/common/components/SearchTeacherFilterBar';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';
import moment from 'moment';

interface Props {
  selectedSchoolYearId: string;
  setSelectedSchoolYearId: (schoolYearId: string) => void;
  gradeLevelIds: string[];
  setGradeLevelIds: (gradeLevelIds: string[]) => void;
  classroomNames: string[];
  setClassroomNames: (classroomNames: string[]) => void;
  setTeacherName: (teacherName: string) => void;
}

export const ClassroomManagementForm = (props: Props) => {
  const {
    selectedSchoolYearId,
    gradeLevelIds,
    classroomNames,
    setSelectedSchoolYearId,
    setGradeLevelIds,
    setClassroomNames,
    setTeacherName
  } = props;

  const [searchText, setSearchText] = useState('');

  const [form] = Form.useForm();
  const { Option } = Select;

  const { data: schoolYears, loading: schoolYearsLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();

  useEffect(() => {
    if (!schoolYearsLoading && currentSchoolYears) {
      setSelectedSchoolYearId(currentSchoolYears.schoolYearId);
    }
  }, [schoolYears, currentSchoolYears]);

  return (
    <div
      css={css`
        .ant-form-item {
          width: 12rem;
        }
        .ant-select-selector {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .ant-select-selection-overflow {
          flex-wrap: nowrap;
        }
      `}
    >
      <Spin spinning={schoolYearsLoading}>
        <Form form={form}>
          <div className="flex justify-between">
            <h3 className="text-xl font-bold">จัดการห้องเรียน</h3>
            <Form.Item label="ปีการศึกษา">
              <Select
                value={selectedSchoolYearId}
                onSelect={(schoolYearId) => {
                  setSelectedSchoolYearId(schoolYearId);
                }}
              >
                {schoolYears?.map(({ name, schoolYearId }) => (
                  <Option 
                    key={schoolYearId}
                    value={schoolYearId}
                    // disabled={parseInt(name) - 543 !== moment().year()}
                    disabled={schoolYearId !== selectedSchoolYearId}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>         
          </div>
          <Divider />
          <SearchTeacherFilterBar
            schoolYearId={selectedSchoolYearId}
            gradeLevelIds={gradeLevelIds}
            setGradeLevelIds={setGradeLevelIds}
            classroomNames={classroomNames}
            setClassroomNames={setClassroomNames}
            setSearchText={setSearchText}
            onPressEnter={() => {
              setTeacherName(searchText);
            }}
          />
        </Form>
      </Spin>
    </div>
  );
};
