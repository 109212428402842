export enum SidebarMenuKey {
  DASHBOARD = ' ',
  CREATE_CLASSROOM = 'create-classroom',
  MEDIA_MANAGEMENT = 'media-management',
  MEDIA_CLASSROOM_MANAGEMENT = 'media-classroom-management',
  CLASSROOM_MANAGEMENT = 'classroom-management',
  STUDENT_MANAGEMENT = 'student-management',
  TEACHER_MANAGEMENT = 'teacher-management',
  SCHOOL_ADMIN_MANAGEMENT = 'school-admin-management',
  ACADEMICRESULTS_MANAGEMENT = 'academic-results-management',
  EVALUATION = 'evaluation',
  ASSIGNMENT_OVERALL = 'assignment-overall',
  MEDIA_MANAGEMENT_FOR_CLASSROOM = 'media-management-for-classroom'
}

export const SIDE_BAR_MENU_TEXT = {
  [SidebarMenuKey.DASHBOARD]: 'หน้าหลักโรงเรียน',
  [SidebarMenuKey.CREATE_CLASSROOM]:'สร้างห้องเรียน',
  [SidebarMenuKey.MEDIA_CLASSROOM_MANAGEMENT]:'จัดการสื่อในห้องเรียน',
  [SidebarMenuKey.MEDIA_MANAGEMENT]: 'สื่อในระบบ',
  [SidebarMenuKey.CLASSROOM_MANAGEMENT]: 'จัดการห้องเรียน',
  [SidebarMenuKey.STUDENT_MANAGEMENT]: 'จัดการนักเรียน',
  [SidebarMenuKey.TEACHER_MANAGEMENT]: 'จัดการครู',
  [SidebarMenuKey.SCHOOL_ADMIN_MANAGEMENT]: 'ผู้ดูแลระบบ',
  [SidebarMenuKey.ACADEMICRESULTS_MANAGEMENT]: 'ผลการเรียนรู้',
  [SidebarMenuKey.EVALUATION]: 'การประเมินผล',
  [SidebarMenuKey.ASSIGNMENT_OVERALL]: 'ภาพรวมการส่งงาน',
  [SidebarMenuKey.MEDIA_MANAGEMENT_FOR_CLASSROOM] : 'จัดการสื่อในระบบ'
};
