import { SchoolAdminGetResponse } from '@aksorn/one-account-sdk';
import { useOneAccountAxios } from 'utils/axios';

export const useSchoolAdmin = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useOneAccountAxios<SchoolAdminGetResponse>({}, { manual: true });

  const execute = (schoolAdminId: string) => {
    return fire({
      url: `/school-admins/${schoolAdminId}`
    });
  };
  return {
    data: response?.data,
    loading,
    execute,
    error: error
  };
};
