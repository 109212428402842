import { ImportExcelParseResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useParseExcel = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ImportExcelParseResponse>(
    {
      method: 'POST'
    },
    { manual: true }
  );

  const execute = (file: File) => {
    const formData = new FormData();
    formData.append('content', file);
    return fire({
      url: `/import-excel/parse-excel`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
