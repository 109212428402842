import {
  ImportExcelErrorFieldType,
  ValidateExcelResponseMessage,
  ValidationStep
} from 'feature/dashboard/dashboard';
import React, { useState } from 'react';
import { UserGender, genderMapper } from 'feature/common/user';

import { ImportExcelClassPeriodLicenseCard } from './ImportExcelClassPeriodLicenseCard';
import { ImportExcelErrorAnnotation } from './ImportExcelErrorAnnotation';
import { ImportExcelModal } from './ImportExcelModal';
import { ImportExcelSchoolStudentBulkResponse } from '@aksorn/teaching-backoffice-sdk';
import { ImportExcelTable } from './ImportExcelTable';
import { TableCell } from 'feature/dashboard/utils/TableCell';
import { UserIdentityDocumentType } from 'feature/common/global';
import dayjs from 'dayjs';

interface ImportExcelSchoolStudentProps {
  data: ImportExcelSchoolStudentBulkResponse;
  onClose: () => void;
}

export const classroomStudentColumns = [
  {
    title: 'เลขที่',
    dataIndex: ['value', 'classroomStudentNumber'],
    key: 'classroomStudentNumber',
    render: (classroomStudentNumber: string, record: any) => {
      return (
        <TableCell
          value={classroomStudentNumber}
          record={record}
          validateField={ImportExcelErrorFieldType.CLASSROOM_STUDENT_NUMBER}
        />
      );
    }
  },
  {
    title: 'อีเมลนักเรียน',
    dataIndex: ['value', 'email'],
    key: 'email',
    render: (email: string, record: any) => {
      return (
        <TableCell
          value={email}
          record={record}
          validateField={ImportExcelErrorFieldType.EMAIL}
        />
      );
    }
  },
  {
    title: 'รหัสประจำตัว',
    dataIndex: ['value', 'studentCode'],
    key: 'studentCode',
    render: (studentCode: string, record: any) => {
      return (
        <TableCell
          value={studentCode}
          record={record}
          validateField={ImportExcelErrorFieldType.STUDENT_CODE}
        />
      );
    }
  },
  {
    title: 'คำนำหน้าชื่อ',
    dataIndex: ['value', 'title'],
    key: 'title',
    render: (title: string, record: any) => (
      <TableCell
        value={title}
        record={record}
        validateField={ImportExcelErrorFieldType.TITLE}
      />
    )
  },
  {
    title: 'ชื่อ',
    dataIndex: ['value', 'firstName', 'th'],
    key: 'firstNameTh',
    render: (firstNameTh: string, record: any) => (
      <TableCell
        value={firstNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_TH}
      />
    )
  },
  {
    title: 'นามสกุล',
    dataIndex: ['value', 'lastName', 'th'],
    key: 'lastNameTh',
    render: (lastNameTh: string, record: any) => (
      <TableCell
        value={lastNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_TH}
      />
    )
  },
  {
    title: 'ชื่อ (ภาษาอังกฤษ)',
    dataIndex: ['value', 'firstName', 'en'],
    key: 'firstNameEn',
    render: (firstNameEn: string, record: any) => (
      <TableCell
        value={firstNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_EN}
      />
    )
  },
  {
    title: 'นามสกุล (ภาษาอังกฤษ)',
    dataIndex: ['value', 'lastName', 'en'],
    key: 'lastNameEn',
    render: (lastNameEn: string, record: any) => (
      <TableCell
        value={lastNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_EN}
      />
    )
  },
  {
    title: 'บัตรประชาชน',
    dataIndex: ['value', 'identityDocument'],
    key: 'citizenId',
    render: (identityDocument: any, record: any) => {
      const hasError = record?.errors?.find(
        (record: any) =>
          record?.fieldName === ImportExcelErrorFieldType.IDENTITY_DOCUMENT_TYPE
      );
      if (identityDocument?.type === UserIdentityDocumentType.CITIZEN_ID) {
        if (hasError) {
          return <div className="text-primary">{identityDocument?.number}</div>;
        }
        return (
          <TableCell
            value={identityDocument?.number}
            record={record}
            validateField={ImportExcelErrorFieldType.IDENTITY_DOCUMENT_NUMBER}
          />
        );
      }
      return <></>;
    }
  },
  {
    title: 'Passport Number',
    dataIndex: ['value', 'identityDocument'],
    key: 'passportNumber',
    render: (identityDocument: any, record: any) => {
      if (identityDocument?.type === UserIdentityDocumentType.PASSPORT_ID) {
        return (
          <TableCell
            value={identityDocument?.number}
            record={record}
            validateField={ImportExcelErrorFieldType.IDENTITY_DOCUMENT_NUMBER}
          />
        );
      }
      return <></>;
    }
  },
  {
    title: 'เพศ',
    dataIndex: ['value', 'gender'],
    key: 'gender',
    render: (gender: string, record: any) => (
      <TableCell
        value={
          Object.values(UserGender).includes(gender as any)
            ? genderMapper[gender as UserGender]
            : gender
        }
        record={record}
        validateField={ImportExcelErrorFieldType.GENDER}
      />
    )
  },
  {
    title: 'ปีเกิด (พ.ศ.)',
    dataIndex: ['value', 'dateOfBirth'],
    key: 'dateOfBirth',
    render: (dateOfBirth: string, record: any) => (
      <TableCell
        value={
          dateOfBirth
            ? dayjs(dateOfBirth)?.add(543, 'years')?.format('YYYY')
            : undefined
        }
        record={record}
        validateField={ImportExcelErrorFieldType.DATE_OF_BIRTH}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    width: '10%',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

export const ImportExcelSchoolStudentResult = ({
  data,
  onClose
}: ImportExcelSchoolStudentProps) => {
  const [visible, setVisible] = useState(true);

  if (data?.message === ValidateExcelResponseMessage.INSUFFICIENT_LICENSES) {
    const classPeriodLicenseDataSource =
      data?.errors && data?.errors[0].fieldErrors;

    return (
      <ImportExcelModal
        title={'จำนวนสื่อไม่เพียงพอ'}
        validationStep={ValidationStep.SCHOOL_STUDENT}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <h3 className="text-lg font-bold">
          จำนวนสื่อไม่เพียงพอ {classPeriodLicenseDataSource?.length} สิทธิ์
        </h3>
        <h5 className="text-sm mt-2">
          จำนวนสิทธิ์ในการใช้สื่อไม่เพียงพอกับจำนวนนักเรียน
          จึงไม่สามารถเพิ่มนักเรียนได้
          กรุณาเพิ่มจำนวนสิทธิ์ก่อนเพิ่มนักเรียนเข้าห้องเรียนนี้
        </h5>
        <div className="flex flex-wrap justify-start items-start mt-6">
          {classPeriodLicenseDataSource?.map(
            ({
              gradeLevel,
              coverImage,
              name,
              subject,
              schoolLicenseId
            }: any) => (
              <ImportExcelClassPeriodLicenseCard
                key={schoolLicenseId}
                gradeLevel={gradeLevel}
                coverImage={coverImage}
                name={name}
                subject={subject}
              />
            )
          )}
        </div>
      </ImportExcelModal>
    );
  } else {
    const classroomStudentDataSource =
      data?.errors && data?.errors[0].fieldErrors;

    return (
      <ImportExcelModal
        title={'ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบและอัปโหลดใหม่อีกครั้ง'}
        validationStep={ValidationStep.SCHOOL_STUDENT}
        visible={visible}
        onClose={() => {
          setVisible(false);
          onClose();
        }}
      >
        <ImportExcelTable
          dataSource={classroomStudentDataSource}
          columns={classroomStudentColumns}
          title="ข้อมูลนักเรียน"
          subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
        />
      </ImportExcelModal>
    );
  }
};
