import { SchoolStudentValidateResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const getErrorMessage = (text: string) => {
  let errorMessage;
  switch (text) {
    case 'this account is not student':
      errorMessage =
        'บัญชีผู้ใช้นี้ไม่ใช่บัญชีสำหรับนักเรียน ไม่สามารถเพิ่มในระบบได้';
      break;
    case 'this account is already in other school':
      errorMessage =
        'บัญชีผู้ใช้นี้เป็นนักเรียนโรงเรียนอื่น ไม่สามารถเพิ่มในระบบได้';
      break;
    // case 'this account is already in this school':
    //   errorMessage =
    //     'บัญชีผู้ใช้นี้อยู่ในโรงเรียนนี้แล้ว ไม่สามารถเพิ่มในระบบได้';
    //   break;
    case 'student cannot have multiple school-classroom in the same school-year':
      errorMessage =
        'บัญชีผู้ใช้นี้อยู่ในห้องหลักของปีการศึกษานี้แล้ว ไม่สามารถเพิ่มในระบบได้';
      break;
    default:
      errorMessage = '';
      break;
  }
  return errorMessage;
};

export const useValidateSchoolStudent = () => {
  const [
    { data: response, loading, error },
    execute
  ] = useAxios<SchoolStudentValidateResponse>(
    {
      // url: `/school-students/validate`,
      url: `/classroom-students/validate`,
      method: 'POST'
    },
    {
      manual: true
    }
  );

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
