import { css, jsx } from '@emotion/react';

/** @jsx jsx */
import { CloseCircleFilled } from '@ant-design/icons';
import { ProgressStepStyled } from 'feature/dashboard/components/import-excel/const';
import { Steps } from 'antd';
import { ValidationStep } from 'feature/dashboard/dashboard';
import { progressIcon } from 'feature/dashboard/utils/getProgressIcon';

const { Step } = Steps;

const ProgressStepHeaderStyled = css`
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #fff !important;
  }
  .ant-steps-item-description {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #fff !important;
    margin-top: 10px !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: #fff !important;
  }
  .ant-steps-item-error
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: #fff !important;
  }
  ${ProgressStepStyled}
`;

interface ImportExcelModalHeaderProps {
  showValidationStep?: boolean;
  validationStep?: ValidationStep;
  title: string;
}

export const ImportExcelModalHeader = ({
  showValidationStep = true,
  validationStep,
  title
}: ImportExcelModalHeaderProps) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex justify-start items-center">
        <CloseCircleFilled style={{ color: '#FFF', fontSize: '20px' }} />
        <span className="text-xl text-white font-bold ml-2">{title}</span>
      </div>
      {showValidationStep && (
        <Steps
          current={validationStep}
          className="max-w-sm"
          progressDot={progressIcon}
          css={ProgressStepHeaderStyled}
          status="error"
        >
          <Step description="จัดการครู" />
          <Step description="จัดการห้องเรียน" />
          <Step description="จัดการคาบเรียน" />
          <Step description="จัดการนักเรียน" />
        </Steps>
      )}
    </div>
  );
};
