import { identity, pickBy } from 'lodash';

import { teachingAxios } from 'utils/axios';

export const getProductLicenseUsageClassPeriods = async (params?: any) => {
  const { data: response } = await teachingAxios.get(
    `/product-license-usage-class-periods`,
    {
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    }
  );
  return response;
};
