import {
  ClassPeriodCreateBody,
  ClassPeriodModifyResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useCreateClassPeriod = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ClassPeriodModifyResponse>(
    {
      method: 'POST'
    },
    { manual: true }
  );
  const execute = ({ data }: { data: ClassPeriodCreateBody }) => {
    return fire({
      url: '/class-periods',
      data
    });
  };

  return {
    data: response?.data,
    loading: loading,
    error,
    execute
  };
};
