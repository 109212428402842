import { 
  // SchoolTeacherDto, 
  SchoolTeacherGetResponse,
  SchoolTeacherTypeGetResponse, 
} from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useSchoolTeacher = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolTeacherGetResponse>({}, { manual: true });

  const execute = (schoolTeacherId: string) => {
    return fire({
      url: `/school-teachers/${schoolTeacherId}`
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};

export const useSchoolTeacherType = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolTeacherTypeGetResponse>({}, { manual: true });

  const execute = (email: string) => {
    return fire({
      url: `/school-teachers/${email}/teachertype`
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};