import { AssignmentDto } from '@aksorn/teaching-backoffice-sdk';
import { ReactComponent as EbookTeacherbagIcon } from 'assets/ebook-teacherbag.svg';
import React from 'react';
import { Typography } from 'antd';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';

interface AssignmentInfoProps {
  assignment: AssignmentDto | undefined;
}

interface AssignmentDetailProps {
  title: string;
  description: string | undefined;
}

export const AssignmentInfo = ({ assignment }: AssignmentInfoProps) => {
  const formatDateTh = (
    date: string | null,
    format = 'DD/MM/YYYY เวลา HH:mm'
  ) => {
    if (!date) return '';
    return dayjs(date).add(543, 'year').format(format).toString();
  };

  return (
    <>
      <div className="flex items-center mb-3">
        <EbookTeacherbagIcon
          className="w-6 h-6 mr-5"
          css={css`
            path {
              fill: #5c5e70;
            }
          `}
        />
        <Typography.Text className="text-xl font-bold text-secondary-gray-60">
          งานที่มอบหมาย
        </Typography.Text>
      </div>
      <div className="grid grid-cols-1 gap-3">
        <AssignmentDetail
          title="หัวข้อ"
          description={assignment?.assignmentName}
        />
        <AssignmentDetail
          title="คำอธิบาย"
          description={assignment?.description}
        />
      </div>
      <Divider />
      <div className="text-base font-bold text-secondary-gray-60 mb-3">
        รายละเอียดการมอบหมาย
      </div>
      <div className="grid grid-cols-1 gap-3">
        <AssignmentDetail
          title="ห้องเรียนที่มอบหมาย"
          description={`${assignment?.gradeLevel.name.th}/${assignment?.classroomName}`}
        />
        <AssignmentDetail
          title="วิชา"
          description={assignment?.subject.name.th}
        />
        <AssignmentDetail
          title="คาบเรียน"
          description={assignment?.classPeriodName}
        />
        <AssignmentDetail
          title="กำหนดส่ง"
          description={formatDateTh(assignment?.endDate ?? null)}
        />
        <AssignmentDetail
          title="คะแนนเต็ม"
          description={assignment?.maxScore?.toString()}
        />
      </div>
    </>
  );
};

const AssignmentDetail = ({ title, description }: AssignmentDetailProps) => {
  return (
    <Typography.Text className="flex flex-col">
      <Typography.Text className="text-sm font-bold text-secondary-gray-60">
        {title}
      </Typography.Text>
      <Typography.Text className="text-sm text-secondary-gray-60">
        {description}
      </Typography.Text>
    </Typography.Text>
  );
};

export const Divider = styled.div`
  border: 1px solid #c7c8d1;
  margin: 12px 0px;
  height: 0px;
`;
