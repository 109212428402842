import { SchoolTeacherDeleteResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const getErrorMessage = (text: string) => {
  switch (text) {
    case 'this teacher is main teacher in classroom':
      return 'ไม่สามารถลบครูออกจากระบบได้ กรุณาเปลี่ยนคุณครูประจำชั้นหลักของห้อง ก่อนลบครูออกจากโรงเรียน';
    case 'this teacher is main teacher in class period':
      return 'ไม่สามารถลบครูออกจากระบบได้ กรุณาเปลี่ยนคุณครูประจำวิชาหลักของวิชา ก่อนลบออกจากโรงเรียน';
    default:
      return 'ลบคุณครูไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';
  }
};

export const useDeleteSchoolTeachers = () => {
  const [{ loading, error }, fire] = useAxios<SchoolTeacherDeleteResponse>(
    {
      url: `/school-teachers`,
      method: 'DELETE'
    },
    { manual: true }
  );

  const execute = async (schoolTeacherIds: string[] = []) => {
    const response = await fire({
      data: {
        schoolTeacherIds
      }
    });
    const firstTeacherData = response.data.data[0];
    if (firstTeacherData.status === 'error') {
      throw new Error(firstTeacherData.message);
    }
  };

  return {
    loading,
    error,
    execute
  };
};
