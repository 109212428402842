import { Form, Select } from 'antd';
import { Global, css } from '@emotion/react';

import { AssignmentUnitListDto } from '@aksorn/teaching-backoffice-sdk';
import React from 'react';

interface AssignmentUnitSelectProps {
  className: string;
  disabled?: boolean;
  assignmentUnits: AssignmentUnitListDto[];
  selectedAssignmentUnitId: string;
  setSelectedAssignmentUnitId: (value: string) => void;
}

export const AssignmentUnitSelect = ({
  disabled = false,
  className,
  assignmentUnits,
  selectedAssignmentUnitId,
  setSelectedAssignmentUnitId
}: AssignmentUnitSelectProps) => {
  const { Option } = Select;
  return (
    <>
      <Global
        styles={css`
          .ant-select-dropdown {
            border-radius: 0 0 10px 10px !important;
          }
          .ant-select-item {
            color: #5c5e70;
          }
        `}
      />
      <Form.Item label="หน่วยการเรียน" className={`${className}`}>
        <Select
          value={selectedAssignmentUnitId}
          disabled={disabled}
          onSelect={(value) => {
            setSelectedAssignmentUnitId(value);
          }}
        >
          <Option value={''}>ทั้งหมด</Option>
          {assignmentUnits.map((assignmentUnit: AssignmentUnitListDto) => (
            <Option
              key={assignmentUnit.assignmentUnitId}
              value={assignmentUnit.assignmentUnitId}
            >
              {assignmentUnit.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
