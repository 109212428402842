/** @jsx jsx */

import { Card, Col, ConfigProvider, Row, Skeleton, Spin } from 'antd';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ReactComponent as BackIcon } from 'assets/back-icon.svg';
import { ReactComponent as ClassroomEmptyIcon } from 'assets/classroom-empty.svg';
import { MediaDetailDrawer } from 'feature/media-management/MediaDetailDrawer';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { ReactComponent as PlusIcon } from 'assets/plus-circle-filled.svg';
import { PrimaryTable } from 'feature/common/components/PrimaryTable';
import { getProductLicenseUsage } from 'api/media/getProductLicenseUsage';
import { getProductLicenseUsageClassPeriods } from 'api/media/getProductLicenseUsageClassPeriods';
import thTH from 'antd/lib/locale/th_TH';

export const MediaDetailPage = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { productId, schoolYearId } = params as any;

  const [media, setMedia] = useState<any>([]);
  const [mediaLoading, setMediaLoading] = useState<boolean>(true);
  const [
    mediaDetailDrawerVisible,
    setMediaDetailDrawerVisible
  ] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const fetchData = async (productId: any, schoolYearId: any) => {
    setMediaLoading(true);
    try {
      const { data: newMediaData } = await getProductLicenseUsage(
        productId,
        schoolYearId
      );
      // console.log('mediaData', mediaData);
      if (!!newMediaData.length) {
        setMedia(newMediaData[0]);
        fetchTableData({
          schoolYearId: schoolYearId,
          schoolLicenseId:
            newMediaData?.[0]?.schoolLicenses?.[0]?.schoolLicenseId
        });
      }
    } catch (error) {
      // console.log('error', error);
    } finally {
      setMediaLoading(false);
    }
  };

  const fetchTableData = async (params: any) => {
    // console.log('params', params);
    setTableLoading(true);
    try {
      const {
        data: productLicenseUsageData
      } = await getProductLicenseUsageClassPeriods(params);
      // console.log('table data', newTableData);
      var newTableData = productLicenseUsageData.map(
        (eachMedia: any, key: number) => ({
          key: key,
          classPeriodId: eachMedia?.classPeriodId,
          classroom: `${eachMedia?.classroom?.gradeLevel?.name?.th || 'ป.-'}/${
            eachMedia?.classroom?.classroomName || '-'
          }`,
          subject: eachMedia?.subject?.name?.th,
          classPeriod: eachMedia?.classPeriodName,
          numberOfClassroomStudent: eachMedia?.numberOfClassroomStudent,
          numberOfLicenseUsageStudent: eachMedia?.numberOfLicenseUsageStudent
        })
      );
      // console.log('newMediaData', newMediaData);
      setTableData(newTableData);
    } catch (error) {
      // console.log('error', error);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    // console.log('location?.state', location?.state);
    handleFetchData();
  }, []);

  const handleFetchData = () => {
    fetchData(productId, schoolYearId);
  };

  const columns = [
    {
      title: 'ห้อง',
      dataIndex: 'classroom',
      key: 'classroom',
      sorter: (a: any, b: any) => a.classroom.localeCompare(b.classroom)
    },
    {
      title: 'วิชา',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a: any, b: any) => a.subject.localeCompare(b.subject)
    },
    {
      title: 'คาบวิชา',
      dataIndex: 'classPeriod',
      key: 'classPeriod',
      sorter: (a: any, b: any) => a.classPeriod.localeCompare(b.classPeriod)
    },
    {
      title: 'จำนวนนักเรียน',
      dataIndex: 'numberOfClassroomStudent',
      key: 'numberOfClassroomStudent',
      align: 'right' as 'right',
      sorter: (a: any, b: any) =>
        a.numberOfClassroomStudent - b.numberOfClassroomStudent
    },
    {
      title: 'จำนวนสื่อ',
      dataIndex: 'numberOfLicenseUsageStudent',
      key: 'numberOfLicenseUsageStudent',
      align: 'right' as 'right',
      sorter: (a: any, b: any) =>
        a.numberOfLicenseUsageStudent - b.numberOfLicenseUsageStudent
    } /*,
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: () => (
        <DeleteIcon
          className="w-5 h-5 cursor-pointer"
          css={css`
            path {
              fill: #5c5e70;
            }
          `}
        />
      ),
      className: 'actions',
      width: 50
    }*/
  ];

  const renderEmptyPrimaryTable = () => {
    if (tableLoading) {
      return <></>;
    }
    return (
      <div className="flex flex-col justify-center items-center">
        <ClassroomEmptyIcon className="w-20" />
        <span className="text-base font-bold mb-4">
          ยังไม่มีห้องที่ใช้งานสื่อ
        </span>
        <SecondaryButton
          className="flex"
          onClick={() => setMediaDetailDrawerVisible(true)}
        >
          <PlusIcon
            className="mr-2"
            css={css`
              path {
                fill: #8f91a3;
              }
            `}
          />
          เลือกคาบวิชา
        </SecondaryButton>
      </div>
    );
  };

  return (
    <PageWrapper className="flex flex-col">
      <div
        className="flex w-full cursor-pointer items-center mb-10"
        css={css`
          color: #1a1a1a;
        `}
        onClick={() => {
          history.push({
            pathname: '/media-management',
            state: location?.state
          });
        }}
      >
        <BackIcon
          css={css`
            width: 19.2px;
            height: 16.8px;
          `}
        />
        <div className="mx-2 font-bold text-xl">รายการสื่อในระบบ</div>
      </div>
      <Row>
        <MediaDetailDrawer
          title="เลือกคาบวิชา"
          subTitle={`เลือกคาบวิชาที่ต้องการมอบสื่อ (${
            media?.numberOfLicenseUsage || 0
          } /
            ${media?.schoolLicenses?.[0]?.quotaLimit || 0})`}
          visible={mediaDetailDrawerVisible}
          schoolLicenseId={media?.schoolLicenses?.[0]?.schoolLicenseId}
          subjectId={media?.subject?.subjectId}
          gradeLevelId={media?.gradeLevel?.gradeLevelId}
          tableLoading={tableLoading}
          refetchMediaData={handleFetchData}
          // fetchTableData={fetchTableData}
          onClose={() => {
            setMediaDetailDrawerVisible(false);
          }}
        />
        <Col
          span={24}
          lg={6}
          xl={5}
          xxl={4}
          className="p-0 lg:pr-6 mb-6 lg:mb-0 flex flex-col justify-center lg:justify-start"
        >
          <Card
            className="w-full h-auto shadow-lg mb-4 self-center"
            css={css`
              border-radius: 20px;
              min-height: calc(100vw * 0.172);
              max-width: 250px;
              @media (min-width: 1024px) {
                max-width: 100%;
              }
              .ant-card-body {
                height: 100%;
                padding: 0;
              }
            `}
          >
            {mediaLoading ? (
              <div className="w-full h-full flex justify-center items-center">
                <Spin />
              </div>
            ) : (
              <>
                {media?.productLevel === 'premium' ? (
                  <div className="absolute z-20 top-0 left-0 mt-4 mr-4 ">
                    <img src={`/assets/aol-premium-flag.svg`} />
                  </div>
                ) : null}
                <img
                  src={media?.coverImage}
                  className="w-full h-autow-full object-cover object-top"
                  css={css`
                    border-radius: 20px;
                  `}
                  alt="media-placeholder"
                />
              </>
            )}
          </Card>
          <Card
            className="border-none shadow-lg"
            css={css`
              border-radius: 20px;
              .ant-card-body {
                height: 100%;
                padding: 16px;
              }
            `}
          >
            {mediaLoading ? (
              <Skeleton active />
            ) : (
              <>
                <div className="text-base font-bold mb-4">
                  {media?.name?.th || <>&nbsp;</>}
                </div>
                <div className="mb-4">
                  <span className="text-lg text-gray-60">
                    {media?.numberOfLicenseUsage || 0} /
                    {media?.schoolLicenses?.[0]?.quotaLimit || 0} สิทธิ์
                  </span>
                </div>
                <div className="text-md">อายุการใช้งาน 1 ปี</div>
                {/*
                <div className="mb-4">
                  <a className="text-md text-blue-400 underline decoration-solid">
                    รายละเอียดเพิ่มเติม
                  </a>
                </div>
                <SecondaryButton className="w-full">
                  ซื้อสิทธิ์เพิ่ม
                </SecondaryButton>
                */}
              </>
            )}
          </Card>
        </Col>
        <Col span={24} lg={18} xl={19} xxl={20} className="w-full">
          <div className="flex justify-between items-center mb-6">
            <div className="font-bold text-xl">คาบวิชาที่ใช้งาน</div>
            <PrimaryButton
              className={`flex ${
                mediaLoading ? 'text-secondary-gray-60' : 'text-white'
              }`}
              onClick={() => setMediaDetailDrawerVisible(true)}
              disable={mediaLoading}
              disabled={mediaLoading}
            >
              <PlusIcon
                className="mr-2"
                css={css`
                  path {
                    fill: ${mediaLoading ? '#5c5e70' : '#ffffff'};
                  }
                `}
              />
              เลือกคาบวิชา
            </PrimaryButton>
          </div>
          <ConfigProvider locale={thTH} renderEmpty={renderEmptyPrimaryTable}>
            <PrimaryTable
              dataSource={tableData}
              columns={columns}
              pagination={false}
              loading={tableLoading}
              scroll={{ x: 635 }}
              css={css`
                .actions {
                  opacity: 1;
                }
                @media (min-width: 1024px) {
                  .ant-table-tbody > .ant-table-row .actions {
                    opacity: 0;
                  }
                }
                .ant-table-tbody > .ant-table-row:hover .actions {
                  opacity: 1;
                }
              `}
            />
          </ConfigProvider>
          {mediaLoading ? (
            <Skeleton active />
          ) : (
            <>
              <div className="mb-2">
                <span className="text-base">
                  นักเรียนที่มีสถานะเป็นนักเรียนในโรงเรียน
                  แต่ไม่ได้เรียนในห้องเรียนที่ใช้งานหนังสือเล่มนี้{' '}
                  {media?.numberOfLicenseUsageStudentNotInClassroom || 0} สิทธิ์
                </span>
              </div>
              <div className="mb-2">
                <span className="text-base">
                  นักเรียนที่ไม่ได้มีสถานะเป็นนักเรียนในโรงเรียน
                  (ลาออก/รออนุมัติ) แต่มีสิทธิ์ในหนังสือเล่มนี้อยู่{' '}
                  {media?.numberOfLicenseUsageStudentSchoolDeleted || 0} สิทธิ์
                </span>
              </div>
              <div className="mb-2">
                <span className="text-base">
                  ครูที่มีสถานะเป็นครูในโรงเรียน
                  และอยู่ในห้องเรียนที่ใช้งานหนังสือเรียนเล่มนี้{' '}
                  {media?.numberOfLicenseUsageTeacher || 0} สิทธิ์
                </span>
              </div>
              <div className="mb-2">
                <span className="text-base">
                  ครูที่มีสถานะเป็นครูในโรงเรียน
                  แต่ไม่ได้สอนในห้องเรียนที่ใช้งานหนังสือเรียนเล่มนี้{' '}
                  {media?.numberOfLicenseUsageTeacherClassPeriodDeleted || 0}{' '}
                  สิทธิ์
                </span>
              </div>
              <div className="mb-2">
                <span className="text-base">
                  ครูที่ไม่ได้มีสถานะเป็นครูในโรงเรียน (ลาออก/รออนุมัติ)
                  แต่มีสิทธิ์ในหนังสือเล่มนี้อยู่{' '}
                  {media?.numberOfLicenseUsageTeacherSchoolDeleted || 0} สิทธิ์
                </span>
              </div>
              <div>
                <span className="text-base">
                  ครู/นักเรียนที่ไม่สามารถระบุสถานะได้
                  แต่มีสิทธิ์ในหนังสือเล่มนี้อยู่{' '}
                  {media?.numberOfLicenseUsageNotInAllGroup || 0} สิทธิ์
                </span>
              </div>
            </>
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};
