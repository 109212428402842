import { SchoolStudentModifyResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useCreateSchoolStudent = () => {
  const [
    { data: response, loading, error },
    execute
  ] = useAxios<SchoolStudentModifyResponse>(
    {
      url: `/school-students`,
      method: 'POST'
    },
    { manual: true }
  );

  return {
    data: response?.data,
    loading: loading,
    error,
    execute
  };
};
