import {
  SchoolSubjectListQuery,
  SchoolSubjectListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useSchoolSubjects = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolSubjectListResponse>(
    {
      url: `/school-subjects`
    },
    { manual: true }
  );

  const execute = (params: SchoolSubjectListQuery) => {
    return fire({
      url: `/school-subjects`,
      params: {
        pageNumber: 1,
        pageSize: params?.pageSize ?? 20,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: response?.data || [],
    loading,
    error,
    execute,
    total: response?.meta?.pagination?.total || 0
  };
};
