/** @jsx jsx */
import { AutoComplete, Input } from 'antd';
import {
  adminCategory,
  studentCategory,
  teacherCategory
} from './UserGuideData';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as SearchFilled } from 'assets/search.svg';
import { css } from '@emotion/react';
import { getUserGuideByTopic } from 'api/user-guide/getUserGuideByTopic';
import { jsx } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Option = AutoComplete.Option;

type categoryType = {
  id: number;
  nodeId: string;
  titleTH: string;
  titleEN: string;
  route: string;
  topic: string;
  imageSrc: string;
  imageAlt: string;
};

type UserGuideData = {
  userGuideId: string;
  userGuideType: string;
  key: number;
  parentId: number;
  seqId: number;
  nodeLevel: number;
  titleEN: string;
  titleTH: string;
  descEN: string;
  descTH: string;
  isPdfUrl: boolean;
  pdfUrl: string;
  isVideoUrl: boolean;
  videoType: string;
  videoUrl: string;
};

export const SearchBar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [options, setOptions] = useState<any>([]);
  const [userGuideList, setUserGuideList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const componentMount = useRef(true);
  const student = 'student';
  const teacher = 'teacher';
  const admin = 'admin';

  useEffect(() => {
    if (options.length === 0 && componentMount.current) fetchUserGuide();
    return () => {
      componentMount.current = false;
    };
  }, [loading]);

  const fetchUserGuide = async () => {
    let UserGuides = [];
    let teacherOption = [];
    let adminOption = [];
    let studentOption = [];
    try {
      setLoading(true);
      const [
        { userGuideList: teacherResponse },
        { userGuideList: adminResponse },
        { userGuideList: studentResponse }
      ] = await Promise.all([
        getUserGuideByTopic({ topic: teacher }),
        getUserGuideByTopic({ topic: admin }),
        getUserGuideByTopic({ topic: student })
      ]);
      UserGuides = teacherResponse.concat(adminResponse, studentResponse);
      teacherOption = await setOptionList(teacherCategory, teacherResponse);
      adminOption = await setOptionList(adminCategory, adminResponse);
      studentOption = await setOptionList(studentCategory, studentResponse);
    } catch (error) {
      console.log(error);
    }
    const optionList = teacherOption.concat(adminOption, studentOption);
    setUserGuideList(UserGuides);
    setOptions(optionList);
    setLoading(false);
  };

  const setOptionList = async (title: categoryType, option: any[]) => {
    const list: any[] = [];
    list.push(
      <Option
        className="font-bold text-red-700"
        value={title.titleTH}
        key={title.topic}
      >
        {title.titleTH}
      </Option>
    );
    {
      option.map((opt) =>
        list.push(
          <Option
            key={opt.key}
            value={opt.titleTH}
            children={undefined}
          ></Option>
        )
      );
    }
    return list;
  };

  const onSelect = (value: any, option: any) => {
    let info;
    let topic: string;
    let key: string;
    switch (option.key) {
      case 'teacher':
        topic = 'teacher';
        key = '0';
        break;
      case 'admin':
        topic = 'admin';
        key = '0';
        break;
      case 'student':
        topic = 'student';
        key = '0';
        break;
      default:
        info = userGuideList.filter(
          (val: { key: any }) => val.key.toString() === option.key
        )[0];
        topic = info.userGuideType;
        key = info.key;
        break;
    }

    let url: string;
    switch (topic) {
      case 'teacher':
        url = 'teacher-guide';
        break;
      case 'admin':
        url = 'admin-guide';
        break;
      case 'student':
        url = 'student-guide';
        break;
      default:
        break;
    }
    setTimeout(() => {
      history.push({
        pathname: `/user-guide/${url}/${key}/${topic}`
      });
    }, 100);
  };

  return (
    <AutoComplete
      className="h-10 text-left"
      filterOption={(input, option) => {
        return (option?.value as string)?.includes(input);
      }}
      placeholder="ค้นหาด้วยคำสำคัญ"
      dropdownMatchSelectWidth={252}
      css={css`
        width: 35%;
        background-color: white;
        border-color: 1px solid #8f91a3;
        border-radius: 0.75em;
        @media (max-width: 670px) {
          width: 80%;
        }
      `}
      dataSource={options}
      onSelect={onSelect}
      children={
        <Input
          suffix={
            <div className="flex">
              <SearchFilled
                css={css`
                  path {
                    fill: #666666;
                  }
                `}
              />
            </div>
          }
        />
      }
    />
  );
};
