import { useHistory, useParams } from 'react-router-dom';

/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

const CardStyled = styled.div`
  box-shadow: 0px 2px 10px rgba(46, 47, 56, 0.1);
  border: 1px solid #f4f4f6;
  border-radius: 12px;
`;
type categoryType = {
  id: number;
  nodeId: string;
  titleTH: string;
  titleEN: string;
  route: string;
  topic: string;
  imageSrc: string;
  imageAlt: string;
};
interface categoryProps {
  category: categoryType;
}

export const CategoryCard = ({ category }: categoryProps) => {
  const history = useHistory();
  return (
    <>
      <CardStyled
        className="group relative w-90 h-56 py-12 px-2 overflow-hidden group-hover:opacity-75 text-center items-center"
        key={category.id}
        onClick={() => {
          history.push({
            pathname: `${category.route}/${category.nodeId}/${category.topic}`
          });
        }}
      >
        <img
          className="w-20 h-20 object-center"
          src={category.imageSrc}
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block'
          }}
        />
        <p className="text-xl font-bold mt-2 mx-3">{category.titleTH}</p>
      </CardStyled>
    </>
  );
};
