/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { Drawer } from 'antd';
import { SchoolTeacherForm } from './SchoolTeacherForm';
import { TeacherFormStep } from './teacher-management';

interface ManageSchoolTeacherInformationDrawerProps {
  title: string;
  visible: boolean;
  schoolTeacherId?: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const ManageSchoolTeacherInformationDrawer = ({
  title,
  visible,
  schoolTeacherId,
  onClose,
  onSubmit
}: ManageSchoolTeacherInformationDrawerProps) => {
  return (
    <Drawer
      placement="right"
      closable={false}
      maskClosable={false}
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
      width={480}
      css={css`
        .ant-drawer-body {
          padding: 1.25rem;
        }
      `}
    >
      <div className="mx-5">
        <div className="font-bold text-xl">{title}</div>
      <img
        alt="profile"
        src="/assets/profile-colour.png"
        className="w-32 h-32 my-8 mt-14 mx-auto"
      />
      <div className="mt-8">
        <SchoolTeacherForm
          schoolTeacherId={schoolTeacherId}
          initialStep={
            schoolTeacherId ? TeacherFormStep.BASIC_INFO : TeacherFormStep.EMAIL
          }
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </div></div>
      
    </Drawer>
  );
};
