import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal as ModalAntd,
  Row,
  Spin,
  message
} from 'antd';
import {
  ClassroomDto,
  ClassroomListDto,
  ClassroomTeacherDto,
  GradeLevelDto,
  Localization,
  SchoolYearCurrentGetDto,
  SchoolYearDto
} from '@aksorn/teaching-backoffice-sdk';
import Icon, { PlusCircleOutlined } from '@ant-design/icons';
// import { getRule, FormRule } from 'utils/getRule';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
/** @jsx jsx */
import React, { Fragment, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import {
  downloadClassroomByGradeLevelExcel,
  downloadClassroomByGradeLevelPdf
} from 'api/download/getClassroomByGradeLavel';
import {
  downloadClassroomExcel,
  downloadClassroomPdf
} from 'api/download/getClassroom';
import { groupBy, isEmpty, map, orderBy, sortBy } from 'lodash';

import { ClassroomListCreateDto } from '@aksorn/teaching-backoffice-sdk/src/generated/models/ClassroomListDto';
// import { ClassroomListCreateDto } from '@aksorn/teaching-backoffice-sdk';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { MANAGE_CLASSROOM_ERROR_MESSAGE_MAPPER } from 'feature/classroom-management/const';
import { Modal } from 'feature/common/components/Modal';
// import { ReactComponent as SuccessIcon } from 'assets/success-all.svg';
import { ReactComponent as ViewIcon } from 'assets/eye-show.svg';
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useDeleteClassroom } from 'hooks/classroom/useDeleteClassroom';
import { useGenClassroomStudentWithTempUser } from 'hooks/classroom-students/useClassroomStudent';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { userStore } from 'stores/userStore';

interface CreateClassroomFormProps {
  setIsAlertVisible: () => void;
  setIsSubmitVisible: () => void;
  setIsLicenseErrorVisible: () => void;
  setIsConfirmVisible: () => void;
  gradeLevels: GradeLevelDto[];
  classrooms: ClassroomListDto[];
  setOnRefresh: Function;
  setIsDuplicateClassNameErrorVisible: Function;
  schoolYears: SchoolYearCurrentGetDto | undefined;
}

export const CreateClassroomForm = ({
  classrooms,
  gradeLevels,
  setIsDuplicateClassNameErrorVisible,
  setIsAlertVisible,
  setIsSubmitVisible,
  setIsLicenseErrorVisible,
  setIsConfirmVisible,
  setOnRefresh,
  schoolYears
}: CreateClassroomFormProps) => {
  const { teachingProfile } = userStore;
  // const { classrooms } = props
  const [classroomForm] = Form.useForm();
  const [
    isWarningLongTextClassroomName,
    setIsWarningLongTextClassroomName
  ] = useState<boolean>(false);
  const [originClassrooms, setOriginClassrooms] = useState<
    ClassroomListCreateDto[]
  >([]);
  const [newCountClassroom, setNewCountClassroom] = useState<number>(0);
  const [newClassrooms, setNewClassrooms] = useState<ClassroomListCreateDto[]>(
    []
  );
  const [dataResultToUpdate, setDataResultToUpdate] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmitted, setLoadingSubmitted] = useState<boolean>(false);
  const [selectedClassroom, setSelectedClassroom] = useState<any>();
  const [isDeletingModalVisible, setIsDeletingModalVisible] = useState(false);
  const [isDocDownload, setIsDocDownload] = useState(false);
  const {
    loading: creating,
    execute: genClassroomStudentWithTempUser
  } = useGenClassroomStudentWithTempUser();
  const history = useHistory();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const {
    loading: isDeletingClassroom,
    execute: deleteClassroom
  } = useDeleteClassroom();
  useEffect(() => {
    setLoading(true);

    if (gradeLevels.length > 0) {
      var count = classrooms.length;
      var addBlankClassrooms = classrooms.map((classroom, index) => {
        return { ...classroom, elementId: index };
      });
      setOriginClassrooms(addBlankClassrooms);
      gradeLevels?.map(async (gradeLevel) => {
        let createClassroom = {
          elementId: count,
          classroomId: '',
          classroomName: '',
          // numberOfClassroomStudent: 0,
          gradeLevel: gradeLevel
          // mainSchoolTeacher: undefined,
          // updatedAt: ''
        } as ClassroomListCreateDto;
        var isNotEmpty = classrooms.filter(
          (i) => i.gradeLevel.gradeLevelId === gradeLevel.gradeLevelId
        );
        if (isNotEmpty.length === 0) addBlankClassrooms.push(createClassroom);
        count = count + 1;
      });
      setNewCountClassroom(count);
      const sortByGradeLevel = sortBy(
        addBlankClassrooms,
        (classroom) => classroom.gradeLevel.name.en
      );
      //setNewClassrooms(classrooms);
      setNewClassrooms(
        orderBy(
          sortByGradeLevel,
          function (o) {
            return parseInt(o.classroomName);
          },
          ['asc']
        )
      );

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [classrooms, gradeLevels]);

  useEffect(() => { }, [newClassrooms]);

  const addNewClassroom = async (gradeLevel: GradeLevelDto) => {
    setLoading(true);

    var count = newCountClassroom;
    setNewCountClassroom(count + 1);
    let createClassroom = {
      elementId: count,
      classroomId: '',
      classroomName: '',
      // numberOfClassroomStudent: 0,
      gradeLevel: gradeLevel
      // mainSchoolTeacher: undefined,
      // updatedAt: ''
    } as ClassroomListCreateDto;

    const sortByGradeLevel = sortBy(
      [...newClassrooms, createClassroom],
      (classroom) => classroom.gradeLevel.name.en
    );
    setNewClassrooms(sortByGradeLevel);

    setLoading(false);
  };

  const handleSubmitClassroom = async (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    e.stopPropagation();
    e.preventDefault();
    setLoadingSubmitted(true);
    const value = classroomForm.getFieldsValue();

    const emptyClassroom = newClassrooms.filter(
      (i) => !i.numberOfClassroomStudent && !i.classroomName
    );
    const tempDifClassroomsWithOutEmpty = newClassrooms.filter(
      (i) => i.numberOfClassroomStudent && i.classroomName
    );
    //  console.log(tempDifClassroomsWithOutEmpty)

    //Combine the two arrays of unique entries
    let resultUpdate = [] as ClassroomListCreateDto[];
    // console.log(originClassrooms)
    tempDifClassroomsWithOutEmpty.map((item) => {
      const compareItem = originClassrooms.find(
        (i) => i.elementId === item.elementId
      );
      //console.log(compareItem)
      if (compareItem) {
        const originItem = compareItem;
        if (
          item.classroomName !== originItem?.classroomName ||
          item.numberOfClassroomStudent !== originItem?.numberOfClassroomStudent
        ) {
          resultUpdate.push(item);
        }
      } else {
        resultUpdate.push(item);
      }
    });

    let isLongTextClassroom = false;
    const resultToUpdate = resultUpdate.map((update) => {
      if (update.classroomName.length > 20) {
        isLongTextClassroom = true;
      }
      return {
        elementId: update.elementId.toString(),
        classroomId: update.classroomId !== '' ? update.classroomId : null,
        classroomName: update.classroomName,
        noOfStudents: update.numberOfClassroomStudent,
        gradeLevelId: update.gradeLevel.gradeLevelId
      };
    });

    //   console.log(resultToUpdate)
    setDataResultToUpdate(resultToUpdate);
    if (resultToUpdate.length > 0 && isLongTextClassroom) {
      // console.log("too long")
      setIsWarningLongTextClassroomName(true);
    } else if (resultToUpdate) {
      // console.log(resultToUpdate)
      submitDataUpdateClassrooms(resultToUpdate);
    }
  };

  const handleSubmitClassroomByClass = async (
  grl:GradeLevelDto) => {
    setLoadingSubmitted(true);
    const value = classroomForm.getFieldsValue();

    const newClass = newClassrooms.filter((i) => i.gradeLevel.gradeLevelId === grl.gradeLevelId)
    const emptyClassroom = newClass.filter(
      (i) => !i.numberOfClassroomStudent && !i.classroomName
    );
    const tempDifClassroomsWithOutEmpty = newClass.filter(
      (i) => i.numberOfClassroomStudent && i.classroomName
    );

    //Combine the two arrays of unique entries
    let resultUpdate = [] as ClassroomListCreateDto[];
    tempDifClassroomsWithOutEmpty.map((item) => {
      const compareItem = originClassrooms.find(
        (i) => i.elementId === item.elementId
      );
      if (compareItem) {
        const originItem = compareItem;
        if (
          item.classroomName !== originItem?.classroomName ||
          item.numberOfClassroomStudent !== originItem?.numberOfClassroomStudent
        ) {
          resultUpdate.push(item);
        }
      } else {
        resultUpdate.push(item);
      }
    });

    let isLongTextClassroom = false;
    const resultToUpdate = resultUpdate.map((update) => {
      if (update.classroomName.length > 20) {
        isLongTextClassroom = true;
      }
      return {
        elementId: update.elementId.toString(),
        classroomId: update.classroomId !== '' ? update.classroomId : null,
        classroomName: update.classroomName,
        noOfStudents: update.numberOfClassroomStudent,
        gradeLevelId: update.gradeLevel.gradeLevelId
      };
    });


    setDataResultToUpdate(resultToUpdate);
    if (resultToUpdate.length > 0 && isLongTextClassroom) {
      // console.log("too long")
      setIsWarningLongTextClassroomName(true);
    } else if (resultToUpdate) {
      // console.log(resultToUpdate)
      submitDataUpdateClassrooms(resultToUpdate);
    }
  };

  const submitDataUpdateClassrooms = async (resultToUpdate: any) => {
    try {
      if (
        currentSchoolYears?.schoolYearId &&
        teachingProfile?.school.schoolId
      ) {
        const {
          data: { data: classroomTempUser }
        } = await genClassroomStudentWithTempUser({
          data: {
            schoolId: teachingProfile.school.schoolId,
            schoolYearId: currentSchoolYears.schoolYearId,
            classroomList: resultToUpdate
          }
        });
        //  console.log(classroomTempUser)
        setIsSubmitVisible();
        setOnRefresh(true);
      }
    } catch (error) {
      const { data: errorsResponse } = error?.response;

      if (errorsResponse?.code === 500) {
        const e = errorsResponse?.errors.shift();
        if (e.errorCode === 401) {
          setIsDuplicateClassNameErrorVisible(true); // แก้เป็นห้องชื่อซ้ำ
        } else if (e.errorCode === 402) {
          setIsLicenseErrorVisible();
        }
      }

      console.log(error);
    } finally {
      setLoadingSubmitted(false);
    }
  };

  const removeClassroom = (elementId: number) => {
    setLoading(true);
    //ถ้ามีไอดีห้องให้ส่งapiไปเช็คถ้าได้ลบ ถ้าไม่ได้เหลือเท่าเดิม
    try {
      const myClassroom = newClassrooms.find(
        (item) => item.elementId === elementId
      );
      // e.stopPropagation();

      if (myClassroom?.classroomId) {
        setSelectedClassroom(myClassroom);
        setIsDeletingModalVisible(true);
      } else {
        const myNewClassroom = newClassrooms.filter(
          (item) => item.elementId !== elementId
        );
        setNewClassrooms(myNewClassroom);
      }
    } catch (error) {
      console.log(error);
      const { data: errorsResponse } = error?.response;
      //if (errorsResponse?.code === 402) {
      setIsAlertVisible();
      // }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadClassroomExcel = async (classroomId: string) => {
    if (currentSchoolYears?.schoolYearId) {
      setIsDocDownload(true);
      try {
        const response = await downloadClassroomExcel(
          classroomId,
          currentSchoolYears?.schoolYearId
        );

      } catch (error) {
        console.log(error);
      }
    }
    setIsDocDownload(false);
  };

  const handleDownloadClassroomPdf = async (classroomId: string) => {
    if (currentSchoolYears?.schoolYearId) {
      setIsDocDownload(true);
      try {
        await downloadClassroomPdf(
          classroomId,
          currentSchoolYears?.schoolYearId
        );
      } catch (error) {
        console.log(error);
      }
    }
    setIsDocDownload(false);
  };
  const downloadClassroomByGradeAll = async (
    gradeLevelId: string,
    gradeLevelName: string
  ) => {
    if (currentSchoolYears?.schoolYearId) {
      setIsDocDownload(true);
      try {
        const excelResponse = await downloadClassroomByGradeLevelExcel(
          gradeLevelId,
          currentSchoolYears?.schoolYearId
        );

      } catch (error) {
        console.log(error);
      }
      try {
        const pdfResponse = await downloadClassroomByGradeLevelPdf(
          gradeLevelId,
          currentSchoolYears?.schoolYearId
        );

      } catch (error) {
        console.log(error);
      }
    }
    setIsDocDownload(false);
  };

  const downloadClassroomByGradeExcel = async (gradeLevelId: string) => {
    if (currentSchoolYears?.schoolYearId) {
      setIsDocDownload(true);
      try {
        await downloadClassroomByGradeLevelExcel(
          gradeLevelId,
          currentSchoolYears?.schoolYearId
        );
      } catch (error) {
        console.log(error);
      }
    }
    setIsDocDownload(false);
  };
  const downloadClassroomByGradePdf = async (gradeLevelId: string) => {
    if (currentSchoolYears?.schoolYearId) {
      setIsDocDownload(true);
      try {
        await downloadClassroomByGradeLevelPdf(
          gradeLevelId,
          currentSchoolYears?.schoolYearId
        );
      } catch (error) {
        console.log(error);
      }
    }
    setIsDocDownload(false);
  };

  const viewClassroom = (classroomId: string) => {
    history.push('/classroom-management/' + classroomId);
  };

  return (
    <div className="pt-7">
      {gradeLevels.length > 0 ? (
        <Fragment>
          <div className="bg-white border border-secondary-gray-10" css={css``}>
            <Row
              gutter={8}
              className="mt-4 mb-4 mx-4"
              css={css`
                /* .ant-form-item {
                                              margin: 0 !important;
                                              } */
              `}
            >
              <Col span={2}>
                <div className="text-base font-bold">ระดับชั้น</div>
              </Col>
              <Col
                span={1}
                className="relative"
                css={css`
                  top: 10px;
                `}
              ></Col>
              <Col span={8}>
                <div className="text-base font-bold ">ชื่อห้อง</div>
              </Col>
              <Col
                span={1}
                className="relative"
                css={css`
                  top: 10px;
                `}
              ></Col>
              <Col span={6}>
                <div className="text-base font-bold "> จำนวนนักเรียน</div>
              </Col>

              <Col
                span={1}
                className="relative"
                css={css`
                  top: 10px;
                `}
              ></Col>
              <Col
                span={5}
                className="flex justify-center self-start"
                css={css`
                  top: 10px;
                `}
              ></Col>
            </Row>{' '}
            <Divider
              className="my-0"
            //  className='my-0'
            />
            <Spin spinning={loading}>
              <Fragment>
                {gradeLevels.map((gradeLevel) => (
                  <Fragment>
                    <Row
                      gutter={8}
                      className="mt-4 mb-4 mx-4"
                      css={css``}
                      key={gradeLevel.gradeLevelId}
                    >
                      <Col span={2} className="flex items-center text-center">
                        <p className="font-bold text-left">
                          {gradeLevel.name.th}
                        </p>
                      </Col>
                      <Col
                        span={1}
                        // className="relative "
                        css={css`
                          top: 10px;
                        `}
                      ></Col>
                      <Col
                        span={8}
                        className="flex items-center text-center"
                      ></Col>
                      <Col span={1}></Col>
                      <Col
                        span={6}
                        className="flex items-center text-center"
                      ></Col>
                      <Col span={1} className="relative"></Col>

                      <Col
                        span={5}
                        // className="flex justify-end self-start my-4"
                        className="flex space-x-8 items-center justify-end"
                      // self-start mt-2
                      >
                        <Dropdown
                          trigger={['click']}
                          overlay={
                            <Menu>
                              <Menu.Item
                                className="px-4 py-2 text-base text-secondary-gray-60"
                                onClick={() => {

                                  downloadClassroomByGradeAll(
                                    gradeLevel.gradeLevelId,
                                    gradeLevel.name.th
                                  );
                                }}
                                css={css`
                                  white-space: normal;
                                  width: 240px;
                                `}
                              >
                                ดาวน์โหลดไฟล์ท้ังหมด
                              </Menu.Item>{' '}
                              <Menu.Item
                                className="px-4 py-2 text-base text-secondary-gray-60"
                                onClick={() => {
                                  downloadClassroomByGradeExcel(
                                    gradeLevel.gradeLevelId
                                  );
                                }}
                                css={css`
                                  white-space: normal;
                                  width: 240px;
                                `}
                              >
                                ดาวน์โหลดเฉพาะใบรายการ บัญชีผู้ใช้งาน (ไฟล์
                                Excel)
                              </Menu.Item>
                              <Menu.Item
                                className="px-4 py-2 text-base text-secondary-gray-60"
                                onClick={() => {
                                  downloadClassroomByGradePdf(
                                    gradeLevel.gradeLevelId
                                  );
                                }}
                                css={css`
                                  white-space: normal;
                                  width: 240px;
                                `}
                              >
                                ดาวน์โหลดเฉพาะบัตรประจำ ตัวบัญชีผู้ใช้งาน (ไฟล์
                                PDF)
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <SecondaryButton
                            className="flex justify-end items-center cursor-pointer  mr-0"
                            css={css`
                              border: 0px none !important;

                              display: block;
                            `}
                          // onClick={() => {

                          //     console.log(gradeLevel)
                          // }}
                          >
                            <DownloadIcon
                              title="ดาวน์โหลดทั้งระดับชั้น"
                              className="mr-2 cursor-pointer"
                            />{' '}
                            ดาวน์โหลดทั้งระดับชั้น{' '}
                          </SecondaryButton>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Divider className="my-0" />
                    {newClassrooms
                      .filter(
                        (c) =>
                          c.gradeLevel.gradeLevelId === gradeLevel.gradeLevelId
                      )
                      .map((classroom, index) => (
                        <Fragment>
                          <Row
                            gutter={8}
                            className="mt-4 mb-4 mx-4"
                            css={css``}
                            key={classroom.classroomId}
                          >
                            <Col
                              span={2}
                              className="flex items-center text-center"
                            >
                              {/* {index === 0 ? (
                                                        <p className="font-bold text-left">
                                                            {gradeLevel.name.th}
                                                        </p>
                                                    ) : null} */}
                            </Col>
                            <Col
                              span={1}
                              // className="relative "
                              css={css`
                                top: 10px;
                              `}
                            ></Col>
                            <Col
                              span={8}
                              className="flex items-center text-center"
                            >
                              <Input
                                className="rounded-xl h-10"
                                value={classroom.classroomName}
                                disabled={classroom.classroomId ? true : false}
                                onChange={(e) => {
                                  //console.log(classroom.classroomId + " = " + e.target.value)
                                  var findIndex = newClassrooms.findIndex(
                                    (i) => i.elementId === classroom.elementId
                                  );
                                  const newClassroomList = newClassrooms.map(
                                    (item, index) => {
                                      if (
                                        item.elementId === classroom.elementId
                                      ) {
                                        const isLetterOrNumber = /^[A-Za-z0-9]*$/.test(
                                          e.target.value
                                        );
                                        if (isLetterOrNumber) {
                                          return {
                                            ...item,
                                            classroomName: e.target.value
                                          };
                                        } else {
                                          return { ...item };
                                        }
                                      }
                                      return { ...item };
                                    }
                                  );

                                  setNewClassrooms(newClassroomList);
                                }}
                                placeholder="ชื่อห้อง"
                              />
                            </Col>
                            <Col span={1}></Col>
                            <Col
                              span={6}
                              className="flex items-center text-center"
                            >
                              <Input
                                className="rounded-xl h-10"
                                type="number"
                                min={0}
                                value={classroom.numberOfClassroomStudent}
                                onChange={(e) => {
                                  var findIndex = newClassrooms.findIndex(
                                    (i) => i.elementId === classroom.elementId
                                  );
                                  const newClassroomList = newClassrooms.map(
                                    (item, index) => {
                                      if (
                                        item.elementId === classroom.elementId
                                      ) {
                                        return {
                                          ...item,
                                          numberOfClassroomStudent: parseInt(
                                            e.target.value
                                          )
                                        };
                                      }
                                      return { ...item };
                                    }
                                  );

                                  setNewClassrooms(newClassroomList);
                                }}
                                placeholder="จำนวนนักเรียน"
                              />
                            </Col>
                            <Col span={1} className="relative"></Col>

                            <Col
                              span={5}
                              // className="flex justify-end self-start my-4"
                              className="flex space-x-8 items-center justify-end"
                            // self-start mt-2
                            >
                              <DeleteIcon
                                title="ลบห้องเรียน"
                                className="cursor-pointer"
                                onClick={() => {
                                  // remove(field.name)
                                  removeClassroom(classroom.elementId);
                                }}
                              />

                              <Dropdown
                                trigger={['click']}
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      className="px-4 py-2 text-base text-secondary-gray-60"
                                      onClick={() => {
                                        handleDownloadClassroomExcel(
                                          classroom.classroomId
                                        );
                                        handleDownloadClassroomPdf(
                                          classroom.classroomId
                                        );
                                      }}
                                      css={css`
                                        white-space: normal;
                                        width: 240px;
                                      `}
                                    >
                                      ดาวน์โหลดไฟล์ท้ังหมด
                                    </Menu.Item>{' '}
                                    <Menu.Item
                                      className="px-4 py-2 text-base text-secondary-gray-60"
                                      onClick={() => {
                                        handleDownloadClassroomExcel(
                                          classroom.classroomId
                                        );
                                      }}
                                      css={css`
                                        white-space: normal;
                                        width: 240px;
                                      `}
                                    >
                                      ดาวน์โหลดเฉพาะใบรายการ บัญชีผู้ใช้งาน
                                      (ไฟล์ Excel)
                                    </Menu.Item>
                                    <Menu.Item
                                      className="px-4 py-2 text-base text-secondary-gray-60"
                                      onClick={() => {
                                        handleDownloadClassroomPdf(
                                          classroom.classroomId
                                        );
                                      }}
                                      css={css`
                                        white-space: normal;
                                        width: 240px;
                                      `}
                                    >
                                      ดาวน์โหลดเฉพาะบัตรประจำ ตัวบัญชีผู้ใช้งาน
                                      (ไฟล์ PDF)
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                <DownloadIcon
                                  title="ดาวน์โหลด"
                                  className="cursor-pointer"
                                />
                              </Dropdown>

                              <ViewIcon
                                title="ดูรายละเอียด"
                                className="cursor-pointer "
                                onClick={() => {
                                  viewClassroom(classroom.classroomId);
                                }}
                              />
                            </Col>
                          </Row>
                          <Divider className="my-0" />
                        </Fragment>
                      ))}

                    <Row gutter={8} className="mt-4 mx-4 mb-4" css={css``}>
                      <Col span={2}></Col> <Col span={1}></Col>
                      {/* <Col span={4}></Col> */}
                      <Col
                        span={3}
                        // className="relative"
                        css={css`
                          /* top: 10px; */
                        `}
                      ></Col>
                      {/* <Col span={6}></Col> */}
                      <Col
                        span={4}
                        //    className="relative"
                        css={css`
                          /* top: 10px; */
                        `}
                      ></Col>
                      <Col
                        span={1}
                        // className="relative"
                        css={css`
                          /* top: 10px; */
                        `}
                      ></Col>
                      <Col span={5} className="flex ">
                        <SecondaryButton
                          className="flex justify-end items-center cursor-pointer"
                          css={css`
                            display: block;
                          `}
                          onClick={() => {
                            addNewClassroom(gradeLevel);
                          }}
                        >
                          <PlusCircleOutlined className="mr-2" /> เพิ่มห้อง{' '}
                          {gradeLevel.name.th}
                        </SecondaryButton>
                      </Col>
                      <Col span={8} className="flex justify-end items-end">
                        <Button
                          className="flex items-center text-base border-none font-bold text-white"
                          css={css`
                            background-color: #f99d34;
                            :hover {
                              background-color: #fcbd10;
                            }
                            width: 240px;
                            height: 40px;
                            border-radius: 10px;
                          `}
                          onClick={() => handleSubmitClassroomByClass(gradeLevel)}
                          disabled={loadingSubmitted}
                        >
                          <Spin
                            spinning={loadingSubmitted}
                            className="mr-2"
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: '1rem',
                                  color: '#C2C2C2'
                                }}
                                spin
                              />
                            }
                          />
                          บันทึกและ Gen Temp E-mail
                        </Button>
                      </Col>
                    </Row>
                    <Divider className="my-0" />
                  </Fragment>
                ))}
              </Fragment>{' '}
            </Spin>
            {/* </Form> */}
          </div>
          <Row className="my-4">
            <Col span={20}></Col>
            <Col span={4} className="flex justify-end items-right">
              <Button
                className="flex items-center text-base border-none font-bold text-white"
                css={css`
                  background-color: #f99d34;
                  :hover {
                    background-color: #fcbd10;
                  }
                  width: 240px;
                  height: 40px;
                  border-radius: 10px;
                `}
                onClick={handleSubmitClassroom}
                disabled={loadingSubmitted}
              >
                <Spin
                  spinning={loadingSubmitted}
                  className="mr-2"
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: '1rem',
                        color: '#C2C2C2'
                      }}
                      spin
                    />
                  }
                />
                บันทึกและ Gen Temp E-mail
              </Button>
            </Col>
          </Row>{' '}
        </Fragment>
      ) : (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <img alt="empty" src="/assets/empty-media.png" />
          <span className="text-gray-40 mt-8">
            ยังไม่มีข้อมูลห้องเรียนและนักเรียน
          </span>
        </div>
      )}
      <Modal
        visible={isDeletingModalVisible}
        title={
          <Fragment>
            คุณต้องการลบห้องเรียน
            <br />
            {`${selectedClassroom?.gradeLevel.name.th}/${selectedClassroom?.classroomName}`}{' '}
            หรือไม่
          </Fragment>
        }
        cancel={{
          onClick: () => {
            setIsDeletingModalVisible(false);
            setSelectedClassroom(null);
          },
          label: 'ยกเลิก'
        }}
        submit={{
          onClick: async () => {
            const hide = message.loading('กำลังดำเนินการ');
            try {
              if (selectedClassroom?.classroomId && currentSchoolYears) {
                await deleteClassroom(selectedClassroom.classroomId);
                setOnRefresh(true);
                message.success('ลบห้องเรียนสำเร็จ');
              } else {
                throw new Error('ไม่มีห้องเรียน');
              }
            } catch (error) {
              console.error(error);

              let errorMessage =
                MANAGE_CLASSROOM_ERROR_MESSAGE_MAPPER[
                error?.response.data.message
                ];
              if (!errorMessage) {
                errorMessage = 'ลบห้องเรียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';
              }

              message.error(errorMessage);
            } finally {
              hide();
              setIsDeletingModalVisible(false);
              setSelectedClassroom(null);
              // setNewClassrooms(newClassrooms.filter((i) => i.elementId !== selectedClassroom.elementId));
              setOnRefresh(true);
            }
          },
          label: 'ยืนยัน',
          loading: isDeletingClassroom
        }}
      />
      <ModalAntd
        visible={isWarningLongTextClassroomName}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <WarningIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'ชื่อห้องเรียนของคุณยาวเกินไป'}
          </h3>

          <p className="text-sm text-center">
            {
              'ชื่อห้องเรียนของคุณยาวเกินกว่าที่ระบบแนะนำ ซึ่งอาจส่งผลต่อรูปแบบชื่อบัญชีผู้ใช้งาน ต้องการยืนยันที่จะใช้ชื่อห้องเรียนนี้ต่อไปหรือไม่?'
            }
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <SecondaryButton
                className="mx-4"
                onClick={() => setIsWarningLongTextClassroomName(false)}
              >
                ยกเลิก
              </SecondaryButton>
              <PrimaryButton
                className="w-24"
                onClick={() => {
                  if (dataResultToUpdate.length > 0) {
                    submitDataUpdateClassrooms(dataResultToUpdate);
                  }
                  setIsWarningLongTextClassroomName(false);
                  //history.push('/student-management')
                }}
              >
                {'ยืนยัน'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </ModalAntd>
      <ModalAntd
        visible={isDocDownload}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2 h-20">
          <p className="text-semibold text-xl mb-2">กำลังดาวน์โหลดเอกสาร</p>
          <p>
            <Spin spinning={isDocDownload}></Spin>
          </p>
        </div>
      </ModalAntd>
      {/* <Modal
                title="กำลังดาวน์โหลดเอกสาร"
                subTitle={`<Spin spinning={loading}></Spin>`}
                visible={isDocDownload}
                width={400}
                //onCancel={() => setIsSubmitVisible(false)}
                css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
            /> */}
    </div>
  );
};
