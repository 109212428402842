import React, { useEffect, useMemo } from 'react';
import {
  useEvaluationsStatistic,
  useEvaluationsStatisticLicense
} from 'hooks/evaluation/useEvaluationsStatistic';

import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { EvaluationEmptyState } from './EvaluationEmptyState';
import { EvaluationPrePostTestStatisticDto } from '@aksorn/teaching-backoffice-sdk';
import { EvaluationStatisticGetQuery } from '@aksorn/teaching-backoffice-sdk';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { OverviewBarChart } from 'feature/common/barchart/OverviewBarChart';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import dayjs from 'dayjs';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';

export const EvaluationAssignmentSubjectScore = observer(() => {
  // const {
  //   data: evaluationStatistic,
  //   loading: isLoadingEvaluationStatistic,
  //   execute: getEvaluationStatistic
  // } = useEvaluationsStatistic();
  const {
    data: evaluationStatistic,
    loading: isLoadingEvaluationStatistic,
    execute: getEvaluationStatisticLicense
  } = useEvaluationsStatisticLicense();

  const { filterData } = evaluationStore;
  const { clearEvaluationDownloads } = evaluationDownloadStore;

  const fetchEvaluationStatistic = async () => {
    try {
      const query: EvaluationStatisticGetQuery = {
        schoolYearId: filterData.selectedSchoolYearId,
        gradeLevelId: filterData.selectedGradeLevel.gradeLevelId
      };
      if (
        filterData.selectedSubject?.subjectId &&
        filterData.selectedGradeLevel
      ) {
        Object.assign(query, {
          subjectId: filterData.selectedSubject?.subjectId
        });
      }
      // await getEvaluationStatistic(query);
      await getEvaluationStatisticLicense(query);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      filterData.selectedGradeLevel?.gradeLevelId &&
      filterData.selectedSchoolYearId
    ) {
      fetchEvaluationStatistic();
    }

    return () => {
      clearEvaluationDownloads();
    };
  }, [
    filterData.selectedGradeLevel?.gradeLevelId,
    filterData.selectedSchoolYearId,
    filterData.selectedSubject?.subjectId
  ]);

  const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

  const fileName = useMemo(() => {
    clearEvaluationDownloads();
    if (filterData.selectedGradeLevel) {
      return `${downloadDate}_${
        filterData.selectedGradeLevel.name.th || 'ทั้งหมด'
      }_ทั้งหมด`;
    }
  }, [filterData.selectedGradeLevel, filterData.selectedSubject]);

  return (
    <div>
      {evaluationStatistic?.length > 0 ? (
        <>
          {evaluationStatistic?.map(
            ({ subjectId, name, gradeLevel, classrooms }) => {
              return (
                <EvaluationContainer
                  loading={{
                    isLoading: isLoadingEvaluationStatistic,
                    height: 202
                  }}
                  className="mb-8"
                  title={name.th}
                  key={subjectId}
                  fileName={
                    fileName && `${fileName}_${name.th}_คะแนนจากงานมอบหมาย`
                  }
                >
                  <div className="flex mb-4">
                    <SquareGraphLabel
                      color="#F99F39"
                      label="คะแนนเฉลี่ยรายห้อง"
                    />
                    <LineGraphLabel
                      color="#FFA159"
                      label="คะแนนเฉลี่ยทั้งระดับชั้น"
                      description={`${
                        gradeLevel.averageScore.toFixed(1) ?? '0.0'
                      } คะแนน/${gradeLevel.numberOfSubmissions} คน`}
                    />
                  </div>
                  <OverviewBarChart
                    axisNames={classrooms.map(
                      (item: any) =>
                        gradeLevel.name.th + '/' + item.classroomName
                    )}
                    dataSource={[
                      {
                        barWidth: 32,
                        barColor: '#F99F39',
                        value: classrooms.map((classroom: any) =>
                          classroom.numberOfSubmissions > 0
                            ? classroom.averageScore.toFixed(1)
                            : ''
                        )
                      }
                    ]}
                    markLines={[
                      {
                        lineColor: '#FFA159',
                        value: gradeLevel.averageScore
                      }
                    ]}
                    chartMaxScore={
                      classrooms.reduce(
                        (
                          barChartMaxScore: number,
                          classroom: EvaluationPrePostTestStatisticDto
                        ) =>
                          barChartMaxScore > classroom.maxScore
                            ? barChartMaxScore
                            : classroom.maxScore,
                        0
                      ) || 100
                    }
                  />
                </EvaluationContainer>
              );
            }
          )}
        </>
      ) : (
        <EvaluationEmptyState />
      )}
    </div>
  );
});
