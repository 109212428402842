import {
  ClassroomDto,
  ImportExcelClassPeriodBulkResponse,
  ImportExcelClassroomResponse,
  ImportExcelParseResponse,
  ImportExcelSchoolStudentBulkResponse,
  ImportExcelSchoolTeacherBulkResponse,
  SchoolYearDto
} from '@aksorn/teaching-backoffice-sdk';
import { Divider, Form, Select, Skeleton } from 'antd';
/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react';
import { Global, css, jsx } from '@emotion/react';
import { Modal, ModalProps } from 'feature/common/components/Modal';
import {
  ParseExcelResponseMessage,
  ValidationStep
} from 'feature/dashboard/dashboard';

// import { DashboardFilterBar } from 'feature/dashboard/DashboardFilterBar';
// import { ReactComponent as Excel } from 'assets/excel.svg';
import { ExcelManagementDrawer } from 'feature/dashboard/components/import-excel/ExcelManagementDrawer';
import { FileItem } from 'feature/dashboard/components/import-excel/FileItem';
import { ImportExcelModalResult } from 'feature/dashboard/components/import-excel/ImportExcelModalResult';
import { ImportExcelParseErrorResult } from 'feature/dashboard/components/import-excel/ImportExcelParseErrorResult';
import { ImportExcelSuccessfulModal } from 'feature/dashboard/components/import-excel/ImportExcelSuccessfulModal';
import { Loading } from 'feature/common/components/Loading';
import Lottie from 'react-lottie';
import { ManagementCard } from 'feature/dashboard/components/ManagementCard';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { ProgressStepStyled } from 'feature/dashboard/components/import-excel/const';
import { RcFile } from 'antd/lib/upload';
import { SchoolDefaultPasswordButton } from 'feature/common/components/school-default-password/SchoolDefaultPasswordButton';
import { SidebarMenuKey } from 'feature/common/components/sidebar.type';
import { Steps } from 'antd';
import animationData from 'assets/lotties/aksorn-logo-loading.json';
import { getDefaultPassword } from 'api/default-password/getDefaultPassword';
// import { dashboardStore } from 'stores/dashboardStore';
// import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { progressIcon } from 'feature/dashboard/utils/getProgressIcon';
import { useCalculateNumberOfData } from 'feature/dashboard/components/useCalculateNumberOfData';
import { useClassroom } from 'hooks/classroom/useClassroom';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useImportExcelValidateClassPeriods } from 'hooks/import-excel/useImportExcelValidateClassPeriods';
import { useImportExcelValidateClassrooms } from 'hooks/import-excel/useImportExcelValidateClassrooms';
import { useImportExcelValidateSchoolStudents } from 'hooks/import-excel/useImportExcelValidateSchoolStudents';
import { useImportExcelValidateSchoolTeachers } from 'hooks/import-excel/useImportExcelValidateSchoolTeachers';
import { useParseExcel } from 'hooks/import-excel/useParseExcel';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';
import { userStore } from 'stores/userStore';

const { Step } = Steps;
const { Option } = Select;

const StepStyled = css`
  .ant-steps-item-finish .ant-steps-icon > span > svg {
    fill: #6fcf97 !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    .ant-steps-item-description {
    color: #6fcf97 !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #6fcf97 !important;
  }
  .ant-steps-item-finish .ant-steps-icon,
  .ant-steps-item-error .ant-steps-icon {
    background-color: #ffffff;
  }
  ${ProgressStepStyled}
`;

export const DashBoardPage = observer(() => {
  const { data, loading } = useCalculateNumberOfData();
  const { teachingProfile, me } = userStore;

  const {
    data: schoolYearsData,
    loading: schoolYearsLoading
  } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState('');
  const [schoolYears, setSchoolYears] = useState<SchoolYearDto[]>([]);
  const [defaultPassword, setDefaultPassword] = useState<string>('');
  const [defaultPasswordLoading, setDefaultPasswordLoading] = useState<boolean>(
    true
  );

  useEffect(() => {
    if (!schoolYearsLoading && currentSchoolYears) {
      setSelectedSchoolYearId(currentSchoolYears.schoolYearId);
      setSchoolYears(schoolYearsData);
    }
  }, [schoolYearsLoading, currentSchoolYears]);

  useEffect(() => {
    if (me) {
      fetchDefaultPassword();
    }
  }, [me]);

  const fetchDefaultPassword = async () => {
    setDefaultPasswordLoading(true);
    const schoolId = me?.schoolId;
    try {
      if (schoolId) {
        const { data: schoolDefaultPassword } = await getDefaultPassword({
          schoolId: schoolId
        });
        setDefaultPassword(schoolDefaultPassword.schoolDefaultPassword);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDefaultPasswordLoading(false);
    }
  };

  const {
    numberOfSchoolLicenses,
    numberOfSchoolSubjects,
    numberOfClassrooms,
    numberOfSchoolStudents,
    numberOfApprovedSchoolTeachers,
    numberOfWaitingForApprovalSchoolTeachers
  } = data;

  const {
    schoolLicensesLoading,
    schoolSubjectsLoading,
    classroomsLoading,
    schoolStudentsLoading,
    schoolTeachersLoading,
    schoolTeachersRequestLoading
  } = loading;

  const managementCards = [
    {
      key: 'classroom-card',
      loading: schoolSubjectsLoading || classroomsLoading,
      title: 'ห้องเรียน',
      imgUrl: 'assets/dashboard-classroom-logo.png',
      values: [
        { amount: numberOfClassrooms, unit: 'ห้องทั้งหมด' },
        { amount: numberOfSchoolSubjects, unit: 'วิชาเรียน' }
      ],
      redirectPath: SidebarMenuKey.CREATE_CLASSROOM,
      // redirectPath: '/classroom-management',
      emptyStateText: 'ยังไม่มีห้องเรียน',
      buttonText: 'เพิ่มห้องเรียน'
    },
    {
      key: 'media-card',
      loading: schoolLicensesLoading,
      title: 'สื่อการสอน',
      imgUrl: 'assets/dashboard-book-logo.png',
      values: [{ amount: numberOfSchoolLicenses, unit: 'สื่อในห้องเรียน' }],
      redirectPath: SidebarMenuKey.MEDIA_MANAGEMENT_FOR_CLASSROOM,
      // redirectPath: '/media-management',
      emptyStateText: 'ยังไม่มีสื่อในโรงเรียน',
      buttonText: 'จัดการสื่อ'
    },
    {
      key: 'student-card',
      loading: schoolStudentsLoading,
      title: 'นักเรียน',
      imgUrl: 'assets/dashboard-book-logo.png',
      values: [{ amount: numberOfSchoolStudents, unit: 'นักเรียนในระบบ' }],
      redirectPath: SidebarMenuKey.STUDENT_MANAGEMENT,
      // redirectPath: '/student-management',
      emptyStateText: 'ยังไม่มีนักเรียน',
      buttonText: 'เพิ่มนักเรียน'
    },
    {
      key: 'teacher-card',
      loading: schoolTeachersLoading || schoolTeachersRequestLoading,
      title: 'ครู',
      imgUrl: 'assets/dashboard-teacher-logo.png',
      values: [
        { amount: numberOfApprovedSchoolTeachers, unit: 'ครูในระบบ' },
        { amount: numberOfWaitingForApprovalSchoolTeachers, unit: 'รออนุมัติ' }
      ],
      redirectPath: SidebarMenuKey.TEACHER_MANAGEMENT,
      // redirectPath: '/teacher-management',
      emptyStateText: 'ยังไม่มีครูผู้สอน',
      buttonText: 'เพิ่มครูผู้สอน'
    }
  ];
  const defaultLoadingAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: JSON.parse(`${animationData}`),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [
    excelManagementDrawerVisible,
    setExcelManagementDrawerVisible
  ] = useState(false);
  const [validationStep, setValidationStep] = useState<ValidationStep>();
  const [validateExcelErrorResponse, setValidateExcelErrorResponse] = useState<
    | ImportExcelSchoolTeacherBulkResponse
    | ImportExcelClassroomResponse
    | ImportExcelClassPeriodBulkResponse
    | ImportExcelSchoolStudentBulkResponse
  >();
  const [
    parseExcelErrorResponse,
    setParseExcelErrorResponse
  ] = useState<ImportExcelParseResponse>();
  const [importedClassroom, setImportedClassroom] = useState<ClassroomDto>();

  const { execute: parseExcel } = useParseExcel();
  const {
    execute: validateSchoolTeachers
  } = useImportExcelValidateSchoolTeachers();

  const { execute: validateClassrooms } = useImportExcelValidateClassrooms();

  const {
    execute: validateClassPeriods
  } = useImportExcelValidateClassPeriods();

  const {
    execute: validateSchoolStudents
  } = useImportExcelValidateSchoolStudents();

  const { execute: getClassroom } = useClassroom();

  // NOTE: There are many modals which could be reused by setModalProps
  const [modalProps, setModalProps] = useState<ModalProps>({
    title: 'ไม่สามารถอัปโหลดได้',
    subTitle: 'ไฟล์ต้องเป็นนามสกุล .xls หรือ .xlsx เท่านั้น',
    submit: {
      label: 'ตกลง',
      onClick: () => {
        setModalProps({ ...modalProps, visible: false });
      }
    },
    visible: false
  });

  const parseAndValidateExcel = async (file: RcFile) => {
    try {
      openParsingExcelModal(ValidationStep.SCHOOL_TEACHER);
      let validationStep = ValidationStep.SCHOOL_TEACHER;
      const {
        data: { data: parseExcelData }
      } = await parseExcel(file);

      if (parseExcelData) {
        const {
          schoolTeachers,
          classroom,
          classPeriods,
          schoolStudents
        } = parseExcelData;
        try {
          await validateSchoolTeachers({ schoolTeachers });

          openParsingExcelModal(ValidationStep.CLASSROOM);
          validationStep = ValidationStep.CLASSROOM;
          const { data: validateClassroomResponse } = await validateClassrooms({
            classroom
          });
          openParsingExcelModal(ValidationStep.CLASS_PERIOD);
          validationStep = ValidationStep.CLASS_PERIOD;
          if (classPeriods.length > 0) {
            await validateClassPeriods({ classroom, classPeriods });
          }

          openParsingExcelModal(ValidationStep.SCHOOL_STUDENT);
          validationStep = ValidationStep.SCHOOL_STUDENT;
          if (schoolStudents.length > 0) {
            await validateSchoolStudents({ classroom, schoolStudents });
          }

          const classroomId = validateClassroomResponse?.data?.classroomId;
          if (classroomId) {
            const { data: classroomResponse } = await getClassroom(classroomId);
            setImportedClassroom(classroomResponse?.data);
          }

          // Dashboard should be refreshed after process something

          closeModal();
        } catch (error) {
          const e = error as any;
          const { data: errorResponse } = e?.response;
          setValidationStep(validationStep);
          setValidateExcelErrorResponse(errorResponse);
          closeModal();
        }
      }
    } catch (error) {
      const e = error as any;
      const { data: errorResponse } = e?.response;
      if (
        errorResponse?.message === ParseExcelResponseMessage.MISSING_WORK_SHEET
      ) {
        openParseExcelError();
      } else {
        setParseExcelErrorResponse(errorResponse);
        closeModal();
      }
    }
  };

  // This could be reused and more readable
  const closeModal = () => {
    setModalProps({ ...modalProps, visible: false });
  };

  const openParseExcelError = () => {
    setModalProps({
      title: 'อัปโหลดไม่สำเร็จ',
      subTitle: 'เนื่องจากข้อมูล Sheet ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
      submit: {
        label: 'ตกลง',
        onClick: closeModal
      },
      visible: true
    });
  };

  const openFileTypeErrorModal = () => {
    setModalProps({
      title: 'ไม่สามารถอัปโหลดได้',
      subTitle: 'ไฟล์ต้องเป็นนามสกุล .xls หรือ .xlsx เท่านั้น',
      submit: {
        label: 'ตกลง',
        onClick: closeModal
      },
      visible: true
    });
  };

  const openLicenseConfirmationModal = (file: RcFile) => {
    setModalProps({
      title: 'ยืนยันการตัดสื่อเข้าคาบ ?',
      subTitle: 'สื่อที่ตัดแล้วไม่สามารถคืนสิทธิ์ได้',
      submit: {
        label: 'ตกลง',
        onClick: () => parseAndValidateExcel(file)
      },
      cancel: {
        label: 'ยกเลิก',
        onClick: closeModal
      },
      visible: true
    });
  };

  const openUploadConfirmationModal = (file: RcFile) => {
    setModalProps({
      title: 'อัปโหลดข้อมูลห้องเรียน ?',
      subTitle: (
        <Fragment>
          ข้อมูลห้องเรียนในระบบ
          <br />
          จะถูกแทนที่ด้วยข้อมูลจากไฟล์นี้
        </Fragment>
      ),
      submit: {
        label: 'ยืนยัน',
        onClick: () => openLicenseConfirmationModal(file)
      },
      cancel: {
        label: 'ยกเลิก',
        onClick: closeModal
      },
      visible: true,
      content: <FileItem file={file} />
    });
  };

  const openParsingExcelModal = (validationStep: ValidationStep) => {
    setModalProps({
      width: 470,
      title: 'กำลังตรวจสอบข้อมูล',
      subTitle: 'กรุณาอย่าปิดหน้านี้ขณะกำลังอัปโหลดข้อมูล',
      content: (
        <div>
          <Steps
            current={validationStep}
            className="max-w-sm mx-auto"
            progressDot={progressIcon}
            css={StepStyled}
          >
            <Step description="จัดการครู" />
            <Step description="จัดการห้องเรียน" />
            <Step description="จัดการคาบเรียน" />
            <Step description="จัดการนักเรียน" />
          </Steps>
          <div
            className="my-12"
            css={css`
              div {
                cursor: default;
              }
            `}
          >
            <Lottie
              isClickToPauseDisabled={true}
              options={defaultLoadingAnimationOptions}
              height={120}
              width={120}
            />
          </div>
        </div>
      ),
      visible: true
    });
  };

  const openUploadingModal = () => {
    setModalProps({
      width: 470,
      title: 'กำลังตรวจสอบข้อมูล',
      subTitle: 'กรุณาอย่าปิดหน้านี้ขณะกำลังอัปโหลดข้อมูล',
      content: (
        <Lottie
          isClickToPauseDisabled={true}
          options={defaultLoadingAnimationOptions}
          height={120}
          width={120}
        />
      ),
      visible: true
    });
  };

  return (
    <PageWrapper>
      {!teachingProfile ? (
        <div className="flex justify-center items-center h-full">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="grid gap-4 md:gap-0 md:flex justify-between items-center mb-6">
            <div className="flex justify-start font-bold">
              <h3 className="text-xl font-bold">
                โรงเรียน{teachingProfile?.school.schoolName.th}
              </h3>
            </div>
            <div className="flex justify-end">
              <SchoolDefaultPasswordButton
                defaultPassword={defaultPassword}
                schoolYearId={selectedSchoolYearId}
                fetchDefaultPassword={fetchDefaultPassword}
                loading={defaultPasswordLoading}
                className="mr-4"
              />
              {schoolYearsLoading ? (
                <div className="flex w-full justify-end mb-5">
                  <Skeleton.Input className="mr-0" active />
                </div>
              ) : (
                <Form.Item label="ปีการศึกษา" className="w-48">
                  {schoolYears.length > 0 ? (
                    <Select
                      value={selectedSchoolYearId}
                      onSelect={(schoolYearId) => {
                        setSelectedSchoolYearId(schoolYearId);
                      }}
                    >
                      {schoolYears.map(({ name, schoolYearId }) => (
                        <Option
                          key={schoolYearId}
                          value={schoolYearId}
                          // disabled={parseInt(name) - 543 !== moment().year()}
                          disabled={schoolYearId !== selectedSchoolYearId}
                        >
                          {name}
                        </Option>
                      ))}
                    </Select>
                  ) : null}
                </Form.Item>
              )}
            </div>
          </div>
          <Divider />
          {/* <DashboardFilterBar />                     */}
          <Global styles={ProgressStepStyled} />
          {/* <div className="flex justify-between font-bold mb-6">
            <button
              id="manage-excel"
              className="flex items-center text-secondary-gray-60 font-bold transition"
              css={css`
                :hover {
                  color: #7e8091 !important;
                  path {
                    fill: #7e8091;
                  }
                }
              `}
              onClick={() => setExcelManagementDrawerVisible(true)}
            >
              <Excel className="mr-2" />
              จัดการข้อมูลด้วย Excel
            </button>
          </div> */}

          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-7 2xl:gap-10">
            {managementCards.map(({ key, ...restProps }) => (
              <ManagementCard
                key={`dashboard-management-${key}`}
                {...restProps}
              />
            ))}
          </div>
          <ExcelManagementDrawer
            visible={excelManagementDrawerVisible}
            onClose={() => setExcelManagementDrawerVisible(false)}
            onUpload={(file: RcFile | undefined) => {
              if (file) {
                setExcelManagementDrawerVisible(false);
                openUploadConfirmationModal(file);
              }
            }}
            onFail={() => {
              openFileTypeErrorModal();
            }}
            onUploading={openUploadingModal}
            onUploadSuccess={closeModal}
          />
          <Modal {...modalProps} />
          {validateExcelErrorResponse && (
            <ImportExcelModalResult
              validationStep={validationStep}
              data={validateExcelErrorResponse}
              onClose={() => {
                setValidationStep(undefined);
                setValidateExcelErrorResponse(undefined);
              }}
            />
          )}
          {parseExcelErrorResponse && (
            <ImportExcelParseErrorResult
              data={parseExcelErrorResponse}
              onClose={() => {
                setValidationStep(undefined);
                setParseExcelErrorResponse(undefined);
              }}
            />
          )}
          {importedClassroom && (
            <ImportExcelSuccessfulModal
              classroom={importedClassroom}
              onClose={() => {
                setImportedClassroom(undefined);
              }}
            />
          )}
        </Fragment>
      )}
    </PageWrapper>
  );
});
