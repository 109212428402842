import {
  AssignmentUnitHeaderListDto,
  AssignmentUnitHeaderListResponse,
  AssignmentUnitOverallHeaderListDto,
  AssignmentUnitOverallHeaderListResponse,
  AssignmentUnitOverallUserListDto,
  AssignmentUnitOverallUserUnitScoreDto,
  AssignmentUnitUserAssignmentScoreDto,
  AssignmentUnitUserListDto
} from '@aksorn/teaching-backoffice-sdk';
import { Global, css } from '@emotion/react';
import { InitialDataTable, TableData, TableType } from './class-period-detail';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, isNumber } from 'lodash';

import { AssignmentInfoModal } from './components/AssignmentInfoModal';
import { AssignmentOverallClassPeriodDetailDrawer } from './AssignmentOverallClassPeriodDetailDrawer';
import { EmptyEvaluation } from './components/EmptyEvaluation';
import { EmptyStudent } from './components/EmptyStudent';
import { EmptyTable } from 'feature/common/components/Table/EmptyTable';
import { EvaluationTable } from 'feature/common/components/Table/EvaluationTable';
import { ReactComponent as StatIcon } from 'assets/stat.svg';
import { StudentDescription } from './components/StudentDescription';
import { TableSkeleton } from 'feature/common/components/Table/TableSkeleton';
import { TextWrapperToolTip } from './components/TextWrapperToolTip';
import classNames from 'classnames';
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useAssignmentUnitHeaders } from 'hooks/assignment-units/useAssignmentUnitHeaders';
import { useAssignmentUnitOverallHeaders } from 'hooks/assignment-units/useAssignmentUnitOverallHeaders';
import { useAssignmentUnitOverallUsers } from 'hooks/assignment-units/useAssignmentUnitOverallUsers';
import { useAssignmentUnitUsers } from 'hooks/assignment-units/useAssignmentUnitUsers';
import { useParams } from 'react-router-dom';

import { Tooltip } from 'antd';
import { BounceLoader } from 'react-spinners';

interface AssignmentOverallClassPeriodDetailTableProps {
  selectedAssignmentUnitId: string;
}

interface GetColumnsProps {
  selectedAssignmentUnitId: string;
  assignmentHeaders:
    | AssignmentUnitOverallHeaderListResponse
    | AssignmentUnitHeaderListResponse;
  dataSize: number;
}

interface GetAssignmentUnitOverallChildrenColumnProps {
  indexHeader: number;
  dataSize: number;
  headerSize: number;
  assignmentUnitOverallHeader: AssignmentUnitOverallHeaderListDto;
}

interface GetAssignmentUnitChildrenColumnProps {
  indexHeader: number;
  dataSize: number;
  headerSize: number;
  assignmentUnitHeader: AssignmentUnitHeaderListDto;
}

type DefaultTableSize = {
  row: number;
  column: number;
};

type StudentInfoDrawerState = {
  classroomStudentId?: string;
  showStudentInfoDrawer: boolean;
};

type AssignmentInfoModal = {
  assignmentId?: string;
  showAssignmentInfoModal: boolean;
};

export const AssignmentOverallClassPeriodDetailTable = ({
  selectedAssignmentUnitId
}: AssignmentOverallClassPeriodDetailTableProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [
    assignmentInfoModal,
    setAssignmentInfoModal
  ] = useState<AssignmentInfoModal>({
    assignmentId: undefined,
    showAssignmentInfoModal: false
  });
  const [
    studentInfoDrawerState,
    setStudentInfoDrawerState
  ] = useState<StudentInfoDrawerState>({
    classroomStudentId: undefined,
    showStudentInfoDrawer: false
  });
  const [tableData, setTableData] = useState<TableData>({
    columns: [],
    dataSource: undefined,
    hasUnit: false,
    hasScore: false,
    fetching: true
  });

  const [divRefCurrent, setDivRefCurrent] = useState<HTMLDivElement>();
  const [defaultTableSize, setDefaultTableSize] = useState<DefaultTableSize>({
    row: 0,
    column: 0
  });

  const { classPeriodId } = useParams() as {
    classPeriodId: string;
  };

  const {
    data: assignmentUnitOverallHeaders,
    loading: assignmentUnitOverallHeadersLoading,
    execute: getAssignmentUnitOverallHeaders
  } = useAssignmentUnitOverallHeaders();

  const {
    data: assignmentUnitOverallUsers,
    loading: assignmentUnitOverallUsersLoading,
    execute: getAssignmentUnitOverallUsers
  } = useAssignmentUnitOverallUsers();

  const {
    data: assignmentUnitHeaders,
    loading: assignmentUnitHeadersLoading,
    execute: getAssignmentUnitHeaders
  } = useAssignmentUnitHeaders();

  const {
    data: assignmentUnitUsers,
    loading: assignmentUnitUsersLoading,
    execute: getAssignmentUnitUsers
  } = useAssignmentUnitUsers();

  const divRef = useCallback((element: HTMLDivElement) => {
    if (element) {
      setDivRefCurrent(element);
    }
  }, []);

  useEffect(() => {
    if (divRefCurrent) {
      const headerSize = 108;
      const rowSize = 56;
      const columnSize = 80;
      const firstColumnSize = 336;

      const divHeight = 610;
      const divWidth = divRefCurrent.getBoundingClientRect().width;
      const numberOfColumns =
        (divWidth - (firstColumnSize + columnSize)) / columnSize;
      const numberOfRows = (divHeight - headerSize) / rowSize;

      setDefaultTableSize({
        row: Math.round(numberOfRows),
        column: Math.floor(numberOfColumns)
      });
    }
  }, [divRefCurrent]);

  useEffect(() => {
    if (defaultTableSize?.row && defaultTableSize?.column) {
      getInitialData();
    }
  }, [selectedAssignmentUnitId, defaultTableSize]);

  useEffect(() => {
    if (
      !isLoading &&
      !assignmentUnitOverallHeadersLoading &&
      !assignmentUnitOverallUsersLoading
    ) {
      setTableData((tableData) => ({
        ...tableData,
        fetching: true
      }));

      initialDataTable({
        rawHeaders: assignmentUnitOverallHeaders,
        rawUsers: assignmentUnitOverallUsers,
        tableType: TableType.OVERALL
      });
    }
  }, [
    isLoading,
    assignmentUnitOverallHeadersLoading,
    assignmentUnitOverallUsersLoading
  ]);

  useEffect(() => {
    if (
      selectedAssignmentUnitId &&
      !isLoading &&
      !assignmentUnitHeadersLoading &&
      !assignmentUnitUsersLoading
    ) {
      setTableData((tableData) => ({
        ...tableData,
        fetching: true
      }));

      initialDataTable({
        rawHeaders: assignmentUnitHeaders,
        rawUsers: assignmentUnitUsers,
        tableType: TableType.UNIT
      });
      setIsLoading(false);
    }
  }, [isLoading, assignmentUnitHeadersLoading, assignmentUnitUsersLoading]);

  const getInitialData = async () => {
    if (selectedAssignmentUnitId) {
      await fetchAssignmentUnit();
    } else {
      await fetchAssignmentUnitOverall();
    }
  };

  const fetchAssignmentUnit = async () => {
    try {
      setIsLoading(true);
      await getAssignmentUnitHeaders(selectedAssignmentUnitId);
      await getAssignmentUnitUsers(selectedAssignmentUnitId);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssignmentUnitOverall = async () => {
    try {
      setIsLoading(true);
      await getAssignmentUnitOverallHeaders({
        classPeriodId
      });
      await getAssignmentUnitOverallUsers({
        classPeriodId
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const initialDataTable = ({
    rawHeaders,
    rawUsers,
    tableType
  }: InitialDataTable) => {
    const headers = addEmptyColumn({
      rawHeaders,
      tableType
    });

    const users = addEmptyRow({
      rawUsers,
      tableType
    });

    const columns = getColumns({
      selectedAssignmentUnitId,
      assignmentHeaders: headers,
      dataSize: users.length
    });

    setTableData((tableData) => ({
      ...tableData,
      columns: columns,
      dataSource: users.length
        ? users
        : [{ classroomStudentId: '-', totalScore: '-' }],
      hasScore: users.length > 0,
      hasUnit: headers?.data?.length > 0,
      fetching: false
    }));
  };

  const addEmptyRow = ({
    rawUsers,
    tableType
  }: {
    rawUsers: AssignmentUnitOverallUserListDto[] | AssignmentUnitUserListDto[];
    tableType: TableType;
  }) => {
    if (isEmpty(rawUsers)) return rawUsers;
    if (rawUsers.length === 0) return rawUsers;
    const users = [...rawUsers];

    if (users.length < defaultTableSize.row) {
      const rowRemaining = defaultTableSize.row - users.length;
      if (tableType === TableType.OVERALL) {
        for (let i = 0; i < rowRemaining; i++) {
          (users as AssignmentUnitOverallUserListDto[]).push({
            classroomStudentId: '',
            classroomStudentNumber: '',
            studentCode: '',
            title: '',
            firstName: { th: '', en: '' },
            lastName: { th: '', en: '' },
            totalScore: undefined,
            unitScores: []
          } as AssignmentUnitOverallUserListDto);
        }
      }
      if (tableType === TableType.UNIT) {
        for (let i = 0; i < rowRemaining; i++) {
          (users as AssignmentUnitUserListDto[]).push({
            classroomStudentId: '',
            classroomStudentNumber: '',
            studentCode: '',
            title: '',
            firstName: { th: '', en: '' },
            lastName: { th: '', en: '' },
            totalScore: undefined,
            assignmentScores: []
          } as AssignmentUnitUserListDto);
        }
      }
    }

    return users;
  };

  const addEmptyColumn = ({
    rawHeaders,
    tableType
  }: {
    rawHeaders:
      | AssignmentUnitOverallHeaderListResponse
      | AssignmentUnitHeaderListResponse;
    tableType: TableType;
  }) => {
    if (isEmpty(rawHeaders)) return rawHeaders;
    if (rawHeaders.data.length === 0) return rawHeaders;
    const headers = { ...rawHeaders };
    if (headers.data.length < defaultTableSize.column) {
      const columnRemaining = defaultTableSize.column - headers.data.length;

      if (tableType === TableType.OVERALL) {
        for (let i = 0; i < columnRemaining; i++) {
          (headers as AssignmentUnitOverallHeaderListResponse).data.push({
            assignmentUnitId: '',
            unitNo: 0,
            maxScore: undefined,
            aksornBookId: '',
            unitNodeId: 0
          } as AssignmentUnitOverallHeaderListDto);
        }
      }
      if (tableType === TableType.UNIT) {
        for (let i = 0; i < columnRemaining; i++) {
          (headers as AssignmentUnitHeaderListResponse).data.push({
            assignmentId: '',
            assignmentNo: 0,
            maxScore: undefined
          } as AssignmentUnitHeaderListDto);
        }
      }
    }

    return headers;
  };

  const onCellAssignmentClick = (assignmentId: string) => {
    setAssignmentInfoModal({
      assignmentId,
      showAssignmentInfoModal: true
    });
  };

  const onCloseAssignmentInfoModal = () => {
    setAssignmentInfoModal({
      assignmentId: undefined,
      showAssignmentInfoModal: false
    });
  };

  const getEmptyContentColumns = ({
    dataSize,
    selectedAssignmentUnitId
  }: {
    dataSize: number;
    selectedAssignmentUnitId: string;
  }) => {
    return [
      {
        width: 651,
        align: 'center',
        render: (
          _value: AssignmentUnitOverallUserListDto | AssignmentUnitUserListDto,
          _record: AssignmentUnitOverallUserListDto | AssignmentUnitUserListDto,
          index: number
        ) => {
          if (index === 0)
            return {
              children: (
                <EmptyEvaluation
                  hasSelectedAssignmentUnit={
                    selectedAssignmentUnitId ? true : false
                  }
                />
              ),
              props: {
                colSpan: 8,
                rowSpan: dataSize
              }
            };
          else {
            return {
              props: {
                colSpan: 0,
                rowSpan: 0
              }
            };
          }
        }
      }
    ];
  };

  const getAssignmentUnitChildrenColumn = ({
    dataSize,
    headerSize,
    indexHeader,
    assignmentUnitHeader
  }: GetAssignmentUnitChildrenColumnProps) => {
    if (headerSize > 0 && dataSize > 0) {
      return [
        {
          title: assignmentUnitHeader.assignmentId
            ? isNumber(assignmentUnitHeader.maxScore)
              ? assignmentUnitHeader.maxScore.toString()
              : '-'
            : '',
          key: assignmentUnitHeader.assignmentId,
          dataIndex: 'assignmentScores',
          width: 80,
          align: 'center',
          className: 'cursor-pointer',
          render: (
            assignmentScores: AssignmentUnitUserAssignmentScoreDto[],
            dataSource: AssignmentUnitUserListDto
          ) => {
            const assignmentScore = assignmentScores.find(
              (score) =>
                score.assignmentId === assignmentUnitHeader.assignmentId
            );
            if (!assignmentScore) {
              return dataSource.classroomStudentId &&
                assignmentUnitHeader.assignmentId
                ? '-'
                : '';
            }
            return (
              <TextWrapperToolTip
                score={assignmentScore.score}
                className={classNames('w-full truncate', {
                  'text-system-complete': !assignmentScore.isLateSubmission,
                  'text-aksorn-orange': assignmentScore.isLateSubmission,
                  'text-secondary-gray-60': !assignmentScore.score
                })}
              />
            );
          },
          onHeaderCell: () => {
            return {
              //[click-score in header table] redirect to assignment
              onClick: () => {
                if (assignmentUnitHeader.assignmentId) {
                  onCellAssignmentClick(assignmentUnitHeader.assignmentId);
                }
              }
            };
          },
          onCell: () => {
            return {
              //[click-score in table] redirect to assignment
              onClick: () => {
                if (assignmentUnitHeader.assignmentId) {
                  onCellAssignmentClick(assignmentUnitHeader.assignmentId);
                }
              }
            };
          }
        }
      ];
    } else {
      if (dataSize > 0) {
        return [
          {
            title: assignmentUnitHeader.assignmentId
              ? isNumber(assignmentUnitHeader.maxScore)
                ? assignmentUnitHeader.maxScore.toString()
                : '-'
              : '',
            width: 80,
            dataIndex: 'assignmentScores',
            align: 'center',
            render: (
              _assignmentScores: AssignmentUnitUserAssignmentScoreDto[],
              _assignmentUnitUser: AssignmentUnitUserListDto,
              indexRecord: number
            ) => {
              if (indexHeader === 0 && indexRecord === 0) {
                return {
                  children: <EmptyEvaluation hasSelectedAssignmentUnit />,
                  props: {
                    colSpan: headerSize,
                    rowSpan: dataSize
                  }
                };
              } else {
                return {
                  props: {
                    colSpan: 0,
                    rowSpan: 0
                  }
                };
              }
            }
          }
        ];
      } else {
        return [
          {
            title: assignmentUnitHeader.assignmentId
              ? isNumber(assignmentUnitHeader.maxScore)
                ? assignmentUnitHeader.maxScore.toString()
                : '-'
              : '',
            width: 80,
            align: 'center',
            render: () => {
              if (indexHeader === 0) {
                return {
                  children: <EmptyEvaluation hasSelectedAssignmentUnit />,
                  props: {
                    colSpan: headerSize
                  }
                };
              }
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        ];
      }
    }
  };

  const getAssignmentUnitOverallChildrenColumns = ({
    indexHeader,
    dataSize,
    headerSize,
    assignmentUnitOverallHeader
  }: GetAssignmentUnitOverallChildrenColumnProps) => {
    if (headerSize > 0 && dataSize > 0) {
      return [
        {
          title: assignmentUnitOverallHeader.aksornBookId
            ? isNumber(assignmentUnitOverallHeader.maxScore)
              ? assignmentUnitOverallHeader.maxScore.toString()
              : '-'
            : '',
          key: `${assignmentUnitOverallHeader.aksornBookId}${assignmentUnitOverallHeader.unitNo}`,
          dataIndex: 'unitScores',
          // width: 80,
          width: 160,
          align: 'center',
          render: (
            unitScores: AssignmentUnitOverallUserUnitScoreDto[],
            dataSource: AssignmentUnitOverallUserListDto
          ) => {
            const unitScore = unitScores.find(
              (score) =>
                score.assignmentUnitId ===
                assignmentUnitOverallHeader.assignmentUnitId
            );
            if (!unitScore) {
              return dataSource.classroomStudentId &&
                assignmentUnitOverallHeader.aksornBookId
                ? '-'
                : '';
            }
            return (
              <TextWrapperToolTip
                score={unitScore.unitScore}
                className={classNames({
                  'text-aksorn-orange': isNumber(unitScore.unitScore)
                    ? isNumber(assignmentUnitOverallHeader.maxScore)
                      ? unitScore.unitScore <
                        assignmentUnitOverallHeader.maxScore / 2
                      : false
                    : false
                })}
              />
            );
          }
        }
      ];
    } else {
      if (dataSize > 0) {
        return [
          {
            title: assignmentUnitOverallHeader.aksornBookId
              ? isNumber(assignmentUnitOverallHeader.maxScore)
                ? assignmentUnitOverallHeader.maxScore.toString()
                : '-'
              : '',
            width: 80,
            dataIndex: 'unitScores',
            align: 'center',
            render: (
              _unitScores: AssignmentUnitOverallUserUnitScoreDto[],
              _dataSource: AssignmentUnitOverallUserListDto,
              indexRecord: number
            ) => {
              if (indexHeader === 0 && indexRecord === 0) {
                return {
                  children: <EmptyEvaluation />,
                  props: {
                    colSpan: headerSize,
                    rowSpan: dataSize
                  }
                };
              } else {
                return {
                  props: {
                    colSpan: 0,
                    rowSpan: 0
                  }
                };
              }
            }
          }
        ];
      } else {
        return [
          {
            title: assignmentUnitOverallHeader.aksornBookId
              ? isNumber(assignmentUnitOverallHeader.maxScore)
                ? assignmentUnitOverallHeader.maxScore.toString()
                : '-'
              : '',
            width: 80,
            align: 'center',
            render: () => {
              if (indexHeader === 0) {
                return {
                  children: <EmptyEvaluation />,
                  props: {
                    colSpan: headerSize
                  }
                };
              }
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        ];
      }
    }
  };

  const getAssignmentUnitOverallColumns = ({
    dataSize,
    assignmentHeaders
  }: {
    dataSize: number;
    assignmentHeaders: AssignmentUnitOverallHeaderListResponse;
  }) => {
    return assignmentHeaders.data.map(
      (
        assignmentUnitOverallHeader: AssignmentUnitOverallHeaderListDto,
        indexHeader: number
      ) => {
        return {
          // title: assignmentUnitOverallHeader.unitNo,
          title:
            <div style={{maxWidth:'160px'}}
              css={css`
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            `}
            >
              <Tooltip
                placement="top"
                title={
                  <span>
                    <BounceLoader size="20" color="white" />
                    <span style={{ marginLeft: '25px' }}>{ assignmentUnitOverallHeader?.label }</span>
                  </span>
                }
              >
                { assignmentUnitOverallHeader?.label }
              </Tooltip>
            </div>,
          key: assignmentUnitOverallHeader.assignmentUnitId,
          width: 80,
          align: 'center',
          children: getAssignmentUnitOverallChildrenColumns({
            dataSize,
            indexHeader,
            assignmentUnitOverallHeader,
            headerSize: assignmentHeaders.data.length
          })
        };
      }
    );
  };

  const getAssignmentUnitColumns = ({
    dataSize,
    assignmentUnitHeaders
  }: {
    dataSize: number;
    assignmentUnitHeaders: AssignmentUnitHeaderListResponse;
  }) => {
    return assignmentUnitHeaders.data.map(
      (
        assignmentUnitHeader: AssignmentUnitHeaderListDto,
        indexHeader: number
      ) => {
        return {
          title: assignmentUnitHeader.assignmentNo
            ? `K${assignmentUnitHeader.assignmentNo}`
            : '',
          key: assignmentUnitHeader.assignmentId,
          width: 80,
          align: 'center',
          className: 'cursor-pointer',
          children: getAssignmentUnitChildrenColumn({
            dataSize,
            indexHeader,
            assignmentUnitHeader,
            headerSize: assignmentUnitHeaders.data.length
          }),
          onHeaderCell: () => {
            return {
              onClick: () => {
                if (assignmentUnitHeader.assignmentId) {
                  onCellAssignmentClick(assignmentUnitHeader.assignmentId);
                }
              }
            };
          }
        };
      }
    );
  };

  const getUnitColumns = ({
    dataSize,
    assignmentHeaders,
    selectedAssignmentUnitId
  }: GetColumnsProps) => {
    if (selectedAssignmentUnitId) {
      return getAssignmentUnitColumns({
        dataSize,
        assignmentUnitHeaders: assignmentHeaders as AssignmentUnitHeaderListResponse
      });
    } else {
      return getAssignmentUnitOverallColumns({
        dataSize,
        assignmentHeaders: assignmentHeaders as AssignmentUnitOverallHeaderListResponse
      });
    }
  };

  const getColumns = ({
    dataSize,
    assignmentHeaders,
    selectedAssignmentUnitId
  }: GetColumnsProps) => {
    const hasUnit = assignmentHeaders?.data?.length > 0;
    const hasData = dataSize > 0;

    const contentColumns = hasUnit
      ? getUnitColumns({
          dataSize,
          assignmentHeaders,
          selectedAssignmentUnitId
        })
      : getEmptyContentColumns({ dataSize, selectedAssignmentUnitId });

    const onStudentCellClick = (classroomStudentId: string) => {
      setStudentInfoDrawerState({
        classroomStudentId,
        showStudentInfoDrawer: true
      });
    };

    const columns = [
      {
        key: 'unit',
        title: selectedAssignmentUnitId ? 'ครั้งที่' : 'หน่วยที่',
        fixed: 'left',
        width: 336,
        children: [
          {
            key: 'classroomStudentId',
            title: selectedAssignmentUnitId ? 'คะแนนเต็ม' : 'คะแนนงานมอบหมาย',
            dataIndex: 'classroomStudentId',
            width: 336,
            fixed: 'left',
            className: 'cursor-pointer',
            render: (
              _classroomStudentId: string,
              dataSource:
                | AssignmentUnitOverallUserListDto
                | AssignmentUnitUserListDto
            ) => {
              return hasData ? (
                <div>
                  {dataSource.classroomStudentId ? (
                    <StudentDescription
                      classroomStudentNumber={dataSource.classroomStudentNumber}
                      studentCode={dataSource.studentCode}
                      title={dataSource.title}
                      firstName={dataSource.firstName.th}
                      lastName={dataSource.lastName.th}
                    />
                  ) : null}
                </div>
              ) : (
                <EmptyStudent />
              );
            },
            onCell: (
              dataSource:
                | AssignmentUnitOverallUserListDto
                | AssignmentUnitUserListDto
            ) => {
              return {
                onClick: () => {
                  if (hasData) {
                    onStudentCellClick(dataSource.classroomStudentId);
                  }
                }
              };
            }
          }
        ]
      },
      {
        key: 'total',
        title: 'รวม',
        fixed: 'left',
        width: 80,
        align: 'center',
        children: [
          {
            title: isNumber(assignmentHeaders?.meta?.totalMaxScore)
              ? assignmentHeaders?.meta?.totalMaxScore?.toString()
              : '-',
            dataIndex: 'totalScore',
            width: 80,
            align: 'center',
            fixed: 'left',
            key: 'totalScore',
            render: (
              totalScore: number,
              dataSource:
                | AssignmentUnitOverallUserListDto
                | AssignmentUnitUserListDto
            ) => {
              return (
                <>
                  {dataSource.classroomStudentId && (
                    <TextWrapperToolTip
                      score={totalScore}
                      className={classNames({
                        'text-aksorn-orange': selectedAssignmentUnitId
                          ? false
                          : isNumber(totalScore) &&
                            isNumber(assignmentHeaders.meta?.totalMaxScore)
                          ? totalScore <
                            assignmentHeaders.meta?.totalMaxScore / 2
                          : false
                      })}
                    />
                  )}
                </>
              );
            }
          }
        ]
      },
      ...contentColumns
    ];

    return columns;
  };

  const onCloseStudentInfoDrawer = () => {
    setStudentInfoDrawerState({
      classroomStudentId: undefined,
      showStudentInfoDrawer: false
    });
  };

  return (
    <div
      css={css`
        max-width: 100%;
      `}
      ref={divRef}
    >
      <Global
        styles={css`
          .ant-tooltip-inner {
            height: fit-content;
            border-radius: 8px;
            padding: 8px 16px;
            filter: drop-shadow(0px 10px 30px rgba(46, 47, 56, 0.1));
          }
          .ant-modal-content {
            border-radius: 20px !important;
          }
        `}
      />
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <React.Fragment>
          {!tableData.fetching && !tableData.hasScore && !tableData.hasUnit ? (
            <div
              className="flex items-center justify-center w-full"
              css={css`
                height: 550px;
              `}
            >
              <EmptyTable
                icon={
                  <StatIcon
                    className="w-20 h-20"
                    css={css`
                      path {
                        fill: #e3e4e8;
                      }
                    `}
                  />
                }
                emptyText={'ไม่มีข้อมูล'}
                emptyClassName={'text-secondary-gray-60'}
              />
            </div>
          ) : (
            <>
              <EvaluationTable
                dataSource={tableData.dataSource}
                columns={tableData?.columns}
                pagination={false}
                scroll={{ x: 'max-content' }}
                customStyles={{
                  header: {
                    backgroundColor: '#6fcf97',
                    activeColumns: [
                      {
                        colNo: 2,
                        backgroundColor: '#39ac69',
                        bodyBackgroundColor: '#d9f2e3'
                      }
                    ],
                    hasUnit: tableData.hasUnit
                  },
                  body: {
                    hasScore: tableData.hasScore
                  }
                }}
              />
              <AssignmentOverallClassPeriodDetailDrawer
                classroomStudentId={studentInfoDrawerState.classroomStudentId}
                visible={studentInfoDrawerState.showStudentInfoDrawer}
                onClose={onCloseStudentInfoDrawer}
              />
              <AssignmentInfoModal
                visible={assignmentInfoModal.showAssignmentInfoModal}
                assignmentId={assignmentInfoModal.assignmentId}
                onClose={onCloseAssignmentInfoModal}
              />
            </>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
