import {
  AssignmentUnitListDto,
  LearningUnitOverallHeaderListDto
} from '@aksorn/teaching-backoffice-sdk';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { BounceLoader } from 'react-spinners';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { getLearningUnitOverallHeaders } from 'api/learning-units/getLearningUnitOverallHeaders';
import { useTranslation } from 'react-i18next';

interface UnitButtonTabProps {
  onClick: () => void;
  isActive?: boolean;
  disabled?: boolean;
  className?: string;
  children?: any;
}
interface LearningOutcomePrePostTestFilterBarProps {
  data: AssignmentUnitListDto[];
  selectedAssignmentUnitId: string;
  setSelectedAssignmentUnitId: (unitId: string) => void;
  classPeriodId: any;
}

const LearningUnitButton = ({
  isActive,
  disabled = false,
  onClick,
  children,
  ...restProps
}: UnitButtonTabProps) => {
  return (
    <div
      className={classNames(
        'mr-2 px-4 flex items-center justify-center cursor-pointer transition-colors duration-200 ease-in-out whitespace-no-wrap text-base rounded-lg',
        {
          hidden: !disabled,
          'mr-2 flex items-center justify-center transition-colors duration-200 ease-in-out whitespace-no-wrap text-base rounded-lg': disabled
        },
        {
          'bg-white text-black border border-secondary-gray-10': !isActive,
          'font-bold bg-light-blue text-dark-blue border border-dark-blue': isActive
        }
      )}
      css={css`
        min-width: 110px;
        height: 32px;
      `}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </div>
  );
};

export const LearningOutcomePrePostTestFilterBar = ({
  data,
  selectedAssignmentUnitId,
  setSelectedAssignmentUnitId,
  classPeriodId
}: LearningOutcomePrePostTestFilterBarProps) => {
  const { t } = useTranslation();
  const [learningUnitOverallHeaders, setLearningUnitOverallHeaders] = useState<
    LearningUnitOverallHeaderListDto[]
  >();

  useEffect(() => {
    if (classPeriodId) {
      getInitialData(classPeriodId);
    }
  }, []);

  const getInitialData = async (classPeriodId: string) => {
    try {
      const {
        data: learningUnitOverallHeaders
      } = await getLearningUnitOverallHeaders({
        classPeriodId
      });
      setLearningUnitOverallHeaders(learningUnitOverallHeaders);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex justify-start mb-6 text-lg overflow-x-auto md:w-full">
      <LearningUnitButton
        isActive={selectedAssignmentUnitId === ''}
        disabled={true}
        onClick={() => {
          setSelectedAssignmentUnitId('');
        }}
      >
        {/* {t('totalScore')} */}
        {t('คะแนนรวม')}
      </LearningUnitButton>
      {/* {data.map((item: AssignmentUnitListDto) => (
        <LearningUnitButton
          key={item.assignmentUnitId}
          isActive={selectedAssignmentUnitId === item.assignmentUnitId}
          onClick={() => {
            setSelectedAssignmentUnitId(item.assignmentUnitId);
          }}
        >
          หน่วยที่ {item.unitNo}
        </LearningUnitButton>
      ))} */}
      {learningUnitOverallHeaders
        ? learningUnitOverallHeaders.map((items) => (
            <LearningUnitButton
              key={items.assignmentUnitId}
              isActive={selectedAssignmentUnitId === items.assignmentUnitId}
              disabled={items.assignmentUnitId ? true : false}
              onClick={() => {
                setSelectedAssignmentUnitId(
                  items.assignmentUnitId ? items.assignmentUnitId : ''
                );
              }}
            >
              {/* หน่วยที่ {items.unitNo} */}
              {/* { items.label } */}
              <div
                className="text-base"
                style={{ maxWidth: '150px' }}
                css={css`
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                `}
              >
                <Tooltip
                  placement="top"
                  title={
                    <span>
                      <BounceLoader size="20" color="white" />
                      <span style={{ marginLeft: '25px' }}>{items.label}</span>
                    </span>
                  }
                >
                  {items.label}
                </Tooltip>
              </div>
            </LearningUnitButton>
          ))
        : null}
    </div>
  );
};
