import {
  EvaluationUserListQuery,
  EvaluationUserListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useEvaluationsUsers = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<EvaluationUserListResponse>({}, { manual: true });

  const execute = (params?: EvaluationUserListQuery) => {
    return fire({
      url: `/evaluations/users`,
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute,
    total: response?.meta?.pagination?.total || 0
  };
};
