import { teachingAxios } from 'utils/axios';

const handleResponseDownload = (response: any, fileName: string) => {
  const blob = new Blob([response.data], {
    type: response.data.type || response.headers?.['content-type']
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // here test file name
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const handleResponseDownloadWithFileName = (response: any, type: string) => {
  const filename = response.data.filename;
  const blob = new Blob([Buffer.from(response.data.data, 'base64')], {
    type:
      type === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'application/pdf'
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadClassroomByGradeLevelExcel = async (
  gradeLevelId: string,
  schoolYearId: string
) => {
  const response = await teachingAxios.get(`download/grade-level-excel/`, {
    params: { gradeLevelId: gradeLevelId, schoolYearId: schoolYearId }
    // responseType: 'blob'
  });
  handleResponseDownloadWithFileName(response, 'excel');
  return response;
};

export const downloadClassroomByGradeLevelPdf = async (
  gradeLevelId: string,
  schoolYearId: string
) => {
  const response = await teachingAxios.get(`download/grade-level-pdf/`, {
    params: { gradeLevelId: gradeLevelId, schoolYearId: schoolYearId }
    // responseType: 'arraybuffer',
    // headers: {
    //   Accept: 'application/pdf'
    // }
  });
  handleResponseDownloadWithFileName(response, 'pdf');
  return response;
};
