import React, { useEffect, useState } from 'react';

import { AssignmentOverallClassPeriodDetailTable } from './AssignmentOverallClassPeriodDetailTable';
import { AssignmentUnitSelect } from './components/AssignmentUnitSelect';
import { AssignmentUnitStatusLabel } from './components/AssignmentUnitStatusLabel';
import { OverallStatusLabel } from './components/OverallStatusLabel';
import { Skeleton } from 'antd';
import { useAssignmentUnits } from 'hooks/assignment-units/useAssignmentUnits';
import { useClassPeriod, useBackEndClassPeriod } from 'hooks/class-period/useClassPeriod';
import { useClassPeriodLicense } from 'hooks/class-period-license/useClassPeriodLicense';
import { useParams } from 'react-router-dom';

export const AssignmentOverallClassPeriodDetailOverview = () => {
  const [
    selectedAssignmentUnitId,
    setSelectedAssignmentUnitId
  ] = useState<string>('');
  const { classPeriodId } = useParams() as {
    classPeriodId: string;
  };

  const {
    data: assignmentUnits,
    loading: assignmentUnitsLoading,
    execute: getAssignmentUnits
  } = useAssignmentUnits();

  // const {
  //   data: classPeriod,
  //   loading: classPeriodLoading,
  //   execute: getClassPeriod
  // } = useClassPeriod();
  const {
    data: classPeriod,
    loading: classPeriodLoading,
    execute: getBackEndClassPeriod
  } = useBackEndClassPeriod();

  const {
    data: classPeriodLicense,
    loading: classPeriodLicenseLoading,
    execute: getClassPeriodLicense
  } = useClassPeriodLicense();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    // getClassPeriod(classPeriodId);
    getBackEndClassPeriod(classPeriodId);
    getClassPeriodLicense(classPeriodId);
  };

  useEffect(() => {
    if (classPeriod) {
      getAssignmentUnits({
        classroomId: classPeriod.classroom.classroomId,
        subjectId: classPeriod.subject.subjectId
        // classroomId: classPeriod?.classroom?.classroomId,
        // subjectId: classPeriod?.subject?.subjectId
      });
    }
  }, [classPeriod]);

  return (
    <>
      <div className="flex justify-between items-center my-8">
        {classPeriodLoading || assignmentUnitsLoading ? (
          <Skeleton.Input className="w-72 h-10 rounded-md" active />
        ) : (
          <AssignmentUnitSelect
            className="w-72"
            assignmentUnits={assignmentUnits}
            selectedAssignmentUnitId={selectedAssignmentUnitId}
            setSelectedAssignmentUnitId={setSelectedAssignmentUnitId}
          />
        )}
        {!assignmentUnitsLoading && !classPeriodLoading && (
          <div className="flex items-center text-sm text-secondary-gray-60">
            {selectedAssignmentUnitId ? (
              <AssignmentUnitStatusLabel />
            ) : (
              <OverallStatusLabel />
            )}
          </div>
        )}
      </div>
      <AssignmentOverallClassPeriodDetailTable
        selectedAssignmentUnitId={selectedAssignmentUnitId}
      />
    </>
  );
};
