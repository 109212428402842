import { Card, Skeleton } from 'antd';

import React from 'react';
import { SerializedStyles } from '@emotion/utils';
/** @jsx jsx */
import { jsx } from '@emotion/react';

interface CardSkeletonProps {
  prefix: string;
  className?: string;
  cardStyle?: SerializedStyles;
  children?: React.ReactNode;
  numberOfCard?: number;
}
export const CardSkeleton = ({
  prefix,
  className = '',
  cardStyle,
  children,
  numberOfCard = 4
}: CardSkeletonProps) => {
  return (
    <div className={className}>
      {Array.from(Array(numberOfCard).keys())?.map((idx) => (
        <Card key={prefix + idx} css={cardStyle}>
          {children ? children : <Skeleton active />}
        </Card>
      ))}
    </div>
  );
};
