import { chain, flatten } from 'lodash';

import React from 'react';
import { importExcelErrorMapper } from 'feature/dashboard/utils/getErrorMapper';

interface ImportExcelErrorAnnotationProps {
  record: any;
}
export const ImportExcelErrorAnnotation = ({
  record
}: ImportExcelErrorAnnotationProps) => {
  return (
    <>
      {flatten(
        record?.fieldErrors?.map(
          (error: { fieldName: string; errorMessages: string[] }) => {
            const messages = error?.errorMessages?.map(
              (message: string) => importExcelErrorMapper[message]
            );
            const uniqueMessages = chain(messages).compact().uniq().value();
            const elements = uniqueMessages.map((message) => (
              <div className="text-primary">- {message}</div>
            ));
            return elements;
          }
        )
      )}
    </>
  );
};
