import { Card, Checkbox, Col, Modal, Row, Switch, Table } from 'antd';
import { Global, css, jsx } from '@emotion/react';
/** @jsx jsx */
import React, { useEffect, useState } from 'react';

import { GradeLevelSubjectMediaModal } from './GradeLevelSubjectMediaModal';

interface MediaManagementForClassroomTableProps {
  productLicenseUsageList: any[];
  classroomNameList: any[];
  loading: boolean;
  selectedClassrooms: any[];
  setSelectedClassrooms: Function;
  maxSelectedClassrooms: number;
  setIsAlertModalVisible: Function;
}

export const MediaManagementForClassroomTable = ({
  productLicenseUsageList,
  classroomNameList,
  loading,
  selectedClassrooms,
  setSelectedClassrooms,
  maxSelectedClassrooms,
  setIsAlertModalVisible
}: MediaManagementForClassroomTableProps) => {
  const [dataTable, setDataTable] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalMediaList, setModalMediaList] = useState<any[]>([]);

  let gradeLevelCurrent = '';

  const columns: any = [
    {
      title: 'ระดับชั้น',
      width: 100,
      dataIndex: 'gradeLevel',
      key: 'gradeLevel',
      fixed: 'left',
      render: (gradeLevel: any) => {
        if (gradeLevelCurrent !== gradeLevel.name.th) {
          gradeLevelCurrent = gradeLevel.name.th;
          return <p>{gradeLevel.name.th}</p>;
        } else {
          return <p>{''}</p>;
        }
      }
    },
    {
      title: 'วิชา',
      width: 150,
      dataIndex: 'subject',
      key: 'subject',
      fixed: 'left',
      align: 'center',
      render: (subject: any, record: any) => {
        return (
          <p
            onClick={() => {
              setModalTitle(
                `สื่อวิชา${subject?.name?.th ?? ''} ${
                  record.gradeLevel?.name?.th ?? ''
                }`
              );
              setModalMediaList(record.products);
              setModalVisible(true);
            }}
            className="underline decoration-solid cursor-pointer"
          >
            {subject.name.th}
          </p>
        );
      }
    },
    {
      title: 'จำนวนที่ซื้อ',
      dataIndex: 'quotaLimits',
      key: 'quotaLimits',
      width: 100,
      fixed: 'left',
      align: 'center',
      render: (quotaLimits: number) => {
        return <p>{quotaLimits}</p>;
      }
    },
    ...classroomNameList.map((classroomName, index) => ({
      title: () => <p className="text-center">{classroomName}</p>,
      key: classroomName,
      width: 150,
      render: (record: any) => {
        // console.log('record', record);
        const key = `${record.key}_${index}`;
        const currentClassroomData = record.classroomNames[classroomName];

        const classroomId = currentClassroomData?.classroomId;
        const classPeriodId = currentClassroomData.classPeriodId;
        const classPeriodName = currentClassroomData.classPeriodName;
        const tempClassPeriodName = `${record.subject.name.th} ${record.gradeLevel.name.th}/${classroomName}`;
        const schoolLicenseIds = record.schoolLicenseIds;
        const classPeriodLicenseIds = currentClassroomData.classPeriodLicenseProducts.map(
          (classPeriodLicenseProduct: any) =>
            classPeriodLicenseProduct.classPeriodLicenseId
        );

        const isDefaultChecked = !!currentClassroomData
          .classPeriodLicenseProducts.length;
        const isDisable =
          (isDefaultChecked &&
            !!currentClassroomData.numberOfActiveClassroomStudent) ||
          !classroomId ||
          !schoolLicenseIds.length;
        /*|| !record.quotaRemaining*/
        const isSelected = !!selectedClassrooms.find(
          (selectedClassroom: any) => selectedClassroom.key === key
        );
        const isChecked = isDefaultChecked !== isSelected;

        return (
          <div className="flex justify-center items-center">
            <Checkbox
              key={key}
              checked={isChecked}
              defaultChecked={isDefaultChecked}
              disabled={isDisable}
              onChange={() => {
                if (isChecked !== isDefaultChecked) {
                  const newSelectedClassrooms = selectedClassrooms.filter(
                    (selectedClassroom: any) => selectedClassroom.key !== key
                  );
                  setSelectedClassrooms(newSelectedClassrooms);
                } else if (selectedClassrooms.length >= maxSelectedClassrooms) {
                  setIsAlertModalVisible(true);
                } else {
                  setSelectedClassrooms([
                    ...selectedClassrooms,
                    {
                      key: key,
                      classroomId: classroomId,
                      subjectId: record.subject.subjectId,
                      classPeriodId: classPeriodId,
                      classPeriodName: classPeriodName ?? tempClassPeriodName,
                      schoolLicenseIds: schoolLicenseIds,
                      classPeriodLicenseIds: classPeriodLicenseIds,
                      editType: isDefaultChecked ? 'delete' : 'create'
                    }
                  ]);
                }
              }}
              css={css`
                ${isDefaultChecked && isDisable /* gray-disable-check */
                  ? `.ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #C7C8D1 !important;
                    border-color: #C7C8D1 !important;
                  }
                  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
                    border-color: #f5f5f5 !important;
                  }`
                  : isDefaultChecked &&
                    !isDisable /* dark-grey-check & red-uncheck */
                  ? `.ant-checkbox-inner {
                    border-color: #ED1C24 !important;
                  }
                  .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #5C5E70 !important;
                    border-color: #5C5E70 !important;
                  }`
                  : !isDefaultChecked &&
                    !isDisable /* red-check & gray-uncheck */
                  ? `.ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #ED1C24;
                    border-color: #ED1C24;
                  }`
                  : ''}
              `}
            ></Checkbox>
          </div>
        );
      }
    })),
    {
      title: 'รวมจำนวนสื่อ',
      dataIndex: 'numberOfLicenseUsages',
      key: 'numberOfLicenseUsages',
      // fixed: 'right',
      width: 100,
      align: 'center',
      render: (numberOfLicenseUsages: number) => <p>{numberOfLicenseUsages}</p>
    },
    {
      title: 'เหลือ/เกิน',
      dataIndex: 'quotaRemaining',
      key: 'quotaRemaining',
      // fixed: 'right',
      width: 100,
      align: 'center',
      render: (quotaRemaining: number) => <p>{quotaRemaining}</p>
    },
    {
      title: 'การเข้าใช้ของนร.',
      onHeaderCell: () => {
        return {
          style: {
            borderLeftWidth: 1,
            borderStyle: 'solid',
            borderColor: '#E3E4E8'
          }
        };
      },
      align: 'center',
      children: [
        ...classroomNameList.map((classroomName, index) => ({
          title: () => <p className="text-center">{classroomName}</p>,
          onHeaderCell: () => {
            return {
              style:
                index === 0
                  ? {
                      borderLeftWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#E3E4E8'
                    }
                  : {}
            };
          },
          onCell: () => {
            return {
              style: {
                borderLeftWidth: 1,
                borderStyle: 'solid',
                borderColor: '#E3E4E8'
              }
            };
          },
          key: classroomName + 'Active',
          width: 100,
          render: (record: any) => {
            // console.log('record', record);
            return {
              children: (
                <p className="text-center">
                  {
                    record.classroomNames[classroomName]
                      .numberOfActiveClassroomStudent
                  }
                </p>
              ),
              props: {
                rowSpan: record.rowSpan
              } as any
            };
          }
        }))
      ]
    }
  ];

  //"loading" in useEffect is trigger for re-render table to be able to show numberOfClassroomStudent
  useEffect(() => {
    if (productLicenseUsageList && classroomNameList) {
      setDataTable(productLicenseUsageList);
    }
  }, [classroomNameList, productLicenseUsageList, loading]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataTable}
        scroll={{ x: 1500, y: 500 }}
        pagination={false}
        className="text-lg font-bold"
        css={css`
          th {
            color: #5c5e70 !important;
          }
          p {
            color: #5c5e70 !important;
          }
          .ant-checkbox > .ant-checkbox-inner {
            border-color: #5c5e70;
          }
        `}
        sticky
      />
      <GradeLevelSubjectMediaModal
        visible={modalVisible}
        title={modalTitle}
        mediaList={modalMediaList}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
};
