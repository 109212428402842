import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';
import { ReactComponent as FileIcon } from 'assets/file-icon.svg';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
interface FileItemProps {
  file: RcFile;
}

export const FileItem = ({ file }: FileItemProps) => {
  return (
    <a
      href={URL.createObjectURL(file)}
      download={file.name}
      className="bg-system-notification-grey hover:bg-secondary-gray-10 transition-colors flex w-full px-4 items-center justify-between cursor-pointer h-10 rounded-lg"
    >
      <div className="flex">
        <FileIcon className="mr-2" />
        <div className="text-sm font-normal text-secondary-gray-60 truncate w-44">
          {file.name}
        </div>
      </div>
      <div>
        <DownloadIcon />
      </div>
    </a>
  );
};
