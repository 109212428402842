import { Table } from 'antd';
import styled from '@emotion/styled';

interface EvaluationTableProps {
  customStyles: {
    header: {
      backgroundColor: string;
      color?: string;
      activeColumns?: {
        colNo: number;
        backgroundColor: string;
        bodyBackgroundColor: string;
      }[];
      hasUnit?: boolean;
    };
    body?: {
      hasScore?: boolean;
    };
  };
}

export const EvaluationTable = styled(Table)`
  .ant-table-container {
    background: #fafafa;
  }
  .ant-table-content {
    filter: drop-shadow(0px 10px 30px rgba(46, 47, 56, 0.1));
    border-radius: 20px;
    background-color: #ffffff;
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: ${({ customStyles }: EvaluationTableProps) =>
        customStyles.header.backgroundColor};
      color: ${({ customStyles }: EvaluationTableProps) =>
        customStyles.header.color ?? '#ffffff'};
      font-weight: bold;
      border-bottom: none;
      border-right: 1px solid #e3e4e8;
    }
    ${({ customStyles }: EvaluationTableProps) =>
      customStyles.header.activeColumns &&
      customStyles.header.activeColumns.map((activeColumn) => {
        return `th.ant-table-cell:nth-of-type(${activeColumn.colNo}) {
          background-color: ${activeColumn.backgroundColor};
        }`;
      })}
    th.ant-table-cell:nth-of-type(1) {
      text-align: right;
    }
  }
  ${({ customStyles }: EvaluationTableProps) =>
    customStyles.header.activeColumns &&
    customStyles.header.activeColumns.map((activeColumn) => {
      return `td.ant-table-cell:nth-of-type(${activeColumn.colNo}) {
        background-color: ${activeColumn.bodyBackgroundColor} !important;
      }`;
    })}
  .ant-table-content {
    min-height: 610px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0;
  }
  ${({ customStyles }: EvaluationTableProps) =>
    customStyles?.body?.hasScore &&
    `.ant-table-tbody > tr {
    height: 56px;
  }
  `}
  ${({ customStyles }: EvaluationTableProps) =>
    customStyles.header.hasUnit &&
    customStyles?.body?.hasScore &&
    `.ant-table-row:hover > .ant-table-cell {
        background-color: #F9F9F9 !important;
    }
    `}
  ${({ customStyles }: EvaluationTableProps) =>
    !customStyles?.body?.hasScore &&
    `.ant-table-row {
        height: 610px;
      }`}
`;
