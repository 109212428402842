/** @jsx jsx */
import { Button, Form, Input, Modal, message } from 'antd';
import { FormRule, getRule } from 'utils/getRule';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { ValidateRule } from '../ValidateRule';
import { updateDefaultPassword } from 'api/default-password/updateDefaultPassword';
import { useValidatePassword } from 'hooks/user/useValidatePassword';
import { userStore } from 'stores/userStore';

interface ChangeSchoolDefaultPasswordModalProps {
  visible: boolean;
  setVisible: Function;
  defaultPassword: string;
  schoolYearId?: string;
  onSuccess: Function;
}

export const ChangeSchoolDefaultPasswordModal = ({
  visible,
  setVisible,
  defaultPassword,
  schoolYearId,
  onSuccess
}: ChangeSchoolDefaultPasswordModalProps) => {
  const { me } = userStore;

  const {
    atLeast8Char,
    oneUppercase,
    oneLowercase,
    oneNumber,
    setPassword: setValidatePassword
  } = useValidatePassword();

  const [form] = Form.useForm();
  // const defaultPasswordInput = useRef(defaultPassword);
  const [newPasswordInput, setNewPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (
      form.getFieldValue('newPassword') &&
      form.getFieldValue('newPassword') === defaultPassword
    ) {
      form.setFields([
        {
          name: 'newPassword',
          errors: ['รหัสผ่านใหม่ซ้ำกับรหัสผ่านปัจจุบัน']
        }
      ]);
    } else {
      form.setFields([
        {
          name: 'newPassword',
          errors: []
        }
      ]);
    }

    if (
      !form.getFieldValue('newPassword') ||
      !form.getFieldValue('confirmPassword')
    ) {
      form.setFields([
        {
          name: 'confirmPassword',
          errors: []
        }
      ]);
    } else {
      if (
        form.getFieldValue('newPassword') !==
        form.getFieldValue('confirmPassword')
      ) {
        form.setFields([
          {
            name: 'confirmPassword',
            errors: ['รหัสผ่านไม่ตรงกัน']
          }
        ]);
      } else if (form.getFieldValue('newPassword') === defaultPassword) {
        form.setFields([
          {
            name: 'confirmPassword',
            errors: ['รหัสผ่านใหม่ซ้ำกับรหัสผ่านปัจจุบัน']
          }
        ]);
      } else if (
        form.getFieldValue('newPassword') &&
        !(atLeast8Char && oneUppercase && oneLowercase && oneNumber)
      ) {
        form.setFields([
          {
            name: 'confirmPassword',
            errors: ['รูปแบบรหัสผ่านไม่ถูกต้อง']
          }
        ]);
      } else {
        form.setFields([
          {
            name: 'confirmPassword',
            errors: []
          }
        ]);
      }
    }
  }, [newPasswordInput, confirmPasswordInput]);

  const onFinish = async (value: any) => {
    setIsSubmitting(true);
    const schoolId = me?.schoolId;
    const newPassword = value.newPassword;
    const loading = message.loading('กำลังดำเนินการ...', 0);
    try {
      if (schoolId) {
        await updateDefaultPassword({
          schoolId,
          newPassword,
          schoolYearId
        });
        loading();
        message.success('เปลี่ยนรหัสผ่านโรงเรียนสำเร็จ');
        onSuccess();
      } else {
        loading();
        message.error('เปลี่ยนรหัสผ่านโรงเรียนไม่สำเร็จ กรุาลองใหม่อีกครั้ง');
        // console.log('schoolId not found');
      }
    } catch (error) {
      loading();
      message.error('เปลี่ยนรหัสผ่านโรงเรียนไม่สำเร็จ กรุาลองใหม่อีกครั้ง');
      console.log('error', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      defaultPassword,
      newPassword: '',
      confirmPassword: ''
    });
    setValidatePassword('');
    setNewPasswordInput('');
    setConfirmPasswordInput('');
  }, [visible]);

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      width={410}
      footer={null}
      onCancel={() => setVisible(false)}
      centered
      css={css`
        .ant-modal-body {
          padding: 0;
        }
      `}
    >
      <Form form={form} onFinish={onFinish} className="py-6 px-14">
        <h3 className="font-bold text-2xl mb-9">ตั้งรหัสผ่านโรงเรียนใหม่</h3>
        <Form.Item
          label="รหัสผ่านปัจจุบัน"
          name="defaultPassword"
          validateTrigger="onSubmit"
          initialValue={defaultPassword}
        >
          <Input.Password readOnly />
        </Form.Item>
        <Form.Item
          label="รหัสผ่านใหม่"
          name="newPassword"
          validateTrigger="onSubmit"
          normalize={(value) => value.trim()}
          // rules={[getRule(FormRule.REQUIRE, "กรุณากรอกรหัสผ่านใหม่")]}
          rules={[
            getRule(FormRule.REQUIRE, 'กรุณากรอกรหัสผ่านใหม่'),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  value &&
                  !(atLeast8Char && oneUppercase && oneLowercase && oneNumber)
                )
                  return Promise.reject('รูปแบบรหัสผ่านไม่ถูกต้อง');
                if (value === defaultPassword)
                  return Promise.reject('รหัสผ่านใหม่ซ้ำกับรหัสผ่านปัจจุบัน');
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password
            type="password"
            placeholder="รหัสผ่านใหม่"
            onChange={(e) => {
              const value = e.target.value;
              setValidatePassword(value);
              setNewPasswordInput(value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="ยืนยันรหัสผ่านใหม่"
          name="confirmPassword"
          validateTrigger="onSubmit"
          normalize={(value) => value.trim()}
          rules={[
            getRule(FormRule.REQUIRE, 'กรุณายืนยันรหัสผ่านใหม่'),
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && getFieldValue('newPassword') !== value)
                  return Promise.reject('รหัสผ่านไม่ตรงกัน');
                if (
                  value &&
                  !(atLeast8Char && oneUppercase && oneLowercase && oneNumber)
                )
                  return Promise.reject('รูปแบบรหัสผ่านไม่ถูกต้อง');
                if (value === defaultPassword)
                  return Promise.reject('รหัสผ่านใหม่ซ้ำกับรหัสผ่านปัจจุบัน');
                return Promise.resolve();
              }
            })
          ]}
        >
          <Input.Password
            type="password"
            placeholder="ยืนยันรหัสผ่านใหม่"
            onChange={(e) => {
              const value = e.target.value;
              setConfirmPasswordInput(value);
            }}
          />
        </Form.Item>
        <ValidateRule
          active={atLeast8Char}
          text={'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักขระ'}
          className="mb-4"
        />
        <ValidateRule
          active={oneLowercase && oneUppercase && oneNumber}
          text={
            'รหัสผ่านต้องมีทั้งตัวหนังสือภาษาอังกฤษตัวพิมพ์เล็ก (a-z) และตัวพิมพ์ใหญ่ (A-Z) และตัวเลข (0-9)'
          }
          className="mb-10"
        />
        <div className="w-full flex justify-center">
          <Button
            className="w-32 h-12 rounded-xl bg-primary hover:bg-primary-hover transition-colors duration-500 ease-in-out text-white text-base font-bold"
            type="primary"
            danger
            htmlType="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            ยืนยัน
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
