import {
  PaginationQuery,
  SchoolYearDto,
  SchoolYearListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useSchoolYears = (params?: PaginationQuery) => {
  const [{ data, loading, error }] = useAxios<SchoolYearListResponse>({
    url: '/school-years',
    params: {
      pageNumber: 1,
      pageSize: 100,
      // TODO:ทำ loadmore
      ...pickBy(params, identity)
    }
  });

  return {
    data: data?.data as SchoolYearDto[],
    loading: loading,
    error: error
  };
};
