import { ReactComponent as AksornLogoIcon } from 'assets/logo.svg';
import { default as AksornLogoImg } from 'assets/logo.svg';
import { ReactComponent as FacebookLogoIcon } from 'assets/facebook-logo.svg';
import { default as FacebookLogoImg } from 'assets/facebook-logo.svg';
import { ReactComponent as GoogleLogoIcon } from 'assets/google-logo.svg';
import { default as GoogleLogoImg } from 'assets/google-logo.svg';
import { ReactComponent as LineLogoIcon } from 'assets/line-icon.svg';
import { default as LineLogoImg } from 'assets/line-icon.svg';
import { ProviderType } from '../provider';
/** @jsx jsx */
import { jsx } from '@emotion/react';

type ProviderIconProps = {
  providerName: string;
  className?: string;
};

export const ProviderIcon = ({
  providerName,
  className = ''
}: ProviderIconProps) => {
  switch (providerName) {
    case ProviderType.AKSORN_ONE_ACCOUNT:
      return <AksornLogoIcon className={className} />;
    case ProviderType.FACEBOOK:
      return <FacebookLogoIcon className={className} />;
    case ProviderType.GOOGLE:
      return <GoogleLogoIcon className={className} />;
    case ProviderType.LINE_LOGIN:
      return <LineLogoIcon className={className} />;
    default:
      return <div />;
  }
};

export const ProviderImgIcon = ({
  providerName,
  className
}: ProviderIconProps) => {
  switch (providerName) {
    case ProviderType.AKSORN_ONE_ACCOUNT:
      return (
        <img src={AksornLogoImg} alt={providerName} className={className} />
      );
    case ProviderType.FACEBOOK:
      return (
        <img src={FacebookLogoImg} alt={providerName} className={className} />
      );
    case ProviderType.GOOGLE:
      return (
        <img src={GoogleLogoImg} alt={providerName} className={className} />
      );
    case ProviderType.LINE_LOGIN:
      return <img src={LineLogoImg} alt={providerName} className={className} />;
    default:
      return <div />;
  }
};
