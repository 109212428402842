const teacherCategory = {
  id: 1,
  nodeId: '0',
  titleTH: 'คู่มือการใช้งานสำหรับครู',
  titleEN: "Teacher's user guide",
  route: 'user-guide/teacher-guide',
  topic: 'teacher',
  imageSrc: `assets/userguide-teacher.svg`,
  imageAlt: 'userguide-teacher'
};

const adminCategory = {
  id: 2,
  nodeId: '0',
  titleTH: 'คู่มือการใช้งานสำหรับ Super Admin',
  titleEN: "Admin's user guide",
  route: 'user-guide/admin-guide',
  topic: 'admin',
  imageSrc: `assets/userguide-super-admin.svg`,
  imageAlt: 'super-admin.'
};

const studentCategory = {
  id: 3,
  nodeId: '0',
  titleTH: 'คู่มือการใช้งานสำหรับนักเรียน',
  titleEN: "Student's user guide",
  route: 'user-guide/student-guide',
  topic: 'student',
  imageSrc: `assets/userguide-student.svg`,
  imageAlt: 'student'
};

export { teacherCategory, adminCategory, studentCategory };
