import { css, jsx } from '@emotion/react';

import { ReactComponent as EmptyIcon } from 'assets/evaluation-empty-icon.svg';

/** @jsx jsx */

const EvaluationEmptyState = () => {
  return (
    <div
      className="flex flex-col justify-center items-center py-10 sm:py-12 md:py-14 lg:py-16 xl:py-20 w-full"
      css={css`
        height: calc(100vh - 350px);
      `}
    >
      <EmptyIcon />
      <span className="text-secondary-gray-60 text-base mt-5 font-bold">
        ไม่มีข้อมูล
      </span>
    </div>
  );
};

export { EvaluationEmptyState };
