import { teachingAxios } from 'utils/axios';

export const getProductLicenseUsage = async (
  productId: any,
  schoolYearId: any
) => {
  const { data: response } = await teachingAxios.get(
    `/product-license-usages/${productId}/${schoolYearId}`
  );
  return response;
};
