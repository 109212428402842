import {
  ImportExcelClassPeriodBulkResponse,
  ImportExcelClassroomResponse,
  ImportExcelSchoolStudentBulkResponse,
  ImportExcelSchoolTeacherBulkResponse
} from '@aksorn/teaching-backoffice-sdk';

import { ImportExcelClassPeriodResult } from './ImportExcelClassPeriodResult';
import { ImportExcelClassroomResult } from './ImportExcelClassroomResult';
import { ImportExcelSchoolStudentResult } from './ImportExceClassroomStudentResult';
import { ImportExcelSchoolTeacherResult } from './ImportExcelClassroomTeacherResult';
import React from 'react';
import { ValidationStep } from 'feature/dashboard/dashboard';

interface ImportExcelModalResultProps {
  validationStep?: ValidationStep;
  data:
    | ImportExcelSchoolTeacherBulkResponse
    | ImportExcelClassroomResponse
    | ImportExcelClassPeriodBulkResponse
    | ImportExcelSchoolStudentBulkResponse;
  onClose: () => void;
}

export const ImportExcelModalResult = ({
  data,
  validationStep,
  onClose
}: ImportExcelModalResultProps) => {
  switch (validationStep) {
    case ValidationStep.SCHOOL_TEACHER:
      return (
        <ImportExcelSchoolTeacherResult
          data={data as ImportExcelSchoolTeacherBulkResponse}
          onClose={onClose}
        />
      );
    case ValidationStep.CLASSROOM:
      return (
        <ImportExcelClassroomResult
          data={data as ImportExcelClassroomResponse}
          onClose={onClose}
        />
      );
    case ValidationStep.CLASS_PERIOD:
      return (
        <ImportExcelClassPeriodResult
          data={data as ImportExcelClassPeriodBulkResponse}
          onClose={onClose}
        />
      );
    case ValidationStep.SCHOOL_STUDENT:
      return (
        <ImportExcelSchoolStudentResult
          data={data as ImportExcelSchoolStudentBulkResponse}
          onClose={onClose}
        />
      );
    default:
      return null;
  }
};
