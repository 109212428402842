import {
  AssignmentUnitListDto,
  EvaluationPrePostTestStatisticGetQuery,
  EvaluationPrePostTestStatisticSubjectDto,
  SubjectDto
} from '@aksorn/teaching-backoffice-sdk';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import {
  EVALUATION_ASSIGNMENT_SORT_MAPPER,
  EVALUATION_TEST_TYPE_MAPPER
} from 'feature/evaluation/const';
import {
  EvaluationAssignmentSortBy,
  EvaluationPrePostTestType
} from 'feature/evaluation/evaluation';
import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import {
  useEvaluationsPrePostTestStatistic,
  useEvaluationsPrePostTestStatisticLicense
} from 'hooks/evaluation/useEvaluationPrePostTestStatistic';

import { BarChartProps } from 'feature/common/barchart/barchart';
import { EmptyEvaluation } from './components/EmptyEvaluation';
import { EmptyStudent } from './components/EmptyStudent';
import { EmptyStudentBarChart } from 'feature/common/barchart/EmptyStudentBarChart';
import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { EvaluationEmptyState } from './EvaluationEmptyState';
import { IndividualStudentBarChart } from 'feature/common/barchart/IndividualStudentBarChart';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { OverviewBarChart } from 'feature/common/barchart/OverviewBarChart';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import dayjs from 'dayjs';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';
import { useAssignmentUnitPrePostTestUsers } from 'hooks/assignment-units/useAssignmentUnitPrePostTestUsers';
import { useAssignmentUnits } from 'hooks/assignment-units/useAssignmentUnits';
import { useSubjects } from 'hooks/subject/useSubjects';

/** @jsx jsx */

const SELECT_ALL = 'all';

const { Option } = Select;

const checkedListOptions = Object.values(EvaluationPrePostTestType);

export const EvaluationPrePostTestClassroomScore = observer(() => {
  const [menuTypeIds, setMenuTypeIds] = useState<string>('7,8');
  const [sortBy, setSortBy] = useState(
    EvaluationAssignmentSortBy.STUDENT_NUMBER
  );
  const [selectedSubject, setSelectedSubject] = useState<
    SubjectDto | undefined
  >(undefined);
  const [selectedAssignmentUnit, setSelectedAssignmentUnit] = useState<
    AssignmentUnitListDto | undefined
  >(undefined);
  const [checkedList, setCheckedList] = useState<string[]>(checkedListOptions);
  const [isLoading, setIsLoading] = useState(true);
  const [
    isLoadingAssignmentUnitPrePostTestUsers,
    setIsLoadingAssignmentUnitPrePostTestUsers
  ] = useState(true);

  const [
    evaluationPrePostTestStatisticsOverview,
    setEvaluationPrePostTestStatisticsOverview
  ] = useState<EvaluationPrePostTestStatisticSubjectDto[]>([]);
  const [
    evaluationPrePostTestStatisticsIndividual,
    setEvaluationPrePostTestStatisticsIndividual
  ] = useState<BarChartProps[]>([]);

  // const {
  //   data: evaluationPrePostTestStatistics,
  //   execute: getEvaluationPrePostTestStatistics
  // } = useEvaluationsPrePostTestStatistic();
  const {
    data: evaluationPrePostTestStatistics,
    execute: getEvaluationPrePostTestStatisticsLicense
  } = useEvaluationsPrePostTestStatisticLicense();

  const {
    data: subjects,
    loading: isSubjectsLoading,
    execute: getSubjects
  } = useSubjects();
  const {
    data: assignmentUnits,
    execute: getAssignmentUnits
  } = useAssignmentUnits();

  const {
    data: assignmentUnitPrePostTestUsers,
    execute: getAssignmentUnitPrePostTestUsers
  } = useAssignmentUnitPrePostTestUsers();

  const { filterData } = evaluationStore;
  const { clearEvaluationDownloads } = evaluationDownloadStore;

  const getInitialData = async ({ subjectId }: { subjectId: string }) => {
    try {
      setIsLoading(true);
      if (filterData.selectedClassroom?.classroomId && subjectId) {
        const query: EvaluationPrePostTestStatisticGetQuery = {
          schoolYearId: filterData.selectedSchoolYearId,
          gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
          classroomId: filterData.selectedClassroom?.classroomId
        };
        if (filterData.selectedSubject?.subjectId) {
          Object.assign(query, {
            subjectId: filterData.selectedSubject?.subjectId
          });
        }
        // await getEvaluationPrePostTestStatistics(query);
        await getEvaluationPrePostTestStatisticsLicense(query);
        await getAssignmentUnits({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ??
            selectedSubject?.subjectId ??
            subjectId,
          menuTypeIds
        });
        await getAssignmentUnitPrePostTestUsers({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ??
            selectedSubject?.subjectId ??
            subjectId,
          menuTypeIds,
          sortBy: sortBy as any
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssignmentUnitPrePostTestUsers = async (
    assignmentUnitId?: string
  ) => {
    try {
      setIsLoadingAssignmentUnitPrePostTestUsers(true);
      if (selectedSubject && filterData.selectedClassroom) {
        // await getEvaluationPrePostTestStatistics({
        //   schoolYearId: filterData.selectedSchoolYearId,
        //   gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
        //   classroomId: filterData.selectedClassroom?.classroomId,
        //   unitNo: selectedAssignmentUnit?.unitNo,
        //   subjectId: filterData.selectedSubject?.subjectId
        // });
        await getEvaluationPrePostTestStatisticsLicense({
          schoolYearId: filterData.selectedSchoolYearId,
          gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
          classroomId: filterData.selectedClassroom?.classroomId,
          unitNo: selectedAssignmentUnit?.unitNo,
          subjectId: filterData.selectedSubject?.subjectId
        });
        await getAssignmentUnits({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId,
          menuTypeIds
        });
        await getAssignmentUnitPrePostTestUsers({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId,
          menuTypeIds,
          assignmentUnitId,
          sortBy: sortBy as any
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAssignmentUnitPrePostTestUsers(false);
    }
  };

  const classroomSubject = useMemo(() => {
    if (selectedSubject && evaluationPrePostTestStatistics) {
      if (selectedAssignmentUnit) {
        return evaluationPrePostTestStatistics.find(
          (subject) =>
            subject.subjectId ===
            (filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId)
        );
      } else {
        return evaluationPrePostTestStatisticsOverview.find(
          (subject) =>
            subject.subjectId ===
            (filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId)
        );
      }
    }
  }, [
    selectedSubject,
    filterData.selectedSubject?.subjectId,
    evaluationPrePostTestStatisticsOverview,
    evaluationPrePostTestStatistics
  ]);

  const markLines = useMemo(() => {
    if (classroomSubject) {
      switch (checkedList.length) {
        case 0:
          return undefined;
        case 1:
          if (checkedList[0] === EvaluationPrePostTestType.PRE_TEST) {
            return [
              {
                lineColor: '#DF85FF',
                value: classroomSubject?.classrooms[0].pre.averageScore
              },
              {
                lineColor: '#FFD159',
                value: classroomSubject?.gradeLevel.pre.averageScore
              }
            ];
          } else if (checkedList[0] === EvaluationPrePostTestType.POST_TEST) {
            return [
              {
                lineColor: '#A175FF',
                value: classroomSubject?.classrooms[0].post.averageScore
              },
              {
                lineColor: '#F99D34',
                value: classroomSubject?.gradeLevel.post.averageScore
              }
            ];
          }
          break;
        case 2:
          return [
            {
              lineColor: '#DF85FF',
              value: classroomSubject?.classrooms[0].pre.averageScore
            },
            {
              lineColor: '#A175FF',
              value: classroomSubject?.classrooms[0].post.averageScore
            },
            {
              lineColor: '#FFD159',
              value: classroomSubject?.gradeLevel.pre.averageScore
            },
            {
              lineColor: '#F99D34',
              value: classroomSubject?.gradeLevel.post.averageScore
            }
          ];
      }
    }
  }, [checkedList, classroomSubject]);

  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    if (filterData.selectedClassroom && subjects.length > 0) {
      getInitialData({ subjectId: subjects[0].subjectId });
    }
    return () => {
      clearEvaluationDownloads();
    };
  }, [
    filterData.selectedSubject?.subjectId,
    filterData.selectedClassroom?.classroomId,
    isSubjectsLoading
  ]);

  useEffect(() => {
    if (selectedSubject) {
      fetchAssignmentUnitPrePostTestUsers(
        selectedAssignmentUnit?.assignmentUnitId
      );
    }
  }, [selectedAssignmentUnit]);

  useEffect(() => {
    if (selectedSubject) {
      setSelectedAssignmentUnit(undefined);
      fetchAssignmentUnitPrePostTestUsers();
    }
  }, [selectedSubject, sortBy]);

  useEffect(() => {
    if (
      evaluationPrePostTestStatistics.length > 0 &&
      selectedAssignmentUnit === undefined &&
      evaluationPrePostTestStatisticsOverview.length === 0
    ) {
      setEvaluationPrePostTestStatisticsOverview(
        evaluationPrePostTestStatistics
      );
    }
  }, [evaluationPrePostTestStatistics]);

  useEffect(() => {
    if (subjects.length > 0) {
      setSelectedSubject(subjects[0]);
    }
  }, [isSubjectsLoading]);

  useEffect(() => {
    if (subjects.length > 0) {
      setSelectedAssignmentUnit(undefined);
      setSelectedSubject(subjects[0]);
      setEvaluationPrePostTestStatisticsOverview([]);
    }
  }, [
    filterData.selectedSubject?.subjectId,
    filterData.selectedClassroom?.classroomId
  ]);

  useEffect(() => {
    if (assignmentUnitPrePostTestUsers) {
      if (checkedList.length !== 1) {
        setMenuTypeIds('7,8');
        setEvaluationPrePostTestStatisticsIndividual([
          {
            barColor: '#80D5FF',
            value: assignmentUnitPrePostTestUsers?.map(
              ({ preScore }) => preScore?.toFixed(1) ?? ''
            )
          },
          {
            barColor: '#59ACFF',
            value: assignmentUnitPrePostTestUsers?.map(
              ({ postScore }) => postScore?.toFixed(1) ?? ''
            )
          }
        ]);
      } else {
        if (checkedList[0] === EvaluationPrePostTestType.PRE_TEST) {
          setMenuTypeIds('7');
          setEvaluationPrePostTestStatisticsIndividual([
            {
              barColor: '#80D5FF',
              value: assignmentUnitPrePostTestUsers?.map(
                ({ preScore }) => preScore?.toFixed(1) ?? ''
              )
            }
          ]);
        } else if (checkedList[0] === EvaluationPrePostTestType.POST_TEST) {
          setMenuTypeIds('8');
          setEvaluationPrePostTestStatisticsIndividual([
            {
              barColor: '#59ACFF',
              value: assignmentUnitPrePostTestUsers?.map(
                ({ postScore }) => postScore?.toFixed(1) ?? ''
              )
            }
          ]);
        }
      }
    }
  }, [checkedList, assignmentUnitPrePostTestUsers]);

  const getSubjectChartMaxScore = (evaluationPrePostTestStatistic: any[]) => {
    if (evaluationPrePostTestStatistic) {
      if (evaluationPrePostTestStatistic.length === 1) {
        return Math.max(
          evaluationPrePostTestStatistic[0].classrooms[0].pre.maxScore,
          evaluationPrePostTestStatistic[0].classrooms[0].post.maxScore
        );
      } else {
        return evaluationPrePostTestStatistic?.reduce(
          (barChartMaxScore, score) =>
            barChartMaxScore > getClassroomMaxScore(score.classrooms)
              ? barChartMaxScore
              : score.classrooms
              ? getClassroomMaxScore(score.classrooms)
              : Math.max(score.pre.maxScore, score.post.maxScore)
        );
      }
    } else {
      return undefined;
    }
  };

  const getIndividualStudentBarChartMaxScore = useMemo(() => {
    if (classroomSubject) {
      if (checkedList.length !== 1) {
        return Math.max(
          Math.max(
            classroomSubject?.gradeLevel.pre.maxScore,
            classroomSubject?.gradeLevel.post.maxScore
          ),
          Math.max(
            classroomSubject?.classrooms[0].pre.maxScore,
            classroomSubject?.classrooms[0].post.maxScore
          )
        );
      } else {
        if (checkedList[0] === EvaluationPrePostTestType.PRE_TEST) {
          return Math.max(
            classroomSubject?.gradeLevel.pre.maxScore,
            classroomSubject?.classrooms[0].pre.maxScore
          );
        } else if (checkedList[0] === EvaluationPrePostTestType.POST_TEST) {
          return Math.max(
            classroomSubject?.gradeLevel.post.maxScore,
            classroomSubject?.classrooms[0].post.maxScore
          );
        }
      }
    }
  }, [checkedList, classroomSubject]);

  const getClassroomMaxScore = (classrooms: any[]) => {
    return Math.max(classrooms[0].pre.maxScore, classrooms[0].post.maxScore);
  };

  const classroomAveragePreScore = useMemo(() => {
    if (selectedSubject) {
      return `${
        classroomSubject?.classrooms[0].pre.averageScore.toFixed(1) ?? '0.0'
      } คะแนน/${
        classroomSubject?.classrooms[0].pre.numberOfSubmissions ?? 0
      } คน`;
    }
  }, [classroomSubject, selectedSubject]);

  const classroomAveragePostScore = useMemo(() => {
    if (selectedSubject) {
      return `${
        classroomSubject?.classrooms[0].post.averageScore.toFixed(1) ?? '0.0'
      } คะแนน/${
        classroomSubject?.classrooms[0].post.numberOfSubmissions ?? 0.0
      } คน`;
    }
  }, [classroomSubject, selectedSubject]);

  const gradeLevelAveragePreScore = useMemo(() => {
    if (selectedSubject) {
      return `${
        classroomSubject?.gradeLevel.pre.averageScore.toFixed(1) ?? '0.0'
      } คะแนน/${classroomSubject?.gradeLevel.pre.numberOfSubmissions ?? 0} คน`;
    }
  }, [classroomSubject, selectedSubject]);

  const gradeLevelAveragePostScore = useMemo(() => {
    if (selectedSubject) {
      return `${
        classroomSubject?.gradeLevel.post.averageScore.toFixed(1) ?? '0.0'
      } คะแนน/${classroomSubject?.gradeLevel.post.numberOfSubmissions ?? 0} คน`;
    }
  }, [classroomSubject, selectedSubject]);

  const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

  const fileName = useMemo(() => {
    if (filterData.selectedGradeLevel && filterData.selectedClassroom) {
      return `${downloadDate}_${filterData.selectedGradeLevel.name.th}_${filterData.selectedClassroom?.classroomName}`;
    }
  }, [filterData.selectedClassroom?.classroomId]);

  return (
    <div>
      {evaluationPrePostTestStatisticsOverview.length > 0 ? (
        <div>
          <EvaluationContainer
            loading={{
              isLoading: isLoading,
              height: 202
            }}
            className="mb-8"
            title={`คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียน ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
            fileName={`${fileName}_${
              filterData.selectedSubject?.name.th ?? 'ทั้งหมด'
            }_คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียน`}
          >
            <div className="flex mb-4">
              <SquareGraphLabel color="#80D5FF" label="คะแนนเฉลี่ยก่อนเรียน" />
              <SquareGraphLabel color="#59ACFF" label="คะแนนเฉลี่ยหลังเรียน" />
            </div>
            {filterData.selectedSubject?.subjectId && (
              <div className="flex mb-4">
                <LineGraphLabel
                  color="#FFD159"
                  label={`คะแนนเฉลี่ยก่อนเรียนระดับชั้น ${filterData.selectedGradeLevel.name.th}`}
                  description={`${
                    evaluationPrePostTestStatisticsOverview[0].gradeLevel.pre.averageScore.toFixed(
                      1
                    ) ?? '0.0'
                  } คะแนน/${
                    evaluationPrePostTestStatistics[0].gradeLevel.pre
                      .numberOfSubmissions
                  } คน`}
                />
                <LineGraphLabel
                  color="#FFA159"
                  label={`คะแนนเฉลี่ยหลังเรียนระดับชั้น ${filterData.selectedGradeLevel.name.th}`}
                  description={`${
                    evaluationPrePostTestStatisticsOverview[0].gradeLevel.post.averageScore.toFixed(
                      1
                    ) ?? '0.0'
                  } คะแนน/${
                    evaluationPrePostTestStatistics[0].gradeLevel.post
                      .numberOfSubmissions
                  } คน`}
                />
              </div>
            )}
            <OverviewBarChart
              axisNames={evaluationPrePostTestStatisticsOverview?.map(
                ({ name }) => name.th
              )}
              dataSource={[
                {
                  barWidth: 32,
                  barColor: '#80D5FF',
                  value: evaluationPrePostTestStatisticsOverview?.map(
                    ({ classrooms }) =>
                      classrooms[0].pre.numberOfSubmissions > 0
                        ? classrooms[0].pre.averageScore.toFixed(1)
                        : ''
                  )
                },
                {
                  barWidth: 32,
                  barColor: '#59ACFF',
                  value: evaluationPrePostTestStatisticsOverview?.map(
                    ({ classrooms }) =>
                      classrooms[0].post.numberOfSubmissions > 0
                        ? classrooms[0].post.averageScore.toFixed(1)
                        : ''
                  )
                }
              ]}
              markLines={
                filterData.selectedSubject?.subjectId
                  ? [
                      {
                        lineColor: '#FFD159',
                        value:
                          evaluationPrePostTestStatisticsOverview[0].gradeLevel
                            .pre.averageScore
                      },
                      {
                        lineColor: '#FFA159',
                        value:
                          evaluationPrePostTestStatisticsOverview[0].gradeLevel
                            .post.averageScore
                      }
                    ]
                  : undefined
              }
              chartMaxScore={
                getSubjectChartMaxScore(
                  evaluationPrePostTestStatisticsOverview
                ) !== 0
                  ? getSubjectChartMaxScore(
                      evaluationPrePostTestStatisticsOverview
                    ).toFixed(1)
                  : 100
              }
              elementHeight={100}
            />
          </EvaluationContainer>
          <EvaluationContainer
            loading={{
              isLoading: isLoading || isLoadingAssignmentUnitPrePostTestUsers,
              height: 202
            }}
            className="mb-8"
            title={`คะแนนรายบุคคล ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
            fileName={`${fileName}_${
              filterData.selectedSubject?.name.th ?? selectedSubject?.name.th
            }_${
              selectedAssignmentUnit?.unitNo
                ? `หน่วยที่${selectedAssignmentUnit?.unitNo}`
                : 'ทั้งหมด'
            }_คะแนนรายบุคคล`}
          >
            <div className="flex justify-between">
              <div className="flex">
                {filterData.selectedSubject?.subjectId === undefined && (
                  <Form.Item label="วิชา" className="w-48 mr-2.5">
                    <Select
                      listHeight={160}
                      value={selectedSubject?.subjectId}
                      onSelect={(subjectId) => {
                        const selectedSubject =
                          subjects.find(
                            (subject) => subjectId === subject.subjectId
                          ) || undefined;
                        setSelectedSubject(selectedSubject);
                      }}
                    >
                      {subjects.map((subject) => (
                        <Option
                          key={subject.subjectId}
                          value={subject.subjectId}
                        >
                          {subject.name.th}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item label="หน่วยการเรียนรู้" className="w-48 mr-2.5">
                  <Select
                    listHeight={160}
                    value={
                      selectedAssignmentUnit?.assignmentUnitId || SELECT_ALL
                    }
                    onSelect={(assignmentUnitId) => {
                      const selectedUnitId =
                        assignmentUnits.find(
                          (unit) => unit.assignmentUnitId === assignmentUnitId
                        ) || undefined;
                      setSelectedAssignmentUnit(selectedUnitId);
                    }}
                  >
                    <Option value={SELECT_ALL}>ทั้งหมด</Option>
                    {assignmentUnits.map((unit) => (
                      <Option
                        key={unit.assignmentUnitId}
                        value={unit.assignmentUnitId}
                      >
                        {/* {unit.unitNo} */}
                        {unit.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="เรียงตาม"
                  className="w-48"
                  css={css`
                    margin-bottom: 0 !important;
                  `}
                >
                  <Select
                    value={sortBy}
                    onSelect={(value) => {
                      setSortBy(value);
                    }}
                  >
                    {Object.values(EvaluationAssignmentSortBy).map((sortBy) => (
                      <Option key={sortBy} value={sortBy}>
                        {EVALUATION_ASSIGNMENT_SORT_MAPPER[sortBy]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div
              className="flex items-start text-secondary-gray-60 text-base"
              css={css`
                min-width: 500px;
              `}
            >
              <Checkbox.Group
                style={{ width: '100%' }}
                css={css`
                  .ant-checkbox-checked::after {
                    border: none !important;
                  }

                  .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #ed1c24;
                    border-color: #ed1c24;
                  }
                `}
                defaultValue={checkedListOptions}
                onChange={(e: any) => {
                  setCheckedList(e);
                }}
              >
                <Row>
                  {Object.values(EvaluationPrePostTestType).map((checkList) => (
                    <Col key={checkList}>
                      <Checkbox
                        value={checkList}
                        css={css`
                          width: 130px;
                        `}
                      >
                        {EVALUATION_TEST_TYPE_MAPPER[checkList]}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </div>
            <div className="flex mt-4 text-secondary-black">
              <div
                css={css`
                  width: 136px;
                `}
              >
                <SquareGraphLabel color="#80D5FF" label="คะแนนก่อนเรียน" />
              </div>
              <div>
                <LineGraphLabel
                  color="#DF85FF"
                  label={`คะแนนเฉลี่ยก่อนเรียน ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
                  description={classroomAveragePreScore}
                />
              </div>

              <LineGraphLabel
                color="#FFD159"
                css={css`
                  max-width: 360px;
                `}
                label={`คะแนนเฉลี่ยก่อนเรียนทั้งระดับชั้น ${filterData.selectedGradeLevel.name.th}`}
                description={gradeLevelAveragePreScore}
              />
            </div>
            <div className="flex my-2">
              <div
                css={css`
                  width: 136px;
                `}
              >
                <SquareGraphLabel color="#59ACFF" label="คะแนนหลังเรียน" />
              </div>
              <div
                css={css`
                  margin-right: 2px;
                `}
              >
                <LineGraphLabel
                  color="#A175FF"
                  label={`คะแนนเฉลี่ยหลังเรียน ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
                  description={classroomAveragePostScore}
                />
              </div>
              <LineGraphLabel
                color="#F99D34"
                css={css`
                  max-width: 360px;
                `}
                label={`คะแนนเฉลี่ยหลังเรียนทั้งระดับชั้น ${filterData.selectedGradeLevel.name.th}`}
                description={gradeLevelAveragePostScore}
              />
            </div>
            {checkedList.length === 0 ? (
              <EmptyStudentBarChart
                students={assignmentUnitPrePostTestUsers}
                dataSource={evaluationPrePostTestStatisticsIndividual}
              />
            ) : filterData.selectedClassroom?.numberOfClassroomStudent ? (
              assignmentUnitPrePostTestUsers.length > 0 ? (
                <IndividualStudentBarChart
                  className="mt-8"
                  students={assignmentUnitPrePostTestUsers}
                  dataSource={evaluationPrePostTestStatisticsIndividual}
                  chartMaxScore={getIndividualStudentBarChartMaxScore || 100}
                  markLines={markLines}
                />
              ) : (
                <EmptyEvaluation />
              )
            ) : (
              <EmptyStudent />
            )}
          </EvaluationContainer>
        </div>
      ) : (
        <EvaluationEmptyState />
      )}
    </div>
  );
});
