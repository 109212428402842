export enum AssignmentOverallDetailMenu {
  ASSIGNMENT_OVERVIEW = 'assignment-overview',
  LEARNING_OUTCOME = 'learning-outcome'
}

export enum LearningOutcomeMenu {
  OVERALL = 'overall',
  ASSIGNMENT = 'assignment',
  PRE_POST_TEST = 'pre-post-test'
}

export enum LearningOutcomeSortBy {
  CLASSROOM_STUDENT_NUMBER_ASC = 'classroomStudentNumberAsc',
  NAME_ASC = 'nameAsc',
  TOTAL_SCORE_ASC = 'totalScoreAsc',
  TOTAL_SCORE_DESC = 'totalScoreDesc'
}

export enum LearningOutcomeTestType {
  PRE_TEST = 'pre-test',
  POST_TEST = 'post-test'
}
