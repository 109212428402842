import { OverviewBarChartProps } from 'feature/common/barchart/barchart';
import React from 'react';
import ReactECharts from 'echarts-for-react';
import { getOverviewBarChartSeries } from 'feature/common/barchart/getBarChartSeries';

export const OverviewBarChart = ({
  className = '',
  axisNames = [],
  dataSource,
  markLines,
  chartMaxScore = 100,
  // Interval: gap of xAxis
  interval,
  grid = {},
  elementHeight = 72
}: OverviewBarChartProps) => {
  const barChartColors = dataSource?.map(({ barColor }) => barColor);
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (values: any) {
        const firstValue = values[0];
        const otherValues = values.slice(1, values.length);
        const firstValueColor = barChartColors[0];
        const otherValueColor = barChartColors[1];
        const classroomName = firstValue?.axisValue;
        let format = classroomName;
        if (firstValue.data) {
          format += `
          <br/>
            <span style="display:inline-block;margin-right:4px;border-radius:10px;
            width:10px;height:10px;background-color:${firstValueColor}"></span>  
          
          ${firstValue.data.label ? '-' : firstValue.data}`;
        }

        if (otherValues) {
          otherValues.forEach((value: any) => {
            if (value.data) {
              format += `
              <br/>
              <span style="display:inline-block;margin-right:4px;border-radius:10px;
              width:10px;height:10px;background-color:${otherValueColor}"></span>  
                 ${value.data.label ? '-' : value.data}`;
            }
          });
        }
        return format;
      }
    },
    grid: {
      left: grid.left ?? 20,
      right: grid.right ?? 20,
      bottom: grid.bottom ?? 0,
      top: grid.top ?? 0,
      containLabel: true
    },
    xAxis: {
      type: 'value',
      position: 'top',
      max: chartMaxScore > 0 ? chartMaxScore : 100,
      boundaryGap: [0, 0.01],
      axisLabel: {
        fontFamily: 'Sarabun',
        fontSize: 14,
        color: '#8F91A3'
      },
      axisLine: {
        color: '#E3E4E8'
      },
      ...(interval && { interval })
    },
    yAxis: {
      type: 'category',
      inverse: true,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        fontFamily: 'Sarabun',
        fontSize: 16,
        color: '#2E2F38',
        margin: 42
      },
      data: [...axisNames]
    },
    series: getOverviewBarChartSeries({
      dataSource,
      markLines,
      chartMaxScore
    })
  };

  const componentHeight = axisNames.length * elementHeight + 8;
  return (
    <ReactECharts
      className={className}
      option={option}
      style={{ height: componentHeight }}
    />
  );
};
