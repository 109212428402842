/** @jsx jsx */

import { Drawer, Form, Select, Spin, message } from 'antd';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { SCHOOL_ADMIN_ROLE_TYPE_MAPPER } from './school-admin-management';
import { SchoolAdminCreateBody } from '@aksorn/one-account-sdk';
import { SchoolTeacherDto } from '@aksorn/teaching-backoffice-sdk';
import { useCreateSchoolAdmin } from 'hooks/school-admin/useCreateSchoolAdmin';
import { useSchoolAdmin } from 'hooks/school-admin/useSchoolAdmin';
import { useSchoolAdminRoles } from 'hooks/school-admin-role/useSchoolAdminRoles';
import { useSchoolTeachers } from 'hooks/school-teacher/useSchoolTeachers';

const { Option } = Select;

interface SchoolAdminManagementDrawerProps {
  title: string;
  visible: boolean;
  schoolAdminId?: string;
  isEditing: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const SchoolAdminManagementDrawer = ({
  title,
  visible,
  schoolAdminId,
  isEditing,
  onClose,
  refetch
}: SchoolAdminManagementDrawerProps) => {
  const [form] = Form.useForm();

  const [
    selectedSchoolTeacher,
    setSelectedSchoolTeacher
  ] = useState<SchoolTeacherDto>();
  const [selectedSchoolAdminRoleId, setSelectedSchoolAdminRoleId] = useState(
    ''
  );

  const [schoolTeacherCurrentPage, setSchoolTeacherCurrentPage] = useState(1);
  const [tmpSchoolTeachers, setTmpSchoolTeachers] = useState<
    SchoolTeacherDto[]
  >([]);
  const [schoolTeachers, setSchoolTeachers] = useState<SchoolTeacherDto[]>([]);
  const schoolTeacherPageSize = 100;
  const [
    isLoadSchoolTeacherComplete,
    setIsLoadSchoolTeacherComplete
  ] = useState(false);

  const {
    total: schoolTeacherTotal,
    data: schoolTeacher,
    loading: getSchoolTeachersLoading,
    execute: getSchoolTeachers
  } = useSchoolTeachers();
  const {
    data: schoolAdminRoles,
    loading: isFetchingRoles,
    execute: getSchoolAdminRoles,
    total: numberOfSchoolAdmin
  } = useSchoolAdminRoles();
  const {
    loading: isCreatingSchoolAdmin,
    execute: createSchoolAdmin
  } = useCreateSchoolAdmin();
  const {
    data: schoolAdmin,
    loading: isLoadingSchoolAdmin,
    execute: getSchoolAdmin
  } = useSchoolAdmin();

  useEffect(() => {
    if (visible) {
      getSchoolTeachers({
        pageNumber: schoolTeacherCurrentPage,
        pageSize: schoolTeacherPageSize
      });
      getSchoolAdminRoles();
    }
  }, [visible]);

  useEffect(() => {
    if (schoolTeacherTotal > 0) {
      setTmpSchoolTeachers([...tmpSchoolTeachers, ...schoolTeacher]);

      if (
        schoolTeacherCurrentPage * schoolTeacherPageSize <
        schoolTeacherTotal
      ) {
        setSchoolTeacherCurrentPage(schoolTeacherCurrentPage + 1);
        getSchoolTeachers({
          pageNumber: schoolTeacherCurrentPage + 1,
          pageSize: schoolTeacherPageSize
        });
      } else {
        setIsLoadSchoolTeacherComplete(true);
      }
    }
  }, [schoolTeacher]);

  useEffect(() => {
    setSchoolTeachers([...schoolTeachers, ...tmpSchoolTeachers]);
  }, [isLoadSchoolTeacherComplete]);

  useEffect(() => {
    if (isEditing && schoolAdminId) {
      form.setFieldsValue({
        schoolAdminRole: schoolAdmin?.schoolAdminRole.schoolAdminRoleId
      });
      setSelectedSchoolAdminRoleId(
        schoolAdmin?.schoolAdminRole.schoolAdminRoleId || ''
      );
    }
    if (!isEditing && schoolAdminRoles[0] && !isFetchingRoles) {
      form.setFieldsValue({
        schoolAdminRole: schoolAdminRoles[0]?.schoolAdminRoleId
      });
      setSelectedSchoolAdminRoleId(schoolAdminRoles[0]?.schoolAdminRoleId);
    }
  }, [schoolAdminRoles, schoolAdmin]);

  useEffect(() => {
    if (isEditing && schoolAdminId) {
      fetchSchoolAdmin(schoolAdminId);
    }
  }, [isEditing, schoolAdminId]);

  const fetchSchoolAdmin = async (schoolAdminId: string) => {
    try {
      await getSchoolAdmin(schoolAdminId);

      setSelectedSchoolAdminRoleId(
        schoolAdmin?.schoolAdminRole?.schoolAdminRoleId || ''
      );
    } catch (e) {
      message.error('ไม่สามารถโหลดผู้ดูแลระบบได้');
    }
  };

  const handleSubmitForm = async () => {
    const loading = message.loading(
      `กำลัง${!isEditing ? 'เพิ่ม' : 'แก้ไข'}ผู้ดูแลระบบ...`
    );
    try {
      if (!isEditing) {
        const data = {
          userId: selectedSchoolTeacher?.userId,
          schoolAdminRoleId: selectedSchoolAdminRoleId
        } as SchoolAdminCreateBody;
        await createSchoolAdmin({ data });
      } else {
        //TODO: edit school admin
      }
      message.success(`${!isEditing ? 'เพิ่ม' : 'แก้ไข'}ผู้ดูแลระบบสำเร็จ`);
      onClose();
      refetch();
    } catch (error) {
      console.error(error);
      if (isEditing) {
        message.error('แก้ไขผู้ดูแลระบบไม่สำเร็จ');
      } else if (!isEditing) {
        const e = error as any;
        switch (e?.response?.data?.message) {
          case 'this user is already school admin':
            return message.error(
              'เพิ่มผู้ดูแลระบบไม่สำเร็จ เนื่องจากผู้ใช้ท่านนี้เป็นผู้ดูแลระบบอยู่แล้ว'
            );
          case 'this user is admin in other school':
            return message.error(
              'เพิ่มผู้ดูแลระบบไม่สำเร็จ เนื่องจากเป็นแอดมินอยู่ในโรงเรียนอื่น'
            );
          default:
            return message.error('เพิ่มผู้ดูแลระบบไม่สำเร็จ');
        }
      }
    } finally {
      loading();
    }
  };

  return (
    <Spin
      spinning={
        isLoadingSchoolAdmin || getSchoolTeachersLoading || isFetchingRoles
      }
    >
      <Drawer
        placement="right"
        closable={false}
        maskClosable={false}
        visible={visible}
        onClose={onClose}
        destroyOnClose={true}
        width={480}
        css={css`
          .ant-drawer-body {
            padding: 1.25rem;
          }
        `}
      >
        <div
          className="mx-5 mt-4"
          css={css`
            min-height: 669px;
          `}
        >
          <div className="font-bold text-xl mb-12">{title}</div>
          <Form form={form}>
            {isEditing ? (
              <div className="flex w-full items-center mb-8">
                <img
                  alt="profile"
                  src="/assets/profile-colour.png"
                  className="w-8 mr-2"
                />
                <div className="flex items-center whitespace-pre-line text-sm text-font-main w-64 mr-16">
                  {`${schoolAdmin?.title}${schoolAdmin?.firstName.th} ${schoolAdmin?.lastName.th}`}
                </div>
              </div>
            ) : (
              <Form.Item>
                <Select
                  placeholder="ค้นหาครูเพื่อเพิ่มสิทธิ์ผู้ดูแล"
                  showSearch
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(newSelectedTeacherId: string) => {
                    const selectedTeacher = schoolTeachers.find(
                      ({ schoolTeacherId }) =>
                        schoolTeacherId === newSelectedTeacherId
                    );
                    setSelectedSchoolTeacher(selectedTeacher);
                  }}
                >
                  {schoolTeachers.map(
                    ({ schoolTeacherId, title, firstName, lastName }) => (
                      <Option key={schoolTeacherId} value={schoolTeacherId}>
                        {title + firstName?.th + ' ' + lastName?.th}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="บทบาท" name="schoolAdminRole">
              <Select
                value={selectedSchoolAdminRoleId}
                disabled={numberOfSchoolAdmin <= 1}
                onSelect={(schoolAdminRoleId) => {
                  setSelectedSchoolAdminRoleId(schoolAdminRoleId);
                }}
              >
                {schoolAdminRoles?.map(({ schoolAdminRoleId, roleName }) => (
                  <Option key={schoolAdminRoleId} value={schoolAdminRoleId}>
                    {SCHOOL_ADMIN_ROLE_TYPE_MAPPER[roleName]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div
          className="flex mt-20 mr-5 items-center justify-end"
          css={css`
            @media (min-height: 846px) {
              position: absolute;
              right: 20px;
              bottom: 20px;
            }
          `}
        >
          <SecondaryButton className="mx-4" onClick={onClose}>
            ยกเลิก
          </SecondaryButton>
          <PrimaryButton
            htmlType="submit"
            onClick={handleSubmitForm}
            loading={isCreatingSchoolAdmin}
          >
            {isEditing ? 'แก้ไข' : 'เพิ่ม'}
          </PrimaryButton>
        </div>
      </Drawer>
    </Spin>
  );
};
