export const MANAGE_CLASS_PERIOD_ERROR_MESSAGE_MAPPER: {
  [key: string]: string;
} = {
  'main school teacher must be include in class period teacher':
    'กรุณาเลือกครูประจำวิชาหลัก',
  'some class period schedule have overlap with other class period':
    'เวลาเรียนทับซ้อนกับวิชาอื่นในห้องเรียน'
};

export const MANAGE_CLASSROOM_ERROR_MESSAGE_MAPPER: {
  [key: string]: string;
} = {
  'this classroom already has students':
    'ไม่สามารถลบห้องเรียนได้ เนื่องจากมีนักเรียนอยู่ในห้องเรียน'
};
