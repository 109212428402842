import {
  LearningUnitUserListQuery,
  LearningUnitUserListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useLearningUnitUsers = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<LearningUnitUserListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (
    assignmentUnitId: string,
    params?: LearningUnitUserListQuery
  ) => {
    return fire({
      url: `/learning-units/${assignmentUnitId}/users`,
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error
  };
};
