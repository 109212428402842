/** @jsx jsx */
import {
  adminCategory,
  studentCategory,
  teacherCategory
} from './UserGuideData';

import { CategoryCard } from './UserGuideCardStyle';
import React from 'react';
import { SearchBar } from './UserGuideSearchBar';
import { jsx } from '@emotion/react';

const pcSearchSectionStyle = {
  background: `url('/assets/PCUserguide.png') rgba(46, 47, 56, 0.3)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundBlendMode: 'multiply',
  backgroundPosition: 'center'
};

export const UserGuideMain = () => {
  return (
    <>
      <div className="w-full">
        <div
          style={pcSearchSectionStyle}
          className="text-center py-16 h-72 xl:h-80"
        >
          <p className="mt-4 leading-8 text-white font-semibold text-4xl xl:text-5xl xl:mt-6">
            คู่มือการใช้งานระบบ Aksorn On-Learn PLUS
          </p>
          <p className="mt-4 leading-8 text-white font-semibold text-2xl xl:text-3xl xl:mt-8">
            เราสามารถช่วยเหลือคุณได้อย่างไรบ้าง?
          </p>
          <div className="relative mx-6 mt-6 rounded-md items-center shadow-sm mt-4 xl:mt-8">
            <SearchBar />
          </div>
        </div>
        <div className="text-left mx-16 px-4 mt-10 relative text-2xl">
          <p className="font-bold">คู่มือการใช้งานระบบ Aksorn On-Learn PLUS</p>
          <p className="mt-1 leading-8 font-normal text-lg text-gray-60">
            กรุณาเลือกหมวดหมู่ที่ต้องการ
          </p>
        </div>
        <div className=" mx-16 px-4 py-8">
          <div className="grid grid-cols-3 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-3 cursor-pointer">
            <CategoryCard category={teacherCategory} />
            <CategoryCard category={adminCategory} />
            <CategoryCard category={studentCategory} />
          </div>
        </div>
      </div>
    </>
  );
};
