/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { Footer } from './Footer';
import React from 'react';
import classNames from 'classnames';

export const PageWrapper: React.FunctionComponent<{
  className?: string;
}> = ({ children, className = '' }) => {
  return (
    <>
      <div
        className="w-full min-h-full"
        css={css`
          margin-left: 189px;
        `}
      >
        <div
          className={classNames('py-12 pl-16 pr-20', className)}
          css={css`
            min-height: calc(100vh - 64px);
          `}
        >
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};
