import { ReactComponent as NoUserIcon } from 'assets/no-user.svg';
import React from 'react';
import classNames from 'classnames';

interface EmptyTableProps {
  isLoading?: boolean;
  icon?: JSX.Element;
  emptyText?: string;
  emptyClassName?: string;
  style?: any;
}

export const EmptyTable = ({
  isLoading,
  icon,
  emptyText,
  emptyClassName,
  style
}: EmptyTableProps) => {
  if (isLoading) return null;
  return (
    <div
      className={classNames({
        'flex items-center cursor-default flex-col justify-center': !isLoading
      })}
      style={style}
    >
      {icon ?? <NoUserIcon style={{ fontSize: '80px' }} />}
      <span
        className={`font-bold mt-4 text-base text-center ${emptyClassName}`}
      >
        {emptyText ?? 'ไม่มีนักเรียนในห้องเรียน'}
      </span>
    </div>
  );
};
