export enum ValidationStep {
  SCHOOL_TEACHER = 0,
  CLASSROOM = 1,
  CLASS_PERIOD = 2,
  SCHOOL_STUDENT = 3
}

export enum ValidationStatus {
  WAITING = 'wait',
  PROCESSING = 'process',
  FINISHED = 'finish',
  ERROR = 'error'
}

export enum ImportExcelSheetNameAlias {
  CLASSROOM_TEACHER_SHEET = 'classroomTeacher',
  CLASSROOM_SHEET = 'classroom',
  CLASSROOM_STUDENT_SHEET = 'classroomStudent',
  CLASS_PERIOD_TEACHER_SHEET = 'classPeriodTeacher',
  CLASS_PERIOD_SCHEDULE_SHEET = 'classPeriodSchedule'
}

export enum ValidateExcelResponseMessage {
  INSUFFICIENT_LICENSES = 'insufficient licenses',
  SCHOOL_TEACHER_NOT_FOUND = 'schoolTeacher not found',
  CLASSROOM_NOT_FOUND = 'classroom not found'
}

export enum ParseExcelResponseMessage {
  MISSING_WORK_SHEET = 'missing work sheet'
}

export enum ValidateExcelErrorResultType {
  SCHOOL_TEACHERS = 'schoolTeachers',
  CLASSROOM = 'classroom',
  CLASS_PERIODS = 'classPeriods',
  SCHOOL_STUDENTS = 'schoolStudents'
}

export enum ImportExcelErrorFieldType {
  EMAIL = 'email',
  FIRSTNAME_TH = 'firstName.th',
  FIRSTNAME_EN = 'firstName.en',
  LASTNAME_TH = 'lastName.th',
  LASTNAME_EN = 'firstName.en',
  IDENTITY_DOCUMENT_TYPE = 'identityDocument.type',
  IDENTITY_DOCUMENT_NUMBER = 'identityDocument.number',
  CITIZEN_ID = 'citizenId',
  PASSPORT_NUMBER = 'passportNumber',
  TITLE = 'title',
  GENDER = 'gender',
  DATE_OF_BIRTH = 'dateOfBirth',
  SCHOOL_YEAR = 'schoolYear',
  GRADE_LEVEL = 'gradeLevel',
  CLASSROOM_NAME = 'classroomName',
  STATUS = 'status',
  SUBJECT = 'subject',
  DAY_OF_WEEK = 'dayOfWeek',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  STUDENT_CODE = 'studentCode',
  CLASSROOM_STUDENT_NUMBER = 'classroomStudentNumber'
}

export enum ImportExcelErrorMessage {
  SUBJECT_INVALID = 'invalid subject',
  SUBJECT_NOT_FOUND = 'subject not found',
  SUBJECT_CONFLICTED = 'subject not exists in class period teacher sheet',
  DAY_OF_WEEK_INVALID = 'invalid day of week',
  START_TIME_INVALID = 'invalid start time',
  END_TIME_INVALID = 'invalid end time',
  END_TIME_CONFLICTED = 'end time must be greater than start time',
  SCHEDULE_OVERLAP = 'schedule is overlaped',

  SCHOOL_YEAR_INVALID = 'invalid school year',
  SCHOOL_YEAR_NOT_FOUND = 'school year not found',
  GRADE_LEVEL_INVALID = 'invalid grade level',
  GRADE_LEVEL_NOT_FOUND = 'grade level not found',
  CLASSROOM_NAME_INVALID = 'invalid classroom name',

  EMAIL_INVALID = 'invlaid email',
  EMAIL_DUPLICATE = 'email is duplicated',
  TITLE_INVALID = 'invalid title',
  FIRSTNAME_TH_INVALID = 'invalid firstname th',
  LASTNAME_TH_INVALID = 'invalid lastname th',
  FIRSTNAME_EN_INVALID = 'invalid firstname en',
  LASTNAME_EN_INVALID = 'invalid lastname en',
  CITIZEN_ID_INVALID = 'invalid citizen id',
  PASSPORT_NUMBER_INVALID = 'invalid passport number',
  IDENTITY_DOCUMENT_REQUIRE = 'either citizen id or passport number is required',
  GENDER_INVALID = 'invalid gender',
  DATE_OF_BIRTH_INVALID = 'invalid date of birth',
  TEACHER_STATUS_INVALID = 'invalid teacher status',
  TEACHER_PROFILE_CONFLICT = 'teacher profiles are conflicted',

  CLASSROOM_EMPTY = 'import excel require one classroom',
  CLASSROOM_MORE_THAN_ONE = 'import excel must have only one classroom',
  CLASSROOM_MAIN_TEACHER_NOT_IN_TEACHER_LIST = 'main classroom teacher must be one of classroom teachers',
  CLASSROOM_MAIN_TEACHER_EMPTY = 'classroom require one main classroom teacher',
  CLASSROOM_MAIN_TEACHER_MULTIPLE = 'can not have multiple classroom main teacher',
  CLASSROOM_TEACHER_EMPTY = 'require one classroom teachers',

  CLASS_PERIOD_MAIN_TEACHER_NOT_IN_TEACHER_LIST = 'main class period teacher must be one of class period teachers',
  CLASS_PERIOD_MAIN_TEACHER_EMPTY = 'class period require one main class period teacher',
  CLASS_PERIOD_MAIN_TEACHER_MULTIPLE = 'can not have multiple class period teacher',
  CLASS_PERIOD_TEACHER_EMPTY = 'require one class period teacher',

  CLASSROOM_STUDENT_NUMBER_INVALID = 'invalid classroom student number',
  STUDENT_CODE_INVALID = 'invalid student code',

  ACCOUNT_NOT_TEACHER = 'this account is not teacher',
  ACCOUNT_NOT_STUDENT = 'this account is not student',

  ACCOUNT_ALREADY_IN_OHTER_SCHOOL = 'this account is already in other school',
  ACCOUNT_HAS_PENDING_TEACHER_REQUEST = 'this account is pending school teacher request',
  TEACHER_PRIVATE_NOT_SHARE_ANYONE = 'private teachers cannot be shared with anyone else in the school'
}
