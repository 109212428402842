/** @jsx jsx */

import { Divider, Form, Select, Skeleton, Space } from 'antd';
import { Global, css, jsx } from '@emotion/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { CardSkeleton } from 'feature/common/components/CardSkeleton';
import { MediaCardList } from 'feature/media-management/MediaCardList';
import { MediaManagementFilterBar } from 'feature/media-management/MediaManagementFilterBar';
import { MediaType } from 'feature/common/media';
import { MediaTypeFilterButtonList } from 'feature/media-management/MediaTypeFilterButtonList';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { PrimaryButton } from 'feature/common/components/Button';
import { getProductLicenseUsages } from 'api/media/getProductLicenseUsages';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';

const { Option } = Select;

const SELECT_ALL = 'all';

interface stateType {
  data: {
    pageNumber: number;
    selectedSchoolYearId: string;
    mediaTypeFilter: MediaType | string;
    keyword: string;
    productPrivilegeType: string;
    gradeLevelIds: string[];
    subjectIds: string[];
    sortBy: string;
  };
}

export const MediaManagementPage = () => {
  const history = useHistory();
  const { state } = useLocation<stateType>();

  const [isAlreadyInit, setIsAlreadyInit] = useState<boolean>(false);

  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<string>('');

  const [mediaTypeFilter, setMediaTypeFilter] = useState<MediaType | string>(
    'all'
  );

  const [keyword, setKeyword] = useState<string>('');
  const [productPrivilegeType, setProductPrivilegeType] = useState<string>('');
  const [gradeLevelIds, setGradeLevelIds] = useState<string[]>([SELECT_ALL]);
  const [subjectIds, setSubjectIds] = useState<string[]>([SELECT_ALL]);
  const [sortBy, setSortBy] = useState<string>('aolBookSeqAsc');
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [mediaList, setMediaList] = useState<any>([]);
  const [mediaListLoading, setMediaListLoading] = useState<boolean>(true);

  const [
    isLoadMoreButtonVisible,
    setisLoadMoreButtonVisible
  ] = useState<boolean>(false);

  const { data: schoolYears, loading: isSchoolYearLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();

  const schoolYearId = useMemo(() => {
    if (schoolYears?.length > 0 && !isSchoolYearLoading && currentSchoolYears) {
      return currentSchoolYears.schoolYearId;
    }
  }, [isSchoolYearLoading, currentSchoolYears]);

  const mergeStringInArray = (someArray: string[]) => {
    let arrayString = '';
    for (let i = 0; i < someArray.length; i++) {
      if (i > 0) {
        arrayString = arrayString.concat(',');
      }
      arrayString = arrayString.concat(someArray[i]);
    }
    return arrayString;
  };

  const setNewParamsBeforeFetchData = (data: any) => {
    const {
      pageNumber,
      selectedSchoolYearId,
      mediaTypeFilter,
      keyword,
      productPrivilegeType,
      gradeLevelIds,
      subjectIds,
      sortBy
    } = data;
    let newParams: any = {
      schoolYearId: selectedSchoolYearId,
      pageNumber: pageNumber,
      pageSize: 12
    };

    if (mediaTypeFilter !== 'all') {
      newParams['bookType'] = mediaTypeFilter;
    }
    if (keyword !== '') {
      newParams['keyword'] = keyword;
    }
    if (productPrivilegeType !== '') {
      newParams['productPrivilegeType'] = productPrivilegeType;
    }
    if (!!gradeLevelIds.length && gradeLevelIds[0] !== 'all') {
      newParams['gradeLevelIds'] = mergeStringInArray(gradeLevelIds);
    }
    if (!!subjectIds.length && subjectIds[0] !== 'all') {
      newParams['subjectIds'] = mergeStringInArray(subjectIds);
    }
    if (sortBy !== '') {
      newParams['sortBy'] = sortBy;
    }

    history.push({
      pathname: '/media-management',
      state: { data }
    });

    setCurrentPage(pageNumber);
    fetchData(newParams);
  };

  const fetchData = async (params: any) => {
    setMediaListLoading(true);
    try {
      const {
        data: mediaListData,
        meta: mediaListMeta
      } = await getProductLicenseUsages(params);

      setisLoadMoreButtonVisible(
        !!(mediaListMeta?.pagination?.total > currentPage * 12)
      );
      if (params.pageNumber === 1) {
        setMediaList(mediaListData);
        // console.log('mediaListData1', mediaListData);
      } else {
        setMediaList(mediaList.concat(mediaListData));
        // console.log('mediaListData2', mediaList.concat(mediaListData));
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setMediaListLoading(false);
    }
  };

  useEffect(() => {
    if (schoolYearId) {
      setSelectedSchoolYearId(schoolYearId);
    }
  }, [schoolYearId]);

  useEffect(() => {
    // console.log('isAlreadyInit', isAlreadyInit);
    if (!!selectedSchoolYearId) {
      /* console.log('useEffect', {
        selectedSchoolYearId: selectedSchoolYearId,
        mediaTypeFilter: mediaTypeFilter,
        keyword: keyword,
        productPrivilegeType: productPrivilegeType,
        gradeLevelIds: gradeLevelIds,
        subjectIds: subjectIds,
        sortBy: sortBy
      }); */
      // console.log('state', state);
      setMediaList([]);
      const data = state?.data;
      if (!!data && !isAlreadyInit) {
        // console.log('init with useLocation');
        setSelectedSchoolYearId(data.selectedSchoolYearId || '');
        setMediaTypeFilter(data.mediaTypeFilter || '');
        setKeyword(data.keyword || '');
        setProductPrivilegeType(data.productPrivilegeType || '');
        setGradeLevelIds(data.gradeLevelIds || [SELECT_ALL]);
        setSubjectIds(data.subjectIds || [SELECT_ALL]);
        setSortBy(data.sortBy || 'aolBookSeqAsc');
      } else {
        // console.log('init with useState');
        setNewParamsBeforeFetchData({
          pageNumber: 1,
          selectedSchoolYearId: selectedSchoolYearId,
          mediaTypeFilter: mediaTypeFilter,
          keyword: keyword,
          productPrivilegeType: productPrivilegeType,
          gradeLevelIds: gradeLevelIds,
          subjectIds: subjectIds,
          sortBy: sortBy
        });
      }
      setIsAlreadyInit(true);
    }
  }, [
    selectedSchoolYearId,
    mediaTypeFilter,
    keyword,
    productPrivilegeType,
    gradeLevelIds,
    subjectIds,
    sortBy
  ]);

  useEffect(() => {
    if (!!selectedSchoolYearId && currentPage > 1) {
      setNewParamsBeforeFetchData({
        pageNumber: currentPage,
        selectedSchoolYearId: selectedSchoolYearId,
        mediaTypeFilter: mediaTypeFilter,
        keyword: keyword,
        productPrivilegeType: productPrivilegeType,
        gradeLevelIds: gradeLevelIds,
        subjectIds: subjectIds,
        sortBy: sortBy
      });
    }
  }, [currentPage]);

  return (
    <PageWrapper className="flex flex-col">
      <Global
        styles={css`
          .ant-tree-select > ::-webkit-scrollbar {
            display: none;
          }
          .ant-select-selection-overflow {
            align-items: center;
            flex-wrap: nowrap !important;
          }
          .ant-select-selector {
            overflow-x: auto;
            overflow-y: hidden;
          }
          .ant-select-selection-overflow {
            flex-wrap: nowrap;
          }
          .ant-select-clear {
            margin-top: -8px;
          }
        `}
      />
      <div className="flex justify-between mb-6">
        <h3 className="text-xl font-bold">รายการสื่อในระบบ</h3>
        <Form.Item label="ปีการศึกษา" className="w-48 mb-0">
          <Select
            value={selectedSchoolYearId}
            onSelect={(schoolYearId) => {
              setSelectedSchoolYearId(schoolYearId);
              setCurrentPage(1);
            }}
            className="w-48"
          >
            {schoolYears?.map(({ name, schoolYearId }) => (
              <Option
                key={schoolYearId}
                value={schoolYearId}
                // disabled={parseInt(name) - 543 !== moment().year()}
                disabled={schoolYearId !== selectedSchoolYearId}
              >
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <Divider />
      <MediaTypeFilterButtonList
        mediaTypeFilter={mediaTypeFilter}
        setMediaTypeFilter={setMediaTypeFilter}
      />
      <MediaManagementFilterBar
        schoolYearId={schoolYearId as string}
        setKeyword={setKeyword}
        productPrivilegeType={productPrivilegeType}
        setProductPrivilegeType={setProductPrivilegeType}
        gradeLevelIds={gradeLevelIds}
        setGradeLevelIds={setGradeLevelIds}
        subjectIds={subjectIds}
        setSubjectIds={setSubjectIds}
        sortBy={sortBy}
        setSortBy={setSortBy}
        setCurrentPage={setCurrentPage}
      />
      <MediaCardList
        mediaList={mediaList}
        mediaListLoading={mediaListLoading}
        schoolYearId={schoolYearId as string}
      />
      <CardSkeleton
        prefix="media-card-skeleton"
        className={`${
          mediaListLoading ? 'flex' : 'hidden'
        } flex-wrap -mt-4 -ml-6`}
        cardStyle={css`
          border-radius: 20px;
          width: 244px;
          height: 340px;
          margin: 16px 24px;
        `}
      >
        <Skeleton paragraph={{ rows: 3 }} active />
        <Space className="mt-16 w-full">
          <Skeleton.Input
            css={css`
              .ant-skeleton-input {
                width: 90px !important;
                border-radius: 8px;
                height: 24px !important;
              }
            `}
            active
          />
          <Skeleton.Input
            css={css`
              .ant-skeleton-input {
                width: 90px !important;
                border-radius: 8px;
                height: 24px !important;
              }
            `}
            active
          />
        </Space>
      </CardSkeleton>
      <Divider className="mt-10 mb-8" />
      {isLoadMoreButtonVisible ? (
        <div className="flex justify-center items-center py-5 md:py-0 md:pb-3">
          <PrimaryButton
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            โหลดเพิ่ม
          </PrimaryButton>
        </div>
      ) : (
        <></>
      )}
    </PageWrapper>
  );
};
