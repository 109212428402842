import {
  SchoolStudentModifyResponse,
  SchoolStudentUpdateBody
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useUpdateSchoolStudent = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolStudentModifyResponse>(
    {
      method: 'PUT'
    },
    { manual: true }
  );

  const execute = ({
    schoolStudentId,
    data
  }: {
    schoolStudentId: string;
    data: SchoolStudentUpdateBody;
  }) => {
    return fire({
      url: `/school-students/${schoolStudentId}`,
      data
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
