import { ClassroomForm } from './ClassroomForm';
import { Drawer } from 'antd';
import React from 'react';
import { css } from '@emotion/react';

interface ClassRoomDrawerProps {
  title: string;
  visible: boolean;
  classroomId?: string;
  onClose: () => void;
  onSubmit: () => void;
  // // unlock mainSchoolTeacher 19/08/2022
  mainSchoolTeacher?: string;
}

export const ClassRoomDrawer = ({
  title,
  visible,
  classroomId,
  onClose,
  onSubmit,
  // // unlock mainSchoolTeacher 19/08/2022
  mainSchoolTeacher
}: ClassRoomDrawerProps) => {
  return (
    <Drawer
      placement="right"
      closable={false}
      destroyOnClose={true}
      visible={visible}
      width={480}
      onClose={onClose}
    >
      <div className="font-bold text-xl mb-4">{title}</div>
      <img
        className="w-full object-cover mt-14"
        src="/assets/classroom-default.png"
        alt="classroom cover"
        css={css`
          height: 264px;
        `}
      />
      <div className="mt-8">
        <ClassroomForm
          onSubmit={onSubmit}
          classroomId={classroomId}
          onClose={onClose}
          // // unlock mainSchoolTeacher 19/08/2022
          mainSchoolTeacher={mainSchoolTeacher}
        />
      </div>
    </Drawer>
  );
};
