/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Spin } from 'antd';

export const TableSkeleton = () => {
  return (
    <React.Fragment>
      <div
        className="w-full bg-secondary-gray-10"
        css={css`
          border-radius: 25px 25px 0px 0px;
          height: 51px;
        `}
      ></div>
      <div
        className="flex items-center justify-center w-full border-solid border-2 border-secondary-gray-10"
        css={css`
          border-radius: 0px 0px 25px 25px;
          height: 531px;
        `}
      >
        <Spin
          indicator={
            <LoadingOutlined spin style={{ fontSize: 36, color: '#8F91A3' }} />
          }
        />
      </div>
    </React.Fragment>
  );
};
