import {
  SchoolTeacherGenTempUserResponse,
  SchoolTeacherGenTempUsers,
  SchoolTeacherListQuery,
  SchoolTeacherListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useSchoolTeachers = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolTeacherListResponse>(
    {
      url: `/school-teachers`
    },
    { manual: true }
  );

  const execute = (params?: SchoolTeacherListQuery) => {
    return fire({
      url: `/school-teachers`,
      params: {
        pageNumber: params?.pageNumber ?? 1,
        pageSize: params?.pageSize ?? 20,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: response?.data || [],
    loading,
    error,
    execute,
    total: response?.meta?.pagination?.total || 0
  };
};

export const useSchoolTeachersType = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolTeacherListResponse>(
    {
      url: `/school-teachers/teachertype`
    },
    { manual: true }
  );

  const execute = (params?: SchoolTeacherListQuery) => {
    return fire({
      url: `/school-teachers/teachertype`,
      params: {
        pageNumber: 1,
        pageSize: params?.pageSize ?? 20,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: response?.data || [],
    loading,
    error,
    execute,
    total: response?.meta?.pagination?.total || 0
  };
};


export const useSchoolTeachersGenTempUsers = ()=>{
const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolTeacherGenTempUserResponse>(
    {
       method: 'POST'
    },
    { manual: true }
  );

  const execute = ({ data }: { data: SchoolTeacherGenTempUsers}) => {
    console.log(data)
    return fire({
      url: `/school-teachers/genTempUsers`,
      data
    });
  };

  return {
    data: response?.data || [],
    loading,
    error,
    execute,
  };
};
