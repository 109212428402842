import {
  ClassroomStudentGenTempUserBody,
  ClassroomStudentGenTempUserResponse,
  ClassroomStudentGetResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useClassroomStudent = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<ClassroomStudentGetResponse>({}, { manual: true });

  const execute = (classroomStudentId: string) => {
    return fire({ url: `/classroom-students/${classroomStudentId}` });
  };

  return {
    data: data?.data,
    loading: loading,
    error: error,
    execute
  };
};

export const useGenClassroomStudentWithTempUser = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<ClassroomStudentGenTempUserResponse>(
    {
      method: 'POST',
      timeout: 600000
    },
    { manual: true }
  );
  const execute = ({ data }: { data: ClassroomStudentGenTempUserBody }) => {
    return fire({
      url: '/classroom-students/gen-temp-user-bulk',
      data
    });
  };

  return {
    data: response?.data,
    loading: loading,
    error,
    execute
  };
};
