import { Divider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AssignmentOverallClassPeriodDetailContent } from 'feature/assignment-overall/class-period-detail/AssignmentOverallClassPeriodDetailContent';
import { AssignmentOverallClassPeriodDetailMenuTab } from 'feature/assignment-overall/class-period-detail/AssignmentOverallClassPeriodDetailMenuTab';
import { AssignmentOverallDetailMenu } from 'feature/assignment-overall/assignment-overall';
import { ReactComponent as BackIcon } from 'assets/back-icon.svg';
import { ReactComponent as HomeworkIcon } from 'assets/homework.svg';
import { Modal } from 'feature/common/components/Modal';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { classPeriodLicenseStore } from 'stores/classPeriodLicenseStore';
import { classPeriodStore } from 'stores/classroomStore';
import { css } from '@emotion/react';
import { useClassPeriod } from 'hooks/class-period/useClassPeriod';
import { useClassPeriodLicense } from 'hooks/class-period-license/useClassPeriodLicense';
import { useClassroom } from 'hooks/classroom/useClassroom';

// import { ClassPeriodLicenseList } from 'feature/classroom-management/ClassPeriodLicenseList';

export const AssignmentOverallClassPeriodDetailPage = () => {
  const [menu, setMenu] = useState<AssignmentOverallDetailMenu>(
    AssignmentOverallDetailMenu.ASSIGNMENT_OVERVIEW
  );

  const history = useHistory();
  const params = useParams();

  const { setClassPeriod } = classPeriodStore;
  const { setClassPeriodLicense } = classPeriodLicenseStore;

  const {
    data: classroom,
    loading: classroomLoading,
    execute: getClassroom
  } = useClassroom();

  const {
    data: classPeriod,
    loading: getClassPeriodLoading,
    execute: getClassPeriod
  } = useClassPeriod();

  const {
    data: classPeriodLicense,
    loading: getClassPeriodLicenseLoading,
    execute: getClassPeriodLicenseByClassPeriodId
  } = useClassPeriodLicense();

  const { classroomId } = params as any;
  const { classPeriodId } = params as any;
  const { classPeriodLicenseId } = params as any;

  const loading =
    classroomLoading || getClassPeriodLoading || getClassPeriodLicenseLoading;

  const [disableMenu, setDisableMenu] = useState(false);
  const [openLicenseWarningModal, setOpenLicenseWarningModal] = useState(false);

  useEffect(() => {
    // console.log("step1");
    if (classroomId) {
      getClassroom(classroomId);
      getClassPeriod(classPeriodId);
    }
  }, [classroomId]);

  useEffect(() => {
    // console.log("step2");
    if (classPeriod) {
      setClassPeriod(classPeriod);
      // console.log("step3",classPeriod);
      fetchClassPeriodLicensePremium();
    }
  }, [classPeriod]);

  const fetchClassPeriodLicensePremium = async () => {
    try {
      // console.log("step4",classPeriodId);
      const {
        data: classPeriodLicensePremium
      } = await getClassPeriodLicenseByClassPeriodId(classPeriodId);
      // console.log("step5", classPeriodLicensePremium?.data?.classPeriodLicenseId);
      if (classPeriodLicensePremium?.data?.classPeriodLicenseId !== undefined) {
        // console.log("have license");
        setDisableMenu(true);
        setOpenLicenseWarningModal(false);
      } else {
        // console.log("no license");
        setDisableMenu(false);
        setOpenLicenseWarningModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWrapper className="bg-secondary-white">
      <Spin spinning={loading}>
        <div
          className="flex w-full cursor-pointer items-center mb-10"
          css={css`
            color: #1a1a1a;
          `}
          onClick={() => history.push(`/assignment-overall/${classroomId}`)}
        >
          <BackIcon
            css={css`
              width: 19.2px;
              height: 16.8px;
            `}
          />
          {classroom?.gradeLevel.name.th && classroom?.classroomName && (
            <div className="mx-2 font-bold text-xl">{`คาบวิชา ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}</div>
          )}
        </div>
        {/* {classroom?.gradeLevel.name.th && classroom?.classroomName && (
          <div className="flex items-center my-4 font-bold text-2xl text-secondary-gray-60">
            {`${classPeriod?.classPeriodName ?? ''} ${
              classroom?.gradeLevel.name.th
            }/${classroom?.classroomName}`}
          </div>
        )}
        <Divider /> */}
        {/* <AssignmentOverallClassPeriodDetailMenuTab
          menu={menu}
          setMenu={setMenu}
        /> */}
        {/* <AssignmentOverallClassPeriodDetailContent menu={menu} /> */}
        {disableMenu ? (
          <>
            {classroom?.gradeLevel.name.th && classroom?.classroomName && (
              <div className="flex items-center my-4 font-bold text-2xl text-secondary-gray-60">
                {`${classPeriod?.classPeriodName ?? ''} ${
                  classroom?.gradeLevel.name.th
                }/${classroom?.classroomName}`}
              </div>
            )}
            <Divider />
            <AssignmentOverallClassPeriodDetailMenuTab
              menu={menu}
              setMenu={setMenu}
            />
            <AssignmentOverallClassPeriodDetailContent menu={menu} />
          </>
        ) : (
          <>
            <div
              className="flex flex-col justify-center items-center w-full h-full mt-40"
              css={css`
                min-height: 576px;
                max-height: 576px;
              `}
            >
              <HomeworkIcon />
              <span className="text-gray-40 mt-8 font-bold">
                ข้อมูลภาพรวมการส่งงานเฉพาะผู้ที่ได้รับสิทธิ์เข้าใช้เท่านั้น
              </span>
            </div>
          </>
        )}
      </Spin>

      <Modal
        visible={openLicenseWarningModal}
        title="เมนูนี้เฉพาะผู้ที่ได้รับสิทธิ์เข้าใช้เท่านั้น"
        subTitle="สอบถามหรือสั่งซื้อได้ที่ อจท. 0-2622-2999"
        width={430}
        submit={{
          onClick: () => setOpenLicenseWarningModal(false),
          label: 'ปิด'
        }}
      />
    </PageWrapper>
  );
};
