import {
  GradeLevelDto,
  GradeLevelListResponse,
  PaginationQuery
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useGradeLevels = (params?: PaginationQuery) => {
  const [{ data, loading, error }] = useAxios<GradeLevelListResponse>({
    url: '/grade-levels',
    params: {
      pageNumber: 1,
      // TODO: ทำ loadmore
      pageSize: 100,
      ...pickBy(params, identity)
    }
  });

  return {
    data: data?.data as GradeLevelDto[],
    loading: loading,
    error: error
  };
};
