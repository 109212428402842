import { Divider, Form, Select, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

import { EvaluationContent } from 'feature/evaluation/EvaluationContent';
import { EvaluationFilterBar } from 'feature/evaluation/EvaluationFilterBar';
import { EvaluationMenu } from 'feature/evaluation/evaluation';
import { EvaluationMenuTab } from 'feature/evaluation/EvaluationMenuTab';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useLocation } from 'react-router-dom';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';
import moment from 'moment';

const { Option } = Select;

export const EvaluationPage = observer(() => {
  const [menu, setMenu] = useState<EvaluationMenu>(
    EvaluationMenu.ASSIGNMENT_SCORE
  );
  const { data: schoolYears, loading: schoolYearsLoading } = useSchoolYears();
  const { data: currentSchoolYear } = useCurrentSchoolYear();
  const { state } = useLocation();

  const { filterData, setSelectedSchoolYearId } = evaluationStore;

  const currentMenu = ((state as any)?.menu ||
    EvaluationMenu.ASSIGNMENT_SCORE) as EvaluationMenu;

  useEffect(() => {
    setMenu(currentMenu);
  }, [currentMenu]);

  useEffect(() => {
    if (!schoolYearsLoading && currentSchoolYear) {
      setSelectedSchoolYearId(currentSchoolYear.schoolYearId);
    }
  }, [schoolYearsLoading, currentSchoolYear]);

  return (
    <PageWrapper className="bg-secondary-white">
      <div className="flex justify-between h-10">
        <div className="flex items-center h-8 font-bold text-2xl text-secondary-black">
          การประเมินผล
        </div>
        <div className="flex">
          {schoolYearsLoading ? (
            <div className="flex w-full justify-end mb-5">
              <Skeleton.Input className="mr-0" active />
            </div>
          ) : (
            <Form.Item label="ปีการศึกษา" className="w-48">
              <Select
                value={filterData.selectedSchoolYearId}
                onSelect={(schoolYearId) => {
                  setSelectedSchoolYearId(schoolYearId);
                }}
              >
                {schoolYears.map(({ name, schoolYearId }) => (
                  <Option
                    key={schoolYearId}
                    value={schoolYearId}
                    // disabled={parseInt(name) - 543 !== moment().year()}
                    disabled={schoolYearId !== filterData.selectedSchoolYearId}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
      </div>
      <Divider className="my-8" />
      <EvaluationFilterBar />
      <EvaluationMenuTab menu={menu} setMenu={setMenu} />
      <EvaluationContent menu={menu} />
    </PageWrapper>
  );
});
