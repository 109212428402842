import React from 'react';

export const OverallStatusLabel = () => {
  return (
    <>
      <div className="w-5 h-5 bg-aksorn-orange mr-2" />
      คะแนนต่ำกว่า 50%
    </>
  );
};
