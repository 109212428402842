/** @jsx jsx */

import { Avatar, Button, Pagination, Table, message } from 'antd';
import {
  ClassroomDto,
  ClassroomTeacherDto
} from '@aksorn/teaching-backoffice-sdk';
import { Fragment, useEffect } from 'react';
import { Global, css, jsx } from '@emotion/react';

import { ClassRoomDrawer } from 'feature/classroom-management/ClassroomDrawer';
import { ClassroomListSortBy } from 'feature/classroom-management/classroom-management';
import { ClassroomManagementForm } from 'feature/classroom-management/ClassroomManagementForm';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { MANAGE_CLASSROOM_ERROR_MESSAGE_MAPPER } from 'feature/classroom-management/const';
import { Modal } from 'feature/common/components/Modal';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { SortOrder } from 'feature/common/global';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useClassrooms } from 'hooks/classroom/useClassrooms';
import { useDeleteClassroom } from 'hooks/classroom/useDeleteClassroom';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export const ClassroomManagementPage = () => {
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState<string>('');
  const [gradeLevelIds, setGradeLevelIds] = useState<string[]>([]);
  const [classroomNames, setClassroomNames] = useState<string[]>([]);
  const [showAddClassroomDrawer, setShowAddClassroomDrawer] = useState<boolean>(
    false
  );
  const [teacherName, setTeacherName] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<ClassroomListSortBy>(
    ClassroomListSortBy.UPDATED_AT_DESC
  );
  const [
    selectedClassroom,
    setSelectedClassroom
  ] = useState<ClassroomDto | null>(null);
  const [isDeletingModalVisible, setIsDeletingModalVisible] = useState(false);

  const {
    data: classrooms,
    loading,
    total,
    execute: getClassrooms
  } = useClassrooms();

  const {
    loading: isDeletingClassroom,
    execute: deleteClassroom
  } = useDeleteClassroom();

  const history = useHistory();

  useEffect(() => {
    fetchingClassrooms();
  }, [
    selectedSchoolYearId,
    gradeLevelIds,
    classroomNames,
    teacherName,
    currentPage,
    sortBy
  ]);

  const fetchingClassrooms = async () => {
    if (
      !selectedSchoolYearId ||
      !gradeLevelIds.length ||
      !classroomNames.length
    ) {
      return;
    }

    const query = {
      schoolYearId: selectedSchoolYearId,
      teacherName,
      sortBy: sortBy as any,
      pageNumber: currentPage
    };

    if (gradeLevelIds[0] !== 'all') {
      Object.assign(query, { gradeLevelIds: gradeLevelIds?.join(',') });
    }

    if (classroomNames[0] !== 'all') {
      Object.assign(query, { classroomNames: classroomNames?.join(',') });
    }

    await getClassrooms(query);
  };

  const columns = [
    {
      title: 'ห้อง',
      dataIndex: 'classroomName',
      key: 'classroomName',
      render: (classroomName: string, record: ClassroomDto) => {
        return (
          <Fragment>
            {record?.gradeLevel?.name?.th + '/' + classroomName}
          </Fragment>
        );
      },
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.CLASSROOM_NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.CLASSROOM_NAME_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: 'จำนวนนักเรียน',
      dataIndex: 'numberOfClassroomStudent',
      key: 'numberOfClassroomStudent',
      align: 'right',

      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.NUMBER_OF_STUDENT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.NUMBER_OF_STUDENT_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: 'ครูประจำชั้น (หลัก)',
      dataIndex: 'mainSchoolTeacher',
      key: 'mainSchoolTeacher',
      render: (mainSchoolTeacher: ClassroomTeacherDto) => {
        return (
          <div className="flex justify-start items-center">
            <Avatar icon={<UserOutlined />} />
            <div className="ml-2">
              {mainSchoolTeacher?.firstName?.th
                ? mainSchoolTeacher?.firstName?.th
                : '' + ' ' + mainSchoolTeacher?.lastName?.th
                ? mainSchoolTeacher?.lastName?.th
                : ''}
              {/* unlock mainSchoolTeacher 19/08/2022 */}
              {/* {console.log("mainSchoolTeacher.deletedAt",mainSchoolTeacher?.deletedAt)} */}
              {/* {mainSchoolTeacher?.deletedAt ? (
                <span style={{color:"red",fontWeight:"bold"}}>{' (ลาออกแล้ว)'}</span>
              ) : null} */}
              {mainSchoolTeacher?.deletedAt ? (
                <span>{' (ย้ายโรงเรียน)'}</span>
              ) : null}
            </div>
          </div>
        );
      },
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.TEACHER_NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.TEACHER_NAME_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: 'แก้ไขวันที่',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      render: (updatedAt: string) => {
        return (
          <div>{dayjs(updatedAt).add(543, 'year').format('DD/MM/YY')}</div>
        );
      },
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.UPDATED_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.UPDATED_AT_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: '',
      width: '10%',
      render: (record: ClassroomDto) => (
        <div className="flex items-center justify-end">
          <DeleteIcon
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedClassroom(record);
              setIsDeletingModalVisible(true);
            }}
          />
        </div>
      )
    }
  ];

  return (
    <PageWrapper className="bg-secondary-white">
      <Global
        styles={css`
          thead.ant-table-thead > tr > th {
            background-color: #fff !important;
            font-weight: 700 !important;
          }
          .ant-table-container table > thead > tr:first-child th:first-child {
            border-top-left-radius: 1rem;
          }
          .ant-table-container table > thead > tr:first-child th:last-child {
            border-top-right-radius: 1rem;
          }
          .ant-table {
            border-radius: 1rem !important;
          }
          .ant-table-tbody > tr:last-child > td {
            border-bottom: none !important;
          }
          .ant-tree-select > ::-webkit-scrollbar {
            display: none;
          }
          .ant-select-selection-overflow {
            align-items: center;
            flex-wrap: nowrap !important;
          }
          tr.ant-table-row-level-0 {
            cursor: pointer !important;
          }
        `}
      />
      <ClassroomManagementForm
        selectedSchoolYearId={selectedSchoolYearId}
        gradeLevelIds={gradeLevelIds}
        classroomNames={classroomNames}
        setSelectedSchoolYearId={setSelectedSchoolYearId}
        setGradeLevelIds={setGradeLevelIds}
        setClassroomNames={setClassroomNames}
        setTeacherName={setTeacherName}
      />
      <Button
        className="flex justify-end rounded-lg border-none duration-500 ease-in-out mr-0 ml-auto my-9 font-semibold"
        css={css`
          background-color: #fc9d10;
          width: 60px !important;
          color: white;
          :hover {
            background-color: #fcbd10;
            color: white !important;
          }
        `}
        onClick={() => {
          setShowAddClassroomDrawer(true);
        }}
      >
        + เพิ่ม
      </Button>

      <Table
        rowKey={'classroomId'}
        dataSource={classrooms as any}
        columns={columns as any}
        pagination={false}
        loading={loading}
        onRow={(record: ClassroomDto) => {
          return {
            onClick: () => {
              history.push(`/classroom-management/${record?.classroomId}`);
            }
          };
        }}
      />
      {total > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          defaultPageSize={20}
          total={total}
          onChange={(page) => setCurrentPage(page)}
        />
      )}

      <ClassRoomDrawer
        title="เพิ่มห้องเรียน"
        onClose={() => setShowAddClassroomDrawer(false)}
        visible={showAddClassroomDrawer}
        onSubmit={() => {
          setSortBy(ClassroomListSortBy.UPDATED_AT_DESC);
          setShowAddClassroomDrawer(false);
          fetchingClassrooms();
        }}
      />
      <Modal
        visible={isDeletingModalVisible}
        title={
          <Fragment>
            คุณต้องการลบห้องเรียน
            <br />
            {`${selectedClassroom?.gradeLevel.name.th}/${selectedClassroom?.classroomName}`}{' '}
            หรือไม่
          </Fragment>
        }
        cancel={{
          onClick: () => {
            setIsDeletingModalVisible(false);
            setSelectedClassroom(null);
          },
          label: 'ยกเลิก'
        }}
        submit={{
          onClick: async () => {
            const hide = message.loading('กำลังดำเนินการ');
            try {
              if (selectedClassroom?.classroomId && selectedSchoolYearId) {
                await deleteClassroom(selectedClassroom.classroomId);
                await fetchingClassrooms();
                message.success('ลบห้องเรียนสำเร็จ');
              } else {
                throw new Error('ไม่มีห้องเรียน');
              }
            } catch (error) {
              console.error(error);

              var e = error as any;

              let errorMessage =
                MANAGE_CLASSROOM_ERROR_MESSAGE_MAPPER[e.response.data.message];
              if (!errorMessage) {
                errorMessage = 'ลบห้องเรียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง';
              }

              message.error(errorMessage);
            } finally {
              hide();
              setIsDeletingModalVisible(false);
              setSelectedClassroom(null);
            }
          },
          label: 'ยืนยัน',
          loading: isDeletingClassroom
        }}
      />
    </PageWrapper>
  );
};
