import { EvaluationPrePostTestClassroomScore } from './EvaluationPrePostTestClassroomScore';
import { EvaluationPrePostTestSubjectScore } from './EvaluationPrePostTestSubjectScore';
import React from 'react';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';

export const EvaluationPrePostTest = observer(() => {
  const { filterData } = evaluationStore;

  return (
    <>
      {filterData.selectedClassroom === undefined ? (
        <EvaluationPrePostTestSubjectScore />
      ) : (
        <EvaluationPrePostTestClassroomScore />
      )}
    </>
  );
});
