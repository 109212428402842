/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { ReactComponent as Book } from 'assets/book.svg';

export const MediaDetailLicenseEmpty = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <Book
        css={css`
          width: 73px;
          height: 80px;
          path {
            fill: #e3e4e8;
          }
        `}
      />
      <div className="text-base font-bold text-secondary-gray-60 mt-4">
        ไม่มีการตัดสื่อเข้าคาบเรียนนี้เพิ่ม
      </div>
    </div>
  );
};
