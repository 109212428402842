export type TableData = {
  columns: any[];
  dataSource: any;
  hasUnit: boolean;
  hasScore: boolean;
  fetching: boolean;
};

export enum TableType {
  OVERALL = 'overall',
  UNIT = 'unit'
}

export type InitialDataTable = {
  rawHeaders: any;
  rawUsers: any[];
  tableType: TableType;
};

export enum LearningOutcomeAssignmentSortBy {
  CLASSROOM_STUDENT_NUMBER_ASC = 'classroomStudentNumberAsc',
  NAME_ASC = 'nameAsc',
  TOTAL_SCORE_ASC = 'totalScoreAsc',
  TOTAL_SCORE_DESC = 'totalScoreDesc'
}

export enum MenuType {
  PRE_TEST = '7',
  POST_TEST = '8',
  PRE_POST_TEST = '7,8'
}
