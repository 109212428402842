import { css, jsx } from '@emotion/react';

/** @jsx jsx */
import { Skeleton } from 'antd';
import { TableSkeleton } from 'feature/common/components/Table/TableSkeleton';

export const SchoolAdminManagementSkeleton = () => {
  return (
    <div>
      <div
        className="flex justify-between mb-8"
        css={css`
          .ant-skeleton-element .ant-skeleton-input {
            height: 40px;
            border-radius: 12px;
          }
        `}
      >
        <div
          className="flex"
          css={css`
            .ant-skeleton-element .ant-skeleton-input {
              width: 320px;
            }
          `}
        >
          <Skeleton.Input active />
        </div>
        <div
          className="flex"
          css={css`
            .ant-skeleton-element .ant-skeleton-input {
              width: 120px;
            }
          `}
        >
          <Skeleton.Input active />
        </div>
      </div>
      <TableSkeleton />
    </div>
  );
};
