import { useEffect, useMemo, useState } from 'react';

import { useClassrooms } from 'hooks/classroom/useClassrooms';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useSchoolLicenses } from 'hooks/school-license/getSchoolLicense';
import { useSchoolStudents } from 'hooks/student/useSchoolStudents';
import { useSchoolSubjects } from 'hooks/school-subjects/getSchoolSubjects';
import { useSchoolTeacherRequests } from 'hooks/school-teacher-request/useSchoolTeachersRequests';
import { useSchoolTeachers, useSchoolTeachersType } from 'hooks/school-teacher/useSchoolTeachers';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';

export const useCalculateNumberOfData = () => {
  const [schoolLicensesLoading, setSchoolLicenseLoading] = useState(true);
  const [schoolSubjectsLoading, setSchoolSubjectsLoading] = useState(true);
  const [classroomsLoading, setClassroomsLoading] = useState(true);
  const [schoolStudentsLoading, setShoolStudentsLoading] = useState(true);
  const [schoolTeachersLoading, setSchoolTeachersLoading] = useState(true);
  const [
    schoolTeachersRequestLoading,
    setSchoolTeachersRequestLoading
  ] = useState(true);

  const {
    execute: getSchoolLicenses,
    total: numberOfSchoolLicenses
  } = useSchoolLicenses();
  const {
    execute: getSchoolSubjects,
    total: numberOfSchoolSubjects
  } = useSchoolSubjects();
  const { execute: getClassrooms, total: numberOfClassrooms } = useClassrooms();
  const {
    execute: getSchoolStudents,
    total: numberOfSchoolStudents
  } = useSchoolStudents();
  const {
    execute: getSchoolTeachers,
    total: numberOfApprovedSchoolTeachers
  } = useSchoolTeachers();
  const {
    execute: getSchoolTeacherRequests,
    total: numberOfWaitingForApprovalSchoolTeachers
  // } = useSchoolTeacherRequests({ pageSize: 0 });
  } = useSchoolTeachersType();

  const { data: schoolYears, loading: isSchoolYearLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();

  const schoolYearId = useMemo(() => {
    if (schoolYears?.length > 0 && !isSchoolYearLoading && currentSchoolYears) {
      return currentSchoolYears.schoolYearId;
    }
  }, [isSchoolYearLoading, currentSchoolYears]);

  useEffect(() => {
    if (schoolYearId) {
      try {
        setSchoolLicenseLoading(true);
        setSchoolSubjectsLoading(true);
        setClassroomsLoading(true);
        setShoolStudentsLoading(true);
        setSchoolTeachersLoading(true);
        setSchoolTeachersRequestLoading(true);

        getSchoolLicenses({ pageSize: 0, schoolYearId }).then(() => {
          setSchoolLicenseLoading(false);
        });
        getSchoolSubjects({ pageSize: 0, schoolYearId }).then(() => {
          setSchoolSubjectsLoading(false);
        });
        getClassrooms({ pageSize: 0, schoolYearId }).then(() => {
          setClassroomsLoading(false);
        });
        getSchoolStudents({ pageSize: 0, schoolYearId }).then(() => {
          setShoolStudentsLoading(false);
        });
        getSchoolTeachers({ pageSize: 0 }).then(() => {
          setSchoolTeachersLoading(false);
        });
        getSchoolTeacherRequests().then(() => {
          setSchoolTeachersRequestLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [schoolYearId]);

  return {
    data: {
      numberOfSchoolLicenses,
      numberOfSchoolSubjects,
      numberOfClassrooms,
      numberOfSchoolStudents,
      numberOfApprovedSchoolTeachers,
      numberOfWaitingForApprovalSchoolTeachers
    },
    loading: {
      schoolLicensesLoading,
      schoolSubjectsLoading,
      classroomsLoading,
      schoolStudentsLoading,
      schoolTeachersLoading,
      schoolTeachersRequestLoading
    }
  };
};
