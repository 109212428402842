import { AssignmentOverallClassPeriodDetailOverview } from './AssignmentOverallClassPeriodDetailOverview';
import { AssignmentOverallDetailMenu } from 'feature/assignment-overall/assignment-overall';
import { AssignmnetOverallClassPeriodDetailLearningOutcome } from './AssignmentOverallClassPeriodDetailLearningOutcome';
import React from 'react';

interface AssignmentOverallClassPeriodDetailContentProps {
  menu: AssignmentOverallDetailMenu;
}

export const AssignmentOverallClassPeriodDetailContent = ({
  menu
}: AssignmentOverallClassPeriodDetailContentProps) => {
  switch (menu) {
    case AssignmentOverallDetailMenu.ASSIGNMENT_OVERVIEW:
      return <AssignmentOverallClassPeriodDetailOverview />;
    case AssignmentOverallDetailMenu.LEARNING_OUTCOME:
      return <AssignmnetOverallClassPeriodDetailLearningOutcome />;
    default:
      return <AssignmentOverallClassPeriodDetailOverview />;
  }
};
