/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { EmptyTable } from 'feature/common/components/Table/EmptyTable';
import { EvaluationTable } from 'feature/common/components/Table/EvaluationTable';
import React from 'react';
import { ReactComponent as StatIcon } from 'assets/stat.svg';
import { TableData } from './class-period-detail';
import { TableSkeleton } from 'feature/common/components/Table/TableSkeleton';
import { observer } from 'mobx-react-lite';

interface LearningOutcomeAssignmentTableProps {
  isLoading: boolean;
  tableData: TableData;
  selectedAssignmentUnitId: string;
}

export const LearningOutcomeAssignmentTable = observer(
  ({
    isLoading,
    tableData,
    selectedAssignmentUnitId
  }: LearningOutcomeAssignmentTableProps) => {
    return (
      <>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <React.Fragment>
            {!tableData.fetching &&
            !tableData.hasScore &&
            !tableData.hasUnit ? (
              <div
                className="flex items-center justify-center w-full"
                css={css`
                  height: 550px;
                `}
              >
                <EmptyTable
                  icon={
                    <StatIcon
                      className="w-20 h-20"
                      css={css`
                        path {
                          fill: #e3e4e8;
                        }
                      `}
                    />
                  }
                  emptyText={'ไม่มีข้อมูล'}
                  emptyClassName={'text-secondary-gray-60'}
                />
              </div>
            ) : (
              <>
                <EvaluationTable
                  dataSource={tableData.dataSource}
                  columns={tableData?.columns}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                  customStyles={{
                    header: {
                      backgroundColor: '#FF9933',
                      activeColumns: selectedAssignmentUnitId
                        ? [
                            {
                              colNo: 2,
                              backgroundColor: '#FF791A',
                              bodyBackgroundColor: '#FFE4CA'
                            },
                            {
                              colNo: 3,
                              backgroundColor: '#FF791A',
                              bodyBackgroundColor: '#FFE4CA'
                            }
                          ]
                        : [
                            {
                              colNo: 2,
                              backgroundColor: '#FF791A',
                              bodyBackgroundColor: '#FFE4CA'
                            }
                          ],
                      hasUnit: tableData.hasUnit
                    },
                    body: {
                      hasScore: tableData.hasScore
                    }
                  }}
                />
              </>
            )}
          </React.Fragment>
        )}
      </>
    );
  }
);
