/** @jsx jsx */

import { Col, Row } from 'antd';

import { MediaCard } from './MediaCard';
import React from 'react';
import { jsx } from '@emotion/react';

interface MediaCardListProps {
  //TODO: change to type Media
  mediaList: any[];
  mediaListLoading: boolean;
  schoolYearId: string;
}

export const MediaCardList = ({
  mediaList = [],
  mediaListLoading,
  schoolYearId
}: MediaCardListProps) => {
  // console.log('mediaList', mediaList);
  return (
    <>
      {!mediaListLoading && mediaList.length === 0 ? (
        <div className="w-full h-full flex flex-col justify-center items-center mt-24">
          <img alt="empty" src="/assets/empty-media.png" />
          <span className="text-gray-40 mt-8">ยังไม่มีสื่อเรียน</span>
        </div>
      ) : (
        <Row gutter={[48, 32]} className="w-full h-full">
          {mediaList.map((media: any, key: number) => (
            <Col key={key}>
              <MediaCard media={media} schoolYearId={schoolYearId} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
