import React from 'react';
import { Select } from 'antd';
import dayjs from 'dayjs';

interface DateOfBirthPickerProps {
  className?: string;
  disabled?: boolean;
}

const years: { value: string; text: string }[] = [];
const today = dayjs();
const thisYear = today.format('YYYY');
for (let i = parseInt(thisYear); i >= parseInt(thisYear) - 100; i--) {
  years.push({ value: i.toString(), text: (i + 543).toString() });
}

export const DateOfBirthPicker = ({
  className = '',
  disabled = false,
  ...restProps
}: DateOfBirthPickerProps) => (
  <Select
    className={className}
    placeholder="ปีเกิด"
    disabled={disabled}
    {...restProps}
  >
    {years.map((item) => {
      return (
        <Select.Option key={'option_' + item.value} value={item.value}>
          {item.text}
        </Select.Option>
      );
    })}
  </Select>
);
