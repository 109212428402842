import { ClassroomModifyResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useCreateClassroom = () => {
  const [
    { data: response, loading, error },
    refetch
  ] = useAxios<ClassroomModifyResponse>(
    {
      url: `/classrooms`,
      method: 'POST'
    },
    { manual: true }
  );

  return {
    data: response?.data,
    loading: loading,
    error,
    execute: refetch
  };
};
