import {
  ImportExcelClassroomBody,
  ImportExcelClassroomResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useImportExcelValidateClassrooms = () => {
  const [_, fire] = useAxios<ImportExcelClassroomResponse>(
    {},
    { manual: true }
  );

  const execute = (data: ImportExcelClassroomBody) => {
    return fire({
      method: 'PUT',
      url: '/import-excel/classrooms',
      data
    });
  };

  return {
    execute
  };
};
