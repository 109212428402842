/** @jsx jsx */

import {
  Avatar,
  Divider,
  Form,
  Pagination,
  Select,
  Skeleton,
  Table
} from 'antd';
import {
  ClassroomDto,
  ClassroomTeacherDto
} from '@aksorn/teaching-backoffice-sdk';
import { Fragment, useEffect, useState } from 'react';

import { ClassroomListSortBy } from 'feature/classroom-management/classroom-management';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { SearchTeacherFilterBar } from 'feature/common/components/SearchTeacherFilterBar';
import { SortOrder } from 'feature/common/global';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { jsx } from '@emotion/react';
import { useClassrooms } from 'hooks/classroom/useClassrooms';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useHistory } from 'react-router-dom';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';

import moment from 'moment';

export const AssignmentOverallPage = () => {
  const history = useHistory();

  const columns = [
    {
      title: 'ห้อง',
      dataIndex: 'classroomName',
      key: 'classroomName',
      className: 'cursor-pointer',
      render: (classroomName: string, record: ClassroomDto) => {
        return (
          <Fragment>
            {record?.gradeLevel?.name?.th + '/' + classroomName}
          </Fragment>
        );
      },
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.CLASSROOM_NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.CLASSROOM_NAME_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: 'จำนวนนักเรียน',
      dataIndex: 'numberOfClassroomStudent',
      key: 'numberOfClassroomStudent',
      className: 'cursor-pointer',
      align: 'right',
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.NUMBER_OF_STUDENT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.NUMBER_OF_STUDENT_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: 'ครูประจำชั้น (หลัก)',
      dataIndex: 'mainSchoolTeacher',
      key: 'mainSchoolTeacher',
      className: 'cursor-pointer',
      render: (mainSchoolTeacher: ClassroomTeacherDto) => {
        return (
          <div className="flex justify-start items-center">
            <Avatar className="flex-shrink-0" icon={<UserOutlined />} />
            <div className="ml-2">
              {mainSchoolTeacher?.firstName?.th ? mainSchoolTeacher?.firstName?.th : '' + ' ' +
                mainSchoolTeacher?.lastName?.th ? mainSchoolTeacher?.lastName?.th : ''}

              {/* unlock mainSchoolTeacher 19/08/2022 */}
              {/* {console.log("mainSchoolTeacher.deletedAt",mainSchoolTeacher?.deletedAt)} */}
              {/* {mainSchoolTeacher?.deletedAt ? (
                <span style={{color:"red",fontWeight:"bold"}}>{' (ลาออกแล้ว)'}</span>
              ) : null} */}
              {mainSchoolTeacher?.deletedAt ? (
                <span>{' (ย้ายโรงเรียน)'}</span>
              ) : null}
            </div>
          </div>
        );
      },
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.TEACHER_NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.TEACHER_NAME_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    },
    {
      title: 'แก้ไขวันที่',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      className: 'cursor-pointer',
      render: (updatedAt: string) => {
        return (
          <div>{dayjs(updatedAt).add(543, 'year').format('DD/MM/YY')}</div>
        );
      },
      sorter: (_a: ClassroomDto, _b: ClassroomDto, sortBy: SortOrder) => {
        switch (sortBy) {
          case SortOrder.ASCEND:
            setSortBy(ClassroomListSortBy.UPDATED_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(ClassroomListSortBy.UPDATED_AT_DESC);
            break;
          default:
            break;
        }
      },
      ellipsis: true
    }
  ];

  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState('');
  const [gradeLevelIds, setGradeLevelIds] = useState<string[]>([]);
  const [classroomNames, setClassroomNames] = useState<string[]>([]);
  const [teacherName, setTeacherName] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState<ClassroomListSortBy>(
    ClassroomListSortBy.UPDATED_AT_DESC
  );
  const [searchText, setSearchText] = useState('');

  const { Option } = Select;
  const { data: schoolYears, loading: schoolYearsLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const {
    data: classrooms,
    loading: isClassroomsLoading,
    execute: getClassrooms,
    total
  } = useClassrooms();

  useEffect(() => {
    if (!schoolYearsLoading && currentSchoolYears) {
      setSelectedSchoolYearId(currentSchoolYears.schoolYearId);
    }
  }, [schoolYearsLoading, currentSchoolYears]);

  useEffect(() => {
    if (
      !selectedSchoolYearId ||
      !gradeLevelIds.length ||
      !classroomNames.length
    )
      return;
    const query = {
      schoolYearId: selectedSchoolYearId,
      teacherName,
      sortBy: sortBy as any,
      pageNumber: currentPage
    };

    if (gradeLevelIds[0] !== 'all') {
      Object.assign(query, { gradeLevelIds: gradeLevelIds?.join(',') });
    }

    if (classroomNames[0] !== 'all') {
      Object.assign(query, { classroomNames: classroomNames?.join(',') });
    }

    getClassrooms(query);
  }, [
    selectedSchoolYearId,
    teacherName,
    gradeLevelIds,
    classroomNames,
    sortBy,
    currentPage
  ]);

  return (
    <PageWrapper className="bg-secondary-white">
      <div className="flex justify-between h-10">
        <div className="flex items-center h-8 font-bold text-2xl text-secondary-black">
          ภาพรวมการส่งงาน
        </div>
        <div className="flex">
          {schoolYearsLoading ? (
            <div className="flex w-full justify-end mb-5">
              <Skeleton.Input className="mr-0" active />
            </div>
          ) : (
            <Form.Item label="ปีการศึกษา" className="w-48">
              <Select
                value={selectedSchoolYearId}
                onSelect={(schoolYearId) => {
                  setSelectedSchoolYearId(schoolYearId);
                }}
              >
                {schoolYears.map(({ name, schoolYearId }) => (
                  <Option 
                    key={schoolYearId} 
                    value={schoolYearId}
                    // disabled={parseInt(name) - 543 !== moment().year()}
                    disabled={schoolYearId !== selectedSchoolYearId}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}         
        </div>
      </div>
      <Divider className="my-8" />
      <SearchTeacherFilterBar
        schoolYearId={selectedSchoolYearId}
        gradeLevelIds={gradeLevelIds}
        setGradeLevelIds={setGradeLevelIds}
        classroomNames={classroomNames}
        setClassroomNames={setClassroomNames}
        setSearchText={setSearchText}
        onPressEnter={() => {
          setTeacherName(searchText);
        }}
      />
      <Table
        rowKey={'classroomId'}
        dataSource={classrooms as any}
        columns={columns as any}
        pagination={false}
        loading={isClassroomsLoading}
        onRow={(record: ClassroomDto) => {
          return {
            onClick: () => {
              history.push(`/assignment-overall/${record?.classroomId}`);
            }
          };
        }}
      />
      {total > 0 && (
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          defaultPageSize={20}
          total={total}
          onChange={(page) => setCurrentPage(page)}
        />
      )}
    </PageWrapper>
  );
};
