import {
  ImportExcelClassPeriodBulkBody,
  ImportExcelClassPeriodBulkResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useImportExcelValidateClassPeriods = () => {
  const [_, fire] = useAxios<ImportExcelClassPeriodBulkResponse>(
    {},
    { manual: true }
  );

  const execute = (data: ImportExcelClassPeriodBulkBody) => {
    return fire({
      method: 'PUT',
      url: '/import-excel/class-periods',
      data
    });
  };

  return {
    execute
  };
};
