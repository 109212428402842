import { EvaluationAssignmentScore } from './EvaluationAssignmentScore';
import { EvaluationMenu } from './evaluation';
import { EvaluationPrePostTest } from './EvaluationPrePostTest';
import React from 'react';

interface EvaluationContentProps {
  menu: EvaluationMenu;
}

export const EvaluationContent = ({ menu }: EvaluationContentProps) => {
  switch (menu) {
    case EvaluationMenu.ASSIGNMENT_SCORE:
      return <EvaluationAssignmentScore />;
    case EvaluationMenu.PRE_POST_TEST:
      return <EvaluationPrePostTest />;
    default:
      return <EvaluationAssignmentScore />;
  }
};
