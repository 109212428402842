import {
  LearningUnitOverallUserListQuery,
  LearningUnitOverallUserListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import { teachingAxios } from 'utils/axios';

export const getLearningUnitOverallUsers = async (
  params: LearningUnitOverallUserListQuery
) => {
  const {
    data: response
  } = await teachingAxios.get<LearningUnitOverallUserListResponse>(
    '/learning-units/overall/users',
    {
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    }
  );

  return response;
};
