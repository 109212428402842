/** @jsx jsx */

import { css, jsx } from '@emotion/react';

import { Loading } from 'feature/common/components/Loading';
import { PrimaryButton } from 'feature/common/components/Button';
import { sumBy } from 'lodash';
import { useHistory } from 'react-router';

interface CardValue {
  amount: number;
  unit: string;
}

interface ManagementCardProps {
  loading: boolean;
  title: string;
  imgUrl: string;
  values: CardValue[];
  redirectPath: string;
  emptyStateText: string;
  buttonText: string;
}

interface EmptyStateProps {
  redirectPath: string;
  emptyStateText: string;
  buttonText: string;
}

const EmptyState = ({
  redirectPath,
  emptyStateText,
  buttonText
}: EmptyStateProps) => {
  const history = useHistory();

  return (
    <div>
      <div className="text-secondary-gray-40 text-sm text-center mb-4">
        {emptyStateText}
      </div>
      <PrimaryButton
        onClick={() => {
          history.push(redirectPath);
        }}
        css={css`
          height: 40px !important;
        `}
      >
        {buttonText}
      </PrimaryButton>
    </div>
  );
};

export const ManagementCard = ({
  loading,
  title,
  imgUrl,
  values,
  redirectPath,
  emptyStateText,
  buttonText
}: ManagementCardProps) => {
  const history = useHistory();
  const showEmptyState = sumBy(values, 'amount') === 0;

  return (
    <div
      className="relative bg-white rounded-2xl p-6 pb-3 flex flex-col justify-between cursor-pointer"
      css={css`
        height: 160px;
        border: 1px solid #f4f4f6;
        box-shadow: 0px 2px 10px rgba(115, 118, 140, 0.15);
      `}
      onClick={() => {
        history.push(redirectPath);
      }}
    >
      <img
        src={imgUrl}
        alt="card-bg"
        className="absolute top-0 right-0 rounded-tr-2xl"
      />
      <div className="font-bold text-secondary-gray-40">{title}</div>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Loading />
        </div>
      ) : showEmptyState ? (
        <EmptyState
          emptyStateText={emptyStateText}
          redirectPath={redirectPath}
          buttonText={buttonText}
        />
      ) : (
        <div className="flex justify-between">
          {values.map(({ amount, unit }, idx) => (
            <div key={`management-card-${idx}`}>
              <div className="text-secondary-gray-60 text-2xl mb-2">
                {amount}
              </div>
              <div className="text-secondary-gray-40 text-sm">{unit}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
