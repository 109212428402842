import { Tooltip } from 'antd';
import { isNumber } from 'lodash';
/** @jsx jsx */
import { jsx } from '@emotion/react';

export const TextWrapperToolTip = ({
  score,
  className = '',
  showDecimal = false
}: {
  score: number | undefined;
  className?: string;
  showDecimal?: boolean;
}) => {
  const MAX_CH = 6;

  const hasDecimalPlace = isNumber(score) ? checkHasDecimalPlace(score) : false;

  const scoreText = isNumber(score)
    ? score.toString().length > MAX_CH
      ? `${score.toString().substring(0, MAX_CH)}...`
      : showDecimal
      ? hasDecimalPlace
        ? score.toFixed(1)
        : score.toString()
      : score.toString()
    : '-';

  if (score && score.toString().length > MAX_CH) {
    return (
      <Tooltip
        title={
          <span>
            <span className="text-secondary-gray-60 text-sm">{score}</span>
          </span>
        }
        color="white"
      >
        <div className={className}>
          <span>{scoreText}</span>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={className}>
      <span>{scoreText}</span>
    </div>
  );
};

const checkHasDecimalPlace = (number: number) => {
  if (number % 1 === 0) return false;
  return true;
};
