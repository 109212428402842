/** @jsx jsx */
import {
  OutlinedButton,
  PrimaryButton
} from 'feature/common/components/Button';
import {
  SchoolStudentModifyLicenseUsageDto,
  SchoolStudentModifyResponse
} from '@aksorn/teaching-backoffice-sdk';
import { css, jsx } from '@emotion/react';

import { Alert } from 'antd';
import { ReactComponent as Book } from 'assets/book.svg';
import { SchoolStudentLicenseItem } from './SchoolStudentLicenseItem';

interface SchoolStudentLicenseListProps {
  schoolStudentLicenseUsages?: SchoolStudentModifyLicenseUsageDto[];
  error?: SchoolStudentModifyResponse;
  onBack: () => void;
  onClose: () => void;
}

export const SchoolStudentLicenseList = ({
  schoolStudentLicenseUsages,
  error,
  onBack,
  onClose
}: SchoolStudentLicenseListProps) => {
  return (
    <div className="cursor-default">
      <div
        css={css`
          height: calc(100vh - 152px);
        `}
      >
        {error && (
          <Alert
            className="mb-6"
            message={`สื่อไม่เพียงพอ ${error?.meta?.additionalQuotaRequired} สิทธิ์`}
            description="จำนวนสิทธิ์ในการใช้สื่อไม่เพียงพอกับครูและนักเรียน จึงไม่สามารถเพิ่ม คาบเรียนได้ กรุณาแจ้งผู้ดูแลระบบเพื่อเพิ่มจำนวนสิทธิ์ก่อนสร้างคาบ"
            type="error"
            showIcon
            css={css`
              .ant-alert-message {
                font-weight: bold;
              }
            `}
          />
        )}
        {schoolStudentLicenseUsages &&
        schoolStudentLicenseUsages?.length > 0 ? (
          schoolStudentLicenseUsages?.map((licenseUsage) => (
            <SchoolStudentLicenseItem
              key={licenseUsage.schoolLicenseId}
              licenseUsage={licenseUsage}
              isSufficient={licenseUsage.quotaSufficient}
            />
          ))
        ) : (
          <EmptySchoolStudentLicense />
        )}
      </div>
      <div
        className="absolute bottom-0 flex justify-end py-5"
        css={css`
          right: 40px;
        `}
      >
        {error && (
          <OutlinedButton
            className="w-24 border-2 text-secondary-gray-40 border-secondary-gray-40 mr-4"
            onClick={onBack}
          >
            กลับไปแก้ไข
          </OutlinedButton>
        )}
        <PrimaryButton className="w-24 px-10" onClick={onClose}>
          รับทราบ
        </PrimaryButton>
      </div>
    </div>
  );
};

const EmptySchoolStudentLicense = () => (
  <div className="flex flex-col justify-center items-center h-full">
    <Book
      css={css`
        width: 73px;
        height: 80px;
        path {
          fill: #e3e4e8;
        }
      `}
    />
    <div className="text-base font-bold text-secondary-gray-60 mt-4">
      ไม่มีการตัดสื่อเข้าคาบเรียนนี้เพิ่ม
    </div>
  </div>
);
