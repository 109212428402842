/** @jsx jsx */
import { Button, Form, Input, Modal, Skeleton, message } from 'antd';
import { css, jsx } from '@emotion/react';

import { ReactComponent as SuccessIcon } from 'assets/success-all.svg';

interface PasswordChangeSuccessModalProps {
  visible: boolean;
  setVisible: Function;
}

export const PasswordChangeSuccessModal = ({
  visible,
  setVisible
}: PasswordChangeSuccessModalProps) => {
  return (
    <Modal
      visible={visible}
      closable={false}
      maskClosable={false}
      width={400}
      footer={null}
      onCancel={() => setVisible(false)}
      centered
      css={css`
        .ant-modal-body {
          padding: 0;
        }
      `}
    >
      <div className="w-full flex flex-col items-center py-6 px-10">
        <SuccessIcon className="mb-2" />
        <h1 className="text-2xl font-bold mb-2">
          เปลี่ยนรหัสผ่านโรงเรียนสำเร็จ!
        </h1>
        <p className="text-lg text-center mb-9">
          ระบบได้ทำการบันทึกข้อมูล
          <br />
          รหัสผ่านโรงเรียนเรียบร้อย
        </p>
        <Button
          className="w-32 h-12 rounded-xl bg-primary hover:bg-primary-hover transition-colors duration-500 ease-in-out text-white text-base font-bold"
          type="primary"
          onClick={() => setVisible(false)}
        >
          ตกลง
        </Button>
      </div>
    </Modal>
  );
};
