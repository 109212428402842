import { Table } from 'antd';
import styled from '@emotion/styled';

export const PrimaryTable = styled(Table)`
  .ant-table {
    flex: 1;
    min-height: 301px;
    margin-bottom: 24px;
    border-radius: 20px;
    box-shadow: 0px 2px 10px rgba(115, 118, 140, 0.15);
    justify-content: center;
    overflow: hidden;
  }

  .ant-table-container table > thead > tr:first-of-type th:first-of-type {
    border-top-left-radius: 20px;
  }
  .ant-table-container table > thead > tr:first-of-type th:last-child {
    border-top-right-radius: 20px;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: #ed1c24;
      color: #ffffff;
      font-weight: bold;
      border-bottom: none;
    }
  }

  .ant-table-body {
    flex: 1;
    min-height: calc(100vh - 281.66px);
  }

  .ant-table-thead:hover > tr > th {
    color: white;
    background: #ed1c24 !important;
  }

  .not-submitted-rows {
    cursor: default;
  }

  .ant-table-tbody > .not-submitted-rows > td {
    color: #5c5e70;
    cursor: default;
    border-bottom: 1px solid #f9f9f9;
  }

  .ant-table-tbody > tr > td {
    color: #5c5e70;
    cursor: default;
    border-bottom: 1px solid #f9f9f9;
    overflow: hidden;
    padding: 13px;
  }

  .ant-table-row:hover > .ant-table-cell {
    background-color: #f9f9f9 !important;
  }

  /* Adjust empty icon on the middle of Table */
  .ant-table-empty > .ant-table-container > .ant-table-content > table {
    min-height: 301px;
  }
`;
