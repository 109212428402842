import { Response } from '@aksorn/one-account-sdk';
import { useOneAccountAxios } from 'utils/axios';

export const useDeleteSchoolAdmin = () => {
  const [{ loading, error }, fire] = useOneAccountAxios<Response>(
    {
      method: 'DELETE'
    },
    {
      manual: true
    }
  );

  const execute = async (schoolAdminId: string) => {
    return fire({
      url: `/school-admins/${schoolAdminId}`
    });
  };

  return {
    loading: loading,
    error,
    execute
  };
};
