/** @jsx jsx */
import { Divider, Form, Input, Select, Spin, message } from 'antd';
import { FormRule, getRule } from 'utils/getRule';
import { Global, css } from '@emotion/react';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import {
  SchoolStudentModifyLicenseUsageDto,
  SchoolStudentModifyResponse
} from '@aksorn/teaching-backoffice-sdk';
import {
  UserGender,
  UserIdentityDocumentType,
  UserType,
  genderMapper
} from 'feature/common/user';
import {
  getErrorMessage,
  useValidateSchoolStudent
} from 'hooks/student/useValidateSchoolStudent';
import { useEffect, useMemo, useState } from 'react';

import { ClassroomListQuery } from '@aksorn/teaching-backoffice-sdk';
import { DateOfBirthPicker } from 'feature/common/components/DateOfBirthPicker';
import { Modal } from 'feature/common/components/Modal';
import { StudentFormStep } from './student-management';
import classNames from 'classnames';
import dayjs from 'dayjs';
// import env from '@beam-australia/react-env';
import { jsx } from '@emotion/react';
import { map } from 'lodash';
import { useClassrooms } from 'hooks/classroom/useClassrooms';
import { useCreateSchoolStudent } from 'hooks/student/useCreateSchoolStudent';
import { useForm } from 'antd/lib/form/Form';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';
import { useSchoolStudent } from 'hooks/student/useSchoolStudent';
import { useUpdateSchoolStudent } from 'hooks/student/useUpdateSchoolStudent';
import { validateCitizenId } from 'utils/validateCitizenId';

const { Option } = Select;
const titleOptions = {
  [UserType.TEACHER]: ['นาย', 'นาง', 'นางสาว'],
  [UserType.STUDENT]: ['ด.ช.', 'ด.ญ.', 'นาย', 'นาง', 'นางสาว']
};

interface StudentFormProps {
  height?: number;
  schoolStudentId?: string;
  classroomStudentId?: string;
  schoolYearId: string;
  onNext: ({
    schoolStudentLicenseUsages,
    error
  }: {
    schoolStudentLicenseUsages: SchoolStudentModifyLicenseUsageDto[];
    error?: SchoolStudentModifyResponse;
  }) => void;
  initialStep: StudentFormStep;
  onClose: () => void;
}

export const StudentForm = ({
  schoolStudentId,
  classroomStudentId,
  schoolYearId,
  onNext,
  onClose,
  initialStep
}: StudentFormProps) => {
  const [email, setEmail] = useState('');
  const [idType, setIdType] = useState(UserIdentityDocumentType.PASSPORT_ID);
  const [step, setStep] = useState<StudentFormStep>(initialStep);
  const [isUserVerified, setIsUserVerified] = useState<boolean>(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    false
  );

  const [informationForm] = useForm();
  const [emailForm] = useForm();

  const {
    data: validatedStudentInformation,
    loading: validating,
    execute: validateSchoolStudent
  } = useValidateSchoolStudent();

  const {
    loading: creating,
    execute: createSchoolStudent
  } = useCreateSchoolStudent();

  const {
    loading: updating,
    execute: updateSchoolStudent
  } = useUpdateSchoolStudent();
  const {
    data: schoolStudent,
    loading: schoolStudentLoading,
    execute: getSchoolStudent
  } = useSchoolStudent();

  const {
    data: classrooms,
    loading: classroomsLoading,
    execute: getClassrooms
  } = useClassrooms();

  const { data: gradeLevels, loading: gradeLevelLoading } = useGradeLevels();

  const [gradeLevelIds, setGradeLevelIds] = useState<string[]>([]);

  useEffect(() => {
    if (schoolStudentId) {
      getSchoolStudent(schoolStudentId);
    }
  }, [schoolStudentId]);

  useEffect(() => {
    if (schoolStudent) {
      setEmail(schoolStudent?.email);
      setIdType(schoolStudent?.identityDocument?.type as any);
      setIsUserVerified(schoolStudent?.isUserVerified);
      setGradeLevelIds([schoolStudent?.gradeLevelId]);
      informationForm.setFieldsValue({
        title: schoolStudent.title,
        firstNameTH: schoolStudent.firstName.th,
        lastNameTH: schoolStudent.lastName.th,
        firstNameEN: schoolStudent.firstName.en,
        lastNameEN: schoolStudent.lastName.en,
        gender: schoolStudent.gender,
        dateOfBirth: schoolStudent.dateOfBirth
          ? dayjs(schoolStudent.dateOfBirth).year().toString()
          : undefined,
        // identityDocument: schoolStudent.identityDocument?.number,
        studentCode: schoolStudent.studentCode,
        classroomStudentNumber: schoolStudent.classroomStudentNumber,
        gradeLevelIds: schoolStudent?.gradeLevelId,
        classroomId: schoolStudent?.classroomId
      });
    } else {
      setIsUserVerified(false);
    }
  }, [schoolStudent]);

  useEffect(() => {
    if (validatedStudentInformation) {
      informationForm.setFieldsValue({
        title: validatedStudentInformation.title,
        firstNameTH: validatedStudentInformation.firstName.th,
        lastNameTH: validatedStudentInformation.lastName.th,
        firstNameEN: validatedStudentInformation.firstName.en,
        lastNameEN: validatedStudentInformation.lastName.en,
        gender: validatedStudentInformation.gender,
        dateOfBirth: validatedStudentInformation.dateOfBirth
          ? dayjs(validatedStudentInformation.dateOfBirth).year().toString()
          : undefined
        // identityDocument: validatedStudentInformation.identityDocument?.number
      });
      setIsUserVerified(!!validatedStudentInformation?.emailVerifiedAt);
      // setIdType(validatedStudentInformation?.identityDocument?.type as any);
    }
  }, [validatedStudentInformation]);

  useEffect(() => {
    if (gradeLevelIds.length > 0) {
      const request: ClassroomListQuery = {
        schoolYearId,
        gradeLevelIds: gradeLevelIds?.join(',')
      };

      getClassrooms(request);
    }
  }, [gradeLevelIds]);

  const classroomNames = useMemo(
    () =>
      classrooms?.map(({ gradeLevel, classroomName, classroomId }) => ({
        classroomId,
        label: gradeLevel?.name?.th + '/' + classroomName
      })),
    [classrooms]
  );

  const handleSubmitForm = async () => {
    const fieldsValue = informationForm.getFieldsValue();

    const loading = message.loading(
      `กำลัง${!schoolStudentId ? 'สร้าง' : 'แก้ไข'}นักเรียน...`
    );
    try {
      setIdType(UserIdentityDocumentType.PASSPORT_ID);
      const data = {
        schoolYearId: schoolYearId,
        title: fieldsValue?.title,
        firstName: {
          th: fieldsValue?.firstNameTH,
          en: fieldsValue?.firstNameEN
        },
        lastName: {
          th: fieldsValue?.lastNameTH,
          en: fieldsValue?.lastNameEN
        },
        classroomStudentNumber: fieldsValue?.classroomStudentNumber,
        gender: fieldsValue?.gender,
        dateOfBirth: dayjs(fieldsValue?.dateOfBirth)
          .startOf('year')
          .toISOString(),
        // acceptedConsent: {
        //   // consentVersion: '2'
        //   consentVersion: env('CURRENT_CONSENT_VERSION')
        // },
        identityDocument: {
          type: idType as any,
          number: 'P'
        }
      };

      // console.log(schoolStudentId);

      if (fieldsValue.studentCode) {
        Object.assign(data, {
          studentCode: fieldsValue.studentCode
        });
      }

      if (schoolStudentId) {
        const hasNewClassroom =
          fieldsValue?.classroomId !== schoolStudent?.classroomId;

        if (hasNewClassroom) {
          Object.assign(data, {
            classroomId: fieldsValue?.classroomId
          });
        }

        const {
          data: { data: schoolStudentLicense }
        } = await updateSchoolStudent({
          schoolStudentId: schoolStudentId,
          data: {
            ...data
          }
        });
        onNext({
          schoolStudentLicenseUsages: schoolStudentLicense.licenseUsages
        });
      } else {
        Object.assign(data, {
          email,
          classroomId: fieldsValue?.classroomId
        });

        const {
          data: { data: schoolStudentLicense }
        } = await createSchoolStudent({
          data
        });
        onNext({
          schoolStudentLicenseUsages: schoolStudentLicense.licenseUsages
        });
      }
      message.success(`${schoolStudentId ? 'แก้ไข' : 'สร้าง'}นักเรียนสำเร็จ`);
    } catch (error) {
      const { data: errorResponse } = error?.response;
      console.error(error);
      message.error(`${schoolStudentId ? 'แก้ไข' : 'สร้าง'}นักเรียนไม่สำเร็จ`);
      if (errorResponse?.message === 'insufficient licenses') {
        onNext({
          schoolStudentLicenseUsages: errorResponse?.errors,
          error: errorResponse
        } as {
          schoolStudentLicenseUsages: SchoolStudentModifyLicenseUsageDto[];
          error: SchoolStudentModifyResponse;
        });
      }
    } finally {
      loading();
    }
  };

  const handleSubmitEmail = async (value: { email: string }) => {
    try {
      // console.log('schoolYearId: ' + schoolYearId);
      await validateSchoolStudent({
        data: {
          email: value?.email,
          schoolYearId
        }
      });

      setEmail(value.email);
      setStep(StudentFormStep.BASIC_INFO);
    } catch (error) {
      const errMessage = getErrorMessage(error?.response?.data?.message);
      emailForm.setFields([
        {
          name: 'email',
          errors: [errMessage]
        }
      ]);
    }
  };

  const loading =
    classroomsLoading ||
    gradeLevelLoading ||
    validating ||
    schoolStudentLoading;

  switch (step) {
    case StudentFormStep.EMAIL:
      return (
        <Form form={emailForm} onFinish={handleSubmitEmail}>
          <Form.Item
            name="email"
            normalize={(value) => value.trim()}
            rules={[
              getRule(FormRule.REQUIRE, 'กรุณากรอกอีเมล'),
              getRule(FormRule.EMAIL)
            ]}
          >
            <Input className="rounded-xl h-10" placeholder="อีเมล" />
          </Form.Item>
          <div className="flex items-center justify-end absolute inset-x-10 bottom-5">
            <SecondaryButton className="mx-4" onClick={onClose}>
              ยกเลิก
            </SecondaryButton>
            <PrimaryButton htmlType="submit" className="col-start-2">
              ถัดไป
            </PrimaryButton>
          </div>
        </Form>
      );
    case StudentFormStep.BASIC_INFO:
      return (
        <Spin spinning={loading}>
          <div className="flex justify-between items-center">
            <div className="font-bold text-base"> อีเมล : {email}</div>
            {!schoolStudentId && (
              <SecondaryButton
                className="font-bold rounded-xl border-black hover:text-primary hover:border-primary"
                onClick={() => {
                  setStep(StudentFormStep.EMAIL);
                }}
              >
                แก้ไขอีเมล
              </SecondaryButton>
            )}
          </div>
          <Divider className="mt-6 mb-8" />
          <Form
            form={informationForm}
            onFinish={() => setIsConfirmationModalVisible(true)}
            onValuesChange={(changesValue) => {
              if (Boolean(changesValue.gradeLevelIds)) {
                informationForm.setFieldsValue({
                  classroomId: undefined
                });
              }
            }}
          >
            <Global
              styles={css`
                .ant-spin-container {
                  height: 614px;
                  @media (min-height: 900px) {
                    height: calc(100vh - 284px);
                  }
                }
              `}
            />

            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="title"
                label="คำนำหน้า"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกคำนำหน้า')]}
              >
                <Select placeholder="คำนำหน้า" disabled={isUserVerified}>
                  {titleOptions[UserType.STUDENT].map((title, idx) => (
                    <Option value={title} key={`${title}_${idx}`}>
                      {title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="firstNameTH"
                label="ชื่อ (ภาษาไทย)"
                rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อภาษาไทย')]}
              >
                <Input
                  className="rounded-xl h-10"
                  placeholder="ชื่อ (ภาษาไทย)"
                  disabled={isUserVerified}
                />
              </Form.Item>
              <Form.Item
                name="lastNameTH"
                label="นามสกุล (ภาษาไทย)"
                rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกนามสกุลภาษาไทย')]}
              >
                <Input
                  className="rounded-xl h-10"
                  placeholder="นามสกุล (ภาษาไทย)"
                  disabled={isUserVerified}
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="firstNameEN"
                label="ชื่อ (ภาษาอังกฤษ)"
                // rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกชื่อภาษาอังกฤษ')]}
              >
                <Input
                  className="rounded-xl h-10"
                  placeholder="ชื่อ (ภาษาอังกฤษ)"
                  disabled={isUserVerified}
                />
              </Form.Item>
              <Form.Item
                name="lastNameEN"
                label="นามสกุล (ภาษาอังกฤษ)"
                // rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกนามสกุลภาษาอังกฤษ')]}
              >
                <Input
                  className="rounded-xl h-10"
                  placeholder="นามสกุล (ภาษาอังกฤษ)"
                  disabled={isUserVerified}
                />
              </Form.Item>
            </div>
            <Divider className="mt-0 mb-6" />
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="gradeLevelIds"
                label="ระดับชั้น"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกระดับชั้น')]}
              >
                <Select
                  placeholder="ระดับชั้น"
                  allowClear
                  onSelect={(value: string) => {
                    setGradeLevelIds([value]);
                  }}
                  onClear={() => setGradeLevelIds([])}
                >
                  {gradeLevels?.map(({ gradeLevelId, name }) => (
                    <Option value={gradeLevelId} key={gradeLevelId}>
                      {name?.th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="classroomId"
                label="ห้องเรียน"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกห้องเรียน')]}
              >
                <Select
                  placeholder="ห้องเรียน"
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {classroomNames?.map(({ classroomId, label }) => (
                    <Option value={classroomId} key={classroomId}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item name="studentCode" label="รหัสประจำตัว">
                <Input className="rounded-xl h-10" placeholder="รหัสประจำตัว" />
              </Form.Item>
              <Form.Item
                name="classroomStudentNumber"
                label="เลขที่"
                rules={[getRule(FormRule.REQUIRE, 'กรุณากรอกเลขที่')]}
              >
                <Input className="rounded-xl h-10" placeholder="เลขที่" />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Item
                name="gender"
                label="เพศ"
                rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกเพศ')]}
              >
                <Select placeholder="เพศ" disabled={isUserVerified}>
                  {map(UserGender, (value, _key) => (
                    <Option value={value} key={value}>
                      {genderMapper[value]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="dateOfBirth"
                label="ปีเกิด"
                // rules={[getRule(FormRule.REQUIRE, 'กรุณาเลือกปีเกิด')]}
              >
                <DateOfBirthPicker disabled={isUserVerified} />
              </Form.Item>
            </div>
            {/* <Divider className="mt-2 mb-8" /> */}

            {/* <div className="flex items-center w-2/3 mb-6 mr-2">
              <div
                className={classNames(
                  'flex cursor-pointer justify-center items-center w-60 mr-2 h-7 rounded-full',
                  {
                    'text-white bg-primary font-bold':
                      idType === UserIdentityDocumentType.CITIZEN_ID,
                    'order-gray-400 border':
                      idType !== UserIdentityDocumentType.CITIZEN_ID
                  }
                )}
                onClick={() => {
                  setIdType(UserIdentityDocumentType.CITIZEN_ID);
                }}
              >
                บัตรประชาชน
              </div>
              <div
                className={`flex cursor-pointer justify-center items-center w-60  h-7 rounded-full ${
                  idType === UserIdentityDocumentType.PASSPORT_ID
                    ? 'text-white bg-primary font-bold'
                    : 'text-gray-900 border-gray-400 border'
                }`}
                onClick={() => {
                  setIdType(UserIdentityDocumentType.PASSPORT_ID);
                }}
              >
                หนังสือเดินทาง
              </div>
            </div> */}
            {/* <Form.Item
              name="identityDocument"
              normalize={(value) => value.trim()}
              rules={[
                // getRule(
                //   FormRule.REQUIRE,
                //   'กรุณากรอกหมายเลขบัตรประชาชน/หมายเลขหนังสือเดินทาง'
                // ),
                () => ({
                  validator(rule, value) {
                    const isIdTypeCitizenId =
                      idType === UserIdentityDocumentType.CITIZEN_ID;
                    if (value && isIdTypeCitizenId && !validateCitizenId(value))
                      return Promise.reject('บัตรประชาชนไม่ถูกต้อง');
                    return Promise.resolve();
                  }
                })
              ]}
            >
              <Input
                className="rounded-xl h-10"
                placeholder={`${
                  idType === UserIdentityDocumentType.CITIZEN_ID
                    ? 'หมายเลขบัตรประชาชน'
                    : 'หมายเลขหนังสือเดินทาง'
                }`}
                disabled={isUserVerified}
              />
            </Form.Item> */}

            <div className="flex items-center justify-end absolute inset-x-0 bottom-0">
              <SecondaryButton className="mx-4" onClick={onClose}>
                ยกเลิก
              </SecondaryButton>
              <PrimaryButton htmlType="submit">
                {schoolStudentId ? 'แก้ไข' : 'เพิ่ม'}
              </PrimaryButton>
            </div>
          </Form>
          <Modal
            title={`ยืนยันการ${
              schoolStudentId ? 'แก้ไข' : 'เพิ่ม'
            }นักเรียนและตัดสื่อให้นักเรียน ?`}
            subTitle="สื่อที่ตัดแล้วไม่สามารถคืนสิทธิ์ได้"
            visible={isConfirmationModalVisible}
            cancel={{
              label: 'ยกเลิก',
              onClick: () => setIsConfirmationModalVisible(false)
            }}
            submit={{
              label: 'ตกลง',
              loading: creating || updating,
              onClick: handleSubmitForm
            }}
          />
        </Spin>
      );
  }
};
