import dayjs from 'dayjs';

const formatDateTh = (date: Date | string, format = 'D MMMM YY HH:mm น.') => {
  // return dayjs(date).add(543, 'year').format(format);
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let newFormat = format;
  let yearFormat = '';
  if (format.includes('YYYY')) {
    yearFormat = 'YYYY';
  } else if (format.includes('YY')) {
    yearFormat = 'YY';
  }
  if (yearFormat !== '') {
    const strYear = dayjs(date).add(543, 'year').format(yearFormat);
    newFormat = format.replace(yearFormat, strYear);
  }
  return dayjs(date).format(newFormat);
};

const diffDays = (startDate: Date, endDate: Date) => {
  const diffDays = dayjs(endDate).diff(startDate, 'days');
  return diffDays;
};

export { formatDateTh, diffDays };
