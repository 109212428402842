/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Avatar } from 'antd';
import classNames from 'classnames';

export const StudentDescription = ({
  classroomStudentNumber,
  studentCode,
  title,
  firstName,
  lastName
}: {
  classroomStudentNumber: string;
  studentCode: string | undefined;
  title: string | undefined;
  firstName: string;
  lastName: string;
}) => {
  return (
    <div className="flex">
      <div
        className="flex flex-shrink-0 mr-4"
        css={css`
          width: 34px;
        `}
      >
        <div
          className="w-full truncate"
          css={css`
            max-width: 30px;
          `}
        >
          {classroomStudentNumber}
        </div>
      </div>
      <div
        className={classNames('flex flex-shrink-0', {
          'justify-center': !studentCode
        })}
        css={css`
          width: 62px;
          margin-right: 36px;
        `}
      >
        {studentCode ?? '-'}
      </div>
      <NameStudent
        // avatarText={firstName.substring(0, 3)}
        // name={`${title}${firstName} ${lastName}`}
        avatarText={firstName ? firstName.substring(0, 3) : ''}
        name={firstName ? `${title}${firstName} ${lastName}` : ''}
      />
    </div>
  );
};

const NameStudent = ({
  avatarText,
  name
}: {
  avatarText: string;
  name: string;
}) => {
  return (
    <div className="flex items-center">
      <Avatar className="flex-shrink-0 mr-2 text-xs" size={24}>
        {avatarText}
      </Avatar>
      <div className="flex flex-col">{name}</div>
    </div>
  );
};
