import {
  ImportExcelErrorFieldType,
  ImportExcelSheetNameAlias
} from 'feature/dashboard/dashboard';
import React, { useState } from 'react';
import { UserGender, genderMapper } from 'feature/common/user';

import { Divider } from 'antd';
import { ImportExcelErrorAnnotation } from './ImportExcelErrorAnnotation';
import { ImportExcelModal } from './ImportExcelModal';
import { ImportExcelParseResponse } from '@aksorn/teaching-backoffice-sdk';
import { ImportExcelTable } from './ImportExcelTable';
import { TableCell } from 'feature/dashboard/utils/TableCell';
import { classPeriodColumns } from './ImportExcelClassPeriodResult';
import { classroomColumns } from './ImportExcelClassroomResult';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

interface ImportExcelClassroomResultProps {
  data: ImportExcelParseResponse;
  onClose: () => void;
}

const classroomTeacherColumns = [
  {
    title: 'อีเมล',
    dataIndex: ['value', 'email'],
    key: 'email',
    render: (email: string, record: any) => {
      return (
        <TableCell
          value={email}
          record={record}
          validateField={ImportExcelErrorFieldType.EMAIL}
        />
      );
    }
  },
  {
    title: 'คำนำหน้าชื่อ',
    dataIndex: ['value', 'title'],
    key: 'title',
    render: (title: string, record: any) => (
      <TableCell
        value={title}
        record={record}
        validateField={ImportExcelErrorFieldType.TITLE}
      />
    )
  },
  {
    title: 'ชื่อ',
    dataIndex: ['value', 'firstNameTh'],
    key: 'firstNameTh',
    render: (firstNameTh: string, record: any) => (
      <TableCell
        value={firstNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_TH}
      />
    )
  },
  {
    title: 'นามสกุล',
    dataIndex: ['value', 'lastNameTh'],
    key: 'lastNameTh',
    render: (lastNameTh: string, record: any) => (
      <TableCell
        value={lastNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_TH}
      />
    )
  },
  {
    title: 'ชื่อ (ภาษาอังกฤษ)',
    dataIndex: ['value', 'firstNameEn'],
    key: 'firstNameEn',
    render: (firstNameEn: string, record: any) => (
      <TableCell
        value={firstNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_EN}
      />
    )
  },
  {
    title: 'นามสกุล (ภาษาอังกฤษ)',
    dataIndex: ['value', 'lastNameEn'],
    key: 'lastNameEn',
    render: (lastNameEn: string, record: any) => (
      <TableCell
        value={lastNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_EN}
      />
    )
  },
  {
    title: 'บัตรประชาชน',
    dataIndex: ['value', 'citizenId'],
    key: 'citizenId',
    render: (citizenId: string, record: any) => {
      return (
        <TableCell
          value={citizenId}
          record={record}
          validateField={ImportExcelErrorFieldType.CITIZEN_ID}
        />
      );
    }
  },
  {
    title: 'Passport Number',
    dataIndex: ['value', 'passportNumber'],
    key: 'passportNumber',
    render: (passportNumber: string, record: any) => {
      return (
        <TableCell
          value={passportNumber}
          record={record}
          validateField={ImportExcelErrorFieldType.PASSPORT_NUMBER}
        />
      );
    }
  },
  {
    title: 'เพศ',
    dataIndex: ['value', 'gender'],
    key: 'gender',
    render: (gender: string, record: any) => (
      <TableCell
        value={
          Object.values(UserGender).includes(gender as any)
            ? genderMapper[gender as UserGender]
            : gender
        }
        record={record}
        validateField={ImportExcelErrorFieldType.GENDER}
      />
    )
  },
  {
    title: 'ปีเกิด (พ.ศ.)',
    dataIndex: ['value', 'dateOfBirth'],
    key: 'dateOfBirth',
    render: (dateOfBirth: string, record: any) => (
      <TableCell
        value={
          dateOfBirth
            ? dayjs(dateOfBirth)?.add(543, 'years')?.format('YYYY')
            : undefined
        }
        record={record}
        validateField={ImportExcelErrorFieldType.DATE_OF_BIRTH}
      />
    )
  },
  {
    title: 'สถานะครู',
    dataIndex: ['value', 'status'],
    key: 'status',
    render: (status: string, record: any) => (
      <TableCell
        value={status}
        record={record}
        validateField={ImportExcelErrorFieldType.STATUS}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    width: '10%',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

const classPeriodTeacherColumns = [
  {
    title: 'วิชา',
    dataIndex: ['value', 'subject'],
    key: 'subject',
    render: (subject: string, record: any) => {
      return (
        <TableCell
          value={subject}
          record={record}
          validateField={ImportExcelErrorFieldType.SUBJECT}
        />
      );
    }
  },
  ...classroomTeacherColumns
];

const classroomStudentColumns = [
  {
    title: 'เลขที่',
    dataIndex: ['value', 'classroomStudentNumber'],
    key: 'classroomStudentNumber',
    render: (classroomStudentNumber: string, record: any) => {
      return (
        <TableCell
          value={classroomStudentNumber}
          record={record}
          validateField={ImportExcelErrorFieldType.CLASSROOM_STUDENT_NUMBER}
        />
      );
    }
  },
  {
    title: 'อีเมลนักเรียน',
    dataIndex: ['value', 'email'],
    key: 'email',
    render: (email: string, record: any) => {
      return (
        <TableCell
          value={email}
          record={record}
          validateField={ImportExcelErrorFieldType.EMAIL}
        />
      );
    }
  },
  {
    title: 'รหัสประจำตัว',
    dataIndex: ['value', 'studentCode'],
    key: 'studentCode',
    render: (studentCode: string, record: any) => {
      return (
        <TableCell
          value={studentCode}
          record={record}
          validateField={ImportExcelErrorFieldType.STUDENT_CODE}
        />
      );
    }
  },
  {
    title: 'คำนำหน้าชื่อ',
    dataIndex: ['value', 'title'],
    key: 'title',
    render: (title: string, record: any) => (
      <TableCell
        value={title}
        record={record}
        validateField={ImportExcelErrorFieldType.TITLE}
      />
    )
  },
  {
    title: 'ชื่อ',
    dataIndex: ['value', 'firstNameTh'],
    key: 'firstNameTh',
    render: (firstNameTh: string, record: any) => (
      <TableCell
        value={firstNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_TH}
      />
    )
  },
  {
    title: 'นามสกุล',
    dataIndex: ['value', 'lastNameTh'],
    key: 'lastNameTh',
    render: (lastNameTh: string, record: any) => (
      <TableCell
        value={lastNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_TH}
      />
    )
  },
  {
    title: 'ชื่อ (ภาษาอังกฤษ)',
    dataIndex: ['value', 'firstNameEn'],
    key: 'firstNameEn',
    render: (firstNameEn: string, record: any) => (
      <TableCell
        value={firstNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_EN}
      />
    )
  },
  {
    title: 'นามสกุล (ภาษาอังกฤษ)',
    dataIndex: ['value', 'lastNameEn'],
    key: 'lastNameEn',
    render: (lastNameEn: string, record: any) => (
      <TableCell
        value={lastNameEn}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_EN}
      />
    )
  },
  {
    title: 'บัตรประชาชน',
    dataIndex: ['value', 'citizenId'],
    key: 'citizenId',
    render: (citizenId: string, record: any) => {
      return (
        <TableCell
          value={citizenId}
          record={record}
          validateField={ImportExcelErrorFieldType.CITIZEN_ID}
        />
      );
    }
  },
  {
    title: 'Passport Number',
    dataIndex: ['value', 'passportNumber'],
    key: 'passportNumber',
    render: (passportNumber: string, record: any) => {
      return (
        <TableCell
          value={passportNumber}
          record={record}
          validateField={ImportExcelErrorFieldType.PASSPORT_NUMBER}
        />
      );
    }
  },
  {
    title: 'เพศ',
    dataIndex: ['value', 'gender'],
    key: 'gender',
    render: (gender: string, record: any) => (
      <TableCell
        value={
          Object.values(UserGender).includes(gender as any)
            ? genderMapper[gender as UserGender]
            : gender
        }
        record={record}
        validateField={ImportExcelErrorFieldType.GENDER}
      />
    )
  },
  {
    title: 'ปีเกิด (พ.ศ.)',
    dataIndex: ['value', 'dateOfBirth'],
    key: 'dateOfBirth',
    render: (dateOfBirth: string, record: any) => (
      <TableCell
        value={
          dateOfBirth
            ? dayjs(dateOfBirth)?.add(543, 'years')?.format('YYYY')
            : undefined
        }
        record={record}
        validateField={ImportExcelErrorFieldType.DATE_OF_BIRTH}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    width: '10%',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

export const ImportExcelParseErrorResult = ({
  data,
  onClose
}: ImportExcelClassroomResultProps) => {
  const [visible, setVisible] = useState(true);

  const getDataSource = (sheetNameAlias: ImportExcelSheetNameAlias) => {
    return (
      data?.errors &&
      data.errors.find(
        (error) => (error.sheetNameAlias as any) === sheetNameAlias
      )?.validationErrors
    );
  };

  const classroomTeacherDataSource = getDataSource(
    ImportExcelSheetNameAlias.CLASSROOM_TEACHER_SHEET
  );

  const classroomDataSource = getDataSource(
    ImportExcelSheetNameAlias.CLASSROOM_SHEET
  );

  const classPeriodTeacherDataSource = getDataSource(
    ImportExcelSheetNameAlias.CLASS_PERIOD_TEACHER_SHEET
  );

  const classPeriodDataSource = getDataSource(
    ImportExcelSheetNameAlias.CLASS_PERIOD_SCHEDULE_SHEET
  );

  const classroomStudentDataSource = getDataSource(
    ImportExcelSheetNameAlias.CLASSROOM_STUDENT_SHEET
  );

  const isEmptyClassroomTeacherSheet =
    classroomTeacherDataSource?.length === 1 &&
    isEmpty(classroomTeacherDataSource[0].value);

  const isEmptyClassroomSheet =
    classroomDataSource?.length === 1 && isEmpty(classroomDataSource[0].value);

  return (
    <ImportExcelModal
      title={'ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบและอัปโหลดใหม่อีกครั้ง'}
      showValidationStep={false}
      visible={visible}
      onClose={() => {
        setVisible(false);
        onClose();
      }}
    >
      {classroomTeacherDataSource && (
        <>
          {isEmptyClassroomTeacherSheet ? (
            <ImportExcelTable
              dataSource={[]}
              columns={classroomTeacherColumns}
              title="ข้อมูลครูประจำชั้น"
              subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
              emptyText="ไม่พบข้อมูลครูประจำชั้น"
            />
          ) : (
            <ImportExcelTable
              dataSource={classroomTeacherDataSource}
              columns={classroomTeacherColumns}
              title="ข้อมูลครูประจำชั้น"
              subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
            />
          )}
          <Divider className="my-9" />
        </>
      )}
      {classroomDataSource && (
        <>
          {isEmptyClassroomSheet ? (
            <ImportExcelTable
              dataSource={[]}
              columns={classroomColumns}
              title="ข้อมูลห้องเรียนไม่ถูกต้อง"
              subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
              emptyText="ไม่พบข้อมูลห้องเรียน"
            />
          ) : (
            <ImportExcelTable
              dataSource={classroomDataSource}
              columns={classroomColumns}
              title="ข้อมูลห้องเรียนไม่ถูกต้อง"
              subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
            />
          )}
          <Divider className="my-9" />
        </>
      )}
      {classPeriodTeacherDataSource && (
        <>
          <ImportExcelTable
            dataSource={classPeriodTeacherDataSource}
            columns={classPeriodTeacherColumns}
            title="ข้อมูลครูประจำวิชา"
            subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
          />
          <Divider className="my-9" />
        </>
      )}
      {classPeriodDataSource && (
        <>
          <ImportExcelTable
            dataSource={classPeriodDataSource}
            columns={classPeriodColumns}
            title="ข้อมูลตารางเรียน"
            subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
          />
          <Divider className="my-9" />
        </>
      )}
      {classroomStudentDataSource && (
        <ImportExcelTable
          dataSource={classroomStudentDataSource}
          columns={classroomStudentColumns}
          title="ข้อมูลนักเรียน"
          subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
        />
      )}
    </ImportExcelModal>
  );
};
