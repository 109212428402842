import { Global, css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import {
  SchoolStudentModifyLicenseUsageDto,
  SchoolStudentModifyResponse
} from '@aksorn/teaching-backoffice-sdk';

import { Drawer } from 'antd';
import { SchoolStudentLicenseList } from './SchoolStudentLicenseList';
import { StudentForm } from './StudentForm';
import { StudentFormStep } from './student-management';

interface ManageStudentInformationDrawerProps {
  title: string;
  visible: boolean;
  studentId?: string;
  classroomStudentId?: string;
  schoolYearId: string;
  onClose: () => void;
  refetch: () => void;
}

enum SchoolStudentDrawerStep {
  BASIC_INFO = 'basic-info',
  LICENSE = 'license'
}

export const ManageStudentInformationDrawer = ({
  title,
  visible,
  studentId,
  classroomStudentId,
  schoolYearId,
  onClose,
  refetch
}: ManageStudentInformationDrawerProps) => {
  const [drawerStep, setDrawerStep] = useState(
    SchoolStudentDrawerStep.BASIC_INFO
  );
  const [schoolStudentLicenseUsages, setSchoolStudentLicenseUsages] = useState<
    SchoolStudentModifyLicenseUsageDto[]
  >();
  const [drawerTitle, setDrawerTitle] = useState(title);
  const [errorApplyLicense, setErrorApplyLicense] = useState<
    SchoolStudentModifyResponse | undefined
  >();

  useEffect(() => {
    setDrawerTitle(title);
    setDrawerStep(SchoolStudentDrawerStep.BASIC_INFO);
    setSchoolStudentLicenseUsages(undefined);
    setErrorApplyLicense(undefined);
  }, [visible]);

  const renderDrawerBody = (step: SchoolStudentDrawerStep) => {
    switch (step) {
      case SchoolStudentDrawerStep.BASIC_INFO:
        return (
          <>
            <img
              alt="profile"
              src="/assets/profile-colour.png"
              className="w-32 h-32 mx-auto my-8"
            />
            <StudentForm
              classroomStudentId={classroomStudentId}
              schoolStudentId={studentId}
              schoolYearId={schoolYearId}
              initialStep={
                studentId ? StudentFormStep.BASIC_INFO : StudentFormStep.EMAIL
              }
              onClose={onClose}
              onNext={({
                schoolStudentLicenseUsages,
                error
              }: {
                schoolStudentLicenseUsages: SchoolStudentModifyLicenseUsageDto[];
                error?: SchoolStudentModifyResponse;
              }) => {
                refetch();
                setDrawerTitle('ตัดสื่อเข้าคาบ');
                setSchoolStudentLicenseUsages(schoolStudentLicenseUsages);
                setErrorApplyLicense(error);
                setDrawerStep(SchoolStudentDrawerStep.LICENSE);
              }}
            />
          </>
        );
      case SchoolStudentDrawerStep.LICENSE:
        return (
          <SchoolStudentLicenseList
            schoolStudentLicenseUsages={schoolStudentLicenseUsages}
            error={errorApplyLicense}
            onBack={() => {
              setDrawerStep(SchoolStudentDrawerStep.BASIC_INFO);
              setDrawerTitle(title);
            }}
            onClose={onClose}
          />
        );
      default:
        break;
    }
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
      visible={visible}
      width={480}
      height={900}
      onClose={onClose}
    >
      <Global
        styles={css`
          .ant-drawer-body {
            padding: 1.25rem;
          }
        `}
      />

      <div className="mx-4">
        <div className="font-bold text-xl mb-5 mt-1 mx-0.5">{drawerTitle}</div>
        <div>{renderDrawerBody(drawerStep)}</div>
      </div>
    </Drawer>
  );
};
