import { Form, Select, Skeleton } from 'antd';
import { Global, css } from '@emotion/react';
import {
  useClassrooms,
  useClassroomsLicense
} from 'hooks/classroom/useClassrooms';

import { ClassroomListQuery } from '@aksorn/teaching-backoffice-sdk';
import React from 'react';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';
import { useSubjects } from 'hooks/subject/useSubjects';

const SELECT_ALL = 'all';

const { Option } = Select;

export const EvaluationFilterBar = observer(() => {
  const { data: gradeLevels, loading: gradeLevelsLoading } = useGradeLevels();
  // const {
  //   data: classrooms,
  //   loading: classroomsLoading,
  //   execute: getClassrooms
  // } = useClassrooms();
  const {
    data: classrooms,
    loading: classroomsLoading,
    execute: getClassroomsLicense
  } = useClassroomsLicense();
  const {
    data: subjects,
    loading: subjectsLoading,
    execute: getSubjects
  } = useSubjects();

  const {
    filterData,
    isLoadingFilterData,
    setSelectedGradeLevel,
    setSelectedClassroom,
    setSelectedSubject,
    setDefaultGradeLevel,
    setIsLoadingFilterData
  } = evaluationStore;

  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    if (gradeLevels) {
      setDefaultGradeLevel(gradeLevels[0]);
      setSelectedGradeLevel(gradeLevels[0]);
    }
  }, [gradeLevels]);

  useEffect(() => {
    if (filterData.selectedSchoolYearId && filterData.selectedGradeLevel) {
      const request: ClassroomListQuery = {
        schoolYearId: filterData.selectedSchoolYearId,
        gradeLevelIds: filterData.selectedGradeLevel?.gradeLevelId
      };
      // getClassrooms(request);
      getClassroomsLicense(request);
    }
  }, [filterData.selectedSchoolYearId, filterData.selectedGradeLevel]);

  useEffect(() => {
    if (gradeLevelsLoading || classroomsLoading || subjectsLoading) {
      setIsLoadingFilterData(true);
    } else {
      setIsLoadingFilterData(false);
    }
  }, [gradeLevelsLoading, classroomsLoading, subjectsLoading]);

  return (
    <>
      <Global
        styles={css`
          .ant-select-dropdown {
            border-radius: 0 0 10px 10px !important;
          }
          .ant-select-item {
            color: #5c5e70;
          }
          .ant-skeleton-element .ant-skeleton-input {
            width: 192px;
            height: 40px;
            border-radius: 12px;
            margin-right: 10px;
          }
        `}
      />
      <div
        className="flex w-full items-center justify-between"
        css={css`
          .ant-input-affix-wrapper {
            padding-bottom: 0;
            background-color: transparent;
            border-radius: 0.75rem;
            input {
              height: 40px;
            }
          }
          .ant-input {
            font-size: 1rem;
            height: 30px;
            background-color: transparent;
          }
          .ant-form-item {
            margin-bottom: 12px !important;
          }
        `}
      >
        <div className="flex">
          {isLoadingFilterData ? (
            <div className="flex justify-end mb-6">
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
            </div>
          ) : (
            <>
              <Form.Item label="ชั้นปี" className="w-48 mr-2.5">
                <Select
                  listHeight={160}
                  value={filterData.selectedGradeLevel?.gradeLevelId}
                  onSelect={(gradeLevelId) => {
                    const selectGradeLevel =
                      gradeLevels.find(
                        (gradeLevel) => gradeLevelId === gradeLevel.gradeLevelId
                      ) || gradeLevels[0];
                    setSelectedGradeLevel(selectGradeLevel);
                  }}
                >
                  {gradeLevels.map((gradeLevel) => (
                    <Option
                      key={gradeLevel.gradeLevelId}
                      value={gradeLevel.gradeLevelId}
                    >
                      {gradeLevel.name.th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="ห้อง" className="w-48 mr-2.5">
                <Select
                  listHeight={160}
                  value={
                    filterData.selectedClassroom?.classroomId || SELECT_ALL
                  }
                  onSelect={(classroomId: string) => {
                    const selectedClassroom =
                      classrooms.find(
                        (classroom) => classroomId === classroom.classroomId
                      ) || undefined;
                    setSelectedClassroom(selectedClassroom);
                  }}
                >
                  <Option value={SELECT_ALL}>ทั้งหมด</Option>
                  {classrooms.map(({ classroomName, classroomId }) => (
                    <Option key={classroomId} value={classroomId}>
                      {classroomName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="วิชา" className="w-48 mr-2.5">
                <Select
                  listHeight={160}
                  value={filterData.selectedSubject?.subjectId || SELECT_ALL}
                  onSelect={(subjectId) => {
                    const selectedSubject =
                      subjects.find(
                        (subject) => subjectId === subject.subjectId
                      ) || undefined;
                    setSelectedSubject(selectedSubject);
                  }}
                >
                  <Option value={SELECT_ALL}>ทั้งหมด</Option>
                  {subjects.map((subject) => (
                    <Option key={subject.subjectId} value={subject.subjectId}>
                      {subject.name.th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </div>
      </div>
    </>
  );
});
