import { action, makeObservable, observable } from 'mobx';

export type ConfirmModalProps = {
  id?: string;
  title: string | React.ReactElement;
  description?: string;
  onSubmit?: () => void;
  submitText?: string;
  onCancel?: () => void;
  cancelText?: string;
};
class AppStore {
  confirmations: ConfirmModalProps[] = [];

  constructor() {
    makeObservable(this, {
      confirmations: observable.shallow,
      confirmModal: action,
      setConfirmations: action,
      hideConfirmation: action
    });
  }

  confirmModal = (props: ConfirmModalProps) => {
    this.setConfirmations([
      ...this.confirmations,
      { ...props, id: Math.random().toString() }
    ]);
  };

  setConfirmations = (confirmations: ConfirmModalProps[]) => {
    this.confirmations = confirmations;
  };

  hideConfirmation = (confirmation: ConfirmModalProps) => {
    // const before = this.confirmations;
    const after = this.confirmations.filter((c) => c.id !== confirmation.id);
    this.setConfirmations(after);
  };
}

export const appStore = new AppStore();
