import { ClassroomGetResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useClassroom = () => {
  const [{ data, loading, error }, fire] = useAxios<ClassroomGetResponse>(
    {},
    { manual: true }
  );

  const execute = (classroomId: string) => {
    return fire({ url: `/classrooms/${classroomId}` });
  };

  return {
    data: data?.data,
    loading: loading,
    error: error,
    execute
  };
};
