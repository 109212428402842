import React from 'react';

export const TableCell = ({
  value,
  record,
  validateField
}: {
  value?: string;
  record: any;
  validateField: string;
}) => {
  const hasError = record?.fieldErrors?.find(
    (record: any) => record.fieldName === validateField
  );

  if (hasError) {
    return <div className="text-primary">{value}</div>;
  }
  return <>{value}</>;
};
