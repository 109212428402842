/** @jsx jsx */
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal as ModalAntd,
  Pagination,
  Select,
  Spin,
  Table,
  TreeSelect,
  message
} from 'antd';
import {
  ClassroomDto,
  ClassroomStudentGetDto,
  Localization,
  SchoolStudentDto
} from '@aksorn/teaching-backoffice-sdk';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/react';
import {
  downloadStudentExcel,
  downloadStudentPdf
} from 'api/download/getStudentExcel';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/edit-icon.svg';
import { GhostButton } from 'feature/common/components/Button';
import { ReactComponent as LinkIcon } from '../../assets/link-icon.svg';
import { ManageStudentInformationDrawer } from './ManageStudentInformationDrawer';
import { Modal } from 'feature/common/components/Modal';
import { ProviderIcon } from 'feature/common/components/ProviderIcon';
import { ReactComponent as ResetPasswordIcon } from '../../assets/reset-password.svg';
import { SchoolStudentListQuery } from '@aksorn/teaching-backoffice-sdk';
import { SchoolStudentListSortBy } from './student-management';
import { SearchOutlined } from '@ant-design/icons';
import { SortOrder } from 'feature/common/global';
import { SorterResult } from 'antd/lib/table/interface';
import { TableProps } from 'antd/lib/table';
import { UserResetDefaultPasswordBody } from '@aksorn/one-account-sdk';
import env from '@beam-australia/react-env';
import { formatDateTh } from 'utils/formatDate';
import { isArray } from 'lodash';
import { recordReactionAsCommitted } from 'mobx-react-lite/dist/utils/reactionCleanupTracking';
import { useClassroomNames } from 'hooks/classroom-name/useClassroomNames';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useDeleteClassroomStudents } from '../../hooks/student/useDeleteClassroomStudents';
import { useDeleteStudents } from '../../hooks/student/useDeleteStudents';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';
import { useRecreateInvitationSchoolStudent } from 'hooks/student/useRecreateInvitationSchoolStudent';
import { useResetPassword } from 'hooks/user/useResetPassword';
import { useSchoolStudents } from 'hooks/student/useSchoolStudents';
import { userStore } from 'stores/userStore';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const SELECT_ALL = 'all';

type Props = {
  onEdit: (id: string) => void;
  onDeleting: (id: string) => void;
  onResend: (schoolStudentId: string) => void;
  onResetPassword: (
    userId: string,
    providerId: string,
    email: string,
    studentName: string
  ) => void;
  dataSource: any;
  rowSelection: any;
  total: number;
  loading: boolean;
  setSortBy: (sortOrder: SchoolStudentListSortBy) => void;
  sortBy: string;
};

const StudentTable = ({
  onEdit,
  onDeleting,
  onResend,
  onResetPassword,
  dataSource,
  loading,
  rowSelection,
  total,
  setSortBy,
  sortBy
}: Props) => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, any | null>>(
    {}
  );
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    //console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<any>);
    const mySort = isArray(sorter) ? sorter.shift() : sorter;
    if (
      (mySort?.column as SorterResult<any>) === undefined &&
      mySort?.order === undefined
    ) {
      setSortBy(SchoolStudentListSortBy.CLASSROOM_NAME_ASC);
    }
  };
  const [selectedCurrentSort, setSelectedCurrentSort] = useState('');
  const columns = [
    {
      title: 'ห้อง',
      dataIndex: 'classroom',
      key: 'classroom',
      render: (classroom: ClassroomDto) => {
        return (
          <Fragment>
            {classroom?.gradeLevel?.name?.th + '/' + classroom?.classroomName}
          </Fragment>
        );
      },
      sorter: (
        a: SchoolStudentDto,
        b: SchoolStudentDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolStudentListSortBy.CLASSROOM_NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolStudentListSortBy.CLASSROOM_NAME_DESC);
            break;
          default:
            break;
        }
      },
      sortOrder: sortedInfo.columnKey === 'classroom' ? sortedInfo.order : null
    },

    {
      title: 'เลขที่',
      dataIndex: 'classroomStudentNumber',
      key: 'classroomStudentNumber',
      render: (classroomStudentNumber: string, record: SchoolStudentDto) => {
        return <Fragment>{record?.classroomStudentNumber}</Fragment>;
      },
      sorter: (
        a: SchoolStudentDto,
        b: SchoolStudentDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolStudentListSortBy.CLASSROOM_STUDENT_NUMBER_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolStudentListSortBy.CLASSROOM_STUDENT_NUMBER_DESC);
            break;
          default:
            break;
        }
      },
      sortOrder:
        sortedInfo.columnKey === 'classroomStudentNumber'
          ? sortedInfo.order
          : null
    },

    {
      title: 'บัญชีผู้ใช้งาน (' + total.toString() + ')',
      // dataIndex: 'temp_email',
      // key: 'temp_email',
      render: (record: SchoolStudentDto) => {
        return (
          <Fragment>
            {record?.isTemp === true
              ? record.email
              : record.emailAlternate
              ? record.emailAlternate
              : '-'}
          </Fragment>
        );
      }
      // sorter: (
      //   a: SchoolStudentDto,
      //   b: SchoolStudentDto,
      //   sortOrder: SortOrder
      // ) => {
      //   switch (sortOrder) {
      //     case SortOrder.ASCEND:
      //       setSortBy(SchoolStudentListSortBy.EMAIL_ASC);
      //       break;
      //     case SortOrder.DESCEND:
      //       setSortBy(SchoolStudentListSortBy.EMAIL_DESC);
      //       break;
      //     default:
      //       break;
      //   }
      // }
    },
    {
      title: 'นักเรียน (' + total.toString() + ')',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (firstName: Localization, record: SchoolStudentDto) => {
        return record?.firstName?.th && record?.lastName?.th ? (
          <Fragment>
            {record?.title + firstName?.th + ' ' + record?.lastName?.th}
          </Fragment>
        ) : (
          <Fragment>-</Fragment>
        );
      },
      sorter: (
        a: SchoolStudentDto,
        b: SchoolStudentDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolStudentListSortBy.STUDENT_NAME_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolStudentListSortBy.STUDENT_NAME_DESC);
            break;
          default:
            break;
        }
      },
      sortOrder: sortedInfo.columnKey === 'firstName' ? sortedInfo.order : null
    },

    {
      title: 'อีเมล',
      dataIndex: 'email',
      key: 'email',
      render: (email: string, record: SchoolStudentDto) => {
        return (
          <Fragment>
            {record.isTemp === false ? (
              <div className="flex items-center">
                <ProviderIcon
                  providerName={record?.provider?.name}
                  className="mr-2 w-5 h-5"
                />{' '}
                <div className="my-0">{email}</div>
              </div>
            ) : (
              // : record.emailAlternate
              //   ? record.emailAlternate
              '-'
            )}
          </Fragment>
        );
      },
      sorter: (
        a: SchoolStudentDto,
        b: SchoolStudentDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolStudentListSortBy.EMAIL_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolStudentListSortBy.EMAIL_DESC);
            break;
          default:
            break;
        }
      },
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null
    },

    {
      title: 'สถานะ',
      dataIndex: 'emailVerifiedAt',
      key: 'emailVerifiedAt',
      render: (emailVerifiedAt: string, record: SchoolStudentDto) => (
        <div className="flex space-x-2 items-left justify-left">
          <div
            className={`${
              true ? 'text-status-success' : 'text-status-waiting'
            }`}
          >
            {true ? 'ใช้งานได้' : 'รอยืนยันตัวตน'}
          </div>
          {/* {!emailVerifiedAt ? (
            <LinkIcon
              title="ส่ง Link ยืนยันตัวตนใหม่"
              className="cursor-pointer"
              onClick={() => onResend(record?.schoolStudentId)}
            />
          ) : (
            ''
          )} */}
        </div>
      )
    },
    {
      title: 'แก้ไขล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => (
        // <div>{dayjs(text).add(543, 'year').format('DD/MM/YYYY')}</div>
        <div>{formatDateTh(text, 'DD/MM/YYYY')}</div>
      ),
      sorter: (
        a: SchoolStudentDto,
        b: SchoolStudentDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolStudentListSortBy.UPDATED_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolStudentListSortBy.UPDATED_AT_DESC);
            break;
          default:
            break;
        }
      },
      sortOrder: sortedInfo.columnKey === 'updatedAt' ? sortedInfo.order : null
    },
    {
      title: 'ใช้งานล่าสุด',
      dataIndex: 'lastLoggedInAt',
      key: 'lastLoggedInAt',
      render: (text: any) => (
        // <div>{dayjs(text).add(543, 'year').format('DD/MM/YYYY')}</div>
        <div>{text == null ? '' : formatDateTh(text, 'DD/MM/YYYY')}</div>
      ),
      sorter: (
        a: SchoolStudentDto,
        b: SchoolStudentDto,
        sortOrder: SortOrder
      ) => {
        switch (sortOrder) {
          case SortOrder.ASCEND:
            setSortBy(SchoolStudentListSortBy.LAST_LOGGEDIN_AT_ASC);
            break;
          case SortOrder.DESCEND:
            setSortBy(SchoolStudentListSortBy.LAST_LOGGEDIN_AT_DESC);
            break;
          default:
            break;
        }
      },
      sortOrder:
        sortedInfo.columnKey === 'lastLoggedInAt' ? sortedInfo.order : null
    },
    {
      title: '',
      render: (text: any, record: SchoolStudentDto) => (
        <div className="flex space-x-8 items-center justify-center">
          {/* {record.emailVerifiedAt ? ( */}
          {true ? (
            <ResetPasswordIcon
              title="Reset Password"
              className={
                !record.provider.isSocial
                  ? 'cursor-pointer'
                  : 'cursor-not-allowed'
              }
              css={css`
                ${!record.provider.isSocial ? '' : `path { fill: #e3e4e8; }`}
              `}
              onClick={() => {
                if (!record.provider.isSocial) {
                  onResetPassword(
                    record?.userId,
                    record?.provider.providerId,
                    record?.email,
                    `${record?.title ? record?.title : ''}${
                      record?.firstName?.th ? record?.firstName?.th : ''
                    } ${record?.lastName?.th ? record?.lastName?.th : ''}`
                  );
                }
              }}
            />
          ) : (
            <div className="w-3 h-4" />
          )}
          {/* <div className="w-3 h-4" /> */}
          {/* {!record.emailVerifiedAt ? ( */}
          {false ? (
            // <EditIcon
            //   className="cursor-pointer"
            //   onClick={() => onEdit(record?.classroomStudentId)}
            // />
            <EditIcon
              className="cursor-not-allowed"
              css={css`
                path {
                  fill: #e3e4e8;
                }
              `}
            />
          ) : (
            <div className="w-3 h-4" />
          )}
          <DeleteIcon
            title="ลบนักเรียนออกจากห้อง"
            // className={
            //   !!record.isTemp ? 'cursor-pointer' : 'cursor-not-allowed'
            // }
            // css={css`
            //   ${!!record.isTemp ? '' : `path { fill: #e3e4e8; }`}
            // `}
            className={'cursor-pointer'}
            onClick={() => {
              // if (!!record.isTemp) {
              onDeleting(record.classroomStudentId);
              // }
            }}
          />
        </div>
      )
    }
  ];

  return (
    <Table
      rowSelection={rowSelection}
      dataSource={dataSource}
      columns={columns as any}
      loading={loading}
      pagination={false}
      rowKey="classroomStudentId"
      onChange={handleChange}
    />
  );
};

export const StudentListTable = ({
  schoolYearId,
  defaultPassword
}: {
  schoolYearId: string;
  defaultPassword: string;
}) => {
  const history = useHistory();
  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [selectedSchoolStudentId, setSelectedSchoolStudentId] = useState('');
  const [selectedStudentName, setSelectedStudentName] = useState('');
  const [selectedStudentUserId, setSelectedStudentUserId] = useState('');
  const [selectedStudentProviderId, setSelectedStudentProviderId] = useState(
    ''
  );
  const [selectedEmail, setSelectedEmail] = useState('');
  const [showManageStudentDrawer, setShowManageStudentDrawer] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteManyModal, setOpenDeleteManyModal] = useState(false);
  const [
    openDeleteManySchoolStudentsModal,
    setOpenDeleteManySchoolStudentsModal
  ] = useState(false);
  const [openResendModal, setOpenResendModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [keyword, setKeyword] = useState('');
  const [gradeLevels, setGradeLevels] = useState<string[]>([]);
  const [classroomNames, setClassroomNames] = useState<string[]>([]);
  const [isDocDownload, setIsDocDownload] = useState(false);
  const [sortBy, setSortBy] = useState<SchoolStudentListSortBy>(
    SchoolStudentListSortBy.CLASSROOM_NAME_ASC
  );
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { execute: deleteStudents } = useDeleteStudents();
  const { execute: deleteClassroomStudents } = useDeleteClassroomStudents();
  const {
    execute: recreateInvitationSchoolStudent
  } = useRecreateInvitationSchoolStudent();
  const { execute: resetPassword } = useResetPassword();
  const {
    data: gradeLevelsData,
    loading: gradeLevelsLoading
  } = useGradeLevels();
  const {
    data: classroomNamesData,
    loading: classroomNameLoading,
    execute: getClassroomNames
  } = useClassroomNames();
  const {
    data: schoolStudents,
    loading: schoolStudentsLoading,
    execute: getSchoolStudents,
    total
  } = useSchoolStudents();
  const rowSelection = {
    selectedRowKeys,
    onChange: (selected: any) => {
      // console.log('selected: ' + selected);
      setSelectedRowKeys(selected);
    }
  };

  const { me } = userStore;

  const totalLimitDownloadRecord: number = 500;
  const msgLimitDownloadRecord = `สามารถ ดาวน์โหลดได้ครั้งล่ะไม่เกิน ${totalLimitDownloadRecord} คน กรุณากรองด้วยระดับชั้น หรือห้องเรียน เพิ่มเติม`;

  const gradeLevelsTreeData = [
    {
      title: 'ทั้งหมด',
      value: SELECT_ALL,
      key: SELECT_ALL,
      children: gradeLevelsData?.map(({ name, gradeLevelId }) => ({
        title: name.th,
        value: gradeLevelId,
        key: gradeLevelId
      }))
    }
  ];
  const classroomNamesTreeData = [
    {
      title: 'ทั้งหมด',
      value: SELECT_ALL,
      key: SELECT_ALL,
      children: classroomNamesData?.map(({ classroomName }) => ({
        title: classroomName,
        value: classroomName,
        key: classroomName
      }))
    }
  ];

  const selectAllGradeLevelIds = useMemo(
    () => gradeLevelsData?.map(({ gradeLevelId }) => gradeLevelId),
    [gradeLevelsData]
  );

  const selectAllClassroomNames = useMemo(
    () => classroomNamesData?.map(({ classroomName }) => classroomName),
    [classroomNamesData]
  );

  useEffect(() => {
    if (!schoolYearId) return;
    getClassroomNames(schoolYearId);
  }, [schoolYearId]);

  useEffect(() => {
    if (!gradeLevelsLoading) {
      setGradeLevels(selectAllGradeLevelIds);
    }
  }, [selectAllGradeLevelIds]);

  useEffect(() => {
    if (!classroomNameLoading) {
      setClassroomNames(selectAllClassroomNames);
    }
  }, [selectAllClassroomNames]);

  useEffect(() => {
    if (
      schoolYearId &&
      classroomNames &&
      classroomNames.length > 0 &&
      gradeLevels &&
      gradeLevels.length > 0
    ) {
      const request: SchoolStudentListQuery = {
        schoolYearId,
        keyword: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      };

      const isSelectedAllGradeLevels =
        gradeLevelsData.length === gradeLevels.length;
      if (gradeLevels.length && !isSelectedAllGradeLevels) {
        request.gradeLevelIds = gradeLevels?.join(',');
      }

      const isSelectedAllClassroomName =
        classroomNamesData.length === classroomNames.length;
      if (classroomNames.length && !isSelectedAllClassroomName) {
        request.classroomNames = classroomNames?.join(',');
      }

      getSchoolStudents(request);
    }
  }, [schoolYearId, keyword, classroomNames, gradeLevels, sortBy, currentPage]);

  const handleSearch = () => {
    setKeyword(searchText);
    setCurrentPage(1);
  };

  const handleResendUserInvite = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await recreateInvitationSchoolStudent(selectedSchoolStudentId);
      message.success('ส่ง Link ยืนยันตัวตนสำเร็จ');
      setOpenResendModal(false);
      setSelectedSchoolStudentId('');
    } catch (error) {
      message.error('ส่ง Link ยืนยันตัวตนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleDeleteStudent = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await deleteClassroomStudents([selectedStudentId]);

      message.success('ลบนักเรียนออกจากห้องสำเร็จ');
      setSelectedStudentId('');
      setOpenDeleteModal(false);
      await getSchoolStudents({
        schoolYearId,
        gradeLevelIds: gradeLevels?.join(','),
        classroomNames: classroomNames?.join(','),
        keyword: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
    } catch (error) {
      message.error('ลบนักเรียนออกจากห้องไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleDeleteManyStudents = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await deleteClassroomStudents(selectedRowKeys);
      setSelectedRowKeys([]);
      message.success('ลบนักเรียนออกจากห้องสำเร็จ');
      // setSelectedStudentId('');
      setOpenDeleteManyModal(false);
      await getSchoolStudents({
        schoolYearId,
        gradeLevelIds: gradeLevels?.join(','),
        classroomNames: classroomNames?.join(','),
        keyword: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
    } catch (error) {
      message.error('ลบนักเรียนออกจากห้องไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleDeleteManySchoolStudents = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      await deleteStudents(selectedRowKeys);
      setSelectedRowKeys([]);
      message.success('ลบนักเรียนออกจากโรงเรียนสำเร็จ');
      // setSelectedStudentId('');
      setOpenDeleteManySchoolStudentsModal(false);
      await getSchoolStudents({
        schoolYearId,
        gradeLevelIds: gradeLevels?.join(','),
        classroomNames: classroomNames?.join(','),
        keyword: keyword,
        sortBy: sortBy as any,
        pageNumber: currentPage
      });
    } catch (error) {
      message.error('ลบนักเรียนออกจากโรงเรียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleResetPassword = async () => {
    const hide = message.loading('กำลังดำเนินการ');
    try {
      const data = {
        userId: selectedStudentUserId,
        providerId: selectedStudentProviderId
      } as UserResetDefaultPasswordBody;
      await resetPassword({ data });
      message.success('Reset Password สำเร็จ');
      setOpenResetPasswordModal(false);
      setSelectedStudentName('');
      setSelectedEmail('');
      setSelectedStudentUserId('');
      setSelectedStudentProviderId('');
    } catch (error) {
      message.error('Reset Password ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
    } finally {
      hide();
    }
  };

  const handleStudentDownloadExcel = async () => {
    const schoolId = me?.schoolId;
    if (schoolId && currentSchoolYears) {
      if (total > totalLimitDownloadRecord) {
        message.error(msgLimitDownloadRecord);
      } else {
        setIsDocDownload(true);
        try {
          await downloadStudentExcel(
            schoolId,
            currentSchoolYears.schoolYearId,
            currentSchoolYears.name,
            gradeLevels?.join(','),
            classroomNames?.join(',')
          );
        } catch (error) {
          console.log(error);
        }
        setIsDocDownload(false);
      }
    }
  };

  const handleStudentDownloadPdf = async () => {
    const schoolId = me?.schoolId;
    if (schoolId && currentSchoolYears) {
      if (total > totalLimitDownloadRecord) {
        message.error(msgLimitDownloadRecord);
      } else {
        setIsDocDownload(true);
        try {
          await downloadStudentPdf(
            schoolId,
            currentSchoolYears.schoolYearId,
            currentSchoolYears.name,
            gradeLevels?.join(','),
            classroomNames?.join(',')
          );
        } catch (error) {
          console.log(error);
        }
        setIsDocDownload(false);
      }
    }
  };

  const handleStudentDownloadAll = async () => {
    const schoolId = me?.schoolId;
    if (schoolId && currentSchoolYears) {
      if (total > totalLimitDownloadRecord) {
        message.error(msgLimitDownloadRecord);
      } else {
        setIsDocDownload(true);
        try {
          await downloadStudentExcel(
            schoolId,
            currentSchoolYears.schoolYearId,
            currentSchoolYears.name,
            gradeLevels?.join(','),
            classroomNames?.join(',')
          );
        } catch (error) {
          console.log(error);
        }
        try {
          await downloadStudentPdf(
            schoolId,
            currentSchoolYears.schoolYearId,
            currentSchoolYears.name,
            gradeLevels?.join(','),
            classroomNames?.join(',')
          );
        } catch (error) {
          console.log(error);
        }
        setIsDocDownload(false);
      }
    }
  };

  return (
    <div>
      <Form>
        <div className="flex w-full mb-10 items-center justify-between">
          <Form.Item>
            <Input
              className="w-72"
              placeholder="ค้นหาจากรายชื่อนักเรียน"
              suffix={<SearchOutlined onClick={handleSearch} />}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onPressEnter={handleSearch}
            />
          </Form.Item>
          <div className="flex">
            <Form.Item label="ระดับชั้น" className="mr-4">
              <TreeSelect
                placeholder="ระดับชั้น"
                treeData={gradeLevelsTreeData}
                treeDefaultExpandAll={true}
                treeCheckable={true}
                value={gradeLevels}
                showCheckedStrategy={SHOW_PARENT}
                maxTagCount={2}
                onSelect={(value) => {
                  if (value === SELECT_ALL) {
                    setGradeLevels(selectAllGradeLevelIds);
                  } else {
                    setGradeLevels([...gradeLevels, value]);
                  }
                }}
                onDeselect={(value) => {
                  if (value === SELECT_ALL) {
                    setGradeLevels([]);
                  } else {
                    const filteredGradeLevelIds = gradeLevels?.filter(
                      (gradeLevel) => gradeLevel !== value
                    );
                    setGradeLevels(filteredGradeLevelIds);
                  }
                }}
              >
                {gradeLevelsData?.map(({ gradeLevelId, name }) => (
                  <Option value={gradeLevelId} key={gradeLevelId}>
                    {name?.th}
                  </Option>
                ))}
              </TreeSelect>
            </Form.Item>
            <Form.Item label="ห้องเรียน">
              <TreeSelect
                treeData={classroomNamesTreeData}
                treeDefaultExpandAll={true}
                treeCheckable={true}
                value={classroomNames}
                showCheckedStrategy={SHOW_PARENT}
                maxTagCount={2}
                placeholder="ห้องเรียน"
                onSelect={(value) => {
                  if (value === SELECT_ALL) {
                    setClassroomNames(selectAllClassroomNames);
                  } else {
                    setClassroomNames([...classroomNames, value]);
                  }
                }}
                onDeselect={(value) => {
                  if (value === SELECT_ALL) {
                    setClassroomNames([]);
                  } else {
                    const filteredClassroomNames = classroomNames?.filter(
                      (classroomName) => classroomName !== value
                    );
                    setClassroomNames(filteredClassroomNames);
                  }
                }}
              >
                {classroomNamesData?.map(({ classroomName }) => (
                  <Option value={classroomName} key={classroomName}>
                    {classroomName}
                  </Option>
                ))}
              </TreeSelect>
            </Form.Item>
          </div>
        </div>

        <div className="flex justify-between w-full px-3 pr-0 mx-0 mt-9 mb-6 items-center">
          {/* <DeleteIcon
            title="ลบนักเรียนออกจากห้อง"
            className="cursor-pointer"
            onClick={() => {
              if (selectedRowKeys.length !== 0) {
                setOpenDeleteManyModal(true);
              } else {
                message.error('กรุณาเลือกนักเรียนที่ต้องการลบออกจากห้อง');
              }
            }}
          /> */}
          <div>รายการนักเรียนทั้งหมด</div>
          <div className="flex items-center justify-end">
            <div className="ml-4">
              <GhostButton
                className="flex  rounded-lg border-none font-bold "
                css={css`
                  /* background-color: #333333; */
                  /* :hover {
                    background-color: #000000;
                  } */
                  /* color: selectedRowKeys.length === 0 ?#C7C8D1:#333333; */
                `}
                disabled={selectedRowKeys.length === 0}
                onClick={() => {
                  if (selectedRowKeys.length !== 0) {
                    setOpenDeleteManySchoolStudentsModal(true);
                  } else {
                    message.error(
                      'กรุณาเลือกนักเรียนที่ต้องการลบออกจากโรงเรียน'
                    );
                  }
                }}
              >
                <DeleteIcon className="mr-2" /> ลบนักเรียนออกจากโรงเรียน
              </GhostButton>
            </div>
            <div className="ml-4">
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      className="px-4 py-2 text-base text-secondary-gray-60"
                      onClick={() => {
                        handleStudentDownloadAll();
                      }}
                      css={css`
                        white-space: normal;
                        width: 240px;
                      `}
                    >
                      ดาวน์โหลดไฟล์ท้ังหมด
                    </Menu.Item>{' '}
                    <Menu.Item
                      className="px-4 py-2 text-base text-secondary-gray-60"
                      onClick={() => {
                        handleStudentDownloadExcel();
                      }}
                      css={css`
                        white-space: normal;
                        width: 240px;
                      `}
                    >
                      ดาวน์โหลดเฉพาะใบรายการ บัญชีผู้ใช้งาน (ไฟล์ Excel)
                    </Menu.Item>
                    <Menu.Item
                      className="px-4 py-2 text-base text-secondary-gray-60"
                      onClick={() => {
                        handleStudentDownloadPdf();
                      }}
                      css={css`
                        white-space: normal;
                        width: 240px;
                      `}
                    >
                      ดาวน์โหลดเฉพาะบัตรประจำ ตัวบัญชีผู้ใช้งาน (ไฟล์ PDF)
                    </Menu.Item>
                  </Menu>
                }
              >
                <GhostButton
                  className="flex rounded-lg border-none font-bold text-black"
                  // css={css`
                  //   background-color: #fc9d10;
                  //   :hover {
                  //     background-color: #fcbd10;
                  //   }
                  // `}
                  // onClick={() => handleStudentDownload()}
                >
                  <DownloadIcon className="mr-2" />
                  ดาวน์โหลดไฟล์ทั้งหมด
                </GhostButton>
              </Dropdown>
            </div>
            {/* <div className="ml-4">
              <Button
                className="flex  rounded-lg border-none font-bold text-base text-white"
                css={css`
                height:40px;
                  background-color: #fc9d10;
                  :hover {
                    background-color: #fcbd10;
                  }
                `}
                onClick={() => {
                  history.push(`/create-classroom`);
                  //setShowManageStudentDrawer(true)
                }}
              >
                เพิ่มบัญชีผู้ใช้งานนักเรียน
              </Button>
            </div> */}
          </div>
        </div>
        <StudentTable
          onEdit={(id) => {
            setSelectedStudentId(id);
            setShowManageStudentDrawer(true);
          }}
          onDeleting={(id) => {
            setSelectedStudentId(id);
            setOpenDeleteModal(true);
          }}
          onResend={(schoolStudentId) => {
            setSelectedSchoolStudentId(schoolStudentId);
            setOpenResendModal(true);
          }}
          onResetPassword={(userId, providerId, email, studentName) => {
            setSelectedEmail(email);
            if (!studentName) {
              setSelectedStudentName('');
            } else {
              setSelectedStudentName(studentName);
            }
            setSelectedStudentProviderId(providerId);
            setSelectedStudentUserId(userId);
            setOpenResetPasswordModal(true);
          }}
          rowSelection={rowSelection}
          total={total}
          dataSource={schoolStudents}
          loading={schoolStudentsLoading}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
        {total > 0 && (
          <Pagination
            defaultPageSize={20}
            current={currentPage}
            showSizeChanger={false}
            total={total}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
        <ManageStudentInformationDrawer
          title={selectedStudentId ? 'แก้ไขนักเรียน' : 'เพิ่มนักเรียน'}
          visible={showManageStudentDrawer}
          schoolYearId={schoolYearId}
          studentId={selectedStudentId}
          // classroomStudentId={selectedStudentId}
          refetch={() => {
            getSchoolStudents({
              schoolYearId,
              gradeLevelIds: gradeLevels?.join(','),
              classroomNames: classroomNames?.join(','),
              keyword: keyword,
              sortBy: sortBy as any,
              pageNumber: currentPage
            });
          }}
          onClose={() => {
            setShowManageStudentDrawer(false);
            setSelectedStudentId('');
          }}
        />
        <Modal
          title="ยืนยันการนำออกจากระบบ"
          subTitle="กรุณายืนยันเพื่อลบนักเรียนออกจากห้อง"
          cancel={{
            onClick: () => {
              setOpenDeleteModal(false);
              setSelectedStudentId('');
            },
            label: 'ยกเลิก'
          }}
          submit={{
            onClick: handleDeleteStudent,
            label: 'ยืนยัน'
          }}
          visible={openDeleteModal}
        />
        <Modal
          title="ยืนยันการนำออกจากระบบ"
          subTitle="กรุณายืนยันเพื่อลบนักเรียนออกจากห้อง"
          cancel={{
            onClick: () => setOpenDeleteManyModal(false),
            label: 'ยกเลิก'
          }}
          submit={{
            onClick: handleDeleteManyStudents,
            label: 'ยืนยัน'
          }}
          visible={openDeleteManyModal}
        />
        <Modal
          title="ยืนยันการนำออกจากโรงเรียน"
          subTitle="กรุณายืนยันเพื่อลบนักเรียนออกจากโรงเรียน"
          cancel={{
            onClick: () => setOpenDeleteManySchoolStudentsModal(false),
            label: 'ยกเลิก'
          }}
          submit={{
            onClick: handleDeleteManySchoolStudents,
            label: 'ยืนยัน'
          }}
          visible={openDeleteManySchoolStudentsModal}
        />
        <Modal
          title="ยืนยันการส่ง Link ยืนยันตัวตนใหม่"
          subTitle="กรุณายืนยันเพื่อส่ง Link ยืนยันตัวตนใหม่"
          cancel={{
            onClick: () => setOpenResendModal(false),
            label: 'ยกเลิก'
          }}
          submit={{
            onClick: handleResendUserInvite,
            label: 'ยืนยัน'
          }}
          visible={openResendModal}
        />
        <Modal
          title="ยืนยันการรีเซ็ตรหัสผ่าน?"
          subTitle={`หากกดยืนยัน รหัสผ่านของ ${selectedStudentName} (${selectedEmail}) จะถูกรีเซ็ตเป็น 
          ${defaultPassword}`}
          cancel={{
            onClick: () => setOpenResetPasswordModal(false),
            label: 'ยกเลิก'
          }}
          submit={{
            onClick: handleResetPassword,
            label: 'ยืนยัน'
          }}
          visible={openResetPasswordModal}
        />
      </Form>

      <ModalAntd
        visible={isDocDownload}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}
        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2 h-20">
          <p className="text-semibold text-xl mb-2">กำลังดาวน์โหลดเอกสาร</p>
          <p>
            <Spin spinning={isDocDownload}></Spin>
          </p>
        </div>
      </ModalAntd>
    </div>
  );
};
