import React, { useState } from 'react';

import { AssignmentOverallLearningOutcomeContent } from './AssignmentOverallLearningOutcomeContent';
import { AssignmentOverallLearningOutcomeMenu } from './AssignmentOverallLearningOutcomeMenu';
import { LearningOutcomeMenu } from 'feature/assignment-overall/assignment-overall';

export const AssignmnetOverallClassPeriodDetailLearningOutcome = () => {
  const [menu, setMenu] = useState<LearningOutcomeMenu>(
    LearningOutcomeMenu.OVERALL
  );

  return (
    <>
      <AssignmentOverallLearningOutcomeMenu menu={menu} setMenu={setMenu} />
      <AssignmentOverallLearningOutcomeContent menu={menu} />
    </>
  );
};
