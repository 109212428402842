import { Response } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useDeleteClassroomStudents = () => {
  const [{ loading, error }, fire] = useAxios<Response>(
    {
      url: `/classroom-students`,
      method: 'DELETE'
    },
    {
      manual: true
    }
  );

  const execute = async (classroomStudentIds: string[] = []) => {
    return fire({
      data: {
        classroomStudentIds
      }
    });
  };

  return {
    loading,
    error,
    execute
  };
};
