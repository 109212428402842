/** @jsx jsx */

import { Col, Row } from 'antd';
import { css, jsx } from '@emotion/react';

import { ReactComponent as Cancel } from 'assets/cancel.svg';
import { MediaDetailLicenseEmpty } from './MediaDetailLicenseEmpty';
import React from 'react';

interface MediaDetailLicenseListProps {
  licenseData: any;
}

export const MediaDetailLicenseList = ({
  licenseData
}: MediaDetailLicenseListProps) => {
  return (
    <div>
      <div
        css={css`
          height: calc(100vh - 152px);
        `}
      >
        {licenseData?.licenseUsages &&
        licenseData?.licenseUsages?.length > 0 ? (
          licenseData?.licenseUsages?.map((licenseUsage: any) => (
            <div
              className="flex relative mb-6"
              css={css`
                height: 152px;
              `}
            >
              {licenseUsage?.productPrivilegeType === 'premium' ? (
                <div className="absolute z-20 top-0 left-0 mt-2 mr-2 ">
                  <img
                    src={`/assets/aol-premium-flag.svg`}
                    css={css`
                      width: 31px;
                      height: 26px;
                    `}
                  />
                </div>
              ) : null}
              <img
                src={licenseUsage?.coverImage}
                alt="product-cover"
                className="mr-4"
              />
              <Row
                gutter={[0, 8]}
                className="w-full text-base"
                css={css`
                  height: fit-content;
                `}
              >
                <Col className="text-gray-60 text-sm" span={24}>
                  {licenseUsage?.subject?.name?.th}{' '}
                  {licenseUsage?.gradeLevel?.name?.th}
                </Col>
                <Col className="font-bold" span={24}>
                  {licenseUsage?.name?.th}
                </Col>
                {!licenseUsage?.quotaSufficient ? (
                  <React.Fragment>
                    <Col className="text-gray-60" span={24}>
                      จำนวนสื่อไม่เพียงพอ{' '}
                      <span className="font-bold text-primary">
                        {licenseUsage?.quotaRequired} สิทธิ์
                      </span>
                    </Col>
                    <Col className="flex font-bold text-primary" span={24}>
                      <Cancel
                        className="relative mr-2"
                        css={css`
                          top: 2px;
                          path {
                            fill: #ed1c24;
                          }
                        `}
                      />
                      จำนวนสื่อไม่เพียงพอต่อการตัดสิทธิ์เข้าห้องเรียน
                      แต่ยังเพิ่มสื่อและใช้งานได้ตามจำนวน
                      ผู้ดูแลสามารถซื้อสื่อเพิ่มได้ในภายหลัง
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Col className="text-gray-60" span={24}>
                      จำนวนสิทธิ์ที่ตัด{' '}
                      <span className="font-bold text-primary">
                        {licenseUsage?.quotaRequired} สิทธิ์
                      </span>
                    </Col>
                    <Col className="text-gray-60" span={24}>
                      จำนวนสิทธิ์ที่เหลือหลังตัด{' '}
                      <span className="font-bold text-secondary-gray-60">
                        {licenseUsage?.quotaLimit -
                          licenseUsage?.quotaUsage -
                          licenseUsage?.quotaRequired}{' '}
                        สิทธิ์
                      </span>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </div>
          ))
        ) : (
          <MediaDetailLicenseEmpty />
        )}
      </div>
    </div>
  );
};
