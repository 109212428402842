/** @jsx jsx */

import { css, jsx } from '@emotion/react';

export const TwoLineTruncate = ({ children, ...restProps }: any) => (
  <div
    css={css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
    {...restProps}
  >
    {children}
  </div>
);
