import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AssignmentOverallClassPeriodContent } from 'feature/assignment-overall/class-period/AssignmentOverallClassPeriodContent';
import { ReactComponent as BackIcon } from 'assets/back-icon.svg';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { Spin } from 'antd';
import { css } from '@emotion/react';
import { useClassroom } from 'hooks/classroom/useClassroom';

export const AssignmentOverallClassPeriodsPage = () => {
  const history = useHistory();
  const params = useParams();

  const {
    data: classroom,
    loading: classroomLoading,
    execute: getClassroom
  } = useClassroom();

  const { classroomId } = params as any;

  const loading = classroomLoading;

  useEffect(() => {
    if (classroomId) {
      getClassroom(classroomId);
    }
  }, [classroomId]);

  return (
    <PageWrapper className="bg-secondary-white">
      <Spin spinning={loading}>
        <div
          className="flex w-full cursor-pointer items-center mb-10"
          css={css`
            color: #1a1a1a;
          `}
          onClick={() => history.push('/assignment-overall')}
        >
          <BackIcon
            css={css`
              width: 19.2px;
              height: 16.8px;
            `}
          />
          <div className="mx-2 font-bold text-xl">ภาพรวมการส่งงาน</div>
        </div>
        {classroom?.gradeLevel.name.th && classroom?.classroomName && (
          <div className="flex items-center my-4 font-bold text-2xl text-secondary-gray-60">
            {`คาบวิชา ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}
          `}
          </div>
        )}
        <AssignmentOverallClassPeriodContent />
      </Spin>
    </PageWrapper>
  );
};
