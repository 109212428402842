import { MediaDetailForm } from './MediaDetailForm';
import { MediaDetailLicenseList } from './MediaDetailLicenseList';
/** @jsx jsx */
import { jsx } from '@emotion/react';

interface MediaDetailDrawerBodyProps {
  page: string;
  schoolYearId: string;
  schoolLicenseId?: string;
  subjectId?: string;
  gradeLevelId?: string;
  selectedMedia: any;
  setSelectedMedia: Function;
  licenseData: any;
}

export const MediaDetailDrawerBody = ({
  page,
  schoolYearId,
  schoolLicenseId,
  subjectId,
  gradeLevelId,
  selectedMedia,
  setSelectedMedia,
  licenseData
}: MediaDetailDrawerBodyProps) => {
  const renderMediaDetailDrawerBody = () => {
    switch (page) {
      case 'select':
        return (
          <MediaDetailForm
            schoolYearId={schoolYearId as string}
            schoolLicenseId={schoolLicenseId as string}
            subjectId={subjectId as string}
            gradeLevelId={gradeLevelId as string}
            selectedMedia={selectedMedia}
            setSelectedMedia={setSelectedMedia}
          />
        );
      case 'summary':
        return <MediaDetailLicenseList licenseData={licenseData} />;
    }
  };

  return <div>{renderMediaDetailDrawerBody()}</div>;
};
