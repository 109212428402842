import { css, jsx } from '@emotion/react';

/** @jsx jsx */

interface SquareGraphLabelProps {
  color: string;
  label: string;
  description?: string;
}

export const SquareGraphLabel = ({
  color,
  label,
  description
}: SquareGraphLabelProps) => {
  return (
    <div className="flex items-center mr-4">
      <div
        className="w-4 h-4 mr-2"
        css={css`
          background-color: ${color};
        `}
      />
      <div className="text-sm">{label}</div>
      {description && (
        <div className="text-secondary-gray-40 text-sm mx-2">
          ({description})
        </div>
      )}
    </div>
  );
};
