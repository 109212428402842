import { Redirect, useLocation } from 'react-router';

import React from 'react';
import { observer } from 'mobx-react-lite';
import { userStore } from 'stores/userStore';

export const ExchangeTokenPage = observer(() => {
  const { setToken } = userStore;
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';
  const redirectPath = query.get('r') || '';

  localStorage.setItem('token', token);
  setToken(token);

  return <Redirect to={'/' + redirectPath} />;
});
