import {
  LearningUnitOverallHeaderListQuery,
  LearningUnitOverallHeaderListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useLearningUnitOverallHeaders = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<LearningUnitOverallHeaderListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (params: LearningUnitOverallHeaderListQuery) => {
    return fire({
      url: '/learning-units/overall/headers',
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data || [],
    loading: loading,
    execute,
    error: error
  };
};
