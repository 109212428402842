/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useHistory, useLocation } from 'react-router-dom';

import { Card } from 'antd';
import { TwoLineTruncate } from 'feature/common/components/TwoLineTruncate';

interface MediaCardProps {
  //TODO: change to type Media
  media: any;
  schoolYearId: string;
}

export const MediaCard = ({ media, schoolYearId }: MediaCardProps) => {
  const history = useHistory();
  const location = useLocation();
  const {
    coverImage,
    gradeLevel,
    name,
    numberOfLicenseUsage,
    productId,
    productLevel,
    schoolLicenses
  } = media;
  return (
    <Card
      className="border-none shadow-lg cursor-pointer"
      css={css`
        border-radius: 20px;
        width: 244px;
        height: 340px;
        .ant-card-body {
          height: 100%;
          padding: 0;
        }
      `}
      onClick={() =>
        history.push({
          pathname: `/media-management/${productId}/${schoolYearId}`,
          state: location.state
        })
      }
    >
      {productLevel === 'premium' ? (
        <div className="absolute z-20 top-0 left-0 mt-4 mr-4 ">
          <img src={`/assets/aol-premium-flag.svg`} />
        </div>
      ) : null}
      <img
        src={coverImage}
        className="w-full object-cover object-top"
        css={css`
          height: 220px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        `}
        alt="media-placeholder"
      />
      <div
        className="w-full"
        css={css`
          height: 4px;
          background: #6fcf97;
        `}
      ></div>
      <div
        className="w-fit absolute flex justify-center ml-4 -mt-4"
        css={css`
          border-radius: 16px;
          background: #6fcf97;
        `}
      >
        {!!gradeLevel?.name?.th ? (
          <span className="text-sm text-white font-bold mx-2 my-1">
            {gradeLevel.name.th}
          </span>
        ) : (
          <div className="w-9 h-7"></div>
        )}
      </div>
      <div
        className="flex flex-col px-4 py-3 justify-between"
        css={css`
          height: 116px;
        `}
      >
        <TwoLineTruncate className="text-lg font-bold mb-2">
          {name?.th || ''}
        </TwoLineTruncate>
        <span className="text-lg text-gray-60">
          {numberOfLicenseUsage}/{schoolLicenses?.[0]?.quotaLimit || 0} สิทธิ์
        </span>
      </div>
    </Card>
  );
};
