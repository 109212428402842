import {
  LearningUnitOverallStatisticGetDto,
  LearningUnitOverallUserListDto
} from '@aksorn/teaching-backoffice-sdk';
import React, { useEffect, useState } from 'react';

import { ReactComponent as EmptyIcon } from 'assets/evaluation-empty-icon.svg';
import { LearningOutcomeOverallIndividualScoreSection } from './LearningOutcomeOverallIndividualScoreSection';
import { LearningOutcomeOverallOverviewScoreSection } from './LearningOutcomeOverallOverviewScoreSection';
import { LearningOutcomeSortBy } from '../assignment-overall';
import { css } from '@emotion/react';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { getLearningUnitOverallStatistic } from 'api/learning-units/getLearningUnitOverallStatistic';
import { getLearningUnitOverallUsers } from 'api/learning-units/getLearningUnitOverallUsers';
import { useClassPeriod, useBackEndClassPeriod } from 'hooks/class-period/useClassPeriod';
import { useClassroom } from 'hooks/classroom/useClassroom';
import { useParams } from 'react-router-dom';

export const LearningOutcomeOverall = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [
    learningUnitOverallStatistic,
    setLearningUnitOverallStatistic
  ] = useState<LearningUnitOverallStatisticGetDto>();
  const [learningUnitOverallUsers, setLearningUnitOverallUsers] = useState<
    LearningUnitOverallUserListDto[]
  >();
  const [sortBy, setSortBy] = useState(
    LearningOutcomeSortBy.CLASSROOM_STUDENT_NUMBER_ASC
  );

  const { clearEvaluationDownloads } = evaluationDownloadStore;

  const params = useParams();

  const {
    data: classroom,
    loading: isClassroomLoading,
    execute: getClassroom
  } = useClassroom();
  // const {
  //   data: classPeriod,
  //   loading: isClassPeriodLoading,
  //   execute: getClassPeriod
  // } = useClassPeriod();
  const {
    data: classPeriod,
    loading: isClassPeriodLoading,
    execute: getBackEndClassPeriod
  } = useBackEndClassPeriod();

  const { classroomId } = params as any;
  const { classPeriodId } = params as any;

  const loading = isLoading || isClassroomLoading;

  useEffect(() => {
    getInitialData(classPeriodId);

    return () => {
      clearEvaluationDownloads();
    };
  }, []);

  useEffect(() => {
    if (classroomId) {
      getClassroom(classroomId);
    }
  }, [classroomId]);

  useEffect(() => {
    if (classPeriodId) {
      // getClassPeriod(classPeriodId);
      getBackEndClassPeriod(classPeriodId);
    }
  }, [classPeriodId]);

  useEffect(() => {
    if (!isLoading) {
      fetchLearningUnitOverallUsers({
        classPeriodId: classPeriodId,
        sortBy: sortBy
      });
    }
  }, [sortBy]);

  const getInitialData = async (classPeriodId: string) => {
    try {
      setIsLoading(true);
      const {
        data: learningUnitOverallStatistic
      } = await getLearningUnitOverallStatistic({
        classPeriodId
      });
      const {
        data: learningUnitOverallUsers
      } = await getLearningUnitOverallUsers({ classPeriodId });

      setLearningUnitOverallStatistic(learningUnitOverallStatistic);
      setLearningUnitOverallUsers(learningUnitOverallUsers);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLearningUnitOverallUsers = async ({
    classPeriodId,
    sortBy
  }: {
    classPeriodId: string;
    sortBy: LearningOutcomeSortBy;
  }) => {
    try {
      const {
        data: learningUnitOverallUsers
      } = await getLearningUnitOverallUsers({
        classPeriodId,
        sortBy: sortBy as any
      });
      setLearningUnitOverallUsers(learningUnitOverallUsers);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!loading &&
      !isClassPeriodLoading &&
      !learningUnitOverallStatistic &&
      !learningUnitOverallUsers ? (
        <div
          className="flex flex-col justify-center items-center py-10 sm:py-12 md:py-14 lg:py-16 xl:py-20 w-full"
          css={css`
            height: calc(100vh - 350px);
          `}
        >
          <EmptyIcon />
          <span className="text-secondary-gray-60 text-base mt-5 font-bold">
            ไม่มีข้อมูล
          </span>
        </div>
      ) : (
        <>
          <LearningOutcomeOverallOverviewScoreSection
            loading={loading || isClassPeriodLoading}
            learningUnitOverallStatistic={learningUnitOverallStatistic}
            classroom={classroom}
            classPeriod={classPeriod}
          />
          <LearningOutcomeOverallIndividualScoreSection
            loading={loading}
            learningUnitOverallStatistic={learningUnitOverallStatistic}
            learningUnitOverallUsers={learningUnitOverallUsers}
            classroom={classroom}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </>
      )}
    </>
  );
};
