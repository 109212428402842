import { css, jsx } from '@emotion/react';

/** @jsx jsx */

interface LineGraphLabelProps {
  color: string;
  label: string;
  description?: string;
}

export const LineGraphLabel = ({
  color,
  label,
  description
}: LineGraphLabelProps) => {
  return (
    <div className="flex items-center mr-4">
      <div
        className="w-4 border mr-2"
        css={css`
          border-color: ${color};
        `}
      />
      <div className="text-secondary-black text-sm">{label}</div>
      {description && (
        <div className="text-secondary-gray-40 text-sm mx-2">
          ({description})
        </div>
      )}
    </div>
  );
};
