export enum SchoolType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}
export enum ClassroomType {
  PRIMARY = 'primary',
  INDEPENDENT = 'independent'
}

export enum ClassroomListSortBy {
  TEACHER_NAME_ASC = 'teacherNameAsc',
  TEACHER_NAME_DESC = 'teacherNameDesc',
  CLASSROOM_NAME_ASC = 'classroomNameAsc',
  CLASSROOM_NAME_DESC = 'classroomNameDesc',
  UPDATED_AT_ASC = 'updatedAtAsc',
  UPDATED_AT_DESC = 'updatedAtDesc',
  NUMBER_OF_STUDENT_ASC = 'numberOfStudentAsc',
  NUMBER_OF_STUDENT_DESC = 'numberOfStudentDesc'
}
