import {
  ImportExcelErrorFieldType,
  ValidateExcelErrorResultType,
  ValidationStep
} from 'feature/dashboard/dashboard';
import React, { Fragment, useState } from 'react';

import { Divider } from 'antd';
import { ImportExcelClassroomResponse } from '@aksorn/teaching-backoffice-sdk';
import { ImportExcelErrorAnnotation } from './ImportExcelErrorAnnotation';
import { ImportExcelModal } from './ImportExcelModal';
import { ImportExcelTable } from './ImportExcelTable';
import { TableCell } from 'feature/dashboard/utils/TableCell';

interface ImportExcelClassroomResultProps {
  data: ImportExcelClassroomResponse;
  onClose: () => void;
}

export const classroomColumns = [
  {
    title: 'ปีการศึกษา',
    dataIndex: ['value', 'schoolYear'],
    key: 'schoolYear',
    render: (schoolYear: string, record: any) => (
      <TableCell
        value={schoolYear}
        record={record}
        validateField={ImportExcelErrorFieldType.SCHOOL_YEAR}
      />
    )
  },
  {
    title: 'ระดับชั้น',
    dataIndex: ['value', 'gradeLevel'],
    key: 'gradeLevel',
    render: (gradeLevel: string, record: any) => (
      <TableCell
        value={gradeLevel}
        record={record}
        validateField={ImportExcelErrorFieldType.GRADE_LEVEL}
      />
    )
  },
  {
    title: 'ชือห้อง',
    dataIndex: ['value', 'classroomName'],
    key: 'classroomName',
    render: (classroomName: string, record: any) => (
      <TableCell
        value={classroomName}
        record={record}
        validateField={ImportExcelErrorFieldType.CLASSROOM_NAME}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

const classroomTeacherColumns = [
  {
    title: 'อีเมลครูประจำชั้น',
    dataIndex: ['value', 'email'],
    key: 'email',
    render: (email: string, record: any) => {
      return (
        <TableCell
          value={email}
          record={record}
          validateField={ImportExcelErrorFieldType.EMAIL}
        />
      );
    }
  },
  {
    title: 'คำนำหน้าชื่อ',
    dataIndex: ['value', 'title'],
    key: 'title',
    render: (title: string, record: any) => (
      <TableCell
        value={title}
        record={record}
        validateField={ImportExcelErrorFieldType.TITLE}
      />
    )
  },
  {
    title: 'ชื่อ',
    dataIndex: ['value', 'firstNameTh'],
    key: 'firstNameTh',
    render: (firstNameTh: string, record: any) => (
      <TableCell
        value={firstNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_TH}
      />
    )
  },
  {
    title: 'นามสกุล',
    dataIndex: ['value', 'lastNameTh'],
    key: 'lastNameTh',
    render: (lastNameTh: string, record: any) => (
      <TableCell
        value={lastNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_TH}
      />
    )
  },
  {
    title: 'สถานะครู',
    dataIndex: ['value', 'status'],
    key: 'status',
    render: (status: string, record: any) => (
      <TableCell
        value={status}
        record={record}
        validateField={ImportExcelErrorFieldType.STATUS}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

export const ImportExcelClassroomResult = ({
  data,
  onClose
}: ImportExcelClassroomResultProps) => {
  const [visible, setVisible] = useState(true);

  const classroomDataSource =
    data?.errors &&
    data?.errors.find(
      (error) => error.fieldName === ValidateExcelErrorResultType.CLASSROOM
    )?.fieldErrors;
  const classroomTeacherDataSource =
    data?.errors &&
    data?.errors.find(
      (error) =>
        error.fieldName === ValidateExcelErrorResultType.SCHOOL_TEACHERS
    )?.fieldErrors;

  return (
    <ImportExcelModal
      title={'ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบและอัปโหลดใหม่อีกครั้ง'}
      validationStep={ValidationStep.CLASSROOM}
      visible={visible}
      onClose={() => {
        setVisible(false);
        onClose();
      }}
    >
      {classroomDataSource && (
        <Fragment>
          <ImportExcelTable
            dataSource={classroomDataSource}
            columns={classroomColumns}
            title="ข้อมูลห้องเรียนไม่ถูกต้อง"
            subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
          />
          {classroomTeacherDataSource && <Divider className="my-9" />}
        </Fragment>
      )}
      {classroomTeacherDataSource && (
        <ImportExcelTable
          dataSource={classroomTeacherDataSource}
          columns={classroomTeacherColumns}
          title="ข้อมูลครูประจำชั้น"
          subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
        />
      )}
    </ImportExcelModal>
  );
};
