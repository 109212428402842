import {
  AssignmentUnitListDto,
  EvaluationStatisticGetQuery,
  EvaluationStatisticSubjectDto,
  SubjectDto
} from '@aksorn/teaching-backoffice-sdk';
import { Form, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useEvaluationsStatistic,
  useEvaluationsStatisticLicense
} from 'hooks/evaluation/useEvaluationsStatistic';

import { EVALUATION_ASSIGNMENT_SORT_MAPPER } from 'feature/evaluation/const';
import { EmptyEvaluation } from './components/EmptyEvaluation';
import { EmptyStudent } from './components/EmptyStudent';
import { EvaluationAssignmentSortBy } from 'feature/evaluation/evaluation';
import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { EvaluationEmptyState } from './EvaluationEmptyState';
import { IndividualStudentBarChart } from 'feature/common/barchart/IndividualStudentBarChart';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { OverviewBarChart } from 'feature/common/barchart/OverviewBarChart';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';
import { useAssignmentUnits } from 'hooks/assignment-units/useAssignmentUnits';
import { useEvaluationsUsers } from 'hooks/evaluation/useEvaluationUsers';
import { useSubjects } from 'hooks/subject/useSubjects';

const SELECT_ALL = 'all';

const { Option } = Select;

export const EvaluationAssignmentClassroomScore = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEvaluationUsers, setIsLoadingEvaluationUsers] = useState(
    true
  );
  const [sortBy, setSortBy] = useState(
    EvaluationAssignmentSortBy.STUDENT_NUMBER
  );
  const [selectedSubject, setSelectedSubject] = useState<
    SubjectDto | undefined
  >(undefined);
  const [selectedAssignmentUnit, setSelectedAssignmentUnit] = useState<
    AssignmentUnitListDto | undefined
  >(undefined);
  const [
    evaluationStatisticsOverview,
    setEvaluationStatisticsOverview
  ] = useState<EvaluationStatisticSubjectDto[]>([]);
  // const {
  //   data: evaluationStatistics,
  //   execute: getEvaluationStatistics
  // } = useEvaluationsStatistic();
  const {
    data: evaluationStatistics,
    execute: getEvaluationStatisticsLicense
  } = useEvaluationsStatisticLicense();

  const {
    data: evaluationUsers,
    execute: getEvaluationUsers
  } = useEvaluationsUsers();
  const {
    data: subjects,
    loading: isSubjectsLoading,
    execute: getSubjects
  } = useSubjects();
  const {
    data: assignmentUnits,
    execute: getAssignmentUnits
  } = useAssignmentUnits();

  const getInitialData = async ({ subjectId }: { subjectId: string }) => {
    try {
      setIsLoading(true);
      if (filterData.selectedClassroom?.classroomId) {
        const query: EvaluationStatisticGetQuery = {
          schoolYearId: filterData.selectedSchoolYearId,
          gradeLevelId: filterData.selectedGradeLevel.gradeLevelId,
          classroomId: filterData.selectedClassroom.classroomId
        };
        if (
          filterData.selectedSubject?.subjectId &&
          filterData.selectedGradeLevel
        ) {
          Object.assign(query, {
            subjectId: filterData.selectedSubject?.subjectId
          });
        }
        // await getEvaluationStatistics(query);
        await getEvaluationStatisticsLicense(query);
        await getAssignmentUnits({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ??
            selectedSubject?.subjectId ??
            subjectId
        });

        await getEvaluationUsers({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ??
            selectedSubject?.subjectId ??
            subjectId,
          sortBy: sortBy as any
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEvaluationUser = async (assignmentUnitId?: string) => {
    try {
      setIsLoadingEvaluationUsers(true);
      if (
        filterData.selectedClassroom &&
        selectedSubject &&
        evaluationStatisticsOverview.length > 0
      ) {
        // await getEvaluationStatistics({
        //   schoolYearId: filterData.selectedSchoolYearId,
        //   gradeLevelId: filterData.selectedGradeLevel.gradeLevelId,
        //   classroomId: filterData.selectedClassroom.classroomId,
        //   subjectId:
        //     filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId,
        //   unitNo: selectedAssignmentUnit?.unitNo
        // });
        await getEvaluationStatisticsLicense({
          schoolYearId: filterData.selectedSchoolYearId,
          gradeLevelId: filterData.selectedGradeLevel.gradeLevelId,
          classroomId: filterData.selectedClassroom.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId,
          unitNo: selectedAssignmentUnit?.unitNo
        });
        await getEvaluationUsers({
          classroomId: filterData.selectedClassroom.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId,
          assignmentUnitId,
          sortBy: sortBy as any
        });
        await getAssignmentUnits({
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId:
            filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEvaluationUsers(false);
    }
  };

  const { filterData } = evaluationStore;
  const { clearEvaluationDownloads } = evaluationDownloadStore;

  const barChartMaxScore = useMemo(
    () =>
      evaluationStatisticsOverview?.reduce(
        (maxScore: number, score: EvaluationStatisticSubjectDto) =>
          score.classrooms[0].maxScore > score.gradeLevel.maxScore
            ? maxScore > score.classrooms[0].maxScore
              ? maxScore
              : score.classrooms[0].maxScore
            : maxScore > score.gradeLevel.maxScore
            ? maxScore
            : score.gradeLevel.maxScore,
        0
      ),
    [evaluationStatisticsOverview]
  );

  const classroomSubject = useMemo(() => {
    if (
      selectedSubject &&
      evaluationStatistics &&
      evaluationStatisticsOverview
    ) {
      if (selectedAssignmentUnit) {
        return evaluationStatistics.find(
          (subject) =>
            subject.subjectId ===
            (filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId)
        );
      } else {
        return evaluationStatisticsOverview.find(
          (subject) =>
            subject.subjectId ===
            (filterData.selectedSubject?.subjectId ?? selectedSubject.subjectId)
        );
      }
    }
  }, [
    selectedSubject,
    filterData.selectedSubject?.subjectId,
    evaluationStatisticsOverview,
    evaluationStatistics
  ]);

  const classroomAverageScore = useMemo(() => {
    if (selectedSubject) {
      return `${
        classroomSubject?.classrooms[0].averageScore.toFixed(1) ?? '0.0'
      } คะแนน/${classroomSubject?.classrooms[0].numberOfSubmissions ?? 0} คน`;
    }
  }, [classroomSubject, selectedSubject]);

  const gradeLevelAverageScore = useMemo(() => {
    if (selectedSubject) {
      return `${
        classroomSubject?.gradeLevel.averageScore.toFixed(1) ?? '0.0'
      } คะแนน/${classroomSubject?.gradeLevel.numberOfSubmissions ?? 0} คน`;
    }
  }, [classroomSubject, selectedSubject]);

  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    if (filterData.selectedClassroom && subjects.length > 0) {
      getInitialData({ subjectId: subjects[0].subjectId });
    }

    return () => {
      clearEvaluationDownloads();
    };
  }, [
    filterData.selectedSubject?.subjectId,
    filterData.selectedClassroom?.classroomId,
    isSubjectsLoading
  ]);

  useEffect(() => {
    if (
      evaluationStatistics.length > 0 &&
      selectedAssignmentUnit === undefined &&
      evaluationStatisticsOverview.length === 0
    ) {
      setEvaluationStatisticsOverview(evaluationStatistics);
    }
  }, [evaluationStatistics]);

  useEffect(() => {
    if (selectedSubject) {
      fetchEvaluationUser(selectedAssignmentUnit?.assignmentUnitId);
    }
  }, [selectedAssignmentUnit]);

  useEffect(() => {
    if (selectedSubject) {
      setSelectedAssignmentUnit(undefined);
      fetchEvaluationUser();
    }
  }, [selectedSubject, sortBy]);

  useEffect(() => {
    if (subjects.length > 0) {
      setSelectedSubject(subjects[0]);
    }
  }, [isSubjectsLoading]);

  useEffect(() => {
    setSelectedAssignmentUnit(undefined);
    setSelectedSubject(subjects[0]);
    setEvaluationStatisticsOverview([]);
  }, [
    filterData.selectedSubject?.subjectId,
    filterData.selectedClassroom?.classroomId
  ]);

  const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

  const fileName = useMemo(() => {
    if (filterData.selectedGradeLevel && filterData.selectedClassroom) {
      return `${downloadDate}_${
        filterData.selectedGradeLevel.name.th || 'ทั้งหมด'
      }_${filterData.selectedClassroom?.classroomName || 'ทั้งหมด'}`;
    }
  }, [
    filterData.selectedGradeLevel.gradeLevelId,
    filterData.selectedClassroom?.classroomId,
    selectedSubject
  ]);

  return (
    <div>
      {evaluationStatisticsOverview?.length > 0 ? (
        <div>
          <EvaluationContainer
            loading={{
              isLoading: isLoading,
              height: 202
            }}
            className="mb-8"
            title={`ภาพรวมคะแนนรายวิชา ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
            fileName={`${fileName}_${
              filterData.selectedSubject?.name.th ?? 'ทั้งหมด'
            }_ภาพรวมคะแนนรายวิชา`}
          >
            <div className="flex mb-4">
              <SquareGraphLabel color="#FCC88D" label="คะแนนเฉลี่ยรายห้อง" />
              <SquareGraphLabel
                color="#F99F39"
                label={`คะแนนเฉลี่ยทั้งระดับชั้น ${filterData.selectedGradeLevel.name.th}`}
              />
            </div>
            <OverviewBarChart
              axisNames={evaluationStatisticsOverview?.map(
                ({ name }) => name.th
              )}
              dataSource={[
                {
                  barWidth: 32,
                  barColor: '#FCC88D',
                  value: evaluationStatisticsOverview?.map(({ classrooms }) =>
                    classrooms[0].numberOfSubmissions > 0
                      ? classrooms[0].averageScore.toFixed(1)
                      : ''
                  )
                },
                {
                  barWidth: 32,
                  barColor: '#F99F39',
                  value: evaluationStatisticsOverview?.map(({ gradeLevel }) =>
                    gradeLevel.numberOfSubmissions > 0
                      ? gradeLevel.averageScore.toFixed(1)
                      : ''
                  )
                }
              ]}
              chartMaxScore={barChartMaxScore || 100}
              elementHeight={100}
            />
          </EvaluationContainer>
          <EvaluationContainer
            loading={{
              isLoading: isLoading || isLoadingEvaluationUsers,
              height: 202
            }}
            className="mb-8"
            title={`คะแนนรายบุคคล ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
            fileName={`${fileName}_${
              filterData.selectedSubject?.name.th ?? selectedSubject?.name.th
            }_${
              selectedAssignmentUnit?.unitNo
                ? `หน่วยที่${selectedAssignmentUnit?.unitNo}`
                : 'ทั้งหมด'
            }_คะแนนรายบุคคล`}
          >
            <div className="flex justify-between">
              <div className="flex">
                {filterData.selectedSubject?.subjectId === undefined && (
                  <Form.Item label="วิชา" className="w-48 mr-2.5">
                    <Select
                      listHeight={160}
                      value={selectedSubject?.subjectId || SELECT_ALL}
                      onSelect={(subjectId: string) => {
                        const selectedSubject =
                          subjects.find(
                            (subject) => subjectId === subject.subjectId
                          ) || undefined;
                        setSelectedSubject(selectedSubject);
                      }}
                    >
                      {subjects.map((subject) => (
                        <Option
                          key={subject.subjectId}
                          value={subject.subjectId}
                        >
                          {subject.name.th}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item label="หน่วยการเรียนรู้" className="w-48 mr-2.5">
                  <Select
                    listHeight={160}
                    value={
                      selectedAssignmentUnit?.assignmentUnitId || SELECT_ALL
                    }
                    onSelect={(assignmentUnitId) => {
                      const selectedUnitId =
                        assignmentUnits.find(
                          (unit) => unit.assignmentUnitId === assignmentUnitId
                        ) || undefined;
                      setSelectedAssignmentUnit(selectedUnitId);
                    }}
                  >
                    <Option value={SELECT_ALL}>ทั้งหมด</Option>
                    {assignmentUnits.map((unit) => (
                      <Option
                        key={unit.assignmentUnitId}
                        value={unit.assignmentUnitId}
                      >
                        {/* {unit.unitNo} */}
                        {unit.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div>
                <Form.Item
                  label="เรียงตาม"
                  className="w-48"
                  css={css`
                    margin-bottom: 0 !important;
                  `}
                >
                  <Select
                    value={sortBy}
                    onSelect={(value) => {
                      setSortBy(value);
                    }}
                  >
                    {Object.values(EvaluationAssignmentSortBy).map((sortBy) => (
                      <Option key={sortBy} value={sortBy}>
                        {EVALUATION_ASSIGNMENT_SORT_MAPPER[sortBy]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="flex mb-4">
              <LineGraphLabel
                color="#FF8080"
                label={`คะแนนเฉลี่ย ${filterData.selectedGradeLevel.name.th}/${filterData.selectedClassroom?.classroomName}`}
                description={classroomAverageScore}
              />
              <LineGraphLabel
                color="#ED1C24"
                label={`คะแนนเฉลี่ยทั้งระดับชั้น ${filterData.selectedGradeLevel.name.th}`}
                description={gradeLevelAverageScore}
              />
            </div>
            {filterData.selectedClassroom?.numberOfClassroomStudent ? (
              <>
                {evaluationUsers?.length ? (
                  <IndividualStudentBarChart
                    students={evaluationUsers}
                    dataSource={
                      [
                        {
                          barWidth: 24,
                          barColor: '#F99D34',
                          value: evaluationUsers?.map(
                            ({ totalScore }) => totalScore?.toFixed(1) ?? ''
                          )
                        }
                      ] || []
                    }
                    chartMaxScore={
                      classroomSubject?.gradeLevel
                        ? Math.max(
                            classroomSubject?.gradeLevel.maxScore,
                            classroomSubject?.classrooms[0].maxScore
                          )
                        : 100
                    }
                    markLines={[
                      {
                        lineColor: '#FF8080',
                        value: classroomSubject?.classrooms[0].averageScore ?? 0
                      },
                      {
                        lineColor: '#ED1C24',
                        value: classroomSubject?.gradeLevel.averageScore ?? 0
                      }
                    ]}
                  />
                ) : (
                  <EmptyEvaluation />
                )}
              </>
            ) : (
              <EmptyStudent />
            )}
          </EvaluationContainer>
        </div>
      ) : (
        <EvaluationEmptyState />
      )}
    </div>
  );
});
