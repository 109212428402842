import { LearningOutcomeAssignment } from './LearningOutcomeAssignment';
import { LearningOutcomeMenu } from 'feature/assignment-overall/assignment-overall';
import { LearningOutcomeOverall } from './LearningOutcomeOverall';
import { LearningOutcomePrePostTest } from './LearningOutcomePrePostTest';
import React from 'react';

interface AssignmentOverallLearningOutcomeContentProps {
  menu: LearningOutcomeMenu;
}

export const AssignmentOverallLearningOutcomeContent = ({
  menu
}: AssignmentOverallLearningOutcomeContentProps) => {
  switch (menu) {
    case LearningOutcomeMenu.OVERALL:
      return <LearningOutcomeOverall />;
    case LearningOutcomeMenu.ASSIGNMENT:
      return <LearningOutcomeAssignment />;
    case LearningOutcomeMenu.PRE_POST_TEST:
      return <LearningOutcomePrePostTest />;
    default:
      return <LearningOutcomeOverall />;
  }
};
