import { DayOfWeek, DayOfWeekMapper } from 'feature/common/global';
import {
  ImportExcelErrorFieldType,
  ValidateExcelErrorResultType,
  ValidateExcelResponseMessage,
  ValidationStep
} from 'feature/dashboard/dashboard';
import React, { Fragment, useState } from 'react';

import { Divider } from 'antd';
import { ImportExcelClassPeriodBulkResponse } from '@aksorn/teaching-backoffice-sdk';
import { ImportExcelClassPeriodLicenseCard } from './ImportExcelClassPeriodLicenseCard';
import { ImportExcelErrorAnnotation } from './ImportExcelErrorAnnotation';
import { ImportExcelModal } from './ImportExcelModal';
import { ImportExcelTable } from './ImportExcelTable';
import { TableCell } from 'feature/dashboard/utils/TableCell';

interface ImportExcelClassPeriodResultProps {
  data: ImportExcelClassPeriodBulkResponse;
  onClose: () => void;
}

export const classPeriodColumns = [
  {
    title: 'วิชา',
    dataIndex: ['value', 'subject'],
    key: 'subject',
    render: (subject: string, record: any) => {
      return (
        <TableCell
          value={subject}
          record={record}
          validateField={ImportExcelErrorFieldType.SUBJECT}
        />
      );
    }
  },
  {
    title: 'วันที่เรียน',
    dataIndex: ['value', 'dayOfWeek'],
    key: 'dayOfWeek',
    render: (dayOfWeek: string, record: any) => (
      <TableCell
        value={
          Object.values(DayOfWeek).includes(dayOfWeek as any)
            ? DayOfWeekMapper[dayOfWeek as DayOfWeek]
            : dayOfWeek
        }
        record={record}
        validateField={ImportExcelErrorFieldType.DAY_OF_WEEK}
      />
    )
  },
  {
    title: 'เวลาเริ่มเรียน',
    dataIndex: ['value', 'startTime'],
    key: 'startTime',
    render: (startTime: string, record: any) => (
      <TableCell
        value={startTime?.slice(0, 5)}
        record={record}
        validateField={ImportExcelErrorFieldType.START_TIME}
      />
    )
  },
  {
    title: 'เวลาสิ้นสุด',
    dataIndex: ['value', 'endTime'],
    key: 'endTime',
    render: (endTime: string, record: any) => (
      <TableCell
        value={endTime?.slice(0, 5)}
        record={record}
        validateField={ImportExcelErrorFieldType.END_TIME}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

const classPeriodTeacherColumns = [
  {
    title: 'วิชา',
    dataIndex: ['value', 'subject'],
    key: 'subject',
    render: (subject: string, record: any) => {
      return (
        <TableCell
          value={subject}
          record={record}
          validateField={ImportExcelErrorFieldType.SUBJECT}
        />
      );
    }
  },
  {
    title: 'อีเมลครูประจำวิชา',
    dataIndex: ['value', 'email'],
    key: 'email',
    render: (email: string, record: any) => {
      return (
        <TableCell
          value={email}
          record={record}
          validateField={ImportExcelErrorFieldType.EMAIL}
        />
      );
    }
  },
  {
    title: 'คำนำหน้าชื่อ',
    dataIndex: ['value', 'title'],
    key: 'title',
    render: (title: string, record: any) => (
      <TableCell
        value={title}
        record={record}
        validateField={ImportExcelErrorFieldType.TITLE}
      />
    )
  },
  {
    title: 'ชื่อ',
    dataIndex: ['value', 'firstNameTh'],
    key: 'firstNameTh',
    render: (firstNameTh: string, record: any) => (
      <TableCell
        value={firstNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.FIRSTNAME_TH}
      />
    )
  },
  {
    title: 'นามสกุล',
    dataIndex: ['value', 'lastNameTh'],
    key: 'lastNameTh',
    render: (lastNameTh: string, record: any) => (
      <TableCell
        value={lastNameTh}
        record={record}
        validateField={ImportExcelErrorFieldType.LASTNAME_TH}
      />
    )
  },
  {
    title: 'สถานะครู',
    dataIndex: ['value', 'status'],
    key: 'status',
    render: (status: string, record: any) => (
      <TableCell
        value={status}
        record={record}
        validateField={ImportExcelErrorFieldType.STATUS}
      />
    )
  },
  {
    title: 'หมายเหตุ',
    key: 'action',
    render: (_: any, record: any) => {
      return <ImportExcelErrorAnnotation record={record} />;
    }
  }
];

export const ImportExcelClassPeriodResult = ({
  data,
  onClose
}: ImportExcelClassPeriodResultProps) => {
  const [visible, setVisible] = useState(true);

  const classPeriodLicenseDataSource =
    data?.errors &&
    data?.errors.find(
      (error) => error.fieldName === ValidateExcelErrorResultType.CLASS_PERIODS
    )?.fieldErrors;

  if (data?.message === ValidateExcelResponseMessage.INSUFFICIENT_LICENSES) {
    return (
      <ImportExcelModal
        title={'จำนวนสื่อไม่เพียงพอ'}
        validationStep={ValidationStep.CLASS_PERIOD}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      >
        <h3 className="text-lg font-bold">
          จำนวนสื่อไม่เพียงพอ {classPeriodLicenseDataSource?.length} สิทธิ์
        </h3>
        <h5 className="text-sm mt-2">
          จำนวนสิทธิ์ในการใช้สื่อไม่เพียงพอกับจำนวนครูประจำวิชา
          จึงไม่สามารถเพิ่มครูประจำวิชาได้
          กรุณาเพิ่มจำนวนสิทธิ์ก่อนเพิ่มครูเข้าวิชา
        </h5>
        <div className="flex flex-wrap justify-start items-start mt-6">
          {classPeriodLicenseDataSource?.map(
            ({
              gradeLevel,
              coverImage,
              name,
              subject,
              schoolLicenseId
            }: any) => (
              <ImportExcelClassPeriodLicenseCard
                key={schoolLicenseId}
                gradeLevel={gradeLevel}
                coverImage={coverImage}
                name={name}
                subject={subject}
              />
            )
          )}
        </div>
      </ImportExcelModal>
    );
  } else {
    const classPeriodDataSource =
      data?.errors &&
      data?.errors.find(
        (error) =>
          error.fieldName === ValidateExcelErrorResultType.CLASS_PERIODS
      )?.fieldErrors;
    const classPeriodTeacherDataSource =
      data?.errors &&
      data?.errors.find(
        (error) =>
          error.fieldName === ValidateExcelErrorResultType.SCHOOL_TEACHERS
      )?.fieldErrors;

    return (
      <ImportExcelModal
        title={'ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบและอัปโหลดใหม่อีกครั้ง'}
        validationStep={ValidationStep.CLASS_PERIOD}
        visible={visible}
        onClose={() => {
          setVisible(false);
          onClose();
        }}
      >
        {classPeriodDataSource && (
          <Fragment>
            <ImportExcelTable
              dataSource={classPeriodDataSource}
              columns={classPeriodColumns}
              title="ข้อมูลตารางเรียน"
              subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
            />
            {classPeriodTeacherDataSource && <Divider className="my-9" />}
          </Fragment>
        )}
        {classPeriodTeacherDataSource && (
          <ImportExcelTable
            dataSource={classPeriodTeacherDataSource}
            columns={classPeriodTeacherColumns}
            title="ข้อมูลครูประจำวิชา"
            subTitle="กรุณาตรวจสอบข้อมูลก่อนอัปโหลดอีกครั้ง"
          />
        )}
      </ImportExcelModal>
    );
  }
};
