import { Modal } from 'antd';
import { PrimaryButton } from 'feature/common/components/Button';
import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg';

interface AlertModalProps {
  visible: boolean;
  setVisible: Function;
  maxSelectedClassrooms: number;
}

export const AlertModal = ({
  visible,
  setVisible,
  maxSelectedClassrooms
}: AlertModalProps) => {
  return (
    <Modal
      visible={visible}
      width={400}
      footer={null}
      closable={false}
      centered
      bodyStyle={{
        paddingLeft: 40,
        paddingRight: 40
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <WarningIcon />
        <h3 className="font-bold text-xl mt-2 text-center">
          ไม่สามารถเลือกห้องเรียนเพิ่มเติมได้
        </h3>
        <p className="text-md text-center mt-2">
          เนื่องจากสามารถเลือกห้องเรียนที่ต้องการตัดสื่อ ได้ไม่เกิน{' '}
          {maxSelectedClassrooms} รายการต่อครั้ง
        </p>
        <div className="flex justify-center gap-4 mt-8 mx-auto">
          <PrimaryButton className="w-36" onClick={() => setVisible(false)}>
            ปิด
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
