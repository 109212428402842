export enum EvaluationMenu {
  ASSIGNMENT_SCORE = 'assignment-score',
  PRE_POST_TEST = 'pre-post-test'
}

export const EVALUATION_MENU_MAPPER = {
  [EvaluationMenu.ASSIGNMENT_SCORE]: 'คะแนนจากงานที่มอบหมาย (K)',
  [EvaluationMenu.PRE_POST_TEST]: 'แบบทดสอบก่อนเรียน-หลังเรียน'
};

export enum EvaluationAssignmentSortBy {
  STUDENT_NUMBER = 'classroomStudentNumberAsc',
  NAME = 'nameAsc',
  SCORE_DESC = 'totalScoreDesc',
  SCORE_ASC = 'totalScoreAsc'
}

export enum EvaluationPrePostTestType {
  PRE_TEST = 'pre-test',
  POST_TEST = 'post-test'
}
