import React, { useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router';

import env from '@beam-australia/react-env';
import { oneAccountAxios } from 'utils/axios';
import { userStore } from 'stores/userStore';

export const VercelLoginPage = () => {
  const { vercelId } = useParams() as { vercelId: string };

  const history = useHistory();

  if (vercelId !== env('VERCEL_GIT_REPO_ID')) return <Redirect to="/" />;

  const { setToken } = userStore;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    handleLogin();
  }, []);

  const handleLogin = async () => {
    const { data: response } = await oneAccountAxios.post('/auth/login', {
      email: 'pang.chanissa@gmail.com',
      password: '123123aA'
    });

    const token = response.data?.accessToken;

    localStorage.setItem('token', token);
    setToken(token);
    history.push('/');
  };

  return <div></div>;
};
