/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Button } from 'antd';
import classNames from 'classnames';

export const PrimaryButton = ({
  className,
  disable = false,
  ...props
}: any) => (
  <Button
    className={classNames(
      {
        'bg-primary hover:bg-primary-hover text-white': !disable,
        'bg-gray-20 text-black border-gray-20': disable
      },
      'rounded-xl transition-colors duration-500 ease-in-out font-bold text-base border-none h-10',
      className
    )}
    css={css`
      min-width: 120px;
    `}
    type="primary"
    danger
    {...props}
  />
);

export const SecondaryButton = ({
  className,
  disable = false,
  ...props
}: any) => (
  <Button
    /*
    className={`h-10 bg-white text-secondary-gray-60 border-secondary-gray-60 border-2 border-solid rounded-xl transition-colors duration-500 ease-in-out font-bold text-base px-4
    ${className}`}
    */
    className={classNames(
      {
        'text-secondary-gray-60 border-secondary-gray-60': !disable,
        'text-secondary-gray-40 border-secondary-gray-40': disable
      },
      'h-10 bg-white border-2 border-solid rounded-xl transition-colors duration-500 ease-in-out font-bold text-base px-4',
      className
    )}
    css={css`
      min-width: 120px;
    `}
    danger
    {...props}
  />
);

export const GhostButton = ({
  className = '',
  color = '#5c5e70',
  border = 2,
  borderRadius = 10,
  ...props
}: any) => (
  <Button
    className={`font-bold text-base ${className}`}
    css={css`
      min-width: 120px;
      min-height: 40px;
      border: ${border}px solid ${color} !important;
      border-radius: ${borderRadius}px;
      color: ${color} !important;
      :active,
      :focus {
        color: ${color} !important;
        border: ${border}px solid ${color} !important;
      }
      :hover {
        color: #ed1c24 !important;
        border: ${border}px solid #ed1c24 !important;
      }
    `}
    {...props}
  />
);

export const OutlinedButton = ({
  className,
  disable = false,
  ...props
}: any) => (
  <Button
    className={classNames(
      {
        'bg-white hover:bg-gray-100 text-black border-black': !disable,
        'bg-gray-20 text-black border-gray-20': disable
      },
      'rounded-xl transition-colors duration-500 ease-in-out font-bold text-base',
      className
    )}
    css={css`
      min-width: 120px;
      height: 40px;
    `}
    danger
    {...props}
  />
);
