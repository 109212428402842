import { css, jsx } from '@emotion/react';

import { ChangeSchoolDefaultPasswordModal } from './ChangeSchoolDefaultPasswordModal';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';
import { PasswordChangeSuccessModal } from './PasswordChangeSuccessModal';
/** @jsx jsx */
import { Skeleton } from 'antd';
import { useState } from 'react';

interface SchoolDefaultPasswordButtonProps {
  defaultPassword: string;
  schoolYearId?: string;
  fetchDefaultPassword: Function;
  loading: boolean;
  className?: string;
}

export const SchoolDefaultPasswordButton = ({
  defaultPassword,
  schoolYearId,
  fetchDefaultPassword,
  loading,
  className
}: SchoolDefaultPasswordButtonProps) => {
  const [
    changePasswordModalVisible,
    setChangePasswordModalVisible
  ] = useState<boolean>(false);
  const [successModalVisible, setSuccessModalVisible] = useState<boolean>(
    false
  );

  const onChangePasswordSuccess = () => {
    setChangePasswordModalVisible(false);
    fetchDefaultPassword();
    setSuccessModalVisible(true);
  };

  return (
    <div className={className || ''}>
      <div className="flex justify-center items-center h-10 px-4 border-2 rounded-lg text-center border-secondary-gray-60">
        <p className="font-bold mr-3">รหัสผ่านโรงเรียน</p>
        <div className="mr-3">
          {loading ? (
            <Skeleton.Input
              css={css`
                .ant-skeleton-input {
                  width: 54px !important;
                  height: 24px !important;
                  border-radius: 8px;
                }
              `}
              active
            />
          ) : (
            <p className="font-bold text-yellow-500">{defaultPassword}</p>
          )}
        </div>

        <EditIcon
          onClick={() => {
            if (!loading) {
              setChangePasswordModalVisible(true);
            }
          }}
          className={loading ? 'cursor-no-drop' : 'cursor-pointer'}
          css={css`
            path {
              fill: ${loading ? '#c7c8d1' : '#f59e0b'};
            }
          `}
        />
      </div>
      <ChangeSchoolDefaultPasswordModal
        visible={changePasswordModalVisible}
        setVisible={setChangePasswordModalVisible}
        defaultPassword={defaultPassword}
        schoolYearId={schoolYearId}
        onSuccess={onChangePasswordSuccess}
      />
      <PasswordChangeSuccessModal
        visible={successModalVisible}
        setVisible={setSuccessModalVisible}
      />
    </div>
  );
};
