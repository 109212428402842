import React, { useEffect, useMemo, useState } from 'react';
import {
  useEvaluationsPrePostTestStatistic,
  useEvaluationsPrePostTestStatisticLicense
} from 'hooks/evaluation/useEvaluationPrePostTestStatistic';

import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { EvaluationEmptyState } from './EvaluationEmptyState';
import { EvaluationPrePostTestStatisticClassroomDto } from '@aksorn/teaching-backoffice-sdk';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { OverviewBarChart } from 'feature/common/barchart/OverviewBarChart';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import dayjs from 'dayjs';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { evaluationStore } from 'stores/evaluationStore';
import { observer } from 'mobx-react-lite';

export const EvaluationPrePostTestSubjectScore = observer(() => {
  const [
    isLoadingEvaluationPrePostTestStatistic,
    setIsLoadingEvaluationPrePostTestStatistic
  ] = useState(true);
  const { filterData } = evaluationStore;
  const { clearEvaluationDownloads } = evaluationDownloadStore;

  // const {
  //   data: evaluationPrePostTestStatistic,
  //   execute: getEvaluationPrePostTestStatistic
  // } = useEvaluationsPrePostTestStatistic();
  const {
    data: evaluationPrePostTestStatistic,
    execute: getEvaluationPrePostTestStatisticsLicense
  } = useEvaluationsPrePostTestStatisticLicense();

  const getInitailData = async () => {
    try {
      setIsLoadingEvaluationPrePostTestStatistic(false);
      // await getEvaluationPrePostTestStatistic({
      //   schoolYearId: filterData.selectedSchoolYearId,
      //   gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
      //   classroomId: filterData.selectedClassroom?.classroomId,
      //   subjectId: filterData.selectedSubject?.subjectId
      // });
      await getEvaluationPrePostTestStatisticsLicense({
        schoolYearId: filterData.selectedSchoolYearId,
        gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
        classroomId: filterData.selectedClassroom?.classroomId,
        subjectId: filterData.selectedSubject?.subjectId
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEvaluationPrePostTestStatistic(false);
    }
  };

  const fetchEvaluationPrePostTestStatistic = async () => {
    try {
      setIsLoadingEvaluationPrePostTestStatistic(true);
      if (filterData.selectedSubject?.subjectId === undefined) {
        // await getEvaluationPrePostTestStatistic({
        //   schoolYearId: filterData.selectedSchoolYearId,
        //   gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId
        // });
        await getEvaluationPrePostTestStatisticsLicense({
          schoolYearId: filterData.selectedSchoolYearId,
          gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId
        });
      } else {
        // await getEvaluationPrePostTestStatistic({
        //   schoolYearId: filterData.selectedSchoolYearId,
        //   gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
        //   classroomId: filterData.selectedClassroom?.classroomId,
        //   subjectId: filterData.selectedSubject?.subjectId
        // });
        await getEvaluationPrePostTestStatisticsLicense({
          schoolYearId: filterData.selectedSchoolYearId,
          gradeLevelId: filterData.selectedGradeLevel?.gradeLevelId,
          classroomId: filterData.selectedClassroom?.classroomId,
          subjectId: filterData.selectedSubject?.subjectId
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingEvaluationPrePostTestStatistic(false);
    }
  };

  useEffect(() => {
    getInitailData();
    return () => {
      clearEvaluationDownloads();
    };
  }, []);

  useEffect(() => {
    fetchEvaluationPrePostTestStatistic();
  }, [
    filterData.selectedGradeLevel?.gradeLevelId,
    filterData.selectedSubject?.subjectId
  ]);

  const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

  const fileName = useMemo(() => {
    clearEvaluationDownloads();
    if (filterData.selectedGradeLevel) {
      return `${downloadDate}_${
        filterData.selectedGradeLevel.name.th || 'ทั้งหมด'
      }_ทั้งหมด`;
    }
  }, [filterData.selectedGradeLevel, filterData.selectedSubject]);

  return (
    <div>
      {evaluationPrePostTestStatistic?.length ? (
        <>
          {evaluationPrePostTestStatistic?.map(
            ({ subjectId, name, gradeLevel, classrooms }) => {
              return (
                <EvaluationContainer
                  key={subjectId}
                  className="mb-8"
                  loading={{
                    isLoading:
                      !evaluationPrePostTestStatistic ||
                      isLoadingEvaluationPrePostTestStatistic,
                    height: 202
                  }}
                  title={name.th}
                  fileName={
                    fileName &&
                    `${fileName}_${name.th}_แบบทดสอบก่อนเรียน-หลังเรียน`
                  }
                >
                  <div className="flex mb-4">
                    <SquareGraphLabel
                      color="#80D5FF"
                      label="คะแนนเฉลี่ยก่อนเรียน"
                    />
                    <SquareGraphLabel
                      color="#59ACFF"
                      label="คะแนนเฉลี่ยหลังเรียนระดับชั้น"
                    />
                  </div>
                  <div className="flex mb-4">
                    <LineGraphLabel
                      color="#FFD159"
                      label={`คะแนนเฉลี่ยก่อนเรียนระดับชั้น ${gradeLevel.name.th}`}
                      description={`${
                        gradeLevel.pre.averageScore.toFixed(1) ?? '0.0'
                      } คะแนน/${gradeLevel.pre.numberOfSubmissions} คน`}
                    />
                    <LineGraphLabel
                      color="#FFA159"
                      label={`คะแนนเฉลี่ยหลังเรียนระดับชั้น ${gradeLevel.name.th}`}
                      description={`${
                        gradeLevel.post.averageScore.toFixed(1) ?? '0.0'
                      } คะแนน/${gradeLevel.post.numberOfSubmissions} คน`}
                    />
                  </div>
                  <OverviewBarChart
                    dataSource={[
                      {
                        barWidth: 32,
                        barColor: '#80D5FF',
                        value: classrooms.map(
                          (
                            classroom: EvaluationPrePostTestStatisticClassroomDto
                          ) =>
                            classroom.pre.numberOfSubmissions > 0
                              ? classroom.pre.averageScore.toFixed(1)
                              : ''
                        )
                      },
                      {
                        barWidth: 32,
                        barColor: '#59ACFF',
                        value: classrooms.map(
                          (
                            classroom: EvaluationPrePostTestStatisticClassroomDto
                          ) =>
                            classroom.post.numberOfSubmissions > 0
                              ? classroom.post.averageScore.toFixed(1)
                              : ''
                        )
                      }
                    ]}
                    axisNames={classrooms.map(
                      (classroom: EvaluationPrePostTestStatisticClassroomDto) =>
                        gradeLevel.name.th + '/' + classroom.classroomName
                    )}
                    chartMaxScore={
                      classrooms.reduce(
                        (barChartMaxScore, classroom) =>
                          barChartMaxScore >
                          Math.max(
                            classroom.pre.maxScore,
                            classroom.post.maxScore
                          )
                            ? barChartMaxScore
                            : Math.max(
                                classroom.pre.maxScore,
                                classroom.post.maxScore
                              ),
                        0
                      ) || 100
                    }
                    markLines={[
                      {
                        lineColor: '#FFD159',
                        value: gradeLevel.pre.averageScore
                      },
                      {
                        lineColor: '#FFA159',
                        value: gradeLevel.post.averageScore
                      }
                    ]}
                    elementHeight={100}
                  />
                </EvaluationContainer>
              );
            }
          )}
        </>
      ) : (
        <EvaluationEmptyState />
      )}
    </div>
  );
});
