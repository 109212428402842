import { UserGuideListQuery } from '@aksorn/teaching-web-sdk';
import { teachingAxios } from 'utils/axios';
export const getUserGuideByTopic = async (params?: UserGuideListQuery) => {
  const { data: response } = await teachingAxios.get(`/userguide/topic`, {
    params: {
      topic: params?.topic
    }
  });
  return response;
};
