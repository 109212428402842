import {
  ImportExcelSchoolStudentBulkBody,
  ImportExcelSchoolStudentBulkResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useImportExcelValidateSchoolStudents = () => {
  const [_, fire] = useAxios<ImportExcelSchoolStudentBulkResponse>(
    {},
    { manual: true }
  );

  const execute = (data: ImportExcelSchoolStudentBulkBody) => {
    return fire({
      method: 'PUT',
      url: '/import-excel/school-students',
      data
    });
  };

  return {
    execute
  };
};
