import { css, jsx } from '@emotion/react';

import classNames from 'classnames';

/** @jsx jsx */

interface Props {
  onClick: () => void;
  isActive?: boolean;
  className?: string;
  children?: any;
}

export const MenuButton = ({
  isActive,
  onClick,
  children,
  ...restProps
}: Props) => {
  return (
    <div
      className={classNames(
        'mr-2 py-1 lg:py-2 px-6 lg:px-8 cursor-pointer transition-colors duration-200 ease-in-out whitespace-no-wrap text-sm lg:text-base rounded-xl',
        {
          'bg-transparent': !isActive,
          'bg-secondary-light-pink font-bold text-primary': isActive
        }
      )}
      css={css`
        :hover {
          background-color: ${isActive ? '#FFE5E6' : '#fffafa'} !important;
        }
      `}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </div>
  );
};

export const LearningOutcomeMenuButton = ({
  isActive,
  onClick,
  children,
  ...restProps
}: Props) => {
  return (
    <div
      className={classNames(
        'mr-4 py-1 px-3 justify-center cursor-pointer transition-colors duration-200 ease-in-out whitespace-no-wrap rounded-lg text-base border border-secondary-gray-10',
        {
          'bg-transparent': !isActive,
          'bg-secondary-light-pink font-bold text-primary border-primary': isActive
        }
      )}
      css={css`
        :hover {
          background-color: ${isActive ? '#FFE5E6' : '#fffafa'} !important;
        }
      `}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </div>
  );
};
