import {
    LearningUnitOverallHeaderListQuery,
    LearningUnitOverallHeaderListResponse
  } from '@aksorn/teaching-backoffice-sdk';
  import { identity, pickBy } from 'lodash';
  
  import { teachingAxios } from 'utils/axios';
  
  export const getLearningUnitOverallHeaders = async (
    params: LearningUnitOverallHeaderListQuery
  ) => {
    const {
      data: response
    } = await teachingAxios.get<LearningUnitOverallHeaderListResponse>(
      '/learning-units/overall/headers',
      {
        params: {
          pageNumber: 1,
          pageSize: 100,
          ...pickBy(params, identity)
        }
      }
    );
    return response;
  };
  