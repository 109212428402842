import { DataNode, DirectoryTreeProps } from 'antd/lib/tree';
import { Global, css } from '@emotion/react';
import React, { Key, useEffect, useRef, useState } from 'react';
import {
  adminCategory,
  studentCategory,
  teacherCategory
} from './UserGuideData';
import { useHistory, useParams } from 'react-router-dom';

import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import { DownOutlined } from '@ant-design/icons';
// import { Sidebar } from 'features/common/components';
import { observer } from 'mobx-react-lite';

// May 24 jun 2022

type UserGuideData = {
  userGuideId: string;
  userGuideType: string;
  key: number;
  parentId: number;
  seqId: number;
  nodeLevel: number;
  titleEN: string;
  titleTH: string;
  descEN: string;
  descTH: string;
  isPdfUrl: boolean;
  pdfUrl: string;
  isVideoUrl: boolean;
  videoType: string;
  videoUrl: string;
};

interface Props {
  // meType: string;
  setSelectedNodeFromSidebar: (nodeId: string) => void;
  userGuideData: UserGuideData[];
}

export const UserGuideSidebar = ({
  setSelectedNodeFromSidebar,
  userGuideData
}: Props) => {
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [userGuideList, setUserGuideList] = useState([]);
  const [expandedKey, setExpandedKey] = useState<Key[]>([]);
  const params = useParams();
  const history = useHistory();
  const { nodeId, topic } = params as any;
  const componentMount = useRef(true);

  const findUserGuides = async () => {
    await setTreeData(customTree(userGuideData, null));
  };

  useEffect(() => {
    if (treeData.length === 0 && componentMount) findUserGuides();
    return () => {
      componentMount.current = false;
    };
  }, [userGuideData]);

  function customTree(userGuides: any[], pid: any) {
    const guideList: any[] = [];
    if (topic != null && userGuides.length > 0) {
      userGuides.forEach((obj) => {
        if (obj.parentId === pid) {
          const children = customTree(userGuides, obj.key);
          obj.title = obj.titleTH;
          if (children.length > 0) obj.children = children;
          else obj.isLeaf = true;
          const {
            userGuideId,
            seqId,
            nodeLevel,
            titleEN,
            titleTH,
            isPdfUrl,
            pdfUrl,
            isVideoUrl,
            videoUrl,
            descEN,
            descTH,
            createdAt,
            createdBy,
            deletedAt,
            deletedBy,
            updatedAt,
            updatedBy,
            ...content
          } = obj;
          guideList.push(content);
        }
      });
    }
    return guideList;
  }

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    const node = userGuideData.filter((val) => val.key === keys[0])[0];
    if (expandedKey.length > 0 && !info.node.children) {
      for (let i = 0; i < expandedKey.length; i++) {
        const expandedNode = userGuideData.filter(
          (val) => val.key === expandedKey[i]
        )[0];
        if (expandedNode.parentId === node.parentId) {
          const expanded = [...expandedKey];
          expanded.splice(-i);
          setExpandedKey(expanded);
        }
      }
    }
    setSelectedNodeFromSidebar(keys[0].toString());
  };

  const onExpand = (expandedKeys: Key[], { expanded: bool, node }: any) => {
    const selectedNode = userGuideData.filter(
      (val) => val.key === expandedKeys[expandedKeys.length - 1]
    )[0];
    if (!expandedKey.includes(node.key) && bool) {
      if (expandedKey.length) {
        for (let i = expandedKey.length - 1; i >= 0; i--) {
          const expandedNode = userGuideData.filter(
            (val) => val.key === expandedKey[i]
          )[0];
          if (
            expandedNode.parentId === node.parentId &&
            expandedNode.key !== node.key
          ) {
            const expanded = [...expandedKeys];
            expanded.splice(i, 1);
            expandedKeys = expanded;
            break;
          }
        }
      }
    }
    setExpandedKey(expandedKeys);
  };

  return (
    <>
      <div
        className="border-2 px-4 pt-2 h-full"
        css={css`
          overflow-x: hidden;
        `}
      >
        <div className="hidden md:block pt-4 pb-2 px-3">
          {topic === 'teacher' && (
            <div
              key={teacherCategory.titleTH}
              className="flex whitespace-normal text-black font-bold text-xl mt-4 xl:text-xl"
            >
              {teacherCategory.titleTH}
            </div>
          )}
          {topic === 'admin' && (
            <div
              key={adminCategory.titleTH}
              className="flex whitespace-normal text-black font-bold text-xl mt-4 xl:text-xl"
            >
              {adminCategory.titleTH}
            </div>
          )}
          {topic === 'student' && (
            <div
              key={studentCategory.titleTH}
              className="text-black font-bold text-xl mt-4 xl:text-xl"
            >
              {studentCategory.titleTH}
            </div>
          )}
        </div>
        {/* <div className="pl-3 pb-4"> */}
        <Global
          styles={css`
            .ant-tree .ant-tree-treenode {
              font-size: 1.25em;
              line-height: 1.5em;
              // width: 100%;
              padding: 0.5em 0em;
              align-itmes: center;
            }
            .ant-tree.ant-tree-directory
              .ant-tree-treenode-selected:hover::before,
            .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
              background: #fff7f7;
              color: #ed1c24;
              border-radius: 0.5em;
            }
            .ant-tree.ant-tree-directory
              .ant-tree-treenode
              .ant-tree-node-content-wrapper.ant-tree-node-selected {
              color: #ed1c24;
            }
            .ant-tree-switcher {
              right: 0;
              position: absolute;
              align-self: center;
            }
            .ant-tree.ant-tree-directory
              .ant-tree-treenode-selected
              .ant-tree-switcher {
              verticle-align: 0;
            }
            .ant-tree .ant-tree-treenode-switcher-open {
              background: #f4f4f6;
              border-radius: 0.5em;
            }
            .ant-tree-treenode {
              font-size: 1.25em;
              line-height: 1.25em;
            }
            .ant-tree.ant-tree-directory
              .ant-tree-treenode-selected
              .ant-tree-switcher {
              color: #ed1c24;
            }
            .ant-tree-switcher .ant-tree-switcher-icon,
            .ant-tree-switcher .ant-select-tree-switcher-icon {
              vertical-align: 0;
            }
            .ant-tree.ant-tree-directory .ant-tree-treenode {
              padding: 0.5em;
            }
          `}
        />
        <DirectoryTree
          onExpand={onExpand}
          showIcon={false}
          expandedKeys={expandedKey}
          switcherIcon={<DownOutlined />}
          onSelect={onSelect}
          treeData={treeData}
        />
      </div>
      {/* </div> */}
    </>
  );
};
