import {
  SchoolTeacherModifyResponse,
  SchoolTeacherUpdateBody
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useUpdateSchoolTeacher = () => {
  const [
    { data: response, loading, error },
    fire
  ] = useAxios<SchoolTeacherModifyResponse>(
    {
      method: 'PUT'
    },
    { manual: true }
  );

  const execute = ({
    schoolTeacherId,
    data
  }: {
    schoolTeacherId: string;
    data: SchoolTeacherUpdateBody;
  }) => {
    return fire({
      url: `/school-teachers/${schoolTeacherId}`,
      data
    });
  };

  return {
    data: response?.data,
    loading,
    error,
    execute
  };
};
