import {
  AssignmentUnitListDto,
  AssignmentUnitListQuery,
  ClassPeriodGetDto,
  ClassroomDto,
  PrePostTestStatisticGetDto,
  PrePostTestStatisticGetQuery,
  PrePostTestUserListDto,
  PrePostTestUserListQuery
} from '@aksorn/teaching-backoffice-sdk';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import { Global, css } from '@emotion/react';
import {
  LEARNING_OUTCOME_SORT_MAPPER,
  LEARNING_OUTCOME_TEST_TYPE_MAPPER
} from 'feature/assignment-overall/const';
import {
  LearningOutcomeSortBy,
  LearningOutcomeTestType
} from 'feature/assignment-overall/assignment-overall';
import { useEffect, useMemo, useState } from 'react';

import { BarChartProps } from 'feature/common/barchart/barchart';
import { EmptyStudentBarChart } from 'feature/common/barchart/EmptyStudentBarChart';
import { EmptyTable } from 'feature/common/components/Table/EmptyTable';
import { EvaluationContainer } from 'feature/common/components/EvaluationContainer';
import { IndividualStudentBarChart } from 'feature/common/barchart/IndividualStudentBarChart';
import { LearningOutcomePrePostTestFilterBar } from './LearningOutcomePrePostTestFilterBar';
import { LineGraphLabel } from 'feature/common/components/LineGraphLabel';
import { MenuType } from './class-period-detail';
import React from 'react';
import { SquareGraphLabel } from 'feature/common/components/SquareGraphLabel';
import dayjs from 'dayjs';
import { getAssignmentUnitPrePostTestStatistic } from 'api/assignment-units/getAssignmentUnitPrePostTestStatistic';
import { getAssignmentUnitPrePostTestUsers } from 'api/assignment-units/getAssignmentUnitPrePostTestUsers';
import { getAssignmentUnits } from 'api/assignment-units/getAssignmentUnits';
import { observer } from 'mobx-react-lite';

const { Option } = Select;

interface LearningOutcomePrePostTestIndividualScoreSectionProps {
  isLoading: boolean;
  classroom?: ClassroomDto;
  classPeriod?: ClassPeriodGetDto;
  assignmentUnitPrePostTestStatistic?: PrePostTestStatisticGetDto;
}
const checkedListOptions = Object.values(LearningOutcomeTestType);

export const LearningOutcomePrePostTestIndividualScoreSection = observer(
  ({
    isLoading,
    classroom,
    classPeriod,
    assignmentUnitPrePostTestStatistic: defaultAssignmentUnitPrePostTestStatistic
  }: LearningOutcomePrePostTestIndividualScoreSectionProps) => {
    const [sortBy, setSortBy] = useState(
      LearningOutcomeSortBy.CLASSROOM_STUDENT_NUMBER_ASC
    );
    const [menuTypeIds, setMenuTypeIds] = useState<MenuType>(
      MenuType.PRE_POST_TEST
    );
    const [assignmentUnits, setAssignmentUnits] = useState<
      AssignmentUnitListDto[]
    >([]);
    const [selectedAssignmentUnitId, setSelectedAssignmentUnitId] = useState(
      ''
    );
    const [
      isAssignmentUnitPrePostTestUsersLoading,
      setIsAssignmentUnitPrePostTestUsersLoading
    ] = useState(true);
    const [
      assignmentUnitPrePostTestStatistic,
      setAssignmentUnitPrePostTestStatistic
    ] = useState(defaultAssignmentUnitPrePostTestStatistic);
    const [
      assignmentUnitPrePostTestUsers,
      setAssignmentUnitPrePostTestUsers
    ] = useState<PrePostTestUserListDto[]>();
    const [
      individualBarChartDataSource,
      setIndividualBarChartDataSource
    ] = useState<BarChartProps[]>([]);
    const [checkedList, setCheckedList] = useState<string[]>(
      checkedListOptions
    );

    useEffect(() => {
      if (classPeriod && classroom) {
        getInitialData({
          classroomId: classroom?.classroomId,
          // subjectId: classPeriod?.subject.subjectId
          subjectId: classPeriod?.subject?.subjectId
        });
      }
    }, [classPeriod, classroom]);

    useEffect(() => {
      if (classPeriod) {
        fetchAssignmentUnitPrePostTestStatistic({
          classPeriodId: classPeriod?.classPeriodId,
          unitNo: parseInt(selectedAssignmentUnitNo)
        });
        if (classroom) {
          fetchAssignmentUnitPrePostTestUsers({
            classroomId: classroom?.classroomId,
            // subjectId: classPeriod?.subject.subjectId,
            subjectId: classPeriod?.subject?.subjectId,
            selectedAssignmentUnitId: selectedAssignmentUnitId,
            sortBy
          });
        }
      }
    }, [selectedAssignmentUnitId, sortBy, classPeriod, classroom]);

    useEffect(() => {
      if (assignmentUnitPrePostTestUsers) {
        switch (checkedList.length) {
          case 0:
            setIndividualBarChartDataSource([
              {
                barColor: '#80D5FF',
                value: assignmentUnitPrePostTestUsers.map(() => 0)
              }
            ]);
            break;
          case 1:
            const isPreTest = checkedList.includes(
              LearningOutcomeTestType.PRE_TEST
            );
            setMenuTypeIds(isPreTest ? MenuType.PRE_TEST : MenuType.POST_TEST);
            setIndividualBarChartDataSource([
              {
                barColor: isPreTest ? '#80D5FF' : '#59ACFF',
                value: isPreTest
                  ? assignmentUnitPrePostTestUsers.map(
                      (items) => items.preScore?.toFixed(1) ?? ''
                    )
                  : assignmentUnitPrePostTestUsers.map(
                      (items) => items.postScore?.toFixed(1) ?? ''
                    )
              }
            ]);
            break;
          case 2:
            setMenuTypeIds(MenuType.PRE_POST_TEST);
            setIndividualBarChartDataSource([
              {
                barColor: '#80D5FF',
                value: assignmentUnitPrePostTestUsers.map(
                  (items) => items.preScore?.toFixed(1) ?? ''
                )
              },
              {
                barColor: '#59ACFF',
                value: assignmentUnitPrePostTestUsers.map(
                  (items) => items.postScore?.toFixed(1) ?? ''
                )
              }
            ]);
            break;
        }
      }
    }, [checkedList, assignmentUnitPrePostTestUsers]);

    const markLines = useMemo(() => {
      if (!assignmentUnitPrePostTestStatistic) return;
      switch (checkedList.length) {
        case 0:
          return undefined;
        case 1:
          const isPreTest = checkedList[0] === LearningOutcomeTestType.PRE_TEST;
          return [
            {
              lineColor: isPreTest ? '#DF85FF' : '#A175FF',
              value: isPreTest
                ? assignmentUnitPrePostTestStatistic?.pre.classPeriod
                    .averageScore
                : assignmentUnitPrePostTestStatistic?.post.classPeriod
                    .averageScore
            },
            {
              lineColor: isPreTest ? '#FFD159' : '#F99D34',
              value: isPreTest
                ? assignmentUnitPrePostTestStatistic?.pre.gradeLevel
                    .averageScore
                : assignmentUnitPrePostTestStatistic?.post.gradeLevel
                    .averageScore
            }
          ];
        case 2:
          return [
            {
              lineColor: '#DF85FF',
              value:
                assignmentUnitPrePostTestStatistic?.pre.classPeriod.averageScore
            },
            {
              lineColor: '#A175FF',
              value:
                assignmentUnitPrePostTestStatistic?.post.classPeriod
                  .averageScore
            },
            {
              lineColor: '#FFD159',
              value:
                assignmentUnitPrePostTestStatistic?.pre.gradeLevel.averageScore
            },
            {
              lineColor: '#F99D34',
              value:
                assignmentUnitPrePostTestStatistic?.post.gradeLevel.averageScore
            }
          ];
      }
    }, [checkedList, assignmentUnitPrePostTestStatistic]);

    const downloadDate = dayjs()?.add(543, 'year').format('YYYY-MM-DD');

    const fileName = useMemo(() => {
      if (classPeriod && classroom) {
        return `${downloadDate}_${classroom.gradeLevel.name.th}_${classroom.classroomName}_${classPeriod.subject.name.th}`;
      }
    }, [classPeriod, classroom]);

    const selectedAssignmentUnitNo = useMemo(() => {
      const selectedAssignmentUnit =
        assignmentUnits.find(
          (unit) => unit.assignmentUnitId === selectedAssignmentUnitId
        ) || undefined;

      if (selectedAssignmentUnit) {
        return `หน่วยที่${selectedAssignmentUnit.unitNo}`;
      } else {
        return 'คะแนนรวม';
      }
    }, [selectedAssignmentUnitId]);

    const fetchAssignmentUnitPrePostTestStatistic = async ({
      classPeriodId,
      unitNo
    }: {
      classPeriodId: string;
      unitNo: number;
    }) => {
      try {
        const query: PrePostTestStatisticGetQuery = {
          classPeriodId: classPeriodId,
          unitNo: unitNo
        };
        if (selectedAssignmentUnitId) {
          const unitNo = assignmentUnits.find(
            (assignmentUnit) =>
              assignmentUnit.assignmentUnitId === selectedAssignmentUnitId
          )?.unitNo;
          Object.assign(query, { unitNo });
        }
        const {
          data: assignmentUnitPrePostTestStatistic
        } = await getAssignmentUnitPrePostTestStatistic(query);
        setAssignmentUnitPrePostTestStatistic(
          assignmentUnitPrePostTestStatistic
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchAssignmentUnitPrePostTestUsers = async ({
      classroomId,
      subjectId,
      selectedAssignmentUnitId,
      sortBy
    }: {
      classroomId: string;
      subjectId: string;
      selectedAssignmentUnitId: string;
      sortBy: LearningOutcomeSortBy;
    }) => {
      try {
        const query: PrePostTestUserListQuery = {
          classroomId: classroomId,
          subjectId: subjectId,
          menuTypeIds: menuTypeIds,
          assignmentUnitId: selectedAssignmentUnitId,
          sortBy: sortBy as any
        };

        const {
          data: assignmentUnitPrePostTestUsers
        } = await getAssignmentUnitPrePostTestUsers(query);
        setAssignmentUnitPrePostTestUsers(assignmentUnitPrePostTestUsers);
        setIndividualBarChartDataSource([
          {
            barColor: '#80D5FF',
            value: assignmentUnitPrePostTestUsers.map(
              (user) => user.preScore?.toFixed(1) ?? ''
            )
          },
          {
            barColor: '#59ACFF',
            value: assignmentUnitPrePostTestUsers.map(
              (user) => user.postScore?.toFixed(1) ?? ''
            )
          }
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    const getInitialData = async ({
      classroomId,
      subjectId
    }: {
      classroomId: string;
      subjectId: string;
    }) => {
      try {
        setIsAssignmentUnitPrePostTestUsersLoading(true);
        const assignmentUnitQuery: AssignmentUnitListQuery = {
          classroomId: classroomId,
          subjectId: subjectId,
          menuTypeIds: menuTypeIds
        };
        const { data: assignmentUnits } = await getAssignmentUnits(
          assignmentUnitQuery
        );
        setAssignmentUnits(assignmentUnits);

        const prePostTestQuery: PrePostTestUserListQuery = {
          classroomId: classroomId,
          subjectId: subjectId,
          menuTypeIds: menuTypeIds,
          assignmentUnitId: selectedAssignmentUnitId,
          sortBy: sortBy as any
        };
        const {
          data: assignmentUnitPrePostTestUsers
        } = await getAssignmentUnitPrePostTestUsers(prePostTestQuery);
        setAssignmentUnitPrePostTestUsers(assignmentUnitPrePostTestUsers);

        setIndividualBarChartDataSource([
          {
            barColor: '#80D5FF',
            value: assignmentUnitPrePostTestUsers.map(
              (user) => user.preScore?.toFixed(1) ?? ''
            )
          },
          {
            barColor: '#59ACFF',
            value: assignmentUnitPrePostTestUsers.map(
              (user) => user.postScore?.toFixed(1) ?? ''
            )
          }
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsAssignmentUnitPrePostTestUsersLoading(false);
      }
    };

    const getBarChartMaxScore = ({
      assignmentUnitPrePostTestStatistic
    }: {
      assignmentUnitPrePostTestStatistic?: PrePostTestStatisticGetDto;
    }) => {
      if (checkedList.length > 1) {
        return Math.max(
          assignmentUnitPrePostTestStatistic?.pre.gradeLevel.maxScore ?? 100,
          assignmentUnitPrePostTestStatistic?.post.gradeLevel.maxScore ?? 100
        );
      } else {
        if (checkedList[0] === LearningOutcomeTestType.PRE_TEST) {
          return assignmentUnitPrePostTestStatistic?.pre.gradeLevel.maxScore;
        } else if (checkedList[0] === LearningOutcomeTestType.POST_TEST) {
          return assignmentUnitPrePostTestStatistic?.post.gradeLevel.maxScore;
        }
      }
    };

    return (
      <EvaluationContainer
        loading={{
          isLoading:
            !classPeriod ||
            isLoading ||
            isAssignmentUnitPrePostTestUsersLoading,
          height: 562
        }}
        title={`คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียนรายบุคคล ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
        fileName={`${fileName}_คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียนรายบุคคล_${selectedAssignmentUnitNo}`}
      >
        {assignmentUnitPrePostTestUsers &&
        assignmentUnitPrePostTestUsers.length > 0 ? (
          <>
            <Global
              styles={css`
                .ant-checkbox-checked::after {
                  border: none !important;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: #ed1c24;
                  border-color: #ed1c24;
                }
                .ant-col-7 {
                  flex: 0%;
                  max-width: 132px;
                  margin-right: 8px;
                }
              `}
            />
            <div className="flex justify-between h-10 mb-3.5">
              <div className="flex font-bold text-secondary-black items-start">
                <Checkbox.Group
                  className="w-96"
                  defaultValue={checkedListOptions}
                  onChange={(e: any) => {
                    setCheckedList(e);
                  }}
                >
                  <Row>
                    {Object.values(LearningOutcomeTestType).map((checkList) => (
                      <Col key={checkList} span={7}>
                        <Checkbox value={checkList}>
                          {LEARNING_OUTCOME_TEST_TYPE_MAPPER[checkList]}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </div>
              <Form.Item
                label="เรียงตาม"
                className="w-48"
                css={css`
                  margin-bottom: 0 !important;
                `}
              >
                <Select
                  value={sortBy}
                  onSelect={(value) => {
                    setSortBy(value);
                  }}
                >
                  {Object.values(LearningOutcomeSortBy).map((sortBy) => (
                    <Option key={sortBy} value={sortBy}>
                      {LEARNING_OUTCOME_SORT_MAPPER[sortBy]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <LearningOutcomePrePostTestFilterBar
              data={assignmentUnits}
              selectedAssignmentUnitId={selectedAssignmentUnitId}
              setSelectedAssignmentUnitId={setSelectedAssignmentUnitId}
              classPeriodId={classPeriod?.classPeriodId}
            />
            <div className="flex mt-8 text-secondary-black">
              <div
                css={css`
                  width: 136px;
                `}
              >
                <SquareGraphLabel color="#80D5FF" label="คะแนนก่อนเรียน" />
              </div>
              <div>
                <LineGraphLabel
                  color="#DF85FF"
                  label={`คะแนนเฉลี่ยก่อนเรียน ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
                  description={`${
                    assignmentUnitPrePostTestStatistic?.pre.classPeriod.averageScore.toFixed(
                      1
                    ) ?? '0.0'
                  } คะแนน/${
                    assignmentUnitPrePostTestStatistic?.pre.classPeriod
                      .numberOfSubmissions
                  } คน`}
                />
              </div>

              <LineGraphLabel
                color="#FFD159"
                css={css`
                  max-width: 360px;
                `}
                label={`คะแนนเฉลี่ยก่อนเรียนทั้งระดับชั้น`}
                description={`${
                  assignmentUnitPrePostTestStatistic?.pre.gradeLevel.averageScore.toFixed(
                    1
                  ) ?? '0.0'
                } คะแนน/${
                  assignmentUnitPrePostTestStatistic?.pre.gradeLevel
                    .numberOfSubmissions
                } คน`}
              />
            </div>
            <div className="flex my-2">
              <div
                css={css`
                  width: 136px;
                `}
              >
                <SquareGraphLabel color="#59ACFF" label="คะแนนหลังเรียน" />
              </div>
              <div
                css={css`
                  margin-right: 2px;
                `}
              >
                <LineGraphLabel
                  color="#A175FF"
                  label={`คะแนนเฉลี่ยหลังเรียน ${classroom?.gradeLevel.name.th}/${classroom?.classroomName}`}
                  description={`${
                    assignmentUnitPrePostTestStatistic?.post.classPeriod.averageScore.toFixed(
                      1
                    ) ?? '0.0'
                  } คะแนน/${
                    assignmentUnitPrePostTestStatistic?.post.classPeriod
                      .numberOfSubmissions
                  } คน`}
                />
              </div>
              <LineGraphLabel
                color="#F99D34"
                css={css`
                  max-width: 360px;
                `}
                label="คะแนนเฉลี่ยหลังเรียนทั้งระดับชั้น"
                description={`${
                  assignmentUnitPrePostTestStatistic?.post.gradeLevel.averageScore.toFixed(
                    1
                  ) ?? '0.0'
                } คะแนน/${
                  assignmentUnitPrePostTestStatistic?.post.gradeLevel
                    .numberOfSubmissions
                } คน`}
              />
            </div>
            <div className="w-full h-full">
              {checkedList.length === 0 ? (
                <EmptyStudentBarChart
                  className="mt-8"
                  students={assignmentUnitPrePostTestUsers}
                  dataSource={individualBarChartDataSource}
                />
              ) : (
                <IndividualStudentBarChart
                  className="mt-8"
                  students={assignmentUnitPrePostTestUsers}
                  dataSource={individualBarChartDataSource}
                  markLines={markLines}
                  chartMaxScore={
                    getBarChartMaxScore({
                      assignmentUnitPrePostTestStatistic
                    }) || 100
                  }
                  interval={10}
                />
              )}
            </div>
          </>
        ) : (
          <EmptyTable
            emptyClassName="text-secondary-gray-60"
            emptyText={'ไม่มีนักเรียนในห้องเรียน'}
            style={{
              minHeight: '400px'
            }}
          />
        )}
      </EvaluationContainer>
    );
  }
);
