import {
  LearningUnitOverallUserListQuery,
  LearningUnitOverallUserListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useLearningUnitOverallUsers = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<LearningUnitOverallUserListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (params: LearningUnitOverallUserListQuery) => {
    return fire({
      url: '/learning-units/overall/users',
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error
  };
};
