/** @jsx jsx */

import { Card, Col, Divider, Form, Row, Select, Spin } from 'antd';
import { Global, css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';

import { ReactComponent as CheckIcon } from 'assets/check-circle-large.svg';
import { ReactComponent as ClassroomEmptyIcon } from 'assets/classroom-empty.svg';
import { getCover } from 'feature/common/global';
import { getProductLicenseClassPeriod } from 'api/media/getProductLicenseClassPeriod';
import { useForm } from 'antd/lib/form/Form';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';
import { useSubjects } from 'hooks/subject/useSubjects';

const { Option } = Select;
interface MediaDetailFormProps {
  schoolYearId: string;
  schoolLicenseId: string;
  subjectId: string;
  gradeLevelId: string;
  selectedMedia: any[];
  setSelectedMedia: Function;
}

export const MediaDetailForm = ({
  schoolYearId,
  schoolLicenseId,
  subjectId,
  gradeLevelId,
  selectedMedia,
  setSelectedMedia
}: MediaDetailFormProps) => {
  const [mediaLoading, setMediaLoading] = useState<boolean>(true);
  const [media, setMedia] = useState<any>([]);
  const [selectedGradeLevelId, setSelectedGradeLevelId] = useState<any>(
    gradeLevelId
  );
  const [selectedSubjectId, setSelectedSubjectId] = useState<any>(subjectId);

  const {
    data: gradeLevelsData,
    loading: gradeLevelsLoading
  } = useGradeLevels();
  const {
    data: subjectsData,
    loading: subjectsLoading,
    execute: getSubjects
  } = useSubjects();

  const fetchData = async (params: any) => {
    // console.log('params', params);
    setMediaLoading(true);
    try {
      var { data: mediaData } = await getProductLicenseClassPeriod(params);
      mediaData = mediaData.filter(
        (eachMedia: any) => !eachMedia.classPeriodLicenses.length
      );
      // console.log('mediaData', mediaData);
      setMedia(mediaData);
    } catch (error) {
      // console.log('error', error);
    } finally {
      setMediaLoading(false);
    }
  };

  const fetchInitData = async () => {
    setMediaLoading(true);
    try {
      let mediaData = [];
      var { data: classPeriodMediaData } = await getProductLicenseClassPeriod({
        schoolYearId: schoolYearId,
        schoolLicenseId: schoolLicenseId,
        subjectId: subjectId,
        gradeLevelId: gradeLevelId
      });
      mediaData = classPeriodMediaData;
      // console.log('mediaData drawer', mediaData);
      mediaDetailForm.setFieldsValue({
        gradeLevel: gradeLevelId,
        subject: subjectId
      });
      /*
      if (!mediaData.length) {
        var { data: allMediaData } = await getProductLicenseClassPeriod({
          schoolYearId: schoolYearId,
          schoolLicenseId: schoolLicenseId
        });
        mediaData = allMediaData;
        mediaDetailForm.setFieldsValue({
          gradeLevel: '',
          subject: ''
        });
        setSelectedGradeLevelId('');
        setSelectedSubjectId('');
      }
      */
      mediaData = mediaData.filter(
        (eachMedia: any) => !eachMedia.classPeriodLicenses.length
      );
      // console.log('mediaData', mediaData);
      setMedia(mediaData);
    } catch (error) {
      // console.log('error', error);
    } finally {
      setMediaLoading(false);
    }
  };

  useEffect(() => {
    if (!!schoolYearId) {
      fetchInitData();
      getSubjects();
    }
  }, [schoolYearId]);

  const [mediaDetailForm] = useForm();
  const loading = mediaLoading || gradeLevelsLoading || subjectsLoading;

  return (
    <Spin spinning={loading}>
      <Form form={mediaDetailForm}>
        <Global
          styles={css`
            label {
              background-color: #ffffff !important;
            }
          `}
        />
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="gradeLevel"
              label="ระดับชั้น"
              css={css`
                .ant-form-item-label label {
                  height: 20px;
                }
              `}
            >
              <Select
                disabled
                value={selectedGradeLevelId}
                onChange={(value) => {
                  // console.log('gradeId value', value);
                  setSelectedGradeLevelId(value);
                  setSelectedMedia([]);
                  let newParams: any = {
                    schoolYearId: schoolYearId,
                    schoolLicenseId: schoolLicenseId
                  };
                  if (value !== '') {
                    newParams['gradeLevelId'] = value;
                  }
                  if (selectedSubjectId !== '') {
                    newParams['subjectId'] = selectedSubjectId;
                  }
                  fetchData(newParams);
                }}
              >
                <Option value={''} key={'all'}>
                  ทั้งหมด
                </Option>
                {gradeLevelsData?.map(({ gradeLevelId, name }) => (
                  <Option value={gradeLevelId} key={gradeLevelId}>
                    {name.th}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="subject"
              label="วิชา"
              css={css`
                .ant-form-item-label label {
                  height: 20px;
                }
              `}
            >
              <Select
                disabled
                value={selectedSubjectId}
                onChange={(value) => {
                  // console.log('gradeId value', value);
                  setSelectedSubjectId(value);
                  setSelectedMedia([]);
                  let newParams: any = {
                    schoolYearId: schoolYearId,
                    schoolLicenseId: schoolLicenseId
                  };
                  if (selectedGradeLevelId !== '') {
                    newParams['gradeLevelId'] = selectedGradeLevelId;
                  }
                  if (value !== '') {
                    newParams['subjectId'] = value;
                  }
                  fetchData(newParams);
                }}
              >
                <Option value={''} key={'all'}>
                  ทั้งหมด
                </Option>
                {subjectsData?.map(({ subjectId, name }) => (
                  <Option value={subjectId} key={subjectId}>
                    {name.th}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider className="mt-0 mb-6 bg-gray-10" />
        <div
          className="overflow-y-auto"
          css={css`
            height: calc(100vh - 277px);
          `}
        >
          {!loading && !media.length ? (
            <div className="h-full flex flex-col justify-center items-center">
              <ClassroomEmptyIcon className="mb-6" />
              <span className="text-base font-bold text-gray-60 mb-4">
                ไม่พบคาบวิชา
              </span>
            </div>
          ) : (
            media.map((eachMedia: any) => (
              <Card
                key={eachMedia.mediaId}
                className={`${
                  !!selectedMedia.find(
                    (mediaId: any) =>
                      mediaId.classPeriodId === eachMedia?.classPeriodId
                  )
                    ? 'border-solid'
                    : 'border-none'
                } cursor-pointer w-full mb-4`}
                // className={`border-none cursor-pointer w-full mb-4`}
                css={css`
                  border-radius: 12px;
                  height: 144px;
                  box-shadow: 0px 2px 10px rgba(115, 118, 140, 0.15);
                  .ant-card-body {
                    height: 100%;
                    padding: 0;
                  }
                  border: 2px #ff8080;
                `}
                onClick={() => {
                  // console.log('selectedMedia', selectedMedia);
                  if (
                    !!selectedMedia.find(
                      (mediaId: any) =>
                        mediaId.classPeriodId === eachMedia?.classPeriodId
                    )
                  ) {
                    // console.log('case 1');
                    let newSelectedMedia = selectedMedia;
                    newSelectedMedia = newSelectedMedia.filter(
                      (mediaId: any) =>
                        mediaId.classPeriodId !== eachMedia?.classPeriodId
                    );
                    setSelectedMedia(newSelectedMedia);
                  } else {
                    // console.log('case 2');
                    // setSelectedMedia([
                    //   // ...selectedMedia,
                    //   {
                    //     classPeriodId: eachMedia?.classPeriodId,
                    //     gradeLevelId:
                    //       eachMedia?.classroom?.gradeLevel?.gradeLevelId,
                    //     subjectId: eachMedia?.subject?.subjectId
                    //   }
                    // ]
                    // );

                    ///punn edit 22/08/22
                    setSelectedMedia([
                      ...selectedMedia,
                      {
                        classPeriodId: eachMedia?.classPeriodId,
                        gradeLevelId:
                          eachMedia?.classroom?.gradeLevel?.gradeLevelId,
                        subjectId: eachMedia?.subject?.subjectId
                      }
                    ]);
                  }
                }}
              >
                <div className="flex">
                  <div
                    className="relative"
                    css={css`
                      ${!!selectedMedia.find(
                        (mediaId: any) =>
                          mediaId.classPeriodId === eachMedia?.classPeriodId
                      )
                        ? 'width: 142px; height: 140px;'
                        : 'width: 144px; height: 144px;'}
                    `}
                  >
                    <img
                      src={getCover(eachMedia?.subject?.name?.th)}
                      onError={(e: any) => {
                        e.target.src = '/assets/class-period-cover.png';
                      }}
                      className="absolute object-cover object-center"
                      css={css`
                        width: 100%;
                        height: 100%;
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                        ${!!selectedMedia.find(
                          (mediaId: any) =>
                            mediaId.classPeriodId === eachMedia?.classPeriodId
                        )
                          ? 'opacity: 0.8;'
                          : 'opacity: 1;'}
                      `}
                      alt="media-placeholder"
                    />
                    <CheckIcon
                      className={`absolute w-10 h-10 ${
                        !!selectedMedia.find(
                          (mediaId: any) =>
                            mediaId.classPeriodId === eachMedia?.classPeriodId
                        )
                          ? 'flex'
                          : 'hidden'
                      }`}
                      css={css`
                        top: 50px;
                        left: 51px;
                      `}
                    />
                  </div>

                  <div className="flex flex-col p-4">
                    <span className="text-md text-gray-60">
                      {eachMedia?.classroom?.gradeLevel?.name?.th || 'ป.-'}/
                      {eachMedia?.classroom?.classroomName || '-'}
                    </span>
                    <div className="text-base font-bold">
                      {eachMedia?.classPeriodName || ''}
                    </div>
                    <div className="text-lg text-gray-60">
                      <span>
                        นักเรียน {eachMedia?.numberOfClassroomStudent || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            ))
          )}
        </div>
      </Form>
    </Spin>
  );
};
