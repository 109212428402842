import { ReactComponent as CheckIcon } from 'assets/circle-filled-checked.svg';
import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import { ReactComponent as UncheckIcon } from 'assets/circle-outlined-unchecked.svg';

interface Props {
  active: boolean;
  text: string;
  className?: string;
}

export const ValidateRule = ({ active, text, className }: Props) => {
  return (
    <div
      className={`flex items-baseline text-xs md:text-sm ${className ?? ''}`}
    >
      <div className={'flex items-center'}>
        {active ? (
          <CheckIcon className="mr-3" />
        ) : (
          <UncheckIcon className="mr-3" />
        )}
      </div>
      <p>{text}</p>
    </div>
  );
};
