export enum StudentFormStep {
  EMAIL = 'email',
  BASIC_INFO = 'basicInfo'
}
export enum SchoolStudentListSortBy {
  STUDENT_NAME_ASC = 'studentNameAsc',
  STUDENT_NAME_DESC = 'studentNameDesc',
  EMAIL_ASC = 'emailAsc',
  EMAIL_DESC = 'emailDesc',
  CLASSROOM_NAME_ASC = 'classroomNameAsc',
  CLASSROOM_NAME_DESC = 'classroomNameDesc',
  UPDATED_AT_ASC = 'updatedAtAsc',
  UPDATED_AT_DESC = 'updatedAtDesc',
  LAST_LOGGEDIN_AT_ASC = 'lastLoggedInAtAsc',
  LAST_LOGGEDIN_AT_DESC = 'lastLoggedInAtDesc',
  CLASSROOM_STUDENT_NUMBER_ASC = 'classroomStudentNumberAsc',
  CLASSROOM_STUDENT_NUMBER_DESC = 'classroomStudentNumberDesc',
}
