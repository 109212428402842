/** @jsx jsx */

import { Form, Input, Select, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { css, jsx } from '@emotion/react';

import { MediaType } from 'feature/common/media';
import { SearchOutlined } from '@ant-design/icons';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';
import { useLocation } from 'react-router-dom';
import { useSubjects } from 'hooks/subject/useSubjects';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;
const SELECT_ALL = 'all';

interface MediaManagementFilterBarProps {
  schoolYearId: string;
  setKeyword: Function;
  productPrivilegeType: string;
  setProductPrivilegeType: Function;
  gradeLevelIds: string[];
  setGradeLevelIds: Function;
  subjectIds: string[];
  setSubjectIds: Function;
  sortBy: string;
  setSortBy: Function;
  setCurrentPage: Function;
}

interface stateType {
  data: {
    pageNumber: number;
    selectedSchoolYearId: string;
    mediaTypeFilter: MediaType | string;
    keyword: string;
    productPrivilegeType: string;
    gradeLevelIds: string[];
    subjectIds: string[];
    sortBy: string;
  };
}

export const MediaManagementFilterBar = ({
  schoolYearId,
  setKeyword,
  productPrivilegeType,
  setProductPrivilegeType,
  gradeLevelIds,
  setGradeLevelIds,
  subjectIds,
  setSubjectIds,
  sortBy,
  setSortBy,
  setCurrentPage
}: MediaManagementFilterBarProps) => {
  const { state } = useLocation<stateType>();

  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState(state?.data?.keyword || '');

  const {
    data: gradeLevelsData,
    loading: gradeLevelsLoading
  } = useGradeLevels();
  const {
    data: subjectsData,
    loading: subjectsLoading,
    execute: getSubjects
  } = useSubjects();

  const selectAllGradeLevelIds = useMemo(
    () => gradeLevelsData?.map(({ gradeLevelId }) => gradeLevelId),
    [gradeLevelsData]
  );

  useEffect(() => {
    if (!selectAllGradeLevelIds) return;
    form.setFieldsValue({ gradeLevelIds: SELECT_ALL });
    setGradeLevelIds([SELECT_ALL]);
  }, [selectAllGradeLevelIds]);

  useEffect(() => {
    if (schoolYearId) {
      getSubjects();
    }
  }, [schoolYearId]);

  const productPrivilegeTypeList = [
    {
      value: '',
      name: 'ทั้งหมด'
    },
    {
      value: 'basic',
      name: 'ฉบับปกติ'
    },
    {
      value: 'premium',
      name: 'ฉบับ Aksorn On-Learn PLUS'
    }
  ];
  const gradeLevelsTreeData = [
    {
      title: 'ทั้งหมด',
      value: SELECT_ALL,
      key: SELECT_ALL,
      children: gradeLevelsData?.map(({ name, gradeLevelId }) => ({
        title: name.th,
        value: gradeLevelId,
        key: gradeLevelId
      }))
    }
  ];
  const subjectsTreeData = [
    {
      title: 'ทั้งหมด',
      value: SELECT_ALL,
      key: SELECT_ALL,
      children: subjectsData?.map(({ name, subjectId }) => ({
        title: name.th,
        value: subjectId,
        key: subjectId
      }))
    }
  ];
  const sortByList = [
    {
      value: 'aolBookSeqAsc',
      name: 'กลุ่มสาระการเรียนรู้'
    },
    {
      value: 'updatedAtDesc',
      name: 'ล่าสุด'
    },
    {
      value: 'updatedAtAsc',
      name: 'เก่าสุด'
    },
    {
      value: 'numberOfLicenseUsageAsc',
      name: 'จำนวนสิทธิ์'
    }
  ];

  return (
    <div className="w-full flex flex-col 2xl:flex-row justify-between mb-8">
      <Input
        className="rounded-xl h-10 w-full md:w-72 bg-transparent mb-6 2xl:mb-0"
        placeholder="ค้นหาสื่อเรียน"
        suffix={
          <SearchOutlined
            onClick={() => {
              setKeyword(searchText);
              setCurrentPage(1);
            }}
          />
        }
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onPressEnter={() => {
          setKeyword(searchText);
          setCurrentPage(1);
        }}
      />
      <Form
        className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:gap-0 xl:flex xl:flex-shrink 2xl:flex-shrink-0"
        initialValues={{ gradeLevelIds: state?.data?.gradeLevelIds || ['all'] }}
      >
        <Form.Item
          label="ประเภท"
          css={css`
            .ant-form-item-label label {
              height: 20px;
            }
          `}
          className="mb-0 mr-0 xl:mr-4 2xl:mr-0 ml-0 2xl:ml-4 w-full xl:w-56"
        >
          <Select
            value={productPrivilegeType}
            onChange={(value) => {
              setProductPrivilegeType(value);
              setCurrentPage(1);
            }}
          >
            {productPrivilegeTypeList.map(({ value, name }) => (
              <Option key={value} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="ระดับชั้น"
          name="gradeLevelIds"
          className="mb-0 mr-0 xl:mr-4 2xl:mr-0 ml-0 2xl:ml-4 w-full xl:w-44"
          css={css`
            .ant-form-item-label label {
              height: 20px;
            }
          `}
        >
          <TreeSelect
            placeholder="ระดับชั้น"
            value={gradeLevelIds}
            onChange={(value) => {
              // console.log('gradeLevelIds value', value);
              setGradeLevelIds(value);
              setCurrentPage(1);
            }}
            disabled={gradeLevelsLoading}
            treeData={gradeLevelsTreeData}
            showCheckedStrategy={SHOW_PARENT}
            maxTagCount={2}
            allowClear
            treeCheckable
            treeDefaultExpandAll
            css={css`
              .ant-select-selector {
                min-width: 176px;
              }
            `}
          >
            {gradeLevelsData?.map(({ gradeLevelId, name }) => (
              <Option value={gradeLevelId} key={gradeLevelId}>
                {name?.th}
              </Option>
            ))}
          </TreeSelect>
        </Form.Item>
        <Form.Item
          label="วิชา"
          className="mb-0 mr-0 xl:mr-4 2xl:mr-0 ml-0 2xl:ml-4 w-full xl:w-56"
          css={css`
            .ant-form-item-label label {
              height: 20px;
            }
          `}
        >
          <TreeSelect
            placeholder="วิชา"
            value={subjectIds}
            onChange={(value) => {
              // console.log('subjectIds value', value);
              setSubjectIds(value);
              setCurrentPage(1);
            }}
            disabled={subjectsLoading}
            treeData={subjectsTreeData}
            showCheckedStrategy={SHOW_PARENT}
            maxTagCount={2}
            allowClear
            treeCheckable
            treeDefaultExpandAll
          >
            {subjectsData?.map(({ subjectId, name }) => (
              <Option key={subjectId} value={subjectId}>
                {name?.th}
              </Option>
            ))}
          </TreeSelect>
        </Form.Item>
        <Form.Item
          label="เรียงจาก"
          css={css`
            .ant-form-item-label label {
              height: 20px;
            }
          `}
          className="mb-0 mr-0 xl:mr-4 2xl:mr-0 ml-0 2xl:ml-4 w-full xl:w-44"
        >
          <Select
            value={sortBy}
            onChange={(value) => {
              setSortBy(value);
              setCurrentPage(1);
            }}
            css={css`
              .ant-select-selector {
                min-width: 176px;
              }
            `}
          >
            {sortByList.map(({ value, name }) => (
              <Option key={value} value={value}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};
