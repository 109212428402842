import { Form, Select } from 'antd';

import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { MenuButton } from 'feature/common/components/MenuButton';
import { PageWrapper } from 'feature/common/components/PageWrapperMock';
import React from 'react';

const { Option } = Select;

export const AcademicResultPage = () => {
  const menuList = ['คะแนนจากงานที่มอบหมาย (K)', 'แบบทดสอบก่อนเรียน-หลังเรียน'];

  return (
    <PageWrapper className="flex flex-col bg-white">
      <div className="flex justify-end gap-2 w-full">
        <Form.Item label="ชั้นปี">
          <Select value={''}>
            <Option value="">ชั้นปีที่ 1</Option>
          </Select>
        </Form.Item>
        <Form.Item label="ห้อง">
          <Select value={''}>
            <Option value="">ทุกห้อง</Option>
          </Select>
        </Form.Item>
        <Form.Item label="วิชา">
          <Select value={''}>
            <Option value="">ทุกวิชา</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="flex justify-between">
        <div className="flex justify-start mb-8 text-lg overflow-x-auto md:w-full">
          {menuList.map((menu, index) => (
            <MenuButton key={index} isActive={index === 0} onClick={() => {}}>
              {menu}
            </MenuButton>
          ))}
        </div>
        <div className="flex justify-end">
          <button className="flex items-center transition-colors duration-200 ease-in-out text-base font-bold bg-red-aksorn text-white rounded-xl outline-none px-4 ml-4 h-10 w-32">
            <DownloadIcon className="mr-2 w-5 h-5" />
            ดาวน์โหลด
          </button>
        </div>
      </div>
      <div className="flex w-full items-center">
        <img
          src="/assets/mock-subject1.jpg"
          alt="mock-summary-result"
          className="w-full h-full"
        />
      </div>
      <div className="flex w-full items-center">
        <img
          src="/assets/mock-subject2.jpg"
          alt="mock-result-students"
          className="w-full h-full"
        />
      </div>
      <div className="flex w-full items-center">
        <img
          src="/assets/mock-subject3.jpg"
          alt="mock-result-students"
          className="w-full h-full"
        />
      </div>
    </PageWrapper>
  );
};
