import { AssignmentGetResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useAssignment = () => {
  const [{ data, loading, error }, fire] = useAxios<AssignmentGetResponse>(
    {},
    {
      manual: true
    }
  );
  const execute = (assignmentId: string) => {
    return fire({
      url: `/assignments/${assignmentId}`
    });
  };

  return {
    data: data?.data,
    loading: loading,
    execute,
    error: error
  };
};
