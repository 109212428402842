import { Drawer, Skeleton } from 'antd';
import { Global, css } from '@emotion/react';
import React, { useEffect } from 'react';

import { OutlinedButton } from 'feature/common/components/Button';
import { StudentInfo } from './components/StudentInfo';
import { StudentInfoSkeleton } from './components/StudentInfoSkeleton';
import { useClassroomStudent } from 'hooks/classroom-students/useClassroomStudent';

interface AssignmentOverallClassPeriodDetailDrawerProps {
  classroomStudentId: string | undefined;
  visible: boolean;
  onClose: () => void;
}

interface DrawerTitleProps {
  title: string;
}

interface DrawerSubTitleProps {
  subtitle: string;
}

export const AssignmentOverallClassPeriodDetailDrawer = ({
  classroomStudentId,
  visible,
  onClose
}: AssignmentOverallClassPeriodDetailDrawerProps) => {
  const {
    data: classroomStudent,
    loading: classroomStudentLoading,
    execute: getClassroomStudent
  } = useClassroomStudent();

  useEffect(() => {
    if (classroomStudentId) {
      getClassroomStudent(classroomStudentId);
    }
  }, [classroomStudentId]);

  return (
    <Drawer
      placement="right"
      closable={false}
      destroyOnClose={true}
      visible={visible}
      width={480}
      height={900}
      onClose={onClose}
      footer={
        <div className="flex w-full justify-end px-7">
          <OutlinedButton
            className="border-2 text-secondary-gray-60 border-secondary-gray-60 font-bold"
            onClick={onClose}
          >
            ปิด
          </OutlinedButton>
        </div>
      }
      footerStyle={{
        border: 'none'
      }}
      css={css`
        .ant-drawer-content-wrapper {
          max-width: 100vw;
        }
      `}
    >
      <Global
        styles={css`
          .ant-drawer-content {
            padding-top: 24px;
            padding-bottom: 20px;
          }
          .ant-drawer-body {
            padding: 0 28px;
          }
        `}
      />
      {classroomStudentLoading ? (
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 1, width: '193px' }}
        />
      ) : (
        <>
          <DrawerTitle title={'ข้อมูลนักเรียน'} />
          <DrawerSubTitle
            subtitle={`${classroomStudent?.gradeLevel.name.th ?? ''}/${
              classroomStudent?.classroomName
            }`}
          />
        </>
      )}
      <div className="mt-8">
        {classroomStudentLoading || !classroomStudent ? (
          <StudentInfoSkeleton />
        ) : (
          <StudentInfo classroomStudent={classroomStudent} />
        )}
      </div>
    </Drawer>
  );
};

const DrawerTitle = ({ title }: DrawerTitleProps) => {
  return <p className="text-secondary-black font-bold text-2xl">{title}</p>;
};

const DrawerSubTitle = ({ subtitle }: DrawerSubTitleProps) => {
  return <p className="text-secondary-gray-60 text-base mt-1">{subtitle}</p>;
};
