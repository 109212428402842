/** @jsx jsx */

import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';

import { jsx } from '@emotion/react';

interface MediaDetailDrawerFooterProps {
  page: string;
  handleOnClose: Function;
  selectedMedia: any;
  setIsConfirmationModalVisible: Function;
}

export const MediaDetailDrawerFooter = ({
  page,
  handleOnClose,
  selectedMedia,
  setIsConfirmationModalVisible
}: MediaDetailDrawerFooterProps) => {
  const renderMediaDetailDrawerFooter = () => {
    switch (page) {
      case 'select':
        return (
          <div className="flex w-full justify-end">
            <SecondaryButton className="mx-4" onClick={handleOnClose}>
              ยกเลิก
            </SecondaryButton>
            <PrimaryButton
              htmlType="submit"
              disabled={!selectedMedia.length}
              disable={!selectedMedia.length}
              onClick={() => setIsConfirmationModalVisible(true)}
            >
              ตกลง
            </PrimaryButton>
          </div>
        );
      case 'summary':
        return (
          <div className="flex w-full justify-end">
            <PrimaryButton onClick={handleOnClose}>รับทราบ</PrimaryButton>
          </div>
        );
    }
  };

  return <div>{renderMediaDetailDrawerFooter()}</div>;
};
