import {
  AverageLineProps,
  BarChartProps
} from 'feature/common/barchart/barchart';

export const handleEmptyScore = ({
  studentScores,
  barName,
  chartMaxScore
}: {
  studentScores: number[];
  barName?: string;
  chartMaxScore: number;
}) => {
  return studentScores?.map((studentScore) => {
    if (!studentScore) {
      return {
        value: chartMaxScore > 0 ? chartMaxScore : 100,
        label: {
          formatter: 'ยังไม่มี' + (barName || 'คะแนน'),
          position: 'inside',
          color: '#8F91A3',
          fontSize: 12
        },
        itemStyle: {
          color: '#FBFBFB',
          opacity: 0.8
        }
      };
    } else {
      return studentScore;
    }
  });
};

export const getOverviewBarChartSeries = ({
  dataSource,
  markLines,
  chartMaxScore
}: {
  dataSource: BarChartProps[];
  markLines?: AverageLineProps[];
  chartMaxScore: number;
}) => {
  return [
    ...dataSource.map((barChart) => {
      return {
        type: 'bar',
        barGap: 0,
        zlevel: 999,
        barWidth: barChart.barWidth || 24,
        itemStyle: { color: barChart.barColor },
        label: {
          show: true,
          position: 'right',
          fontFamily: 'Sarabun',
          fontSize: 14,
          color: '#5C5E70'
        },
        data: handleEmptyScore({
          studentScores: barChart.value as number[],
          barName: barChart?.barName,
          chartMaxScore
        })
      };
    }),
    ...(markLines?.map((markLine) => ({
      type: 'line',
      markLine: {
        symbol: 'none',
        label: {
          show: false
        },
        lineStyle: {
          color: markLine?.lineColor,
          type: 'dashed'
        },
        data: [{ xAxis: markLine?.value }]
      }
    })) ?? [])
  ];
};

export const getIndividualStudentBarChartSeries = ({
  dataSource,
  markLines,
  chartMaxScore
}: {
  dataSource: BarChartProps[];
  markLines?: AverageLineProps[];
  chartMaxScore: number;
}) => {
  return [
    ...dataSource.map((barChart) => {
      return {
        type: 'bar',
        barGap: 0,
        zlevel: 999,
        barWidth: barChart.barWidth || 24,
        itemStyle: { color: barChart.barColor },
        label: {
          show: true,
          position: 'right',
          fontFamily: 'Sarabun',
          fontSize: 14,
          color: '#5C5E70'
        },
        data: handleEmptyScore({
          studentScores: barChart.value as number[],
          barName: barChart?.barName,
          chartMaxScore
        })
      };
    }),
    ...(markLines?.map((markLine) => {
      return {
        type: 'line',
        markLine: {
          symbol: 'none',
          label: {
            show: false
          },
          lineStyle: {
            color: markLine.lineColor,
            type: 'dashed'
          },
          data: [{ xAxis: markLine.value }]
        }
      };
    }) ?? [])
  ];
};
