import { Response } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useRecreateInvitationSchoolStudent = () => {
  const [{ loading, error }, fire] = useAxios<Response>({}, { manual: true });

  const execute = (schoolStudentId: string) => {
    return fire({
      url: `/school-students/${schoolStudentId}/recreateInvitation`,
      method: 'POST'
    });
  };

  return {
    loading,
    error,
    execute
  };
};
