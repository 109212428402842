import { EVALUATION_MENU_MAPPER, EvaluationMenu } from './evaluation';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { MenuButton } from 'feature/common/components/MenuButton';
import React from 'react';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { observer } from 'mobx-react-lite';

interface EvaluationMenuTabProps {
  menu: EvaluationMenu;
  setMenu: (evaluationMenu: EvaluationMenu) => void;
}

export const EvaluationMenuTab = observer(
  ({ menu, setMenu }: EvaluationMenuTabProps) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const { evaluationDownloads, downloadEvaluation } = evaluationDownloadStore;

    return (
      <div className="flex justify-between">
        <div className="flex justify-start mb-6 text-lg overflow-x-auto md:w-full">
          {Object.values(EvaluationMenu).map((key) => (
            <MenuButton
              key={key}
              isActive={menu === key}
              onClick={() => {
                setMenu(key);
                history.push({
                  pathname,
                  search: `?menu=${key}`,
                  state: {
                    menu: key
                  }
                });
              }}
            >
              {EVALUATION_MENU_MAPPER[key]}
            </MenuButton>
          ))}
        </div>
        {evaluationDownloads.length > 0 && (
          <button
            className="flex items-center transition-colors duration-200 ease-in-out text-base font-bold bg-red-aksorn text-white rounded-xl outline-none px-4 ml-4 h-10 w-32"
            onClick={() => downloadEvaluation(menu)}
          >
            <DownloadIcon className="mr-2 w-5 h-5" />
            ดาวน์โหลด
          </button>
        )}
      </div>
    );
  }
);
