import { Form, Input, Select, TreeSelect } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { useClassroomNames } from 'hooks/classroom-name/useClassroomNames';
import { useGradeLevels } from 'hooks/grade-levels/useGradeLevels';

const { SHOW_PARENT } = TreeSelect;
const SELECT_ALL = 'all';
const { Option } = Select;

export const SearchTeacherFilterBar = ({
  schoolYearId,
  gradeLevelIds,
  classroomNames,
  searchText,
  setGradeLevelIds,
  setClassroomNames,
  setSearchText,
  onPressEnter
}: {
  schoolYearId?: string;
  gradeLevelIds: string[];
  classroomNames: string[];
  searchText?: string;
  setGradeLevelIds: (gradeLevelIds: string[]) => void;
  setClassroomNames: (classroomNames: string[]) => void;
  setSearchText: (searchText: string) => void;
  onPressEnter: () => void;
}) => {
  const [form] = Form.useForm();

  const {
    data: gradeLevelsData,
    loading: gradeLevelsLoading
  } = useGradeLevels();

  const {
    data: classroomNamesData,
    loading: classroomNameLoading,
    execute: getClassroomNames
  } = useClassroomNames();

  const selectAllGradeLevelIds = useMemo(
    () => gradeLevelsData?.map(({ gradeLevelId }) => gradeLevelId),
    [gradeLevelsData]
  );

  const selectAllClassroomNames = useMemo(
    () => classroomNamesData?.map(({ classroomName }) => classroomName),
    [classroomNamesData]
  );

  useEffect(() => {
    if (!schoolYearId) return;
    getClassroomNames(schoolYearId);
  }, [schoolYearId]);

  useEffect(() => {
    if (!selectAllGradeLevelIds) return;
    form.setFieldsValue({ gradeLevelIds: SELECT_ALL });
    setGradeLevelIds(['all']);
  }, [selectAllGradeLevelIds]);

  useEffect(() => {
    if (!selectAllClassroomNames) return;
    form.setFieldsValue({ classroomNames: SELECT_ALL });
    setClassroomNames(['all']);
  }, [selectAllClassroomNames]);

  const gradeLevelsTreeData = [
    {
      title: 'ทั้งหมด',
      value: SELECT_ALL,
      key: SELECT_ALL,
      children: gradeLevelsData?.map(({ name, gradeLevelId }) => ({
        title: name.th,
        value: gradeLevelId,
        key: gradeLevelId
      }))
    }
  ];

  const classroomNamesTreeData = [
    {
      title: 'ทั้งหมด',
      value: SELECT_ALL,
      key: SELECT_ALL,
      children: classroomNamesData?.map(({ classroomName }) => ({
        title: classroomName,
        value: classroomName,
        key: classroomName
      }))
    }
  ];

  return (
    <Form form={form}>
      <div className="flex w-full mb-10 items-center justify-between">
        <Form.Item>
          <Input
            className="w-72"
            placeholder="ค้นหาด้วยชื่อครูประจำชั้น(หลัก)"
            suffix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onPressEnter={onPressEnter}
          />
        </Form.Item>
        <div className="flex flex-shrink-0">
          <Form.Item label="ระดับชั้น" name="gradeLevelIds" className="mr-4">
            <TreeSelect
              disabled={gradeLevelsLoading}
              treeCheckable
              treeDefaultExpandAll
              placeholder="ระดับชั้น"
              treeData={gradeLevelsTreeData}
              value={gradeLevelIds}
              showCheckedStrategy={SHOW_PARENT}
              maxTagCount={2}
              onSelect={(value) => {
                if (value === SELECT_ALL) {
                  setGradeLevelIds(['all']);
                } else {
                  setGradeLevelIds([...gradeLevelIds, value]);
                }
              }}
              onDeselect={(value) => {
                if (value === SELECT_ALL) {
                  setGradeLevelIds([]);
                } else {
                  const filteredGradeLevelIds = gradeLevelIds?.filter(
                    (gradeLevelId) => gradeLevelId !== value
                  );
                  setGradeLevelIds(filteredGradeLevelIds);
                }
              }}
            >
              {gradeLevelsData?.map(({ gradeLevelId, name }) => (
                <Option value={gradeLevelId} key={gradeLevelId}>
                  {name?.th}
                </Option>
              ))}
            </TreeSelect>
          </Form.Item>
          <Form.Item label="ห้องเรียน" name="classroomNames">
            <TreeSelect
              treeCheckable
              treeDefaultExpandAll
              disabled={classroomNameLoading}
              treeData={classroomNamesTreeData}
              value={classroomNames}
              showCheckedStrategy={SHOW_PARENT}
              maxTagCount={2}
              placeholder="ห้องเรียน"
              onSelect={(value) => {
                if (value === SELECT_ALL) {
                  setClassroomNames(['all']);
                } else {
                  setClassroomNames([...(classroomNames ?? []), value]);
                }
              }}
              onDeselect={(value) => {
                if (value === SELECT_ALL) {
                  setClassroomNames([]);
                } else {
                  const filteredClassroomNames = classroomNames?.filter(
                    (classroomName) => classroomName !== value
                  );
                  setClassroomNames(filteredClassroomNames);
                }
              }}
            >
              {classroomNamesData?.map(({ classroomName }) => (
                <Option value={classroomName} key={classroomName}>
                  {classroomName}
                </Option>
              ))}
            </TreeSelect>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
