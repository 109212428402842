import {
  AssignmentUnitListQuery,
  AssignmentUnitListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useAssignmentUnits = () => {
  const [{ data, loading, error }, fire] = useAxios<AssignmentUnitListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (params: AssignmentUnitListQuery) => {
    return fire({
      url: '/assignment-units',
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data?.data || [],
    loading: loading,
    execute,
    error: error
  };
};
