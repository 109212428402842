import {
  AssignmentUnitListQuery,
  AssignmentUnitListResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import { teachingAxios } from 'utils/axios';

export const getAssignmentUnits = async (params: AssignmentUnitListQuery) => {
  const {
    data: response
  } = await teachingAxios.get<AssignmentUnitListResponse>(`/assignment-units`, {
    params: {
      pageNumber: 1,
      pageSize: 100,
      ...pickBy(params, identity)
    }
  });
  return response;
};
