import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import React from 'react';
import { ValidationStatus } from 'feature/dashboard/dashboard';

export const progressIcon = (
  icon: any,
  { index, status }: { index: number; status: ValidationStatus }
) => {
  switch (status) {
    case ValidationStatus.FINISHED:
      return (
        <CheckCircleFilled
          style={{
            fontSize: '20px',
            color: '#FFF'
          }}
        />
      );
    case ValidationStatus.ERROR:
      return <CloseCircleFilled style={{ fontSize: '20px', color: '#FFF' }} />;
    default:
      return (
        <div className="ant-steps-icon bg-secondary-pink-60 w-5 h-5 text-xs text-primary flex justify-center items-center rounded-full top-0">
          {index + 1}
        </div>
      );
  }
};
