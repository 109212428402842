import { CATEGORIES, EXCEL_FILE_TYPES } from './const';
import { Checkbox, Drawer, Row, Upload } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { GhostButton, PrimaryButton } from 'feature/common/components/Button';
/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

// import { FileItem } from './FileItem';
import { ReactComponent as FileUploadIcon } from 'assets/file-upload-icon.svg';

const { Dragger } = Upload;

interface ExcelManagementDrawerProps {
  visible: boolean;
  onClose: () => void;
  onUpload: (file: RcFile | undefined) => void;
  onFail: () => void;
  onUploading: () => void;
  onUploadSuccess: () => void;
}

enum ExcelManagementDrawerStep {
  UPLOAD_STEP = 'upload-step',
  CHECK_STEP = 'check-step'
}

export const ExcelManagementDrawer = ({
  visible,
  onClose,
  onUpload,
  onFail,
  onUploading,
  onUploadSuccess
}: ExcelManagementDrawerProps) => {
  const [step, setStep] = useState(ExcelManagementDrawerStep.UPLOAD_STEP);
  const [excelFile, setExcelFile] = useState<RcFile>();
  const [uploading, setUploading] = useState(false);

  const draggerProps = {
    height: 84,
    name: 'ข้อมูลห้องเรียน',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file: RcFile) => {
      if (!EXCEL_FILE_TYPES.includes(file.type)) {
        onFail();
        return false;
      }
      setExcelFile(file);
      setStep(ExcelManagementDrawerStep.CHECK_STEP);
      return true;
    },
    onChange: (info: UploadChangeParam) => {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    if (uploading) {
      onUploading();
    } else {
      onUploadSuccess();
    }
  }, [uploading]);

  const renderContent = () => {
    switch (step) {
      case ExcelManagementDrawerStep.UPLOAD_STEP:
        return (
          <Fragment>
            <Global
              styles={css`
                .ant-upload.ant-upload-drag {
                  border: 1px dashed #8f91a3;
                  border-radius: 10px;
                  background: white;
                }
              `}
            />
            <div className="pt-6 pb-8">
              <h2 className="font-bold text-2xl text-black mb-1">
                จัดการข้อมูลด้วย Excel
              </h2>
              <div className="font-normal text-base text-secondary-gray-60">
                จัดการข้อมูลจำนวนมากได้ด้วยไฟล์เดียว
              </div>
              <div className="font-bold text-primary text-base">
                *หลังจากอัปโหลดไฟล์นี้ ข้อมูลในห้องทั้งหมดจะถูกแทนที่ทันที
              </div>
            </div>
            <Dragger {...draggerProps}>
              <div
                css={css`
                  margin-left: 136px;
                `}
              >
                <div className="flex items-center mb-1">
                  <FileUploadIcon className="mr-2" />
                  <div className="text-sm font-normal text-secondary-gray-60">
                    วางไฟล์ที่นี่ หรือ{' '}
                    <span
                      className="text-secondary-light-blue cursor-pointer underline"
                      id="upload-file"
                    >
                      เลือกไฟล์
                    </span>
                  </div>
                </div>
                <div className="text-secondary-gray-40 text-xs font-normal text-left">
                  ไฟล์ที่รองรับ .xls, .xlsx
                </div>
              </div>
            </Dragger>
            {/* <div className="mt-6">
              <div className="font-normal text-sm text-secondary-gray-60 mb-2">
                ตัวอย่างการกรอกข้อมูลในระบบ
              </div>
              <FileItem file={{ name: 'ตัวอย่างข้อมูลห้องเรียน.xlsx' }} />
            </div> */}
            <div className="mt-6 justify-center flex items-center">
              <a href={`/templates/teaching_info_template.xlsx`}>
                ตัวอย่างการกรอกข้อมูลในระบบ
              </a>
            </div>
            <div className="absolute bottom-0 py-4 right-0 pr-10 w-40">
              <GhostButton
                onClick={onClose}
                color="#5c5e70"
                border={2}
                borderRadius={10}
              >
                ปิด
              </GhostButton>
            </div>
          </Fragment>
        );
      case ExcelManagementDrawerStep.CHECK_STEP:
        return (
          <Fragment>
            <div className="pt-6 pb-4 mb-4">
              <h2 className="font-bold text-2xl text-black mb-1">
                จัดการข้อมูลด้วย Excel
              </h2>
              <div className="font-normal text-base text-secondary-gray-60">
                กรุณาเลือกสิ่งที่ต้องการอัปโหลด
              </div>
            </div>
            {CATEGORIES.map((category: string) => (
              <Row key={category} className="mb-4">
                <Checkbox
                  className="text-sm font-normal"
                  checked={true}
                  disabled={true}
                  css={css`
                    .ant-checkbox-disabled + span {
                      color: #444444 !important;
                    }
                  `}
                >
                  {category}
                </Checkbox>
              </Row>
            ))}
            <div className="absolute bottom-0 py-4 right-0 pr-10 w-96">
              <div className="grid grid-cols-2 gap-6">
                <GhostButton className="w-40" onClick={onClose}>
                  ปิด
                </GhostButton>
                <PrimaryButton onClick={() => onUpload(excelFile)}>
                  อัปโหลด
                </PrimaryButton>
              </div>
            </div>
          </Fragment>
        );
    }
  };
  return (
    <Drawer
      visible={visible}
      width={560}
      closable={false}
      destroyOnClose={true}
      css={css`
        .ant-drawer-body {
          padding: 0 40px;
        }
      `}
      afterVisibleChange={(visible: boolean) => {
        if (!visible) {
          setStep(ExcelManagementDrawerStep.UPLOAD_STEP);
        }
      }}
    >
      {renderContent()}
    </Drawer>
  );
};
