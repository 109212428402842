import { EvaluationAssignmentClassroomScore } from './EvaluationAssignmentClassroomScore';
import { EvaluationAssignmentSubjectScore } from './EvaluationAssignmentSubjectScore';
import { evaluationStore } from 'stores/evaluationStore';
import { jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';

/** @jsx jsx */

export const EvaluationAssignmentScore = observer(() => {
  const { filterData } = evaluationStore;

  return (
    <div>
      {filterData.selectedClassroom?.classroomId === undefined ? (
        <EvaluationAssignmentSubjectScore />
      ) : (
        <EvaluationAssignmentClassroomScore />
      )}
    </div>
  );
});
