import { Divider } from 'antd';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import React from 'react';
import { SchoolAdminManagement } from 'feature/school-admin-management/SchoolAdminManagement';

export const SchoolAdminManagementPage = () => {
  return (
    <PageWrapper>
      <h1 className="text-xl font-bold">ผู้ดูแลระบบ</h1>
      <Divider className="my-8" />
      <SchoolAdminManagement />
    </PageWrapper>
  );
};
