import React from 'react';

export const AssignmentUnitStatusLabel = () => {
  return (
    <>
      <div className="rounded-full h-6 w-6 flex items-center justify-center bg-system-complete mr-3.5" />
      ส่งงานตรงเวลา
      <div className="rounded-full h-6 w-6 flex items-center justify-center bg-aksorn-orange ml-4 mr-3.5" />
      ส่งงานช้า
      <div className="rounded-full h-6 w-6 flex items-center justify-center bg-secondary-gray-60 ml-4 mr-3.5" />
      ยังไม่ส่ง
    </>
  );
};
