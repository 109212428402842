import {
  LearningUnitHeaderListResponse,
  PaginationQuery
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import useAxios from 'axios-hooks';

export const useLearningUnitHeaders = () => {
  const [
    { data, loading, error },
    fire
  ] = useAxios<LearningUnitHeaderListResponse>(
    {},
    {
      manual: true
    }
  );

  const execute = (assignmentUnitId: string, params?: PaginationQuery) => {
    return fire({
      url: `/learning-units/${assignmentUnitId}/headers`,
      params: {
        pageNumber: 1,
        pageSize: 100,
        ...pickBy(params, identity)
      }
    });
  };

  return {
    data: data,
    loading: loading,
    execute,
    error: error
  };
};
