/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useCallback, useState } from 'react';

import { ReactComponent as DownloadIcon } from 'assets/download-single-chart-icon.svg';
import { EvaluationContainerLoading } from './Loading';
import { ReactNode } from 'react';
import { Skeleton } from 'antd';
import { downloadEvaluationBarChart } from 'feature/evaluation/downloadEvaluationBarChart';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

interface EvaluationContainerProps {
  className?: string;
  loading?: { isLoading: boolean; height: number };
  title: string;
  children: ReactNode;
  fileName?: string;
}

export const EvaluationContainer = observer(
  ({
    className = '',
    loading = { isLoading: false, height: 0 },
    title,
    children,
    fileName
  }: EvaluationContainerProps) => {
    const [
      selectedSingleBarChartRef,
      setSelectedSingleBarChartRef
    ] = useState<HTMLDivElement>();
    const {
      evaluationDownloads,
      setEvaluationDownloads
    } = evaluationDownloadStore;

    const location = useLocation();
    const pathName = location.pathname.split('/')[1];

    const evaluationRef = useCallback(
      (ref: HTMLDivElement) => {
        switch (pathName) {
          case 'evaluation':
            if (fileName && ref !== null) {
              if (
                evaluationDownloads[1] &&
                fileName.split('_')[5] === 'คะแนนรายบุคคล'
              ) {
                evaluationDownloads.pop();
                setEvaluationDownloads(ref, fileName);
              } else if (
                !evaluationDownloads.find(
                  (evaluation) => evaluation.download === ref
                )
              ) {
                setEvaluationDownloads(ref, fileName);
              }
            }
            setSelectedSingleBarChartRef(ref);
            break;
          case 'assignment-overall':
            if (fileName && ref !== null) {
              if (
                evaluationDownloads[0] &&
                fileName.split('_')[4] === 'คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียน'
              ) {
                evaluationDownloads.shift();
                evaluationDownloads.unshift({
                  download: ref,
                  fileName: fileName
                });
              } else if (
                evaluationDownloads[1] &&
                fileName.split('_')[4] ===
                  'คะแนนจากแบบทดสอบก่อนเรียน-หลังเรียนรายบุคคล'
              ) {
                evaluationDownloads.pop();
                setEvaluationDownloads(ref, fileName);
              } else if (
                ref !== null &&
                !evaluationDownloads.find(
                  (evaluation) => evaluation.download === ref
                )
              ) {
                setEvaluationDownloads(ref, fileName);
              }
            }
            setSelectedSingleBarChartRef(ref);
        }
      },
      [fileName, evaluationDownloads]
    );

    if (loading.isLoading) {
      return (
        <div
          className={`bg-white rounded-2xl ${className}`}
          css={css`
            box-shadow: 0px 10px 30px rgba(46, 47, 56, 0.1);
            .ant-skeleton-header {
              vertical-align: middle;
            }
            .ant-skeleton-paragraph {
              display: none;
            }
          `}
        >
          <div className="flex p-6">
            <Skeleton avatar paragraph={{ rows: 0 }} active />
          </div>
          <div className="border-t-1 border-secondary-gray-10 mx-2" />
          <div
            className="p-6"
            css={css`
              height: ${loading.height}px;
            `}
          >
            <EvaluationContainerLoading size={36} color="#8F91A3" />
          </div>
        </div>
      );
    }

    return (
      <div ref={evaluationRef}>
        <div
          className={`bg-white rounded-2xl ${className}`}
          css={css`
            box-shadow: 0px 10px 30px rgba(46, 47, 56, 0.1);
          `}
        >
          <div className="flex p-6 justify-between items-center">
            <div className="flex">
              <img
                src="/assets/orange-graph.png"
                alt="graph"
                className="w-8 h-8"
              />
              <div className="text-secondary-black text-xl font-bold ml-2">
                {title}
              </div>
            </div>
            <div
              className="flex mx-2 py-4 items-center justify-center rounded-lg font-bold text-secondary-gray-60 text-base cursor-pointer transition duration-500 ease-in-out"
              css={css`
                width: 120px;
                height: 40px;
                :hover {
                  background-color: #f4f4f6 !important;
                }
              `}
              onClick={() =>
                downloadEvaluationBarChart(selectedSingleBarChartRef, fileName)
              }
            >
              <DownloadIcon className="mr-3 mb-px" />
              <div>ดาวน์โหลด</div>
            </div>
          </div>
          <div className="border-t mx-4 border-solid border-secondary-gray-10" />
          <div className="p-6">{children}</div>
        </div>
      </div>
    );
  }
);
