import {
  PrePostTestStatisticGetQuery,
  PrePostTestStatisticGetResponse
} from '@aksorn/teaching-backoffice-sdk';
import { identity, pickBy } from 'lodash';

import { teachingAxios } from 'utils/axios';

export const getAssignmentUnitPrePostTestStatistic = async (
  params: PrePostTestStatisticGetQuery
) => {
  const {
    data: response
  } = await teachingAxios.get<PrePostTestStatisticGetResponse>(
    `/assignment-units/pre-post-test/statistic`,
    {
      params: {
        ...pickBy(params, identity)
      }
    }
  );
  return response;
};
