import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';

import { ClassPeriodDrawer } from './ClassPeriodDrawer';
import { ClassRoomDrawer } from './ClassroomDrawer';
import { ClassroomPeriodCard } from 'feature/classroom-management/ClassroomPeriodCard';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';
/** @jsx jsx */
import { Spin } from 'antd';
import { useClassPeriods } from 'hooks/class-period/useClassPeriods';
import { useClassroom } from 'hooks/classroom/useClassroom';
import { useParams } from 'react-router-dom';

export const ClassroomDetailTabContent = () => {
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [isClassPeriodDrawerVisible, setIsClassPeriodDrawerVisible] = useState(
    false
  );
  const { classroomId } = useParams() as { classroomId: string };
  const [classPeriodId, setClassPeriodId] = useState<string | undefined>(
    undefined
  );

  const {
    data: classroom,
    loading: classroomLoading,
    execute: getClassroom
  } = useClassroom();

  const {
    data: classPeriods,
    loading: classPeriodsLoading,
    execute: getClassPeriods
  } = useClassPeriods();

  const classroomTeachersExceptMainTeacher = useMemo(() => {
    // if (classroom) {
    if ((classroom) && (classroom?.mainSchoolTeacher?.schoolTeacherId)) {
      return classroom.classroomTeachers.filter(
        (t) => t.schoolTeacherId !== classroom?.mainSchoolTeacher?.schoolTeacherId
      );
    } else return [];
  }, [classroom]);

  const loading = classroomLoading || classPeriodsLoading;

  useEffect(() => {
    if (classroomId) {
      getClassroom(classroomId);
      getClassPeriods({ classroomId });
    }
  }, [classroomId]);

  return (
    <Spin spinning={loading}>
      <div className="flex justify-start items-center text-2xl font-bold mb-6 mt-4">
        <span className="mr-2">รายละเอียดห้องเรียน</span>
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            setIsEditDrawerVisible(true);
          }}
        />
      </div>
      <div className="flex justify-start items-center over">
        <img
          alt="classroom cover"
          src="/assets/classroom-default.png"
          css={css`
            width: 300px;
            height: 200px;
            border-radius: 20px;
          `}
        />
        <div
          className="bg-white ml-6 p-6"
          css={css`
            border-radius: 20px;
            width: 400px;
            height: 200px;
          `}
        >
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <div className="font-semibold mb-4">ชื่อห้อง</div>
              <div className="text-right mb-4">
                {/* {classroom?.gradeLevel.name.th + '/' + classroom?.classroomName} */}
                {classroom?.gradeLevel.name.th ? classroom?.gradeLevel.name.th + '/' + classroom?.classroomName : null}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-semibold mb-4">จำนวนนักเรียน</div>
              <div className="text-right mb-4">
                {classroom?.numberOfClassroomStudent || '0'}
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <div className="font-semibold ">ครูประจำชั้น</div>
                <div className="text-xs text-neutral-7">
                  {/* unlock mainSchoolTeacher 19/08/2022 */}
                  {/* {classroom?.classroomTeachers &&
                    classroom?.classroomTeachers.length > 2 &&
                    `(ทั้งหมด${classroom?.classroomTeachers?.length}คน)`} */}
                </div>
              </div>
              <div>
                <div className="flex justify-start items-center mb-2">
                  <img
                    className="w-6 mr-2"
                    alt="classroom teacher"
                    src="/assets/profile-colour.png"
                  />
                  <div className="text-base">
                    {(classroom?.mainSchoolTeacher?.firstName?.th || '') +
                      ' ' +
                      (classroom?.mainSchoolTeacher?.lastName?.th || '') +
                      ' '}
                    {/* unlock mainSchoolTeacher 19/08/2022 */}
                    {/* {console.log("deletedAt",classroom?.mainSchoolTeacher?.deletedAt)} */}
                    {/* {classroom?.mainSchoolTeacher?.deletedAt ? (
                        <span style={{color:"red",fontWeight:"bold"}}>{' (ลาออกแล้ว)'}</span>
                      ) : null} */}
                    {classroom?.mainSchoolTeacher?.deletedAt ? (
                      <span>{' (ย้ายโรงเรียน)'}</span>
                    ) : null}
                  </div>
                </div>
                {classroomTeachersExceptMainTeacher
                  ?.slice(0, 1)
                  ?.map((classroomTeacher) => (
                    <div
                      className="flex justify-start items-center mb-2"
                      key={classroomTeacher.schoolTeacherId}
                    >
                      <img
                        className="w-6 mr-2"
                        alt="classroom teacher"
                        src="/assets/profile-colour.png"
                      />
                      <div className="text-base">
                        {classroomTeacher?.firstName?.th +
                          ' ' +
                          classroomTeacher?.lastName?.th}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-2xl font-bold mt-9">
        วิชา ({classPeriods?.length || 0})
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-6">
        {/* <div
          className="flex justify-center items-center text-gray-10 hover:text-gray-60 hover:border-gray-60 w-full bg-transparent cursor-pointer"
          css={css`
            border-radius: 20px;
            border: 3px dashed #e6e6e6;
          `}
          onClick={() => {
            setIsClassPeriodDrawerVisible(true);
          }}
        >
          <div className="text-4xl">+</div>
        </div> */}
        {classPeriods?.map((classPeriod) => (
          <ClassroomPeriodCard
            subject={classPeriod?.subject}
            classPeriodName={classPeriod?.classPeriodName}
            mainSchoolTeacher={classPeriod?.mainSchoolTeacher}
            classPeriodSchedules={classPeriod?.classPeriodSchedules}
            key={classPeriod.classPeriodId}
            onClick={() => {
              setIsClassPeriodDrawerVisible(true);
              setClassPeriodId(classPeriod?.classPeriodId);
            }}
          />
        ))}
      </div>
      {/* {console.log("classroom?.mainSchoolTeacher",classroom?.mainSchoolTeacher)} */}
      <ClassRoomDrawer
        title="แก้ไขห้องเรียน"
        onSubmit={() => {
          getClassroom(classroomId);
          setIsEditDrawerVisible(false);
        }}
        onClose={() => {
          setIsEditDrawerVisible(false);
        }}
        visible={isEditDrawerVisible}
        classroomId={classroom?.classroomId}
        // // unlock mainSchoolTeacher 19/08/2022
        mainSchoolTeacher={classroom?.mainSchoolTeacher?.schoolTeacherId}
      />
      <ClassPeriodDrawer
        title={classPeriodId ? 'แก้ไขวิชา' : 'เพิ่มวิชา'}
        visible={isClassPeriodDrawerVisible}
        classroomId={classroomId}
        classPeriodId={classPeriodId}
        classPeriods={classPeriods}
        onClose={() => {
          setIsClassPeriodDrawerVisible(false);
          setClassPeriodId(undefined);
        }}
        refetch={() => {
          getClassPeriods({ classroomId });
        }}
        // // unlock mainSchoolTeacher 19/08/2022
        mainSchoolTeacher={classPeriodId ? classroom?.mainSchoolTeacher?.schoolTeacherId : ""}
      />
    </Spin>
  );
};
