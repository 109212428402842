import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';

import { Modal } from 'antd';
import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/warning-icon.svg';
import { css } from '@emotion/react';

interface SubmitModalProps {
  visible: boolean;
  setVisible: Function;
  loading: boolean;
  handleSubmit: Function;
}

export const SubmitModal = ({
  visible,
  setVisible,
  loading,
  handleSubmit
}: SubmitModalProps) => {
  return (
    <Modal
      visible={visible}
      width={400}
      footer={null}
      closable={false}
      centered
      bodyStyle={{
        paddingLeft: 40,
        paddingRight: 40
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <WarningIcon />
        <h3 className="font-bold text-xl mt-2 text-center">
          ยืนยันการตัดสื่อเข้าห้องเรียน?
        </h3>
        <p className="text-md text-center mt-2">
          กรุณาตรวจสอบรายการสื่อที่เลือก ก่อนกดปุ่มยืนยัน หากทำการตัดสื่อแล้ว
          จะไม่สามารถเรียกคืนสิทธิ์ได้
        </p>
        <div className="flex justify-center gap-4 mt-8 mx-auto">
          <SecondaryButton
            className="w-36"
            css={css`
              ${loading
                ? `color: #C7C8D1 !important;
                border-color: #C7C8D1 !important;`
                : ''}
            `}
            disable={loading}
            disabled={loading}
            onClick={() => setVisible(false)}
          >
            ยกเลิก
          </SecondaryButton>
          <PrimaryButton
            className="w-36"
            css={css`
              ${loading
                ? `color: #FFFFFF !important;
                background-color: #C7C8D1 !important;`
                : ''}
            `}
            loading={loading}
            disable={loading}
            disabled={loading}
            onClick={handleSubmit}
          >
            ยืนยัน
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
