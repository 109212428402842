import { Skeleton } from 'antd';
/** @jsx jsx */
import { jsx } from '@emotion/react';

export const StudentInfoSkeleton = () => {
  return (
    <div className="flex">
      <div className="flex-initial mr-8">
        <div className="flex flex-col justify-center">
          <Skeleton.Avatar active style={{ width: 90, height: 90 }} />
          <div className="flex items-center justify-center mt-4">
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
        </div>
      </div>
      <div className="flex-auto">
        <div className="grid grid-cols-2 gap-x-5 gap-y-2">
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
          <div>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
