/** @jsx jsx */

import { ClassroomDetailTabContent } from 'feature/classroom-management/ClassroomDetailTabContent';
import { ReactComponent as LeftChevron } from 'assets/chevron-left-icon.svg';
import { PageWrapper } from 'feature/common/components/PageWrapper';
import { Tabs } from 'antd';
import { jsx } from '@emotion/react';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;
export const ClassroomDetailPage = () => {
  const history = useHistory();
  return (
    <PageWrapper className="bg-secondary-white">
      <div className="flex justify-start items-center">
        <LeftChevron
          className="cursor-pointer"
          onClick={() => {
            // history.push('/classroom-management');
            history.push('/create-classroom');
          }}
        />
        <h3 className="text-xl font-bold ml-4">จัดการห้องเรียน</h3>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ห้องเรียน" key="1">
          <ClassroomDetailTabContent />
        </TabPane>
      </Tabs>
    </PageWrapper>
  );
};
