import { Button, Col, Divider, Input, Modal, Row } from 'antd';
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { PrimaryButton } from 'feature/common/components/Button';
import { ReactComponent as SuccessIcon } from 'assets/success-all.svg';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useSchoolTeachersGenTempUsers } from 'hooks/school-teacher/useSchoolTeachers';
import { useState } from 'react';
import { userStore } from 'stores/userStore';

interface SchoolTeacherNewTempProps {
  setOnRefresh: Function;
}

export const SchoolTeacherNewTemp = ({
  setOnRefresh
}: SchoolTeacherNewTempProps) => {
  const [numOfNewTeacherTemp, setNumOfNewTeacherTemp] = useState(0);
  const [isSubmitVisible, setIsSubmitVisible] = useState<boolean>(false);
  const {
    data: teachers,
    loading,
    execute: getSchoolTeacherNewTemps
  } = useSchoolTeachersGenTempUsers();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const { teachingProfile } = userStore;

  const handleGenTeacherTemp = async () => {
    // console.log("gen teacher temp " + numOfNewTeacherTemp)
    if (currentSchoolYears?.schoolYearId && teachingProfile?.school.schoolId) {
      try {
        const { data: result } = await getSchoolTeacherNewTemps({
          data: {
            schoolYearId: currentSchoolYears.schoolYearId,
            noOfNewTeacher: numOfNewTeacherTemp,
            schoolId: teachingProfile.school.schoolId
          }
        });

        //console.log(result)
        setIsSubmitVisible(true);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="pt-7">
      <h2 className="text-basic pb-4 font-bold">{'Account ครู'}</h2>
      <div className="bg-white border border-secondary-gray-10" css={css``}>
        <Row gutter={8} className="mt-4 mb-4 mx-4">
          <Col span={10}>
            <div className="text-base font-bold">ตำแหน่ง</div>
          </Col>
          <Col
            span={4}
            className="relative"
            css={css`
              top: 10px;
            `}
          ></Col>
          <Col span={5}>
            <div className="text-base font-bold ">จำนวนครู</div>
            {/* <Button onClick={() => handleExcelDownload()}>download</Button> */}
          </Col>
          <Col
            span={5}
            // className="relative "
            css={css`
              top: 10px;
            `}
          ></Col>
        </Row>
        <Divider className="my-0" />
        <Row
          gutter={8}
          className="mt-4 mb-4 mx-4"
          css={css``}
          // key={classroom.classroomId}
        >
          <Col span={10} className="flex items-center text-center">
            <p className="font-bold text-left">{'ครู'}</p>
          </Col>
          <Col
            span={4}
            // className="relative "
            css={css`
              top: 10px;
            `}
          ></Col>
          <Col
            span={5}
            //   className=" justify-center items-center"
            //className="my-0"
            className="flex items-center text-center"
          >
            <Input
              className="rounded-xl h-10"
              type={'number'}
              value={numOfNewTeacherTemp}
              min={0}
              onChange={(e) => {
                //console.log("จำนวนครู  = " + e.target.value)
                if (parseInt(e.target.value))
                  setNumOfNewTeacherTemp(parseInt(e.target.value));
                //  else {
                //  }
                //console.log(e.target.value)
                //setNumberOfClassroomStudent(classroom.classroomId, e.target.value);
              }}
              placeholder="จำนวนครู"
            />
          </Col>

          <Col
            span={5}
            // className="relative "
            css={css`
              top: 10px;
            `}
          ></Col>
        </Row>
        <Divider className="my-0" />
      </div>
      <Row className="my-4">
        <Col span={20}></Col>
        <Col span={4} className="flex justify-end items-right">
          <Button
            className="flex items-center text-base border-none font-bold text-white"
            css={css`
              background-color: #f99d34;
              :hover {
                background-color: #fcbd10;
              }
              height: 40px;
              border-radius: 10px;
            `}
            disabled={numOfNewTeacherTemp > 0 ? false : true} //if not fill number on table temp email let it disable
            onClick={handleGenTeacherTemp}
          >
            บันทึกข้อมูล
          </Button>
        </Col>
      </Row>
      <Modal
        visible={isSubmitVisible}
        width={400}
        footer={null}
        closable={false}
        //onCancel={() => setIsSubmitVisible(false)}

        centered
        css={css`
          .ant-modal-body {
            padding: 0;
          }
        `}
      >
        <div className="flex flex-col justify-center items-center pt-2 pb-2 px-2">
          <SuccessIcon className="w-16 h-16 mb-2" />

          <h3 className="font-bold text-xl mb-3 text-center">
            {'Gen Temp E-mail ครูเสร็จสิ้น!'}
          </h3>
          <p className="text-sm text-center">
            {'ระบบทำการเพิ่ม Temp E-mail ครู'}
          </p>
          <p className="text-sm text-center">
            {'จำนวน ' + numOfNewTeacherTemp + ' คน ลงในรายการครูทั้งหมดแล้ว'}
          </p>
          <div className="mt-8">
            <div className="flex justify-center gap-4 m-auto">
              <PrimaryButton
                className="w-24"
                onClick={() => {
                  setIsSubmitVisible(false);
                  setNumOfNewTeacherTemp(0);
                  setOnRefresh(true);
                }}
              >
                {'ตกลง'}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
