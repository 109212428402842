import React, { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation
} from 'react-router-dom';

import { AcademicResultPage } from 'pages/AcademicResultPage';
import { AdminUserGuidePage } from 'pages/AdminUserGuidePage';
import { AssignmentOverallClassPeriodDetailPage } from 'pages/AssignmentOverallClassPeriodDetailPage';
import { AssignmentOverallClassPeriodsPage } from 'pages/AssignmentOverallClassPeriodsPage';
import { AssignmentOverallPage } from 'pages/AssignmentOverallPage';
import { ClassroomDetailPage } from 'pages/ClassroomDetailPage';
import { ClassroomManagementPage } from 'pages/ClassroomManagementPage';
import { CreateClassroomPage } from 'pages/CreateClassroomPage';
import { DashBoardPage } from 'pages/DashBoardPage';
import { Domain } from 'feature/common/global';
import { EvaluationPage } from 'pages/EvaluationPage';
import { ExchangeTokenPage } from 'pages/ExchangeTokenPage';
import { GlobalConfirmationModal } from 'feature/common/components/GlobalConfirmationModal';
import { MediaDetailPage } from './pages/MediaDetailPage';
import { MediaManagementForClassroomsPage } from 'pages/MediaManagementForClassroomsPage';
import { MediaManagementPage } from 'pages/MediaManagementPage';
import { Navbar } from 'feature/common/components/Navbar/Navbar';
import { SchoolAdminManagementPage } from 'pages/SchoolAdminManagementPage';
import { Sidebar } from 'feature/common/components/Sidebar';
import { SidebarMenuKey } from 'feature/common/components/sidebar.type';
import { StudentManagementPage } from 'pages/StudentManagementPage';
import { StudentUserGuidePage } from 'pages/StudentUserGuidePage';
import { TeacherManagementPage } from 'pages/TeacherManagementPage';
import { TeacherUserGuidePage } from 'pages/TeacherUserGuidePage';
import { UserGuidePage } from 'pages/UserGuidePage';
import { UserMeDto } from '@aksorn/teaching-backoffice-sdk';
import { VercelLoginPage } from './pages/VercelLoginPage';
import env from '@beam-australia/react-env';
import { getMe } from 'api/user/getMe';
import { getMyProfile } from 'api/user/getMyProfile';
import { observer } from 'mobx-react-lite';
import { userStore } from 'stores/userStore';

const PrivateRoute = observer(
  ({ userGuide, children, withSidebar = true, ...rest }: any) => {
    const { token, setMe, setTeachingProfile } = userStore;

    const location = useLocation();

    if (!token && env('VERCEL_GIT_REPO_ID')) {
      window.location = `/vercel-login/${env('VERCEL_GIT_REPO_ID')}` as any;
      return null;
    }

    const getUserProfile = async () => {
      try {
        const me = await getMe();
        setMe(me);
        try {
          const myProfile: UserMeDto = await getMyProfile();
          setTeachingProfile(myProfile);
        } catch (error) {
          console.error(error);
          // case no access for school-admin
          /*
          if (error.response.status === 401) {
            appStore.confirmModal({
              title: (
                <>
                  <div className="flex justify-center">
                    <WarningIcon />
                  </div>
                  <div className="flex justify-center text-xl font-medium my-4">
                    ขออภัย
                  </div>
                  <div className="text-sm text-mono-gray-100 flex justify-center">
                    คุณไม่มีสิทธิ์เข้าถึงเว็บไซต์นี้
                  </div>
                </>
              ),
              onSubmit: () => {
                window.location = `${env('TEACHING_WEB_URL')}?logout=1` as any;
              },
              submitText: 'ตกลง'
            });
          }
          */
        }
      } catch (error) {
        // case token invalid or expired
        /*
        if (error.response.status === 404) {
          localStorage.removeItem('token');
          window.location = `${env('TEACHING_WEB_URL')}?logout=1` as any;
        } else {
          if (error.message === 'Request aborted') {
            // Firefox bug when redirect before getMe() success
          } else {
            await logout();
            window.location.reload();
          }
        }
        */
      }
    };

    useEffect(() => {
      console.log('check token');
      if (token) {
        getUserProfile();
      } else {
        const pathname = location.pathname.split('/')[1];

        const redirectPath = Object.values(SidebarMenuKey).includes(
          pathname as any
        )
          ? pathname
          : SidebarMenuKey.DASHBOARD;

        // window.location = `${env('TEACHING_WEB_URL')}/login?domain=${
        //   Domain.SCHOOL_ADMIN
        // }&r=${redirectPath}` as any;
        window.location = `${env('TEACHING_WEB_URL')}/auth?domain=${Domain.SCHOOL_ADMIN}&redirectPath=${redirectPath}` as any;
      }
    }, [token, userGuide]);

    return (
      <>
        {withSidebar && !userGuide && <Sidebar />}
        <Route {...rest}>{children}</Route>
      </>
    );
  }
);

const PublicRoute = ({
  userGuide,
  children,
  withSidebar = true,
  ...rest
}: any) => {
  return (
    <>
      {withSidebar && !userGuide ? <Sidebar /> : null}
      <Route {...rest}>{children}</Route>
    </>
  );
};

function App() {
  return (
    <Router>
      <Navbar />
      <div className="min-h-screen w-full flex mt-12 md:mt-16">
        <Switch>
          <PrivateRoute exact path="/media-management">
            <MediaManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/media-management/:productId/:schoolYearId">
            <MediaDetailPage />
          </PrivateRoute>
          <PrivateRoute exact path="/create-classroom">
            <CreateClassroomPage />
          </PrivateRoute>
          <PrivateRoute exact path="/classroom-management">
            <ClassroomManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/media-management-for-classroom">
            <MediaManagementForClassroomsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/classroom-management/:classroomId">
            <ClassroomDetailPage />
          </PrivateRoute>
          <PrivateRoute exact path="/student-management">
            <StudentManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/teacher-management">
            <TeacherManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/school-admin-management">
            <SchoolAdminManagementPage />
          </PrivateRoute>
          <PrivateRoute exact path="/academic-results-management">
            <AcademicResultPage />
          </PrivateRoute>
          <PrivateRoute exact path="/evaluation">
            <EvaluationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/assignment-overall">
            <AssignmentOverallPage />
          </PrivateRoute>
          <PrivateRoute exact path="/assignment-overall/:classroomId">
            <AssignmentOverallClassPeriodsPage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/assignment-overall/:classroomId/:classPeriodId"
          >
            <AssignmentOverallClassPeriodDetailPage />
          </PrivateRoute>
          <PublicRoute exact path="/exchange-token">
            <ExchangeTokenPage />
          </PublicRoute>
          <PublicRoute exact path="/vercel-login/:vercelId">
            <VercelLoginPage />
          </PublicRoute>
          <PrivateRoute exact path="/user-guide" userGuide={true}>
            <UserGuidePage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user-guide/teacher-guide/:nodeId/:topic"
            userGuide={true}
          >
            <TeacherUserGuidePage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user-guide/admin-guide/:nodeId/:topic"
            userGuide={true}
          >
            <AdminUserGuidePage />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user-guide/student-guide/:nodeId/:topic"
            userGuide={true}
          >
            <StudentUserGuidePage />
          </PrivateRoute>
          <PrivateRoute path="/">
            <DashBoardPage />
          </PrivateRoute>
        </Switch>
      </div>
      <GlobalConfirmationModal />
    </Router>
  );
}

export default App;
