export enum SchoolAdminListSortBy {
  NAME_ASC = 'nameAsc',
  NAME_DESC = 'nameDesc',
  EMAIL_ASC = 'emailAsc',
  EMAIL_DESC = 'emailDesc',
  ROLE_ASC = 'roleAsc',
  ROLE_DESC = 'roleDesc',
  LAST_LOGGED_IN_AT_ASC = 'lastLoggedInAtAsc',
  LAST_LOGGED_IN_AT_DESC = 'lastLoggedInAtDesc'
}
