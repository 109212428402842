import { LearningOutcomeAssignmentSortBy } from './class-period-detail';

export type AssignmentUnitListDto = {
  assignmentUnitId: string;
  label: string;
  unitNo: number;
};

export type PrePostTestStatisticGetDto = {
  pre: any;
  post: any;
};

export type PrePostTestUserListDto = {
  classroomStudentId: string;
  classroomStudentNumber: string;
  studentCode: string;
  title: string;
  firstName: {
    th: string;
    en: string;
  };
  lastName: {
    th: string;
    en: string;
  };
  preScore: number;
  postScore: number;
};

export const MOCK_ASSIGNMENT_UNIT_PRE_POST_TEST_USERS = [
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    classroomStudentNumber: '11',
    studentCode: '15413',
    title: 'ด.ญ.',
    firstName: {
      th: 'จิ๊ดริ๊ด',
      en: 'undefined'
    },
    lastName: {
      th: 'ตัวน้อย',
      en: 'undefined'
    },
    preScore: 30.5,
    postScore: 76.9
  },
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afb1',
    classroomStudentNumber: '12',
    studentCode: '10043',
    title: 'ด.ช.',
    firstName: {
      th: 'เอกวิน',
      en: 'undefined'
    },
    lastName: {
      th: 'โคตรคูล',
      en: 'undefined'
    },
    preScore: 45.5,
    postScore: 56.9
  },
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afc3',
    classroomStudentNumber: '14',
    studentCode: '10023',
    title: 'ด.ช.',
    firstName: {
      th: 'เอกวิน2',
      en: 'undefined'
    },
    lastName: {
      th: 'โคตรคูล2',
      en: 'undefined'
    },
    preScore: 48.4,
    postScore: 51.9
  }
];

export const MOCK_ASSIGNMENT_UNITS: AssignmentUnitListDto[] = [
  {
    assignmentUnitId: '6e7384a0-5967-11eb-8065-f3d26001fd1f',
    label: 'หน่วยการเรียนรู้ที่ 1 แสงและเงา',
    unitNo: 1
  },
  {
    assignmentUnitId: '6e7384a0-5967-11eb-8065-f3d26001fd1f',
    label: 'หน่วยการเรียนรู้ที่ 2 กฎของแรง',
    unitNo: 2
  }
];

export const MOCK_LEARNING_OUTCOME_OVERALL_OVERVIEW = {
  classPeriod: {
    averageScore: 32.5,
    maxScore: 0,
    numberOfSubmissions: 0
  },
  gradeLevel: {
    averageScore: 38,
    maxScore: 60,
    numberOfSubmissions: 0
  }
};

export const MOCK_CLASS_PERIOD = {
  classPeriodId: 'e52f1d0d-8d12-47bd-8121-42a304cd3624',
  classPeriodName: 'วิทยาศาสตร์์ ป.6/1',
  classroomId: 'b9f87c6b-dddb-4170-8d2d-92add70c9c2c',
  classroomName: '1',
  gradeLevel: {
    gradeLevelId: 'c08d59fb-4184-4d10-bb23-df7f9e4b7b8b',
    name: { th: 'ป.6', en: 'Grade 6' }
  },
  subject: {
    name: { th: 'วิทยาศาสตร์', en: 'Science' },
    subjectGroup: {
      subjectGroupId: 'f4b7d03b-304d-4a07-90a0-52157daa2ee9',
      name: { th: 'วิทยาศาสตร์์', en: 'Science' }
    },
    subjectId: 'a0204206-3804-4d04-89f2-80652275e74d'
  }
};

export const MOCK_LEARNING_OUTCOME_OVERALL_USERS = [
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    classroomStudentNumber: '1',
    studentCode: '18756',
    title: 'นาย',
    firstName: {
      th: 'มารึป่าว',
      en: 'en'
    },
    lastName: {
      th: 'มานะ',
      en: 'en'
    },
    totalScore: 50,
    unitScores: [
      {
        assignmentUnitId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        unitScore: 0
      }
    ]
  },
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    classroomStudentNumber: '2',
    studentCode: '18757',
    title: 'นาง',
    firstName: {
      th: 'มานี',
      en: 'en'
    },
    lastName: {
      th: 'มีหม้อ',
      en: 'en'
    },
    totalScore: 99,
    unitScores: [
      {
        assignmentUnitId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        unitScore: 0
      }
    ]
  },
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    classroomStudentNumber: '3',
    studentCode: '18758',
    title: 'นาง',
    firstName: {
      th: 'มานี',
      en: 'en'
    },
    lastName: {
      th: 'หม้อไม่มีแล้ว',
      en: 'en'
    },
    totalScore: 68,
    unitScores: [
      {
        assignmentUnitId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        unitScore: 0
      }
    ]
  },
  {
    classroomStudentId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    classroomStudentNumber: '4',
    studentCode: '18759',
    title: 'นาย',
    firstName: {
      th: 'มานะ',
      en: 'en'
    },
    lastName: {
      th: 'แปลว่าอะไร',
      en: 'en'
    },
    totalScore: 54,
    unitScores: [
      {
        assignmentUnitId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        unitScore: 0
      }
    ]
  }
];

export const MOCK_ASSIGNMENT_UNIT_PRE_POST_TEST_STATS = {
  pre: {
    classPeriod: {
      averageScore: 38.7,
      maxScore: 70.5,
      numberOfSubmissions: 17
    },
    gradeLevel: {
      averageScore: 23.5,
      maxScore: 90.5,
      numberOfSubmissions: 40
    }
  },
  post: {
    classPeriod: {
      averageScore: 48.1,
      maxScore: 77.5,
      numberOfSubmissions: 32
    },
    gradeLevel: {
      averageScore: 53.2,
      maxScore: 90.1,
      numberOfSubmissions: 91
    }
  }
};

export const LEARNING_OUTCOME_ASSIGNMENT_SORT_MAPPER = {
  [LearningOutcomeAssignmentSortBy.CLASSROOM_STUDENT_NUMBER_ASC]: 'เลขที่',
  [LearningOutcomeAssignmentSortBy.NAME_ASC]: 'ชื่อ',
  [LearningOutcomeAssignmentSortBy.TOTAL_SCORE_DESC]: 'คะแนน มาก - น้อย',
  [LearningOutcomeAssignmentSortBy.TOTAL_SCORE_ASC]: 'คะแนน น้อย - มาก'
};
