import { ASSIGNMENT_OVERALL_DETAIL_MENU } from 'feature/assignment-overall/const';
import { AssignmentOverallDetailMenu } from 'feature/assignment-overall/assignment-overall';
import { MenuButton } from 'feature/common/components/MenuButton';
import React from 'react';

interface AssignmentOverallClassPeriodDetailMenuTabProps {
  menu: AssignmentOverallDetailMenu;
  setMenu: (menu: AssignmentOverallDetailMenu) => void;
}
export const AssignmentOverallClassPeriodDetailMenuTab = ({
  menu,
  setMenu
}: AssignmentOverallClassPeriodDetailMenuTabProps) => {
  return (
    <>
      <div className="flex justify-start mb-6 text-lg overflow-x-auto md:w-full">
        {Object.values(AssignmentOverallDetailMenu).map((key) => (
          <MenuButton
            key={key}
            isActive={menu === key}
            onClick={() => setMenu(key)}
          >
            {ASSIGNMENT_OVERALL_DETAIL_MENU[key]}
          </MenuButton>
        ))}
      </div>
    </>
  );
};
