import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Spin } from 'antd';
const loadingIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#ED1C24' }} spin />
);

export const Loading = () => <Spin indicator={loadingIcon} />;

interface EvaluationContainerLoadingProps {
  size?: number;
  color?: string;
}

export const EvaluationContainerLoading = ({
  size,
  color
}: EvaluationContainerLoadingProps) => (
  <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: size ? size + 'px' : '1rem',
          color: color ?? '#C2C2C2'
        }}
        spin
      />
    }
  />
);
