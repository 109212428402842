import { ClassPeriodModifyResponse } from '@aksorn/teaching-backoffice-sdk';
import useAxios from 'axios-hooks';

export const useDeleteClassPeriod = () => {
  const [{ loading, error }, fire] = useAxios<ClassPeriodModifyResponse>(
    {
      method: 'DELETE'
    },
    {
      manual: true
    }
  );

  const execute = async (classPeriodId: string) => {
    return fire({
      url: `/class-periods/${classPeriodId}`
    });
  };

  return {
    loading,
    error,
    execute
  };
};
