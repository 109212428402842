import { Divider, Form, Select, Skeleton, Spin } from 'antd';
import {
  PrimaryButton,
  SecondaryButton
} from 'feature/common/components/Button';
import React, { useCallback, useEffect, useState } from 'react';

import { AlertModal } from './components/AlertModal';
import { ClassPeriodLicenseEditType } from './const';
import { FailModal } from './components/FailModal';
import { MediaManagementForClassroomTable } from './components/MediaManagementForClassroomTable';
import { SubmitModal } from './components/SubmitModal';
import { SuccessModal } from './components/SuccessModal';
import { css } from '@emotion/react';
import { getProductLicenseUsagesForClassrooms } from 'api/media/getProductLicenseUsages';
import { updateClassPeriodsAndClassPeriodLicenses } from 'api/media/updateClassPeriodsAndClassPeriodLicenses';
import { useCurrentSchoolYear } from 'hooks/school-year/useCurrentSchoolYear';
import { useSchoolYears } from 'hooks/school-year/useSchoolYears';

const { Option } = Select;

export const MediaManagementForClassrooms = () => {
  const [selectedSchoolYearId, setSelectedSchoolYearId] = useState('');
  const { data: schoolYears, loading: schoolYearsLoading } = useSchoolYears();
  const { data: currentSchoolYears } = useCurrentSchoolYear();
  const [divRefCurrent, setDivRefCurrent] = useState<HTMLDivElement>();

  const [isAlertModalVisible, setIsAlertModalVisible] = useState<boolean>(
    false
  );
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState<boolean>(
    false
  );
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(
    false
  );
  const [isFailModalVisible, setIsFailModalVisible] = useState<boolean>(false);

  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [productLicenseUsageList, setProductLicenseUsageList] = useState<any>(
    null
  );
  const [classroomNameList, setClassroomNameList] = useState<any>([]);
  const [selectedClassrooms, setSelectedClassrooms] = useState<any[]>([]);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const maxSelectedClassrooms = 10;

  const divRef = useCallback((element: HTMLDivElement) => {
    if (element) {
      setDivRefCurrent(element);
    }
  }, []);

  const fetchData = async (schoolYearId: string) => {
    let newParams: any = { schoolYearId: schoolYearId };
    setTableLoading(true);
    try {
      const { data: tableData } = await getProductLicenseUsagesForClassrooms(
        newParams
      );
      // console.log('tableData', tableData);
      setClassroomNameList(tableData.classroomNames);
      var newProductLicenseUsageList = tableData.productLicenseUsages;
      var gradeLevelsUsage = new Set();

      newProductLicenseUsageList.forEach((newProductLicenseUsage: any) => {
        var newClassroomNames: any = {};
        newProductLicenseUsage.classroomNames.forEach(
          (classroomNameData: any) => {
            newClassroomNames[
              classroomNameData.classroomName
            ] = classroomNameData;
          }
        );
        newProductLicenseUsage.classroomNames = newClassroomNames;
        const currentGradeLevel =
          newProductLicenseUsage.gradeLevel.gradeLevelId;
        if (gradeLevelsUsage.has(currentGradeLevel)) {
          newProductLicenseUsage['rowSpan'] = 0;
        } else {
          const occurCount = newProductLicenseUsageList.filter(
            (eachProductLicenseUsage: any) =>
              eachProductLicenseUsage.gradeLevel.gradeLevelId ===
              currentGradeLevel
          );
          newProductLicenseUsage['rowSpan'] = occurCount.length;
          gradeLevelsUsage.add(currentGradeLevel);
        }
      });

      // console.log('newProductLicenseUsageList', newProductLicenseUsageList);
      setProductLicenseUsageList(newProductLicenseUsageList);
    } catch (error) {
      console.log('error', error);
    } finally {
      setSelectedClassrooms([]);
      setTableLoading(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);
    try {
      const dataListForCreateWithoutClassPeriodId = selectedClassrooms
        .filter(
          (selectedClassroom) =>
            selectedClassroom.editType === ClassPeriodLicenseEditType.CREATE &&
            !selectedClassroom.classPeriodId
        )
        .map((selectedClassroom) => ({
          classroomId: selectedClassroom.classroomId,
          subjectId: selectedClassroom.subjectId,
          classPeriodName: selectedClassroom.classPeriodName
        }));

      const dataListForCreateWithClassPeriodId = selectedClassrooms
        .filter(
          (selectedClassroom) =>
            selectedClassroom.editType === ClassPeriodLicenseEditType.CREATE &&
            !!selectedClassroom.classPeriodId
        )
        .map((selectedClassroom) => ({
          classPeriodId: selectedClassroom.classPeriodId,
          schoolLicenseIds: selectedClassroom.schoolLicenseIds
        }));

      const dataListForDelete = selectedClassrooms
        .filter(
          (selectedClassroom) =>
            selectedClassroom.editType === ClassPeriodLicenseEditType.DELETE
        )
        .map((selectedClassroom) => ({
          classPeriodId: selectedClassroom.classPeriodId,
          classPeriodLicenseIds: selectedClassroom.classPeriodLicenseIds
        }));

      const dataList = {
        dataListForCreateClassPeriods:
          dataListForCreateWithoutClassPeriodId ?? [],
        dataListForCreateClassPeriodLicenses:
          dataListForCreateWithClassPeriodId ?? [],
        dataListForDeleteClassPeriodLicenses: dataListForDelete ?? []
      };

      // console.log('dataList', dataList)

      await updateClassPeriodsAndClassPeriodLicenses(dataList);
      setIsSuccessModalVisible(true);
      fetchData(selectedSchoolYearId);
    } catch (error) {
      setIsFailModalVisible(true);
      console.log('error', error);
    } finally {
      setSubmitLoading(false);
      setIsSubmitModalVisible(false);
    }
  };

  useEffect(() => {
    if (!schoolYearsLoading && currentSchoolYears) {
      setSelectedSchoolYearId(currentSchoolYears.schoolYearId);
      fetchData(currentSchoolYears.schoolYearId);
    }
  }, [schoolYearsLoading, currentSchoolYears]);

  /*
  useEffect(() => {
    console.log('selectedClassrooms', selectedClassrooms);
  }, [selectedClassrooms]);
  */

  return (
    <div
      css={css`
        max-width: 100%;
      `}
      ref={divRef}
    >
      <div className="flex justify-between h-10">
        <div className="flex items-center h-8 font-bold text-2xl text-secondary-black">
          จัดการสื่อในระบบ
        </div>
        <div className="flex">
          {schoolYearsLoading ? (
            <div className="flex w-full justify-end mb-5">
              <Skeleton.Input className="mr-0" active />
            </div>
          ) : (
            <Form.Item label="ปีการศึกษา" className="w-48">
              <Select
                value={selectedSchoolYearId}
                onSelect={(schoolYearId) => {
                  setSelectedSchoolYearId(schoolYearId);
                }}
              >
                {schoolYears?.map(({ name, schoolYearId }) => (
                  <Option
                    key={schoolYearId}
                    value={schoolYearId}
                    // disabled={parseInt(name) - 543 !== moment().year()}
                    disabled={schoolYearId !== selectedSchoolYearId}
                  >
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
      </div>
      <Divider className="my-8" />

      <Spin spinning={tableLoading}>
        <MediaManagementForClassroomTable
          productLicenseUsageList={productLicenseUsageList}
          classroomNameList={classroomNameList}
          loading={tableLoading}
          selectedClassrooms={selectedClassrooms}
          setSelectedClassrooms={setSelectedClassrooms}
          maxSelectedClassrooms={maxSelectedClassrooms}
          setIsAlertModalVisible={setIsAlertModalVisible}
        />
        {/* <CreateClassroomForm gradeLevels={gradeLevels} classrooms={classrooms} setIsSubmitModalVisible={() => setIsSubmitModalVisible(true)} setIsAlertModalVisible={() => setIsAlertModalVisible(true)} /> */}
      </Spin>

      <div className="w-full flex justify-end items-center mt-10">
        <div className="flex justify-between items-center">
          <SecondaryButton
            className="w-48 mr-6"
            css={css`
              ${!!selectedClassrooms.length
                ? ''
                : `color: #C7C8D1 !important;
                border-color: #C7C8D1 !important;`}
            `}
            disable={!selectedClassrooms.length}
            disabled={!selectedClassrooms.length}
            onClick={() => {
              setSelectedClassrooms([]);
            }}
          >
            ยกเลิกรายการที่เลือก
          </SecondaryButton>
          <PrimaryButton
            className="w-48"
            css={css`
              color: #ffffff !important;
              background-color: ${!!selectedClassrooms.length
                ? '#F99D34'
                : '#C7C8D1'}!important;
              :hover {
                background-color: ${!!selectedClassrooms.length
                  ? '#F99D34'
                  : '#C7C8D1'}!important;
              }
            `}
            disable={!selectedClassrooms.length}
            disabled={!selectedClassrooms.length}
            onClick={() => {
              setIsSubmitModalVisible(true);
            }}
          >
            บันทึกข้อมูล{' '}
            {!!selectedClassrooms.length
              ? `(${selectedClassrooms.length}/${maxSelectedClassrooms})`
              : ''}
          </PrimaryButton>
        </div>
      </div>

      <AlertModal
        visible={isAlertModalVisible}
        setVisible={setIsAlertModalVisible}
        maxSelectedClassrooms={maxSelectedClassrooms}
      />

      <SubmitModal
        visible={isSubmitModalVisible}
        setVisible={setIsSubmitModalVisible}
        loading={submitLoading}
        handleSubmit={handleSubmit}
      />

      <SuccessModal
        visible={isSuccessModalVisible}
        setVisible={setIsSuccessModalVisible}
      />

      <FailModal
        visible={isFailModalVisible}
        setVisible={setIsFailModalVisible}
      />

      {/* <Modal
                visible={isSubmitModalVisible}
                width={386}
                footer={null}
                closable={false}
                //onCancel={() => setIsSubmitModalVisible(false)}
                centered
                css={css`
                    .ant-modal-body {
                        padding: 0;
                    }
                    `}
            >
                <div className="flex flex-col justify-center items-center pt-6 pb-6 px-14">
                    <SuccessIcon
                        css={css`
               
                    path {
                      fill: #6fcf97;
                    }
                  `}
                    />

                    <h3 className="font-bold text-xl mb-3 text-center">
                        {'บันทึกข้อมูลห้องเรียนเสร็จสิ้น!'}
                    </h3>
                    <p className="text-sm text-center">
                        {
                            'ระบบได้ทำการบันทึกข้อมูลห้องเรียน และ Gen Temp E-mail นักเรียนเรียบร้อย'
                        }
                    </p>
                    <div className="mt-8">
                        <div className="flex justify-center gap-4 m-auto">
                            <PrimaryButton
                                className="w-24"
                                onClick={() => setIsSubmitModalVisible(false)}
                            >
                                {'ตกลง'}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isAlertModalVisible}
                width={386}
                footer={null}
                closable={false}
                //onCancel={() => setIsSubmitModalVisible(false)}
                centered
                css={css`
                    .ant-modal-body {
                        padding: 0;
                    }
                    `}
            >
                <div className="flex flex-col justify-center items-center pt-6 pb-6 px-14">
                    <WarningIcon className="mb-2" />

                    <h3 className="font-bold text-xl mb-3 text-center">
                        {'ไม่สามารถลบห้องเรียนนี้ได้'}
                    </h3>
                    <p className="text-sm text-center">
                        {
                            'เนื่องจากมีนักเรียนอยู่ในห้องเรียนนี้ '
                        }
                    </p>
                    <div className="mt-8">
                        <div className="flex justify-center gap-4 m-auto">
                            <PrimaryButton
                                className="w-24"
                                onClick={() => setIsAlertModalVisible(false)}
                            >
                                {'ตกลง'}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </Modal> */}
    </div>
  );
};
