/** @jsx jsx */

import { Card, Col, Modal, Row } from 'antd';
import { css, jsx } from '@emotion/react';

import { PrimaryButton } from 'feature/common/components/Button';

interface GradeLevelSubjectMediaModalProps {
  visible: boolean;
  title: string;
  mediaList: any;
  onClose: Function;
}

export const GradeLevelSubjectMediaModal = ({
  visible,
  title,
  mediaList,
  onClose
}: GradeLevelSubjectMediaModalProps) => {
  return (
    <Modal
      visible={visible}
      centered
      closable={false}
      width={800}
      footer={null}
      css={css`
        .ant-modal-body {
          padding: 40px 40px 20px 40px;
        }
      `}
    >
      <div className="flex justify-between items-center">
        <p className="h-8 font-bold text-2xl text-secondary-black ">{title}</p>
        <p className={` text-lg`}>ทั้งหมด {mediaList.length} รายการ</p>
      </div>
      <hr className="mt-4 mb-9" />
      <div
        className="overflow-y-auto"
        css={css`
          height: 436px;
        `}
      >
        {!mediaList.length ? (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <img alt="empty" src="/assets/empty-media.png" />
            <span className="text-gray-40 mt-8">ยังไม่มีสื่อเรียน</span>
          </div>
        ) : (
          <Row gutter={[32, 32]} className="w-full h-full">
            {mediaList.map((media: any, key: number) => (
              <Col key={key}>
                <Card
                  className="border-none shadow-lg cursor-pointer"
                  css={css`
                    border-radius: 20px;
                    width: 200px;
                    height: 272px;
                    .ant-card-body {
                      height: 100%;
                      padding: 0;
                    }
                  `}
                >
                  <div className="absolute z-20 top-0 left-0 mt-4 mr-4 ">
                    <img src={`/assets/aol-premium-flag.svg`} />
                  </div>
                  <img
                    src={media.coverImage}
                    className="w-full object-cover object-top"
                    css={css`
                      height: 272px;
                      border-radius: 20px;
                    `}
                    alt="media-placeholder"
                  />
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
      <div className="flex justify-center items-center pt-4">
        <PrimaryButton className="w-60" onClick={onClose}>
          ปิด
        </PrimaryButton>
      </div>
    </Modal>
  );
};
