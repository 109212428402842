import { Form, Select, Skeleton } from 'antd';
/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';
import { InitialDataTable, TableData, TableType } from './class-period-detail';
import {
  LearningUnitHeaderListDto,
  LearningUnitHeaderListResponse,
  LearningUnitOverallHeaderListDto,
  LearningUnitOverallHeaderListResponse,
  LearningUnitOverallUserListDto,
  LearningUnitOverallUserUnitScoreDto,
  LearningUnitUserAssignmentScoreDto,
  LearningUnitUserListDto
} from '@aksorn/teaching-backoffice-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isNumber } from 'lodash';

import { AssignmentInfoModal } from './components/AssignmentInfoModal';
import { AssignmentUnitSelect } from './components/AssignmentUnitSelect';
import { EmptyEvaluation } from './components/EmptyEvaluation';
import { EmptyStudent } from './components/EmptyStudent';
import ExcelJS from 'exceljs';
import { LEARNING_OUTCOME_ASSIGNMENT_SORT_MAPPER } from './const';
import { LearningOutcomeAssignmentSortBy } from './class-period-detail';
import { LearningOutcomeAssignmentTable } from './LearningOutcomeAssignmentTable';
import { StudentDescription } from './components/StudentDescription';
import { TextWrapperToolTip } from './components/TextWrapperToolTip';
import { classPeriodStore } from 'stores/classroomStore';
import { convertArrayToObject } from 'utils/convertArrayToObject';
import dayjs from 'dayjs';
import { evaluationDownloadStore } from 'stores/evaluationDownloadStore';
import { useAssignmentUnits } from 'hooks/assignment-units/useAssignmentUnits';
import { useLearningUnitHeaders } from 'hooks/learning-units/useLearningUnitHeaders';
import { useLearningUnitOverallHeaders } from 'hooks/learning-units/useLearningUnitOverallHeaders';
import { useLearningUnitOverallUsers } from 'hooks/learning-units/useLearningUnitOverallUsers';
import { useLearningUnitUsers } from 'hooks/learning-units/useLearningUnitUsers';
import { useParams } from 'react-router-dom';

import { Tooltip } from 'antd';
import { BounceLoader } from 'react-spinners';

interface GetLearningUnitOverallColumnsProps {
  dataSize: number;
  learningUnitHeaders: LearningUnitOverallHeaderListResponse;
}

interface GetColumnsProps {
  dataSize: number;
  learningUnitHeaders:
    | LearningUnitHeaderListResponse
    | LearningUnitOverallHeaderListResponse;
  selectedAssignmentUnitId: string;
}

interface GetLearningUnitColumnsProps {
  dataSize: number;
  learningUnitHeaders: LearningUnitHeaderListResponse;
}

interface GetLearningUnitColumnChildrenColumnProps {
  indexHeader: number;
  dataSize: number;
  headerSize: number;
  learningUnitHeader: LearningUnitHeaderListDto;
}

interface GetLearningUnitOverallChildrenColumnProps {
  indexHeader: number;
  dataSize: number;
  headerSize: number;
  learningUnitHeader: LearningUnitOverallHeaderListDto;
}

type DefaultTableSize = {
  row: number;
  column: number;
};

type AssignmentInfoModal = {
  assignmentId: string | undefined;
  showAssignmentInfoModal: boolean;
};

export enum TableScoreType {
  ASSIGNMENT = 'assignment',
  ASSIGNMENT_UNIT = 'assignmentUnit'
}

const handleStudentScore = ({
  columns,
  unitScores,
  scoreType
}: {
  columns: any;
  unitScores:
    | LearningUnitOverallUserUnitScoreDto[]
    | LearningUnitUserAssignmentScoreDto[];
  scoreType: TableScoreType;
}) => {
  const columnsLength = columns.length;
  const sliceColumnLength =
    scoreType === TableScoreType.ASSIGNMENT_UNIT ? 2 : 3;
  const headerColumns = columns
    ?.filter((column: any) => column.title)
    ?.slice(sliceColumnLength, columnsLength);

  if (!unitScores || unitScores.length === 0) {
    return headerColumns?.map(() => '-');
  }

  switch (scoreType) {
    case TableScoreType.ASSIGNMENT_UNIT: {
      const unitScoreKey = convertArrayToObject(unitScores, 'assignmentUnitId');
      return headerColumns?.map((headerColumn: any) => {
        if (headerColumn.key) {
          return unitScoreKey[headerColumn.key]?.unitScore ?? '-';
        }
        return '-';
      });
    }
    case TableScoreType.ASSIGNMENT: {
      const unitScoreKey = convertArrayToObject(unitScores, 'assignmentId');
      return headerColumns?.map((headerColumn: any) => {
        if (headerColumn.key) {
          return (
            unitScoreKey[headerColumn.key]?.scoreOverride ??
            unitScoreKey[headerColumn.key]?.score ??
            '-'
          );
        }
        return '-';
      });
    }
  }
};

export const LearningOutcomeAssignment = () => {
  const [
    selectedAssignmentUnitId,
    setSelectedAssignmentUnitId
  ] = useState<string>('');
  const [sortBy, setSortBy] = useState<LearningOutcomeAssignmentSortBy>(
    LearningOutcomeAssignmentSortBy.CLASSROOM_STUDENT_NUMBER_ASC
  );
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<TableData>({
    columns: [],
    dataSource: undefined,
    hasUnit: false,
    hasScore: false,
    fetching: true
  });
  const [
    assignmentInfoModal,
    setAssignmentInfoModal
  ] = useState<AssignmentInfoModal>({
    assignmentId: undefined,
    showAssignmentInfoModal: false
  });
  const [divRefCurrent, setDivRefCurrent] = useState<HTMLDivElement>();
  const [defaultTableSize, setDefaultTableSize] = useState<DefaultTableSize>({
    row: 0,
    column: 0
  });

  const { Option } = Select;
  const { classPeriod } = classPeriodStore;
  const {
    setSheet,
    setIsDisableDownload,
    clearEvaluationDownloads
  } = evaluationDownloadStore;
  const downloadDate = dayjs().add(543, 'years').format('YYYY-MM-DD');

  const { classPeriodId } = useParams() as {
    classPeriodId: string;
  };

  const {
    data: assignmentUnits,
    loading: assignmentUnitsLoading,
    execute: getAssignmentUnits
  } = useAssignmentUnits();

  const {
    data: learningUnitOverallHeaders,
    loading: learningUnitOverallHeadersLoading,
    execute: getLearningUnitOverallHeaders
  } = useLearningUnitOverallHeaders();

  const {
    data: learningUnitOverallUsers,
    loading: learningUnitOverallUsersLoading,
    execute: getLearningUnitOverallUsers
  } = useLearningUnitOverallUsers();

  const {
    data: learningUnitHeaders,
    loading: learningUnitHeadersLoading,
    execute: getLearningUnitHeaders
  } = useLearningUnitHeaders();

  const {
    data: learningUnitUsers,
    loading: learningUnitUsersLoading,
    execute: getLearningUnitUsers
  } = useLearningUnitUsers();

  const selectedAssignmentUnitName = useMemo(() => {
    const selectedAssignmentUnit = assignmentUnits?.find(
      ({ assignmentUnitId }) => assignmentUnitId === selectedAssignmentUnitId
    );
    if (selectedAssignmentUnit) {
      return selectedAssignmentUnit?.label;
    }
    return 'ทั้งหมด';
  }, [selectedAssignmentUnitId]);

  useEffect(() => {
    return () => {
      setIsDisableDownload(false);
      clearEvaluationDownloads();
    };
  }, []);

  useEffect(() => {
    if (classPeriod) {
      getAssignmentUnits({
        // classroomId: classPeriod.classroom.classroomId,
        // subjectId: classPeriod.subject.subjectId
        classroomId: classPeriod?.classroom?.classroomId,
        subjectId: classPeriod?.subject?.subjectId
      });
    }
  }, [classPeriod]);

  useEffect(() => {
    if (tableData.dataSource) {
      if (!tableData?.hasScore && !tableData?.hasUnit) {
        setIsDisableDownload(true);
      } else {
        (async () => {
          setIsDisableDownload(false);
          const buffer = await getSheetBuffer();
          setSheet({
            buffer,
            fileName: `${downloadDate}_${classPeriod?.classroom?.gradeLevel?.name.th}_${classPeriod?.classroom?.classroomName}_${classPeriod?.subject?.name.th}_${selectedAssignmentUnitName}`
          });
        })();
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (divRefCurrent) {
      const headerSize = 108;
      const rowSize = 56;
      const columnSize = 80;
      const firstColumnSize = 336;

      const divHeight = 610;
      const divWidth = divRefCurrent.getBoundingClientRect().width;
      const numberOfColumns =
        (divWidth - (firstColumnSize + columnSize)) / columnSize;
      const numberOfRows = (divHeight - headerSize) / rowSize;

      setDefaultTableSize({
        row: Math.round(numberOfRows),
        column: Math.floor(numberOfColumns)
      });
    }
  }, [divRefCurrent]);

  useEffect(() => {
    if (defaultTableSize?.row && defaultTableSize?.column) {
      getInitialData();
    }
  }, [selectedAssignmentUnitId, sortBy, defaultTableSize]);

  useEffect(() => {
    if (
      !isLoading &&
      !learningUnitOverallHeadersLoading &&
      !learningUnitOverallUsersLoading
    ) {
      setTableData((tableData) => ({
        ...tableData,
        fetching: true
      }));

      initialDataTable({
        rawHeaders: learningUnitOverallHeaders,
        rawUsers: learningUnitOverallUsers,
        tableType: TableType.OVERALL
      });
    }
  }, [
    isLoading,
    learningUnitOverallHeadersLoading,
    learningUnitOverallUsersLoading
  ]);

  useEffect(() => {
    if (
      selectedAssignmentUnitId &&
      !isLoading &&
      !learningUnitHeadersLoading &&
      !learningUnitUsersLoading
    ) {
      setTableData((tableData) => ({
        ...tableData,
        fetching: true
      }));

      initialDataTable({
        rawHeaders: learningUnitHeaders,
        rawUsers: learningUnitUsers,
        tableType: TableType.UNIT
      });
    }
  }, [isLoading, learningUnitHeadersLoading, learningUnitUsersLoading]);

  const divRef = useCallback((element: HTMLDivElement) => {
    if (element) {
      setDivRefCurrent(element);
    }
  }, []);

  const getInitialData = async () => {
    if (selectedAssignmentUnitId) {
      await fetchingLearningUnit();
    } else {
      await fetchingLearningUnitOverall();
    }
  };

  const fetchingLearningUnit = async () => {
    try {
      setIsLoading(true);
      await getLearningUnitHeaders(selectedAssignmentUnitId);
      await getLearningUnitUsers(selectedAssignmentUnitId, {
        sortBy: sortBy as any
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchingLearningUnitOverall = async () => {
    try {
      setIsLoading(true);
      await getLearningUnitOverallHeaders({
        classPeriodId
      });
      await getLearningUnitOverallUsers({
        classPeriodId,
        sortBy: sortBy as any
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const initialDataTable = ({
    rawHeaders,
    rawUsers,
    tableType
  }: InitialDataTable) => {
    const headers = addEmptyColumn({
      rawHeaders,
      tableType
    });
    const users = addEmptyRow({
      rawUsers,
      tableType
    });
    const columns = getColumns({
      selectedAssignmentUnitId,
      learningUnitHeaders: headers,
      dataSize: users.length
    });

    setTableData((tableData) => ({
      ...tableData,
      columns: columns,
      dataSource: users.length
        ? users
        : [{ classroomStudentId: '-', totalScore: '-' }],
      hasScore: users.length > 0,
      hasUnit: headers?.data?.length > 0,
      fetching: false
    }));
  };

  const onCellAssignmentClick = (assignmentId: string) => {
    setAssignmentInfoModal({
      assignmentId,
      showAssignmentInfoModal: true
    });
  };

  const onCloseAssignmentInfoModal = () => {
    setAssignmentInfoModal({
      assignmentId: undefined,
      showAssignmentInfoModal: false
    });
  };

  const addEmptyRow = ({
    rawUsers,
    tableType
  }: {
    rawUsers: LearningUnitOverallUserListDto[] | LearningUnitUserListDto[];
    tableType: TableType;
  }) => {
    if (isEmpty(rawUsers)) return rawUsers;
    if (rawUsers.length === 0) return rawUsers;
    const users = [...rawUsers];

    if (users.length < defaultTableSize.row) {
      const rowRemaining = defaultTableSize.row - users.length;

      if (tableType === TableType.OVERALL) {
        for (let i = 0; i < rowRemaining; i++) {
          (users as LearningUnitOverallUserListDto[]).push({
            classroomStudentId: '',
            classroomStudentNumber: '',
            studentCode: '',
            title: '',
            firstName: { th: '', en: '' },
            lastName: { th: '', en: '' },
            totalScore: undefined,
            unitScores: []
          } as LearningUnitOverallUserListDto);
        }
      }
      if (tableType === TableType.UNIT) {
        for (let i = 0; i < rowRemaining; i++) {
          (users as LearningUnitUserListDto[]).push({
            classroomStudentId: '',
            classroomStudentNumber: '',
            studentCode: '',
            title: '',
            firstName: { th: '', en: '' },
            lastName: { th: '', en: '' },
            totalScore: undefined,
            assignmentScores: []
          } as LearningUnitUserListDto);
        }
      }
    }

    return users;
  };

  const addEmptyColumn = ({
    rawHeaders,
    tableType
  }: {
    rawHeaders:
      | LearningUnitOverallHeaderListResponse
      | LearningUnitHeaderListResponse;
    tableType: TableType;
  }) => {
    if (isEmpty(rawHeaders)) return rawHeaders;
    if (rawHeaders.data.length === 0) return rawHeaders;
    const headers = { ...rawHeaders };
    if (headers.data.length < defaultTableSize.column) {
      const columnRemaining = defaultTableSize.column - headers.data.length;

      if (tableType === TableType.OVERALL) {
        for (let i = 0; i < columnRemaining; i++) {
          (headers as LearningUnitOverallHeaderListResponse).data.push({
            assignmentUnitId: '',
            label: '',
            unitNo: 0,
            maxScore: undefined,
            aksornBookId: '',
            unitNodeId: 0
          } as LearningUnitOverallHeaderListDto);
        }
      }
      if (tableType === TableType.UNIT) {
        for (let i = 0; i < columnRemaining; i++) {
          (headers as LearningUnitHeaderListResponse).data.push({
            assignmentId: '',
            assignmentNo: 0,
            maxScore: undefined
          } as LearningUnitHeaderListDto);
        }
      }
    }

    return headers;
  };

  const getEmptyContentColumns = ({
    dataSize,
    selectedAssignmentUnitId
  }: {
    dataSize: number;
    selectedAssignmentUnitId: string;
  }) => {
    return [
      {
        width: 651,
        align: 'center',
        colSpan: 8,
        render: (
          _value: LearningUnitOverallUserListDto | LearningUnitUserListDto,
          _record: LearningUnitOverallUserListDto | LearningUnitUserListDto,
          index: number
        ) => {
          if (index === 0)
            return {
              children: (
                <EmptyEvaluation
                  hasSelectedAssignmentUnit={!!selectedAssignmentUnitId}
                />
              ),
              props: {
                colSpan: 8,
                rowSpan: dataSize
              }
            };
          else {
            return {
              props: {
                colSpan: 0,
                rowSpan: 0
              }
            };
          }
        }
      }
    ];
  };

  const getLearningUnitChildrenColumns = ({
    dataSize,
    headerSize,
    indexHeader,
    learningUnitHeader
  }: GetLearningUnitColumnChildrenColumnProps) => {
    if (headerSize > 0 && dataSize > 0) {
      return [
        {
          title: learningUnitHeader.assignmentId
            ? isNumber(learningUnitHeader.maxScore)
              ? learningUnitHeader.maxScore.toString()
              : '-'
            : '',
          key: learningUnitHeader.assignmentId,
          dataIndex: 'assignmentScores',
          width: 80,
          align: 'center',
          className: 'cursor-pointer',
          render: (
            assignmentScores: LearningUnitUserAssignmentScoreDto[],
            dataSource: LearningUnitUserListDto
          ) => {
            const assignmentScore = assignmentScores.find(
              (assignmentScores) =>
                assignmentScores.assignmentId ===
                learningUnitHeader.assignmentId
            );
            if (!assignmentScore) {
              return dataSource.classroomStudentId &&
                learningUnitHeader.assignmentId
                ? '-'
                : '';
            }
            return (
              <TextWrapperToolTip
                score={assignmentScore.scoreOverride ?? assignmentScore.score}
                showDecimal
              />
            );
          },
          onHeaderCell: () => {
            return {
              //TODO: [click-score in header table] redirect to assignment
              onClick: () => {
                if (learningUnitHeader.assignmentId) {
                  onCellAssignmentClick(learningUnitHeader.assignmentId);
                }
              }
            };
          },
          onCell: () => {
            return {
              //TODO: [click-score in table] redirect to assignment
              onClick: () => {
                if (learningUnitHeader.assignmentId) {
                  onCellAssignmentClick(learningUnitHeader.assignmentId);
                }
              }
            };
          }
        }
      ];
    } else {
      if (dataSize > 0) {
        return [
          {
            title: learningUnitHeader.assignmentId
              ? isNumber(learningUnitHeader.maxScore)
                ? learningUnitHeader.maxScore.toString()
                : '-'
              : '',
            width: 80,
            dataIndex: 'assignmentScores',
            align: 'center',
            render: (
              _scores: LearningUnitUserAssignmentScoreDto[],
              _dataSource: LearningUnitUserListDto,
              indexRecord: number
            ) => {
              if (indexHeader === 0 && indexRecord === 0) {
                return {
                  children: <EmptyEvaluation hasSelectedAssignmentUnit />,
                  props: {
                    colSpan: headerSize,
                    rowSpan: dataSize
                  }
                };
              } else {
                return {
                  props: {
                    colSpan: 0,
                    rowSpan: 0
                  }
                };
              }
            }
          }
        ];
      } else {
        return [
          {
            title: learningUnitHeader.assignmentId
              ? isNumber(learningUnitHeader.maxScore)
                ? learningUnitHeader.maxScore.toString()
                : '-'
              : '',
            width: 80,
            align: 'center',
            render: () => {
              if (indexHeader === 0) {
                return {
                  children: <EmptyEvaluation hasSelectedAssignmentUnit />,
                  props: {
                    colSpan: headerSize
                  }
                };
              }

              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        ];
      }
    }
  };

  const getLearningUnitColumns = ({
    dataSize,
    learningUnitHeaders
  }: GetLearningUnitColumnsProps) => {
    return learningUnitHeaders.data.map(
      (learningUnitHeader: LearningUnitHeaderListDto, indexHeader: number) => {
        return {
          title: learningUnitHeader.assignmentNo
            ? `K${learningUnitHeader.assignmentNo}`
            : '',
          key: learningUnitHeader.assignmentId,
          width: 80,
          align: 'center',
          editable: true,
          className: 'cursor-pointer',
          children: getLearningUnitChildrenColumns({
            dataSize,
            indexHeader,
            learningUnitHeader,
            headerSize: learningUnitHeaders.data.length
          }),
          onHeaderCell: () => {
            return {
              onClick: () => {
                if (learningUnitHeader.assignmentId) {
                  onCellAssignmentClick(learningUnitHeader.assignmentId);
                }
              }
            };
          }
        };
      }
    );
  };

  const getLearningUnitOverallChildrenColumns = ({
    indexHeader,
    dataSize,
    headerSize,
    learningUnitHeader
  }: GetLearningUnitOverallChildrenColumnProps) => {
    if (headerSize > 0 && dataSize > 0) {
      return [
        {
          title: learningUnitHeader.aksornBookId
            ? isNumber(learningUnitHeader.maxScore)
              ? learningUnitHeader.maxScore.toString()
              : '0'
            : '',
          key: `${learningUnitHeader.aksornBookId}${learningUnitHeader.unitNo}`,
          dataIndex: 'unitScores',
          // width: 80,
          width: 160,
          align: 'center',
          render: (
            unitScores: LearningUnitOverallUserUnitScoreDto[],
            dataSource: LearningUnitOverallUserListDto
          ) => {
            const unitScore = unitScores.find(
              (unitScore) =>
                unitScore.assignmentUnitId ===
                learningUnitHeader.assignmentUnitId
            );
            if (!unitScore) {
              return dataSource.classroomStudentId &&
                learningUnitHeader.aksornBookId
                ? '-'
                : '';
            }
            return (
              <TextWrapperToolTip score={unitScore.unitScore} showDecimal />
            );
          }
        }
      ];
    } else {
      if (dataSize > 0) {
        return [
          {
            title: learningUnitHeader.aksornBookId
              ? isNumber(learningUnitHeader.maxScore)
                ? learningUnitHeader.maxScore.toString()
                : '0'
              : '',
            width: 80,
            dataIndex: 'unitScores',
            align: 'center',
            render: (
              _unitScores: LearningUnitOverallUserUnitScoreDto[],
              _dataSource: LearningUnitOverallUserListDto,
              indexRecord: number
            ) => {
              if (indexHeader === 0 && indexRecord === 0) {
                return {
                  children: <EmptyEvaluation />,
                  props: {
                    colSpan: headerSize,
                    rowSpan: dataSize
                  }
                };
              } else {
                return {
                  props: {
                    colSpan: 0,
                    rowSpan: 0
                  }
                };
              }
            }
          }
        ];
      } else {
        return [
          {
            title: learningUnitHeader.aksornBookId
              ? isNumber(learningUnitHeader.maxScore)
                ? learningUnitHeader.maxScore.toString()
                : '0'
              : '',
            width: 80,
            align: 'center',
            render: () => {
              if (indexHeader === 0) {
                return {
                  children: <EmptyEvaluation />,
                  props: {
                    colSpan: headerSize
                  }
                };
              }
              return {
                props: {
                  colSpan: 0
                }
              };
            }
          }
        ];
      }
    }
  };

  const getLearningUnitOverallColumns = ({
    dataSize,
    learningUnitHeaders
  }: GetLearningUnitOverallColumnsProps) => {
    return learningUnitHeaders.data.map(
      (
        learningUnitHeader: LearningUnitOverallHeaderListDto,
        indexHeader: number
      ) => {
        return {
          // title: learningUnitHeader.unitNo,
          // title: learningUnitHeader.label,
          title: 
          <div style={{maxWidth:'160px'}}
            css={css`
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          `}
          >
            <Tooltip
              placement="top"
              title={
                <span>
                  <BounceLoader size="20" color="white" />
                  <span style={{ marginLeft: '25px' }}>{ learningUnitHeader.label }</span>
                </span>
              }
            >
              { learningUnitHeader.label }
            </Tooltip>
          </div>,
          key: learningUnitHeader.assignmentUnitId,
          width: 80,
          // align: 'center',
          align: 'left',
          editable: false,
          children: getLearningUnitOverallChildrenColumns({
            dataSize,
            indexHeader,
            learningUnitHeader,
            headerSize: learningUnitHeaders.data.length
          })
        };
      }
    );
  };

  const getUnitColumns = ({
    dataSize,
    learningUnitHeaders,
    selectedAssignmentUnitId
  }: GetColumnsProps) => {
    if (selectedAssignmentUnitId) {
      return getLearningUnitColumns({
        dataSize,
        learningUnitHeaders: learningUnitHeaders as LearningUnitHeaderListResponse
      });
    } else {
      return getLearningUnitOverallColumns({
        dataSize,
        learningUnitHeaders: learningUnitHeaders as LearningUnitOverallHeaderListResponse
      });
    }
  };

  const getColumns = ({
    dataSize,
    learningUnitHeaders,
    selectedAssignmentUnitId
  }: GetColumnsProps) => {
    const hasUnit = learningUnitHeaders?.data?.length > 0;
    const hasData = dataSize > 0;

    const contentColumns = hasUnit
      ? getUnitColumns({
          dataSize,
          learningUnitHeaders,
          selectedAssignmentUnitId
        })
      : getEmptyContentColumns({ dataSize, selectedAssignmentUnitId });

    const unitMaxScore = isNumber(
      (learningUnitHeaders as LearningUnitHeaderListResponse).meta?.unitMaxScore
    )
      ? (learningUnitHeaders as LearningUnitHeaderListResponse).meta?.unitMaxScore?.toString()
      : '0';

    const totalMaxScore = isNumber(
      (learningUnitHeaders as LearningUnitOverallHeaderListResponse).meta
        ?.totalMaxScore
    )
      ? (learningUnitHeaders as LearningUnitOverallHeaderListResponse).meta?.totalMaxScore?.toString()
      : '0';

    const baseColumns = [
      {
        key: 'unit',
        title: selectedAssignmentUnitId ? 'งานที่มอบหมาย' : 'หน่วยที่',
        fixed: 'left',
        width: 336,
        children: [
          {
            key: 'classroomStudentId',
            dataIndex: 'classroomStudentId',
            title: 'คะแนนเต็ม',
            width: 336,
            fixed: 'left',
            render: (
              _classroomStudentId: string,
              dataSource:
                | LearningUnitUserListDto
                | LearningUnitOverallUserListDto
            ) => {
              return hasData ? (
                <div>
                  {dataSource.classroomStudentId && (
                    <StudentDescription
                      classroomStudentNumber={dataSource.classroomStudentNumber}
                      studentCode={dataSource?.studentCode}
                      title={dataSource?.title}
                      firstName={dataSource.firstName.th}
                      lastName={dataSource.lastName.th}
                    />
                  )}
                </div>
              ) : (
                <EmptyStudent />
              );
            }
          }
        ]
      },
      {
        key: 'total',
        title: 'รวม',
        fixed: 'left',
        width: 80,
        align: 'center',
        children: [
          {
            title: selectedAssignmentUnitId ? unitMaxScore : totalMaxScore,
            key: 'unitScore',
            dataIndex: 'unitScore',
            width: 80,
            align: 'center',
            fixed: 'left',
            render: (
              unitScore: number,
              dataSource: LearningUnitUserListDto
            ) => {
              const score = selectedAssignmentUnitId
                ? dataSource?.unitScoreOverride ?? unitScore
                : dataSource?.totalScore;
              return (
                <>
                  {dataSource.classroomStudentId && (
                    <TextWrapperToolTip score={score} showDecimal />
                  )}
                </>
              );
            }
          }
        ]
      }
    ];

    const columns = selectedAssignmentUnitId
      ? [
          ...baseColumns,
          {
            key: 'totalAll',
            title: 'คะแนนมอบหมายรวม',
            fixed: 'left',
            width: 80,
            align: 'center',
            children: [
              {
                title: isNumber(learningUnitHeaders.meta.totalMaxScore)
                  ? learningUnitHeaders.meta?.totalMaxScore?.toString()
                  : '-',
                key: 'totalScore',
                dataIndex: 'totalScore',
                width: 80,
                align: 'center',
                fixed: 'left',
                render: (
                  totalScore: number,
                  dataSource: LearningUnitUserListDto
                ) => {
                  const score = dataSource?.totalScoreOverride ?? totalScore;
                  return (
                    <>
                      {dataSource.classroomStudentId && (
                        <TextWrapperToolTip score={score} showDecimal />
                      )}
                    </>
                  );
                }
              }
            ]
          },
          ...contentColumns
        ]
      : [...baseColumns, ...contentColumns];

    return columns;
  };

  const getSheetBuffer = async () => {
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet('Sheet1');
    workSheet.properties.defaultColWidth = 16;
    workSheet.addRow(['ชื่อวิชา', classPeriod?.subject?.name?.th]);
    workSheet.addRow([
      'ชั้นปี/ห้องเรียน',
      `${classPeriod?.classroom?.gradeLevel?.name.th}/${classPeriod?.classroom?.classroomName}`
    ]);
    workSheet.addRow(['หน่วย', selectedAssignmentUnitName]);

    const cellA1 = workSheet.getCell('A1');
    cellA1.font = {
      size: 14,
      bold: true
    };

    const cellA2 = workSheet.getCell('A2');
    cellA2.font = {
      size: 14,
      bold: true
    };

    const cellA3 = workSheet.getCell('A3');
    cellA3.font = {
      size: 14,
      bold: true
    };

    if (tableData?.hasUnit || tableData?.hasScore) {
      workSheet.addRow([
        '',
        '',
        '',
        ...tableData.columns?.map((column) => column?.title || '')
      ]);

      workSheet.addRow([
        'เลขที่',
        'เลขประจำตัว',
        'ชื่อ',
        ...tableData.columns?.map((column) => {
          if (column?.children?.length > 0) {
            return column?.children[0]?.title || '';
          }
          return '';
        })
      ]);

      workSheet.getRow(4).alignment = { horizontal: 'right' };
      workSheet.getRow(5).alignment = { horizontal: 'right' };
    }

    let rows;

    if (tableData?.hasScore) {
      if (selectedAssignmentUnitId) {
        rows = tableData?.dataSource
          ?.filter((student: any) => !!student?.classroomStudentId)
          ?.map((student: any) => {
            return [
              student.classroomStudentNumber,
              student.studentCode || '-',
              `${student.title}${student.firstName?.th} ${student.lastName?.th}`,
              '',
              student.unitScoreOverride ?? '-',
              student.totalScoreOverride ?? '-',
              ...handleStudentScore({
                columns: tableData.columns,
                unitScores: student.assignmentScores,
                scoreType: TableScoreType.ASSIGNMENT
              })
            ];
          });
      } else {
        rows = tableData?.dataSource
          ?.filter((student: any) => !!student?.classroomStudentId)
          ?.map((student: any) => {
            return [
              student.classroomStudentNumber,
              student.studentCode || '-',
              `${student.title}${student.firstName?.th} ${student.lastName?.th}`,
              '',
              student.totalScore ?? '-',
              ...handleStudentScore({
                columns: tableData.columns,
                unitScores: student.unitScores,
                scoreType: TableScoreType.ASSIGNMENT_UNIT
              })
            ];
          });
      }
      workSheet.addRows(rows, 'i');
    }

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  };

  return (
    <React.Fragment>
      <div className="flex justify-between items-center mb-8">
        {isLoading || assignmentUnitsLoading ? (
          <>
            <Skeleton.Input className="w-72 h-10 rounded-md" active />
            <Skeleton.Input className="w-48 h-10 rounded-md" active />
          </>
        ) : (
          tableData.hasScore &&
          tableData.hasUnit && (
            <>
              <AssignmentUnitSelect
                className="w-72"
                assignmentUnits={assignmentUnits}
                selectedAssignmentUnitId={selectedAssignmentUnitId}
                setSelectedAssignmentUnitId={setSelectedAssignmentUnitId}
              />
              <Form.Item label="เรียงตาม" className="w-48">
                <Select
                  value={sortBy}
                  onSelect={(value) => {
                    setSortBy(value as LearningOutcomeAssignmentSortBy);
                  }}
                >
                  {Object.keys(LEARNING_OUTCOME_ASSIGNMENT_SORT_MAPPER).map(
                    (sortBy: any) => (
                      <Option key={sortBy} value={sortBy}>
                        {
                          LEARNING_OUTCOME_ASSIGNMENT_SORT_MAPPER[
                            sortBy as LearningOutcomeAssignmentSortBy
                          ]
                        }
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </>
          )
        )}
      </div>

      <div
        css={css`
          max-width: 100%;
        `}
        ref={divRef}
      >
        <Global
          styles={css`
            .ant-tooltip-inner {
              height: fit-content;
              border-radius: 8px;
              padding: 8px 16px;
              filter: drop-shadow(0px 10px 30px rgba(46, 47, 56, 0.1));
            }
          `}
        />
        <LearningOutcomeAssignmentTable
          isLoading={isLoading}
          tableData={tableData}
          selectedAssignmentUnitId={selectedAssignmentUnitId}
        />
        <AssignmentInfoModal
          visible={assignmentInfoModal.showAssignmentInfoModal}
          assignmentId={assignmentInfoModal.assignmentId}
          onClose={onCloseAssignmentInfoModal}
        />
      </div>
    </React.Fragment>
  );
};
