import {
  ImportExcelSchoolTeacherBulkBody,
  ImportExcelSchoolTeacherBulkResponse
} from '@aksorn/teaching-backoffice-sdk';

import useAxios from 'axios-hooks';

export const useImportExcelValidateSchoolTeachers = () => {
  const [_, fire] = useAxios<ImportExcelSchoolTeacherBulkResponse>(
    {},
    { manual: true }
  );

  const execute = (data: ImportExcelSchoolTeacherBulkBody) => {
    return fire({
      method: 'PUT',
      url: '/import-excel/school-teachers',
      data
    });
  };

  return {
    execute
  };
};
