import { css } from '@emotion/react';

export const CATEGORIES = [
  'ข้อมูลครูประจำชั้น',
  'ข้อมูลห้องเรียน',
  'ข้อมูลนักเรียน',
  'ข้อมูลวิชาเรียนและครูประจำวิชา',
  'ข้อมูลตารางเรียน'
];

// xlsx and xls
export const EXCEL_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
];

export const ProgressStepStyled = css`
  .ant-steps-icon {
    position: absolute;
    top: -0.4rem;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 40% !important;
  }
  .ant-steps-item-tail::after,
  .ant-steps-item-tail {
    max-width: 85px !important;
    width: 100% !important;
    margin-left: 0 !important;
    left: 3.25rem !important;
  }
  .ant-steps-item-icon,
  .ant-steps-item-process {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: fit-content !important;
    height: fit-content !important;
  }
  .ant-steps-item-content {
    width: 100% !important;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    position: static !important;
  }
  .ant-steps-item:last-child {
    flex: 1 1 !important;
  }
  .ant-steps-item-description {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #ffc4c6 !important;
    margin-top: 10px !important;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: #ffc4c6 !important;
  }
  .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #ffc4c6 !important;
  }
`;
