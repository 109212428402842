import {
  LearningUnitOverallUserListDto,
  PrePostTestUserListDto
} from '@aksorn/teaching-backoffice-sdk';
import React, { useEffect, useRef } from 'react';

import { IndividualStudentBarChartProps } from 'feature/common/barchart/barchart';
import ReactECharts from 'echarts-for-react';
import { isNumber } from 'lodash';

const convertStudentObjectToString = (
  student: LearningUnitOverallUserListDto | PrePostTestUserListDto
) => {
  return `${student.classroomStudentNumber},${student.studentCode || '-'}, ${
    student.title || ''
  },${student.firstName.th} ${student.lastName.th}`;
};

export const EmptyStudentBarChart = ({
  className = '',
  students = [],
  dataSource,
  chartMaxScore = 100,
  // Interval: gap of xAxis
  interval,
  grid = {}
}: IndividualStudentBarChartProps) => {
  const eChartsRef = useRef<any>(null);
  const yAxisCategories = (students as Array<
    LearningUnitOverallUserListDto | PrePostTestUserListDto
  >).map((student) => convertStudentObjectToString(student));

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (values: any) {
        const firstValue = values[0];
        const otherValues = values.slice(1, values.length);
        const title = firstValue?.axisValue?.split(',')[2];
        const studentName = firstValue?.axisValue?.split(',')[3];
        let format = title + studentName;
        if (isNumber(firstValue.data)) {
          format += '<br/>' + firstValue?.marker + '     ' + firstValue.data;
        }

        if (otherValues) {
          otherValues.forEach((value: any) => {
            if (isNumber(value.data)) {
              format += '<br/>' + value.marker + '     ' + value.data;
            }
          });
        }
        return format;
      }
    },
    grid: {
      left: grid.left ?? 320,
      right: grid?.right ?? 20,
      bottom: grid?.bottom ?? 10,
      top: grid?.top ?? 30
    },
    xAxis: {
      type: 'value',
      position: 'top',
      max: chartMaxScore > 0 ? chartMaxScore : 100,

      axisLabel: {
        fontFamily: 'Sarabun',
        fontSize: 14,
        color: '#8F91A3'
      },
      axisLine: {
        color: '#E3E4E8'
      },
      ...(interval && { interval })
    },
    yAxis: {
      type: 'category',
      inverse: true,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      data: yAxisCategories,
      axisLabel: {
        width: 300,
        fontFamily: 'Sarabun',
        lineHeight: 20,
        fontSize: 14,
        color: '#2E2F38',
        formatter: function (value: any) {
          const student = value?.split(',');
          const title = student[2];
          const studentName =
            student[3]?.length > 18
              ? student[3]?.substring(0, 15) + '...'
              : student[3];
          return `{ClassroomStudentNumber|${student[0]}}{StudentCode|${
            student[1]
          }}{NameIcon|${studentName?.substring(
            0,
            3
          )}}{StudentName|${title}${studentName}}`;
        },
        rich: {
          ClassroomStudentNumber: {
            width: 40,
            align: 'left'
          },
          StudentCode: {
            width: 96,
            align: 'left'
          },
          NameIcon: {
            height: 24,
            width: 12,
            lineHeight: 24,
            fontSize: 8,
            align: 'left',
            color: '#fff',
            backgroundColor: '#C4C4C4',
            borderRadius: 100,
            padding: [0, 6, 0, 6]
          },
          StudentName: {
            width: 140,
            padding: [0, 0, 0, 12],
            align: 'left'
          }
        }
      }
    },
    series: [
      ...dataSource.map((barChart) => {
        return {
          type: 'bar',
          barGap: 0,
          zlevel: 999,
          barWidth: barChart.barWidth || 24,
          itemStyle: { color: barChart.barColor },
          label: {
            show: false
          },
          data: [0]
        };
      })
    ]
  };

  useEffect(() => {
    eChartsRef?.current?.getEchartsInstance().clear();
    eChartsRef?.current?.getEchartsInstance().setOption(option);
  }, [option]);

  const componentHeight = students?.length * 72 + 8;
  return (
    <ReactECharts
      className={className}
      option={option}
      style={{ height: componentHeight }}
      ref={eChartsRef}
    />
  );
};
