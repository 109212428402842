import { ConfirmModalProps, appStore } from 'stores/appStore';
import { GhostButton, PrimaryButton } from './Button';
import React, { useState } from 'react';

import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';

export const ConfirmModal: React.FC<ConfirmModalProps> = observer(
  (confirmation) => {
    const {
      title,
      description,
      onSubmit,
      submitText,
      onCancel,
      cancelText
    } = confirmation;
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    return (
      <>
        <Modal
          visible={true}
          centered
          footer={null}
          closable={false}
          width={368}
        >
          <div className="text-lg font-normal text-center">
            {title}
            {description}
          </div>
          <div className="flex justify-center mt-6">
            {cancelText && (
              <GhostButton
                className="mr-4 h-12"
                onClick={async () => {
                  if (onCancel) {
                    await onCancel();
                  }
                  appStore.hideConfirmation(confirmation);
                }}
              >
                {cancelText}
              </GhostButton>
            )}
            {submitText && (
              <PrimaryButton
                loading={loadingSubmit}
                onClick={async (_e: any) => {
                  setLoadingSubmit(true);
                  if (onSubmit) {
                    await onSubmit();
                  }
                  appStore.hideConfirmation(confirmation);
                  setLoadingSubmit(false);
                }}
              >
                {submitText}
              </PrimaryButton>
            )}
          </div>
        </Modal>
      </>
    );
  }
);

export const GlobalConfirmationModal = observer(() => {
  return (
    <>
      {appStore.confirmations.map((props, idx) => {
        return (
          <ConfirmModal
            key={'confirmation-modal' + idx + props?.id}
            {...props}
          />
        );
      })}
    </>
  );
});
