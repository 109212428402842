import { NavLink, useLocation } from 'react-router-dom';
import { css, jsx } from '@emotion/react';

import { Divider } from 'antd';
import { NavbarProfileItem } from './NavbarProfileItem';
import { NavbarType } from './navbar.type';
import { ReactComponent as Shop } from 'assets/shop.svg';
import { SidebarMenuKey } from '../sidebar.type';
/** @jsx jsx */
import env from '@beam-australia/react-env';

type NavbarItemProps = {
  to?: string;
  locationUrl?: string;
  children?: any;
  onClick?: () => {};
};

export const NavbarItem = ({ to, locationUrl, children }: NavbarItemProps) => {
  const location = useLocation();

  if (!to)
    return (
      <a
        href={locationUrl}
        className="flex items-center text-center text-lg hover:text-primary transition-colors px-4 h-full cursor-pointer"
        css={css`
          color: ${location?.pathname === to ? '#ED1C24' : '#5C5E70'};
          path {
            fill: ${location?.pathname === to ? '#ED1C24' : '#5C5E70'};
            transition-property: fill;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
          }
          :hover {
            path {
              fill: #ed1c24;
            }
          }
        `}
      >
        {children}
      </a>
    );

  return (
    <NavLink
      exact
      to={to as any}
      activeStyle={{
        fontWeight: 'bold',
        borderBottomWidth: '4px',
        borderColor: '#ED1C24',
        color: '#ED1C24'
      }}
      isActive={(match, location) => {
        const path = location?.pathname?.split('/')[1];
        if (location?.pathname === to) {
          return true;
        } else if (
          to === '/' &&
          Object.values(SidebarMenuKey).includes(path as any)
        ) {
          return true;
        }
        return false;
      }}
      className="flex items-center text-center text-lg hover:text-primary transition-colors px-4 h-full"
      css={css`
        color: #5c5e70;
        path {
          fill: ${location?.pathname === to ? '#ED1C24' : '#5C5E70'};
          transition-property: fill;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
        }
        :hover {
          path {
            fill: #ed1c24;
          }
        }
      `}
    >
      {children}
    </NavLink>
  );
};
interface NavbarProps {
  type?: NavbarType;
}

export const Navbar = ({ type = NavbarType.AUTH }: NavbarProps) => {
  switch (type) {
    case NavbarType.AUTH:
      return (
        <div className="flex justify-between items-center w-full bg-white px-6 shadow-lg fixed top-0 z-50 h-16">
          <div className="flex items-center h-full">
            <img
              // className="w-6 h-6 md:w-10 md:h-10 cursor-pointer mr-8"
              // src="/assets/aksorn-plus-logo.png"
              className="h-6 md:h-8 leading-16 cursor-pointer mr-4 relative"
              src="/assets/aksorn-plus-logo-new.png"
              alt="Aksorn Logo"
            />
            <NavbarItem to="/">จัดการระบบ</NavbarItem>
            {/* <NavbarItem to="/user-guide">คู่มือการใช้งานระบบ</NavbarItem> */}
          </div>
          <div className="flex items-center h-full">
            {/* <NavbarItem locationUrl={`${env('TEACHING_WEB_URL')}/course`}>
              <Shop className="mr-2" />
              <span className="text-lg">อักษรสโตร์</span>
            </NavbarItem>
            <Divider className="h-8 bg-gray-10 mx-4" type="vertical" /> */}
            <NavbarProfileItem />
          </div>
        </div>
      );
    default:
      return null;
  }
};
